import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from /*   reactReduxFirebase,
 */ "react-redux-firebase";
import { connect } from "react-redux";
/* import { Redirect } from "react-router-dom";
import { isLoaded } from "react-redux-firebase";
import Create from "../candidates/Create";
import ListProjects from "./ListProjects";
import ListCandidates from "./ListCandidates";
import Wait from "../layout/Wait";
import CampaignModal from "./CampaignModal"; */
import { indexCandidateInCampaigns } from "../../helpers/searchApi";

//import Create from "../organisation/Create";
import { notifyUser, setProgressBar } from "../../actions/notifyActions";

import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  /* RefinementList, */
  connectHits,
  Pagination,
} from "react-instantsearch-dom";

const Hits = ({ hits }) => (
  <ol>
    {hits.map(
      (hit) => (
        <li key={hit.objectID}>
          <a
            href={"/candidate/edit/" + hit.objectID}
            rel="noopener noreferrer"
            target="_blank"
          >
            {hit.profile.name}
          </a>
        </li>
      )
      //console.log(hit)
    )}
  </ol>
);

class SearchIndex extends Component {
  state = { searchQuery: "google" };

  indexData() {
    //one time indexation for existing profiles in db
    console.log("Indexation => ", "calling indexation function");
    indexCandidateInCampaigns().then((res) => {
      console.log(res);
    });
  }

  render() {
    const searchClient = algoliasearch(
      "WZVXTOBRTL",
      "98f5c49d2c7c867fcb4171c648375e80"
    );

    const CustomHits = connectHits(Hits);

    return (
      <React.Fragment>
        <InstantSearch
          showMore
          indexName="prod_Candidates"
          searchClient={searchClient}
        >
          <SearchBox
            className="tw-block tw-w-full tw-pl-10 tw-pr-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-leading-5 tw-bg-white tw-shadow-sm tw-placeholder-gray-500 focus:tw-outline-none focus:tw-placeholder-gray-400 focus:tw-ring-1 focus:tw-ring-blue-600 focus:tw-border-blue-600 sm:tw-text-sm"
            showLoadingIndicator
          />
          {/* <RefinementList
            attribute="profile.campaign_id"
            defaultRefinement={["hSnX8lAgfZCj84eQEEsh"]}
          />
          <RefinementList
            attribute="skills.skills"
            limit={5}
            showMore
            searchable
          /> */}
          <CustomHits />

          <Pagination />
        </InstantSearch>

        <button
          className="btn btn-primary  p-2"
          data-toggle="tooltip"
          data-placement="top"
          title="Edit Organisation"
          onClick={() => {
            this.indexData();
          }}
        >
          Hello click me to launch index
        </button>
      </React.Fragment>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  connect(
    (state, props) => ({
      auth: state.firebase.auth,
      notify: state.notify,
    }),
    { notifyUser, setProgressBar }
  )
)(SearchIndex);
