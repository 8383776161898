import React, { Component } from "react";
import { firebaseConnect, firestoreConnect } from "react-redux-firebase";
import T from "i18n-react";
import { compose } from "redux";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { notifyUser } from "../../actions/notifyActions";
import PropTypes from "prop-types";

class Notifications extends Component {
  constructor(props) {
    super(props);
    // Create refs
    this.task = React.createRef();
    this.mention = React.createRef();
    this.project = React.createRef();
    this.activity = React.createRef();
    this.newsletter = React.createRef();
    this.feature = React.createRef();
    this.minor = React.createRef();
    this.submit = React.createRef();
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { user_id, firestore, notifyUser } = this.props;
    this.submit.current.setAttribute("disabled", "disabled");

    // Updated user
    const userSettings = {
      task: this.task.current.checked,
      mention: this.mention.current.checked,
      project: this.project.current.checked,
      activity: this.activity.current.checked,
      newsletter: this.newsletter.current.checked,
      feature: this.feature.current.checked,
      minor: this.minor.current.checked,
    };

    firestore
      .update(
        { collection: "users_email_settings", doc: user_id },
        userSettings
      )
      .then(() => {
        this.submit.current.removeAttribute("disabled");
        notifyUser(T.translate("settings.saved"), "success");
      });
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.checked });

  render() {
    if (!isLoaded(this.props.users_email_settings)) {
      return "";
    }
    const users_email_settings = this.props.users_email_settings[
      this.props.user_id
    ];

    return (
      <div
        className="tab-pane fade"
        role="tabpanel"
        id="notifications"
        aria-labelledby="notifications-tab"
      >
        <div className="tw-antialiased tw-font-sans tw-bg-gray-200">
          <div className="tw-bg-gray-100">
            <div className="tw-max-w-7xl tw-mx-auto tw-py-6 sm:tw-px-6 lg:tw-px-8">
              <div className="tw-shadow sm:tw-rounded-md sm:tw-overflow-hidden">
                <div className="tw-px-4 tw-py-5 tw-bg-white tw-space-y-6 sm:tw-p-6">
                  <form onSubmit={this.onSubmit}>
                    <div className="tw-px-4 tw-py-5 sm:tw-px-6">
                      <p className="text-lg leading-6 font-medium text-gray-900">
                        Activity Notifications
                      </p>
                    </div>
                    <div className="tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-px-6">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox custom-checkbox-switch">
                          <input
                            type="checkbox"
                            name="task"
                            className="custom-control-input"
                            id="notify-1"
                            defaultChecked={users_email_settings.task}
                            ref={this.task}
                            onChange={this.onChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="notify-1"
                          >
                            Someone assigns me to a task
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-control custom-checkbox custom-checkbox-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="notify-2"
                            name="mention"
                            defaultChecked={users_email_settings.mention}
                            ref={this.mention}
                            onChange={this.onChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="notify-2"
                          >
                            Someone mentions me in a conversation
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-control custom-checkbox custom-checkbox-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="notify-3"
                            name="project"
                            defaultChecked={users_email_settings.project}
                            ref={this.project}
                            onChange={this.onChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="notify-3"
                          >
                            Someone adds me to a project
                          </label>
                        </div>
                      </div>
                      <div className="form-group mb-md-4">
                        <div className="custom-control custom-checkbox custom-checkbox-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="notify-4"
                            name="activity"
                            ref={this.activity}
                            defaultChecked={users_email_settings.activity}
                            onChange={this.onChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="notify-4"
                          >
                            Activity on a project I am a member of
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-px-6">
                      <div className="tw-px-4 tw-py-5 sm:tw-px-6">
                        <p className="text-lg leading-6 font-medium text-gray-900">
                          Service Notifications
                        </p>
                      </div>
                      <div className="form-group">
                        <div className="custom-control custom-checkbox custom-checkbox-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="notify-5"
                            name="newsletter"
                            defaultChecked={users_email_settings.newsletter}
                            ref={this.newsletter}
                            onChange={this.onChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="notify-5"
                          >
                            Monthly newsletter
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-control custom-checkbox custom-checkbox-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="notify-6"
                            name="feature"
                            defaultChecked={users_email_settings.feature}
                            ref={this.feature}
                            onChange={this.onChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="notify-6"
                          >
                            Major feature enhancements
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-control custom-checkbox custom-checkbox-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="notify-7"
                            name="minor"
                            ref={this.minor}
                            defaultChecked={users_email_settings.minor}
                            onChange={this.onChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="notify-7"
                          >
                            Minor updates and bug fixes
                          </label>
                        </div>
                      </div>
                      <div className="row justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          ref={this.submit}
                        >
                          Save preferences
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Notifications.propTypes = {
  firestore: PropTypes.object.isRequired,
  notify: PropTypes.object.isRequired,
  notifyUser: PropTypes.func.isRequired,
};

export default compose(
  firebaseConnect(),
  firestoreConnect((props) => [
    {
      collection: "users_email_settings", //"users_infos",
      doc: props.user_id, //"ToAdAEdT27bnSC8nEtrn7BqBVaI3"]
    },
  ]),
  connect(
    (state, props) => ({
      users_email_settings: state.firestore.data.users_email_settings,
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      notify: state.notify,
    }),
    { notifyUser }
  )
)(Notifications);
