import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import T from "i18n-react";
import { TitleComponent } from "../layout/Title";

class Mission extends Component {
  state = {};

  render() {
    const { isAuthenticated } = this.state;
    if (isAuthenticated) {
      return <Redirect to={{ pathname: "/home" }} />;
    } else
      return (
        <React.Fragment>
          <TitleComponent title={"Hived.ai | " + T.translate("Beta.title")} />
          <div className="tw-py-16 tw-bg-gray-50 tw-overflow-hidden">
            <div className="tw-max-w-7xl tw-mx-auto tw-px-4 tw-space-y-8 sm:tw-px-6 lg:tw-px-8">
              <div className="tw-text-base tw-max-w-prose tw-mx-auto lg:tw-max-w-none">
                <h2 className="tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                  {T.translate("mission.title")}
                </h2>
                <p className="tw-mt-2 tw-text-3xl tw-leading-normal tw-font-extrabold tw-tracking-tight tw-text-gray-900 sm:tw-text-4xl">
                  {T.translate("mission.desc")}
                </p>
              </div>
              <div className="tw-relative tw-z-10 tw-text-base tw-max-w-prose tw-mx-auto lg:tw-max-w-5xl lg:tw-mx-0 lg:tw-pr-72">
                <p className="tw-text-lg tw-text-gray-500">
                  {T.translate("mission.comp")}
                </p>
              </div>
              <div className="lg:tw-grid lg:tw-grid-cols-2 lg:tw-gap-8 lg:tw-items-start">
                <div className="tw-relative tw-z-10">
                  <div className="tw-prose tw-prose-blue tw-text-gray-500 tw-mx-auto lg:tw-max-w-none">
                    <p>{T.translate("mission.comp_desc")}</p>
                    <h3 className="tw-mt-2 tw-text-3xl tw-leading-normal tw-pb-3">
                      {T.translate("mission.design")}
                    </h3>
                    <p>{T.translate("mission.design_desc")}</p>
                    <h3 className="tw-mt-2 tw-text-3xl tw-leading-normal tw-pb-3">
                      {T.translate("mission.techno")}
                    </h3>
                    <p>{T.translate("mission.techno_desc")}</p>
                    <h3 className="tw-mt-2 tw-text-3xl tw-leading-normal tw-pb-3">
                      {T.translate("mission.expert")}
                    </h3>
                    <p>{T.translate("mission.expert_desc")}</p>
                    <h3 className="tw-mt-2 tw-text-3xl tw-leading-normal tw-pb-3">
                      {T.translate("mission.success")}
                    </h3>
                    <p>{T.translate("mission.success_desc")}</p>
                  </div>
                  <div className="tw-mt-10 tw-flex tw-text-base tw-max-w-prose tw-mx-auto lg:tw-max-w-none">
                    <div className="tw-rounded-md tw-shadow">
                      <a
                        href="/signup"
                        className="tw-w-full tw-flex tw-items-center tw-justify-center tw-px-5 tw-py-3 tw-border tw-border-transparent tw-tw-text-base tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700"
                      >
                        {T.translate("landing.start")}
                      </a>
                    </div>
                    <div className="tw-rounded-md tw-shadow tw-ml-4">
                      <a
                        href="/signup"
                        className="tw-w-full tw-flex tw-items-center tw-justify-center tw-px-5 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-md tw-text-blue-600 tw-bg-white hover:tw-bg-gray-50"
                      >
                        {T.translate("landing.demo")}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="tw-mt-12 tw-relative tw-text-base tw-max-w-prose tw-mx-auto lg:tw-mt-0 lg:tw-max-w-none">
                  <svg
                    className="tw-absolute tw-top-0 tw-right-0 tw--mt-20 tw--mr-20 lg:tw-top-auto lg:tw-right-auto lg:tw-bottom-1/2 lg:tw-left-1/2 lg:tw-mt-0 lg:tw-mr-0 xl:tw-top-0 xl:tw-right-0 xl:tw--mt-20 xl:tw--mr-20"
                    width="404"
                    height="384"
                    fill="none"
                    viewBox="0 0 404 384"
                    aria-hidden="true"
                  >
                    <defs>
                      <pattern
                        id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          className="text-gray-200"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width="404"
                      height="384"
                      fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)"
                    />
                  </svg>
                  {/* <blockquote className="tw-relative tw-bg-white tw-rounded-lg tw-shadow-lg">
                    <div className="tw-rounded-t-lg tw-px-6 tw-py-8 sm:tw-px-10 sm:tw-pt-10 sm:tw-pb-8">
                      <img
                        src="https://tailwindui.com/img/logos/workcation-logo-blue-600-mark-gray-800-and-blue-600-text.svg"
                        alt="Workcation"
                        className="h-8"
                      />
                      <div className="tw-relative tw-text-lg tw-text-gray-700 tw-font-medium tw-mt-8">
                        <svg
                          className="tw-absolute tw-top-0 tw-left-0 tw-transform tw--translate-x-3 tw--translate-y-2 tw-h-8 tw-w-8 tw-text-gray-200"
                          fill="currentColor"
                          viewBox="0 0 32 32"
                          aria-hidden="true"
                        >
                          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                        </svg>
                        <p className="tw-relative">
                          Tincidunt integer commodo, cursus etiam aliquam neque,
                          et. Consectetur pretium in volutpat, diam. Montes,
                          magna cursus nulla feugiat dignissim id lobortis amet.
                          Laoreet sem est phasellus eu proin massa, lectus.
                        </p>
                      </div>
                    </div>
                    <cite className="tw-relative tw-flex tw-items-center sm:tw-items-start tw-bg-blue-600 tw-rounded-b-lg tw-not-italic tw-py-5 tw-px-6 sm:tw-py-5 sm:tw-pl-12 sm:tw-pr-10 sm:tw-mt-10">
                      <div className="tw-relative tw-rounded-full tw-border-2 tw-border-white sm:tw-absolute sm:tw-top-0 sm:tw-transform sm:tw--translate-y-1/2">
                        <img
                          className="tw-w-12 tw-h-12 sm:tw-w-20 sm:tw-h-20 tw-rounded-full tw-bg-blue-300"
                          src="https://images.unsplash.com/photo-1500917293891-ef795e70e1f6?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2.5&w=160&h=160&q=80"
                          alt=""
                        />
                      </div>
                      <span className="tw-relative tw-ml-4 tw-text-blue-300 tw-font-semibold tw-leading-6 sm:tw-ml-24 sm:tw-pl-1">
                        <p className="tw-text-white tw-font-semibold sm:tw-inline">
                          Judith Black
                        </p>
                        <p className="sm:tw-inline">CEO at Workcation</p>
                      </span>
                    </cite>
                  </blockquote> */}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  connect((state, props) => ({
    auth: state.firebase.auth,
  }))
)(Mission);
