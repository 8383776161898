import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Login from "./auth/login";
import Settings from "./components/settings/Settings";
import Signup from "./auth/Signup";
import Notfound from "./components/pages/Notfound";
import Error from "./components/pages/Error";
import Beta from "./components/pages/Beta";
import ProjectTest from "./components/projects/Campaign";
import EditCandidates from "./components/candidates/Edit";
import CreateProject from "./components/projects/CreateProject";
import EditProject from "./components/projects/EditProject";
import Home from "./components/pages/Home";
import AdminHome from "./components/admin/Home";
import AdminCampaign from "./components/admin/Campaign";
import SearchIndex from "./components/admin/SearchIndex";
import Landing from "./components/pages/Landing";
import Users from "./components/admin/ListUsers";
import Edit from "./components/organisation/Edit";
/* import Payment from "./components/Payment/Payment";
 */ import Billing from "./components/Payment/Billing";
import CampaignCreation from "./components/projects/CampaignCreation";
import Home2 from "./components/home/Index";
/* import Inbox from "./components/inbox/Inbox";
import { UserIsNotAuthenticated, UserIsAuthenticated } from "./helpers/auth"; */
import { Provider } from "react-redux";
import store from "./store";
import "./App.css";
import Video from "./components/VideoInterview/Video";
import LoggedInRoute from "./components/routes/LoggedInRoute";
import LoggedOutRoute from "./components/routes/LoggedOutRoute";
import Profile from "./components/profile/Profile";

//import { HandleProgressBar } from "./components/layout/ProgressBar";

import T from "i18n-react";
import Notactive from "./components/pages/Notactive";
import Time from "./components/pages/Time";
import Mission from "./components/pages/Mission";
import Price from "./components/pages/Price";
import Demo from "./components/pages/Demo";
import Cgu from "./components/pages/Cgu";
import Confidentialite from "./components/pages/Confidentialite";
//import ReactGA from "react-ga";
import Faq from "./components/pages/Faq";
import Reach from "./components/reach/Reach";
import Apply from "./components/reach/Apply";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database"; // If using Firebase database
import "firebase/storage";
import "firebase/firestore";

class App extends Component {
  render() {
    var language =
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage;

    // language would be something like es-ES or es_ES
    // However we store our files with format es.json or en.json
    // therefore retrieve only the first 2 digits
    if (language.length > 2) {
      language = language.split("-")[0];
      language = language.split("_")[0];
    }

    const firebaseConfig = {
      apiKey: "AIzaSyDi0YhRXEC5tY6SFtkdVDiGA6HUoOQsDhg",
      authDomain: "swel-39e81.firebaseapp.com",
      databaseURL: "https://swel-39e81.firebaseio.com",
      projectId: "swel-39e81",
      storageBucket: "swel-39e81.appspot.com",
      messagingSenderId: "942407142171",
    };

    firebase.initializeApp(firebaseConfig);

    const rrfConfig = {
      userProfile: "users",
      useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
    };

    const rrfProps = {
      firebase,
      config: rrfConfig,
      dispatch: store.dispatch,
      createFirestoreInstance, // <- needed if using firestore
    };

    var data = require("./languages/" + language + ".json");
    T.setTexts(data);

    return (
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <Router>
            <>
              {/* <HandleProgressBar /> */}

              <Route path="/" component={Header} />
              <>
                <Switch>
                  <Route exact path="/" component={Landing} />
                  <Route exact path="/time" component={Time} />
                  <Route exact path="/mission" component={Mission} />
                  <Route exact path="/Price" component={Price} />
                  <Route exact path="/demo" component={Demo} />
                  <Route exact path="/cgu" component={Cgu} />
                  <Route
                    exact
                    path="/reach/:id_opportunity"
                    component={Reach}
                  />
                  <Route
                    exact
                    path="/apply/:id_opportunity/:has_applied"
                    component={Apply}
                  />
                  <Route
                    exact
                    path="/confidentialite"
                    component={Confidentialite}
                  />
                  <Route exact path="/faq" component={Faq} />
                  {/* <Route
                    exact
                    path="/payment/:id_campaign"
                    render={({ match, history }) => {
                      return (
                        <LoggedInRoute
                          component={Payment}
                          history={history}
                          match={match}
                        />
                      );
                    }}
                  /> */}
                  <Route
                    exact
                    path="/billing/:id_campaign"
                    render={({ match, history }) => {
                      return (
                        <LoggedInRoute
                          component={Billing}
                          history={history}
                          match={match}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/home"
                    render={({ match, history }) => {
                      return (
                        <LoggedInRoute
                          component={Home}
                          history={history}
                          match={match}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/hmx"
                    render={({ match, history }) => {
                      return (
                        <LoggedInRoute
                          component={Home2}
                          history={history}
                          match={match}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/profile"
                    render={({ match, history }) => {
                      return (
                        <LoggedInRoute
                          component={Profile}
                          history={history}
                          match={match}
                        />
                      );
                    }}
                  />
                  {/* <Route
                    exact
                    path="/inbox"
                    render={({ match, history, location }) => {
                      return (
                        <LoggedInRoute
                          component={Inbox}
                          history={history}
                          match={match}
                          location={location}
                        />
                      );
                    }}
                  /> */}
                  <Route
                    exact
                    path="/interview"
                    render={({ match, history }) => {
                      return (
                        <LoggedInRoute
                          component={Video}
                          history={history}
                          match={match}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/signin"
                    render={({ match, history }) => {
                      return (
                        <LoggedOutRoute
                          component={Login}
                          history={history}
                          match={match}
                        />
                      );
                    }}
                  />

                  <Route
                    exact
                    path="/signup"
                    render={({ match, history }) => {
                      return (
                        <LoggedOutRoute
                          component={Beta}
                          history={history}
                          match={match}
                        />
                      );
                    }}
                    //component={UserIsNotAuthenticated(Beta)}
                  />
                  <Route
                    exact
                    path="/bta/tr54637"
                    render={({ match, history }) => {
                      return (
                        <LoggedOutRoute
                          component={Signup}
                          history={history}
                          match={match}
                        />
                      );
                    }}
                    //component={UserIsNotAuthenticated(Beta)}
                  />
                  {/*  <Route
                  exact
                  path="/bta/tr54637"
                  component={UserIsNotAuthenticated(Signup)}
                /> */}
                  <Route
                    exact
                    path="/settings"
                    render={({ match, history }) => {
                      return (
                        <LoggedInRoute
                          component={Settings}
                          history={history}
                          match={match}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/organisation/edit/:id_org"
                    render={({ match, history }) => {
                      return (
                        <LoggedInRoute
                          component={Edit}
                          history={history}
                          match={match}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/campaign/edit/:id_campaign"
                    render={({ match, history }) => {
                      return (
                        <LoggedInRoute
                          component={EditProject}
                          history={history}
                          match={match}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/campaign/create"
                    render={({ match, history }) => {
                      return (
                        <LoggedInRoute
                          component={CreateProject}
                          history={history}
                          match={match}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/campaign/createnew"
                    render={({ match, history }) => {
                      return (
                        <LoggedInRoute
                          component={CampaignCreation}
                          history={history}
                          match={match}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/adm/X"
                    render={({ match, history }) => {
                      return (
                        <LoggedInRoute
                          component={AdminHome}
                          history={history}
                          match={match}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/adm/X/campaign/:id_campaign"
                    render={({ match, history }) => {
                      return (
                        <LoggedInRoute
                          component={AdminCampaign}
                          history={history}
                          match={match}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/adm/X/index"
                    render={({ match, history }) => {
                      return (
                        <LoggedInRoute
                          component={SearchIndex}
                          history={history}
                          match={match}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/adm/X/users"
                    render={({ match, history }) => {
                      return (
                        <LoggedInRoute
                          component={Users}
                          history={history}
                          match={match}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/campaign/dashboard/:id_campaign"
                    render={({ match, history }) => {
                      return (
                        <LoggedInRoute
                          component={ProjectTest}
                          history={history}
                          match={match}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/campaign/dashboard/:id_campaign/candidate/:id_candidate"
                    render={({ match, history }) => {
                      return (
                        <LoggedInRoute
                          component={ProjectTest}
                          history={history}
                          match={match}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/candidate/edit/:id_candidate"
                    render={({ match, history }) => {
                      return (
                        <LoggedInRoute
                          history={history}
                          match={match}
                          component={EditCandidates}
                        />
                      );
                    }}
                  />
                  <Route exact path="/error" component={Error} />
                  <Route path="/notfound" component={Notfound} />
                  <Route path="/notactive" component={Notactive} />
                  <Route component={Notfound} />
                </Switch>
              </>
              <Route path="/" component={Footer} />
            </>
          </Router>
        </ReactReduxFirebaseProvider>
      </Provider>
    );
  }
}

export default App;
