import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import Profile from "./Profile";
import Avatar from "@material-ui/core/Avatar";
import { getColorFromString } from "../../helpers/global";
import OpportunityInModal from "./OpportunityInModal";
import Status from "./Status";
import { getProjectCandidateId } from "../../helpers/project";
import Activities from "../projects/Activities/Activities";
import Notes from "../projects/Notes/Notes";
import { isLoaded } from "react-redux-firebase";
import Wait from "../layout/Wait";
import T from "i18n-react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class CandidatePane extends Component {
  constructor(props) {
    super(props);

    this.candidateStatus = React.createRef();
    this.state = {
      isPaneOpen: false,
      candidate: "",
      status: null,
      id_rel: null,
    };
  }
  componentDidUpdate(prevProps) {
    if (
      this.state.candidate !== this.props.candidate ||
      this.props.status !== prevProps.status
    ) {
      this.setState({ candidate: this.props.candidate });
    }
    if (
      this.state.isPaneOpen !== this.props.isPaneOpen ||
      this.props.status !== prevProps.status
    ) {
      this.setState({ isPaneOpen: this.props.isPaneOpen });
    }
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    if (!isLoaded(this.props.candidate)) {
      return <Wait />;
    }

    var avatarTitle = "UK";
    var name = "";

    if (
      this.props.candidate_infos !== undefined &&
      this.props.candidate_infos.firstName !== undefined &&
      this.props.candidate_infos.secondName !== undefined
    ) {
      avatarTitle =
        this.props.candidate_infos.firstName.charAt(0) +
        this.props.candidate_infos.secondName.charAt(0);
      name =
        this.props.candidate_infos.firstName +
        " " +
        this.props.candidate_infos.secondName;
    }

    return (
      <SlidingPane
        className="tw-z-50 tw-bg-gray-100"
        overlayClassName="tw-z-50 tw-bg-gray-100"
        isOpen={this.state.isPaneOpen}
        title={
          this.props.candidate_infos !== undefined
            ? this.props.candidate_infos.firstName +
              " " +
              this.props.candidate_infos.secondName
            : ""
        }
        //subtitle="Optional subtitle."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          this.props.PaneStatus(false);
          this.setState({ isPaneOpen: false });
        }}
      >
        <div className="tw-bg-gray-100 tw-max-w-3xl tw-mx-auto tw-px-4 sm:tw-px-6 md:tw-flex md:tw-items-center md:tw-justify-between md:tw-space-x-5 lg:tw-max-w-7xl lg:tw-px-8">
          <div className="tw-flex tw-items-center tw-space-x-5">
            <div className="tw-flex-shrink-0">
              <div className="tw-relative">
                <Avatar
                  className="avatar"
                  style={{
                    color: "#fff",
                    width: "72px",
                    height: "72px",
                    backgroundColor: "#" + getColorFromString(name),
                  }}
                >
                  {avatarTitle.toUpperCase()}
                </Avatar>
                <span
                  className="tw-absolute tw-inset-0 tw-shadow-inner tw-rounded-full"
                  aria-hidden="true"
                ></span>
              </div>
            </div>
            <div>
              <h1 className="tw-text-2xl tw-font-bold tw-text-gray-900">
                {this.props.candidate_infos !== undefined
                  ? this.props.candidate_infos.firstName +
                    " " +
                    this.props.candidate_infos.secondName
                  : ""}
              </h1>
              <p className="tw-text-sm tw-font-medium tw-text-gray-500">
                {this.props.candidate_infos !== undefined &&
                this.props.candidate_infos.position
                  ? this.props.candidate_infos.position
                  : ""}
              </p>
            </div>
          </div>
          <div className="tw-mt-6 tw-flex tw-flex-col-reverse tw-justify-stretch tw-space-y-4 tw-space-y-reverse sm:tw-flex-row-reverse sm:tw-justify-end sm:tw-space-x-reverse sm:tw-space-y-0 sm:tw-space-x-3 md:tw-mt-0 md:tw-flex-row md:tw-space-x-3">
            <Status
              campaign={this.props.campaign}
              candidate={this.props.candidate}
              contributors={this.props.contributors}
              id_rel={getProjectCandidateId(
                this.props.campaign,
                this.props.candidate
              )}
            />
          </div>
          {/* <div className="tw-mt-6 tw-flex tw-flex-col-reverse tw-justify-stretch tw-space-y-4 tw-space-y-reverse sm:tw-flex-row-reverse sm:tw-justify-end sm:tw-space-x-reverse sm:tw-space-y-0 sm:tw-space-x-3 md:tw-mt-0 md:tw-flex-row md:tw-space-x-3">
            <button
              type="button"
              className="tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-offset-gray-100 focus:tw-ring-blue-500"
            >
              Disqualify
            </button>
            <button
              type="button"
              className="tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-text-sm tw-font-medium tw-rounded-md tw-shadow-sm tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-offset-gray-100 focus:tw-ring-blue-500"
            >
              Advance to offer
            </button>
          </div> */}
        </div>
        <div className="tw-mt-4 tw-antialiased tw-font-sans">
          <div className="tw-pastille tw-border-b tw-border-gray-200">
            <nav
              style={{ flexWrap: "unset" }}
              className="nav tw-flex"
              aria-label="Tabs"
            >
              <a
                data-toggle="tab"
                href="#profile"
                role="tab"
                aria-controls="profile"
                aria-selected="true"
                aria-current="page"
                className="tw-pastille active tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300 tw-w-1/4 tw-py-4 tw-px-1 tw-text-center tw-border-b-2 tw-font-medium tw-text-sm"
              >
                <span>{T.translate("profile.profile")}</span>
              </a>
              <a
                data-toggle="tab"
                href="#contact"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
                className="tw-pastille tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300 tw-w-1/4 tw-py-4 tw-px-1 tw-text-center tw-border-b-2 tw-font-medium tw-text-sm"
              >
                <span>{T.translate("profile.contact")}</span>
              </a>
              <a
                data-toggle="tab"
                href="#notes"
                role="tab"
                aria-controls="notes"
                aria-selected="false"
                className="tw-pastille tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300 tw-w-1/4 tw-py-4 tw-px-1 tw-text-center tw-border-b-2 tw-font-medium tw-text-sm"
              >
                <span>{T.translate("profile.notes")}</span>
              </a>
              <a
                data-toggle="tab"
                href="#activity"
                role="tab"
                aria-controls="activity"
                aria-selected="false"
                className="tw-pastille tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300 tw-w-1/4 tw-py-4 tw-px-1 tw-text-center tw-border-b-2 tw-font-medium tw-text-sm"
              >
                <span>{T.translate("profile.activity")}</span>
              </a>
            </nav>
          </div>
        </div>
        <div className="tab-content ">
          <div
            className="tab-pane fade show active"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            {this.props.candidate_infos !== undefined &&
            this.props.candidate_infos.summary ? (
              <div className="container">
                <div className="tw-bg-gray-100 tw-py-6">
                  <div className="tw-max-w-7xl tw-mx-auto sm:tw-px-6 lg:tw-px-8">
                    <div className="tw-bg-white tw-shadow sm:tw-rounded-lg sm:tw-overflow-hidden">
                      <div className="tw-divide-y tw-divide-gray-200">
                        <div className="tw-x-4 tw-py-5 sm:tw-px-6">
                          <h2
                            id="notes-title"
                            className="tw-text-lg tw-font-medium tw-text-gray-900"
                          >
                            {T.translate("profile.summary")}
                          </h2>
                        </div>
                        <div className="tw-px-4 tw-py-6 sm:tw-px-6">
                          {this.props.candidate_infos.summary}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <Profile
              id_candidate={this.state.candidate}
              edition={this.props.edition}
            />
          </div>
          <div
            className="tab-pane fade"
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <OpportunityInModal
              candidate={this.props.candidate}
              opportunity={getProjectCandidateId(
                this.props.campaign,
                this.props.candidate
              )}
            />
          </div>
          <div
            className="tab-pane fade"
            id="notes"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <Notes
              opportunity={getProjectCandidateId(
                this.props.campaign,
                this.props.candidate
              )}
              contributors={this.props.contributors}
              campaign={this.props.campaign}
              candidate={this.props.candidate}
              candidate_name={name}
            />
          </div>
          <div
            className="tab-pane fade"
            id="activity"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <Activities
              opportunity={getProjectCandidateId(
                this.props.campaign,
                this.props.candidate
              )}
              candidate_name={name}
            />
          </div>
        </div>
      </SlidingPane>
    );
  }
}

const mapStateToProps = (
  { firebase: { auth }, firestore: { ordered } },
  state
) => ({
  auth,
  candidatex: state.candidate,
});

export default compose(
  firebaseConnect(),
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "profiles",
      doc: props.candidatex,
      storeAs: "candidate_infos_modal_" + props.candidate,
    },
  ]),
  connect((state, props) => ({
    candidate_infos:
      state.firestore.data["candidate_infos_modal_" + props.candidatex],
    auth: state.firebase.auth,
    notify: state.notify,
  }))
)(CandidatePane);
