import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { checkUserInfos } from "../../helpers/users";
import Avatar from "@material-ui/core/Avatar";

class Contributors extends Component {
  render() {
    const users_infos = checkUserInfos(
      this.props.contributor[this.props.id_user]
    );

    if (!isLoaded(this.props.contributor) || users_infos === undefined) {
      return "";
    }

    return (
      <li>
        <a
          href="#l"
          data-toggle="tooltip"
          data-placement="top"
          title={users_infos.firstname + " " + users_infos.lastname}
        >
          {users_infos.picture === null ? (
            <Avatar
              alt={users_infos.firstname + " " + users_infos.lastname}
              className="avatar"
              src="/theme/img/user.png"
            />
          ) : (
            <Avatar
              alt={users_infos.firstname + " " + users_infos.lastname}
              className="avatar"
              src={users_infos.picture}
            />
          )}
        </a>
      </li>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(props => [
    {
      collection: "users_infos",
      doc: props.id_user
    }
  ]),
  connect((state, props) => ({
    contributor: state.firestore.data.users_infos
  }))
)(Contributors);
