import React, { useState } from "react";
import T from "i18n-react";
import { useCookies } from "react-cookie";

const Cookies = () => {
  const [open, setOpen] = useState(true);
  const [cookies, setCookie] = useCookies(["cookie-name"]);

  if (open && !cookies.CCT)
    return (
      <div className="tw-fixed tw-bottom-0 tw-inset-x-0 tw-pb-2 sm:tw-pb-5">
        <div className="tw-max-w-7xl tw-mx-auto tw-px-2 sm:tw-px-6 lg:tw-px-8">
          <div className="tw-p-2 tw-rounded-lg tw-bg-gray-500 tw-shadow-lg sm:tw-p-3">
            <div className="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
              <div className="tw-w-0 tw-flex-1 tw-flex tw-items-center">
                <span className="tw-flex tw-p-2 tw-rounded-lg tw-bg-gray-700">
                  <svg
                    className="tw-h-6 tw-w-6 tw-text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
                <p className="tw-ml-3 tw-font-medium tw-text-white tw-truncate">
                  <span className="md:tw-hidden">
                    {T.translate("cookies.title")}
                  </span>
                  <span className="tw-hidden md:tw-inline">
                    {T.translate("cookies.desc")}
                  </span>
                </p>
              </div>
              <div className="tw-order-2 tw-flex-shrink-0 sm:tw-order-2 sm:tw-ml-2">
                <button
                  onClick={() => {
                    setCookie("CCT", 1);
                    setOpen(false);
                  }}
                  type="button"
                  className="tw--mr-1 tw-flex p-2 tw-rounded-md hover:tw-bg-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-white"
                >
                  <span className="tw-sr-only">
                    {T.translate("cookies.dissmiss")}
                  </span>
                  <svg
                    className="tw-h-6 tw-w-6 tw-text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="tw-order-3 tw-mt-2 tw-flex-shrink-0 tw-w-full sm:tw-order-3 sm:tw-mt-0 sm:tw-w-auto">
                <a
                  onClick={() => {
                    setCookie("CCT", 1);
                    setOpen(false);
                  }}
                  href="/confidentialite"
                  className="tw-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-gray-600 tw-bg-white hover:tw-bg-gray-50"
                >
                  {T.translate("cookies.more")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else return <></>;
};

export default Cookies;
