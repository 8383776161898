import React, { Component } from "react";
import { firebaseConnect, firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import { notifyUser, setProgressBar } from "../../actions/notifyActions";
import { isLoaded } from "react-redux-firebase";
import Wait from "../layout/Wait";

import { Elements, StripeProvider, CardElement } from "react-stripe-elements";
import { injectStripe } from "react-stripe-elements";

import {
  getCards,
  deleteCard,
  createCustomer,
  addCard,
} from "../../helpers/payment";

class _CardForm extends React.Component {
  constructor(props) {
    super(props);
    this.submit = React.createRef();
  }

  createCustomerStripe = (token, user_infos, firestore) => {
    const uid = this.props.auth.uid;
    createCustomer(token, this.props.auth.email, uid).then(function (customer) {
      if (
        customer.customer !== undefined &&
        customer.customer.id !== undefined
      ) {
        const stripeInfos = {
          id_customer: customer.customer.id,
        };

        firestore
          .set({ collection: "stripe", doc: uid }, stripeInfos)
          .then((infos) => {});
      } else {
      }
    });
  };

  handleSubmit = (ev) => {
    ev.preventDefault();

    this.submit.current.setAttribute("disabled", "disabled");

    this.props.stripe
      .createToken({
        name:
          this.props.user_infos.firstname +
          " " +
          this.props.user_infos.lastname,
      })
      .then(({ token, error }) => {
        if (token !== undefined) {
          if (this.props.customer === null) {
            this.createCustomerStripe(
              token,
              this.props.user_infos,
              this.props.firestore
            );
          } else {
            addCard(token, this.props.customer).then(() => {
              this.props.getFunc(this.props.customer);
            });
          }
          this.submit.current.setAttribute("disabled", "disabled");
        } else {
          this.submit.current.removeAttribute("disabled");
        }
      });
  };
  render() {
    /* var style = {
      base: {
        color: "#32325d",
        lineHeight: "18px",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }; */
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="tw-px-4 tw-py-5 sm:tw-px-6">
          <p className="text-lg leading-6 font-medium text-gray-900">Cards</p>
        </div>
        <div className="tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-px-6 ">
          <div className="card text-center">
            <div className="card-body">
              <CardElement
                style={{
                  base: {
                    color: "#32325d",
                    lineHeight: "18px",
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: "antialiased",
                    fontSize: "16px",
                    border: "1px solid rgb(182, 184, 200, 0.6) !important",
                    "::placeholder": {
                      color: "#3f6eb7",
                    },
                  },
                  invalid: {
                    color: "#f44336",
                    iconColor: "#f44336",
                  },
                }}
              />
              <button
                style={{ marginTop: "15px" }}
                data-dismiss="modal"
                className="btn btn-primary"
                ref={this.submit}
                type="submit"
              >
                Ajouter carte
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const CardForm = injectStripe(_CardForm);

class CardComponent extends React.Component {
  delCard(id_card) {
    if (id_card !== undefined)
      deleteCard(this.props.card.customer, id_card).then((card) => {
        this.props.getFunc(this.props.card.customer);
      });
  }
  render() {
    if (this.props.card !== undefined) {
      return (
        <div onSubmit={this.props.submitDel} className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div
                className="col-auto"
                style={{ fontSize: "1.5rem", color: "#212529" }}
              >
                <i className="fas fa-credit-card"></i>
              </div>
              <div className="col d-flex align-items-center">
                <span>•••• •••• •••• {this.props.card.last4}</span>
                <small className="ml-2">
                  Exp:{" "}
                  {this.props.card.exp_month + "/" + this.props.card.exp_year}
                </small>
              </div>
              <div className="col-auto">
                <button
                  onClick={() => this.delCard(this.props.card.id)}
                  className="btn btn-sm btn-danger"
                >
                  Remove Card
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else return "";
  }
}

class Payment extends Component {
  state = {
    cards: [],
    loading: true,
  };

  componentDidMount() {
    if (
      this.props.stripe_infos !== undefined &&
      this.props.stripe_infos[this.props.auth.uid] !== undefined
    ) {
      this.getCardsFromStripe(
        this.props.stripe_infos[this.props.auth.uid].id_customer
      );
    }
  }

  getCardsFromStripe(id_customer) {
    try {
      getCards(id_customer).then((cards) => {
        this.setState({ cards, loading: false });
      });
    } catch (error) {}
  }

  render() {
    if (!isLoaded(this.props.stripe_infos) || this.state.loading === true) {
      return <Wait />;
    }

    if (
      this.state.cards !== undefined &&
      this.state.cards.cards !== undefined &&
      this.state.cards.cards.data !== undefined
    ) {
      const cards_ids = Object.keys(this.state.cards.cards.data);
      if (cards_ids.length > 0)
        return (
          <>
            <div className="tw-px-4 tw-py-5 sm:tw-px-6">
              <p className="text-lg leading-6 font-medium text-gray-900">
                Payment Method
              </p>
            </div>
            <div className="tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-px-6">
              {cards_ids.map((id) => {
                return (
                  <CardComponent
                    getFunc={this.getCardsFromStripe.bind(this)}
                    key={id}
                    customer={
                      this.props.stripe_infos[this.props.auth.uid].id_customer
                    }
                    card={this.state.cards.cards.data[id]}
                  />
                );
              })}
            </div>
          </>
        );
    }
    return (
      <React.Fragment>
        <StripeProvider apiKey="pk_live_y5E5k85olFygjRp0GWq9Pht1">
          <React.Fragment>
            <Elements>
              <CardForm
                customer={
                  this.props.stripe_infos[this.props.auth.uid] !== undefined
                    ? this.props.stripe_infos[this.props.auth.uid].id_customer
                    : null
                }
                getFunc={this.getCardsFromStripe.bind(this)}
                user_infos={this.props.user_infos}
                auth={this.props.auth}
                firestore={this.props.firestore}
              />
            </Elements>
          </React.Fragment>
        </StripeProvider>
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ firebase: { auth }, firestore: { ordered } }) => ({
  auth,
});

export default compose(
  firebaseConnect(),
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "stripe",
      doc: props.auth.uid,
    },
    {
      collection: "users_infos",
      doc: props.auth.uid,
    },
  ]),
  connect(
    (state, props) => ({
      user_infos: state.firestore.data.users_infos,
      stripe_infos: state.firestore.data.stripe,
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      notify: state.notify,
    }),
    { notifyUser, setProgressBar }
  )
)(Payment);
