import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { isLoaded } from "react-redux-firebase";
//import Create from "../organisation/Create";
import { notifyUser } from "../../actions/notifyActions";
import Wait from "../layout/Wait";
import Success from "./Success";
import Fail from "./Fail";

import Header from "./Header";

class Apply extends Component {
  render() {
    if (!isLoaded(this.props.campaign_candidate)) {
      return <Wait />;
    }

    if (
      this.props.campaign_candidate === null ||
      this.props.campaign_candidate === undefined ||
      this.props.campaign_candidate[this.props.match.params.id_opportunity] ===
        undefined ||
      this.props.campaign_candidate[this.props.match.params.id_opportunity] ===
        null
    )
      return <Redirect to="/notfound" />;
    return (
      <div
        className="row justify-content-center"
        style={{ marginBottom: "2rem" }}
      >
        <div className="col-md-6">
          <div className="container">
            <Header
              campaign={
                this.props.campaign_candidate[
                  this.props.match.params.id_opportunity
                ].campaign
              }
              cta={false}
            />
            <div className="container-fluid d-flex justify-content-between align-items-start">
              <div className="col-xl-12">
                <div className="row">
                  {this.props.match.params.has_applied === "1Xzfes5" ? (
                    <Success
                      profile={
                        this.props.campaign_candidate[
                          this.props.match.params.id_opportunity
                        ].profile
                      }
                      campaign={this.props.campaign_infos}
                      opportunity={this.props.match.params.id_opportunity}
                      opportunity_infos={
                        this.props.campaign_candidate[
                          this.props.match.params.id_opportunity
                        ]
                      }
                    ></Success>
                  ) : (
                    ""
                  )}
                  {this.props.match.params.has_applied === "0" ? (
                    <Fail
                      profile={
                        this.props.campaign_candidate[
                          this.props.match.params.id_opportunity
                        ].profile
                      }
                      campaign={this.props.campaign_infos}
                      opportunity={this.props.match.params.id_opportunity}
                      opportunity_infos={
                        this.props.campaign_candidate[
                          this.props.match.params.id_opportunity
                        ]
                      }
                    ></Fail>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(props => [
    {
      collection: "campaign_candidate",
      doc: props.match.params.id_opportunity
    }
  ]),
  connect(
    (state, props) => ({
      auth: state.firebase.auth,
      campaign_candidate: state.firestore.data.campaign_candidate,
      notify: state.notify
    }),
    { notifyUser }
  )
)(Apply);
