import React, { Component, useState, useEffect } from "react";
//import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { firebaseConnect, firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import T from "i18n-react";
import { actionTypes } from "redux-firestore";
import "../../App.css";
import { Transition } from "@headlessui/react";
import Notifications from "../notifications/Index";
import Alert from "../layout/Alert";

function MobileDropdown(dt) {
  const {
    notificationMenu,
    stt,
    authenticated,
    users_infos,
    uid,
    auth,
    Logout,
    Location,
  } = dt;
  return (
    //Open: "tw-block"
    <div className={`${stt} sm:tw-hidden`}>
      {authenticated && users_infos && users_infos[uid] ? (
        <>
          <div className="tw-pt-2 tw-pb-3 tw-space-y-1">
            {/* Current: "bg-blue-50 tw-border-blue-500 text-blue-700", Default: "tw-border-transparent text-gray-600 hover:bg-gray-50 hover:tw-border-gray-300 hover:text-gray-800" */}
            <Link
              to="/"
              className="bg-blue-50 tw-border-blue-500 text-blue-700 tw-block pl-3 pr-4 py-2 border-l-4 tw-text-base tw-font-medium"
            >
              {T.translate("header.home")}
            </Link>
          </div>
          <div className="tw-pt-4 tw-pb-3 tw-border-t tw-tw-border-gray-200">
            <div className="tw-flex tw-items-center tw-px-4">
              <div className="tw-flex-shrink-0">
                <img
                  className="tw-h-10 tw-w-10 tw-rounded-full"
                  src={auth.photoURL}
                  alt=""
                />
              </div>
              <div className="tw-ml-3">
                <div className="tw-text-base tw-font-medium tw-text-gray-800">{`${users_infos[uid].firstname} ${users_infos[uid].lastname}`}</div>
                <div className="tw-text-sm tw-font-medium tw-text-gray-500">
                  {users_infos[uid].email}
                </div>
              </div>
              <button
                onClick={() => notificationMenu()}
                className="tw-ml-auto tw-bg-white tw-flex-shrink-0 tw-p-1 tw-rounded-full tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
              >
                <span className="tw-sr-only">View notifications</span>
                <svg
                  className="tw-h-6 tw-w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  />
                </svg>
              </button>
            </div>
            <div className="tw-mt-3 tw-space-y-1">
              {/*  <Link
                to="profile"
                className="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100"
                role="menuitem"
              >
                {T.translate("header.profil")}
              </Link> */}

              <Link
                to="settings"
                className="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100"
                role="menuitem"
              >
                {T.translate("header.settings")}
              </Link>
              {users_infos !== null &&
              users_infos[uid] !== (null || undefined) &&
              users_infos[uid].admin === true ? (
                <Link
                  to="/adm/X"
                  className="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100"
                  role="menuitem"
                >
                  {T.translate("header.admin")}
                </Link>
              ) : (
                ""
              )}
              <a
                href="#l"
                onClick={Logout}
                className="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100"
                role="menuitem"
              >
                Log Out
              </a>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="tw-pt-2 tw-pb-3 tw-space-y-1">
            {/* Current: "bg-blue-50 tw-border-blue-500 text-blue-700", Default: "tw-border-transparent text-gray-600 hover:bg-gray-50 hover:tw-border-gray-300 hover:text-gray-800" */}
            <Link
              to="/mission"
              className={`${
                Location.pathname.match("/mission")
                  ? "bg-blue-50 tw-border-blue-500 text-blue-700"
                  : "tw-border-transparent text-gray-600 hover:tw-bg-gray-50 hover:tw-border-gray-300 hover:text-gray-800"
              }  tw-block pl-3 pr-4 py-2 border-l-4 tw-text-base tw-font-medium`}
            >
              {T.translate("header.mission")}
            </Link>

            <Link
              to="/price"
              className={`${
                Location.pathname.match("/price")
                  ? "bg-blue-50 tw-border-blue-500 text-blue-700"
                  : "tw-border-transparent text-gray-600 hover:tw-bg-gray-50 hover:tw-border-gray-300 hover:text-gray-800"
              }  tw-block pl-3 pr-4 py-2 border-l-4 tw-text-base tw-font-medium`}
            >
              {T.translate("header.prix")}
            </Link>

            <Link
              to="/time"
              className={`${
                Location.pathname.match("/time")
                  ? "bg-blue-50 tw-border-blue-500 text-blue-700"
                  : "tw-border-transparent text-gray-600 hover:tw-bg-gray-50 hover:tw-border-gray-300 hover:text-gray-800"
              }  tw-block pl-3 pr-4 py-2 border-l-4 tw-text-base tw-font-medium`}
            >
              {T.translate("header.time")}
            </Link>

            <Link
              to="/demo"
              className={`${
                Location.pathname.match("/demo")
                  ? "bg-blue-50 tw-border-blue-500 text-blue-700"
                  : "tw-border-transparent text-gray-600 hover:tw-bg-gray-50 hover:tw-border-gray-300 hover:text-gray-800"
              }  tw-block pl-3 pr-4 py-2 border-l-4 tw-text-base tw-font-medium`}
            >
              {T.translate("header.demo")}
            </Link>

            <Link
              to="/faq"
              className={`${
                Location.pathname.match("/faq")
                  ? "bg-blue-50 tw-border-blue-500 text-blue-700"
                  : "tw-border-transparent text-gray-600 hover:tw-bg-gray-50 hover:tw-border-gray-300 hover:text-gray-800"
              }  tw-block pl-3 pr-4 py-2 border-l-4 tw-text-base tw-font-medium`}
            >
              {T.translate("header.faq")}
            </Link>
            <Link
              to="/signin"
              className={`${
                Location.pathname.match("/signin")
                  ? "bg-blue-50 tw-border-blue-500 text-blue-700"
                  : "tw-border-transparent text-gray-600 hover:tw-bg-gray-50 hover:tw-border-gray-300 hover:text-gray-800"
              }  tw-block pl-3 pr-4 py-2 border-l-4 tw-text-base tw-font-medium`}
            >
              {T.translate("header.signin")}
            </Link>
            <a
              href="/signup"
              className={`${
                Location.pathname.match("/signup")
                  ? "bg-blue-50 tw-border-blue-500 text-blue-700"
                  : "tw-border-transparent text-gray-600 hover:tw-bg-gray-50 hover:tw-border-gray-300 hover:text-gray-800"
              }  tw-block pl-3 pr-4 py-2 border-l-4 tw-text-base tw-font-medium`}
            >
              {T.translate("header.signup")}
            </a>
          </div>
        </>
      )}
    </div>
  );
}

function Dropdown(dt) {
  const [isOpen, setIsOpen] = useState(false);
  const { authenticated, users_infos, uid, auth, Logout, propRef } = dt;

  useEffect(() => {
    const currentRef = propRef.current;
    document.addEventListener("mousedown", (event) => {
      if (currentRef && !currentRef.contains(event.target)) setIsOpen(false);
    });
    return () => {
      document.addEventListener("mousedown", (event) => {
        if (currentRef && !currentRef.contains(event.target)) setIsOpen(false);
      });
    };
  }, [propRef]);

  return (
    <div ref={propRef} className="tw-ml-3 tw-relative tw-z-10 ...">
      {authenticated === false ? (
        <>
          <Link
            to="signin"
            className="tw-whitespace-nowrap tw-text-base tw-font-medium tw-text-gray-500 hover:tw-text-blue-900"
          >
            {T.translate("header.signin")}
          </Link>
          <Link
            to="signup"
            className="tw-ml-8 tw-whitespace-nowrap tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-base tw-font-medium tw-text-white tw-bg-blue-600 hover:tw-text-white hover:tw-bg-blue-700"
          >
            {T.translate("header.signup")}
          </Link>
        </>
      ) : (
        <>
          <div>
            <button
              type="button"
              onClick={() => setIsOpen(!isOpen)}
              className="tw-max-w-xs tw-bg-white tw-flex tw-items-center tw-text-sm tw-rounded-full focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
              id="user-menu"
              aria-haspopup="true"
            >
              <span className="tw-sr-only">Open user menu</span>
              <img
                className="tw-h-8 w-8 tw-rounded-full"
                src={auth.photoURL ? auth.photoURL : "/theme/img/user.png"}
                alt=""
              />
            </button>
          </div>

          <Transition
            show={isOpen}
            enter="tw-transition tw-ease-out tw-duration-100 tw-transform"
            enterFrom="tw-opacity-0 tw-scale-95"
            enterTo="tw-opacity-100 tw-scale-100"
            leave="tw-transition tw-ease-in tw-duration-75 tw-transform"
            leaveFrom="tw-opacity-100 tw-scale-100"
            leaveTo="tw-opacity-0 tw-scale-95"
          >
            {(ref) => (
              <div
                ref={ref}
                className="tw-origin-top-right tw-absolute tw-right-0 tw-mt-2 tw-w-56 tw-rounded-md tw-shadow-lg tw-py-1 tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5"
              >
                <div className="tw-rounded-md tw-bg-white tw-shadow-xs">
                  <a
                    href="/home"
                    className="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100"
                    role="menuitem"
                  >
                    {T.translate("header.home")}
                  </a>
                  {/* <a
                    href="/profile"
                    className="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100"
                    role="menuitem"
                  >
                    {T.translate("header.profil")}
                  </a>
 */}
                  <a
                    href="/settings"
                    className="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100"
                    role="menuitem"
                  >
                    {T.translate("header.settings")}
                  </a>
                  {users_infos !== null &&
                  users_infos[uid] !== (null || undefined) &&
                  users_infos[uid].admin === true ? (
                    <a
                      href="/adm/X"
                      className="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100"
                      role="menuitem"
                    >
                      {T.translate("header.admin")}
                    </a>
                  ) : (
                    ""
                  )}
                  <a
                    href="#l"
                    onClick={Logout}
                    className="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100"
                    role="menuitem"
                  >
                    Log Out
                  </a>
                </div>
              </div>
            )}
          </Transition>
        </>
      )}
    </div>
  );
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.dropdownRef = React.createRef();
  }

  state = {
    isAuthenticated: false,
    users_infos: null,
    uid: null,
    openMobileMenu: "tw-hidden",
    notificationsPaneOpen: false,
    hasNewNotifications: false,
  };

  /*   static getDerivedStateFromProps(props, state) {
    const { auth } = props;
    if (auth.uid) {
      return { isAuthenticated: true };
    } else {
      return { isAuthenticated: false };
    }
  }
 */
  componentDidUpdate(prevProps) {
    // This will erase any local state updates!
    // Do not do this.
    const { auth, users_infos } = this.props;

    if (this.state.users_infos !== users_infos) {
      this.setState({ users_infos: users_infos });
    }
    if (this.state.uid !== auth.uid) {
      this.setState({ uid: auth.uid });
    }
    if (this.state.isAuthenticated === false && auth.uid) {
      this.setState({ isAuthenticated: true });
    }
    if (this.state.isAuthenticated !== false && !auth.uid) {
      this.setState({ isAuthenticated: false });
    }
  }
  openMobileMenu = (e) => {
    if (this.state.openMobileMenu === "tw-hidden")
      this.setState({
        openMobileMenu: "tw-block",
      });
    else
      this.setState({
        openMobileMenu: "tw-hidden",
      });
  };

  openNotificationsPane = (e) => {
    this.setState({
      notificationsPaneOpen: !this.state.notificationsPaneOpen,
    });
  };
  onLogoutClick = (e) => {
    e.preventDefault();

    const { firebase } = this.props;

    firebase.logout().then(() => {
      // Clear redux-firestore state on logout
      this.props.dispatch({ type: actionTypes.CLEAR_DATA });
      this.setState({ isAuthenticated: false, users_infos: null, uid: null });
    });
  };

  setNewNotifications = (isNew) => {
    this.setState({ hasNewNotifications: isNew });
  };

  render() {
    /*     if (!isLoaded(this.props.users_infos)) {
      return "";
    } */
    const { location } = this.props;

    const { isAuthenticated } = this.state;

    const { message, messageType, isOpen } = this.props.notify;

    /*var users_infos = null;
  
    if (this.props.auth.uid !== undefined &&
    this.props.auth.uid !== null &&
    this.props.users_infos !== null &&
    this.props.users_infos !== undefined &&
    this.props.users_infos[this.props.auth.uid] !==
      (null || undefined) &&
      this.props.users_infos[this.props.auth.uid].admin ===
      true) {
        users_infos = this.props.users_infos
      }*/

    if (
      location.pathname.match("/reach") ||
      location.pathname.match("/apply")
    ) {
      return "";
    } else {
      return (
        <>
          <nav className="tw-bg-white tw-border-b tw-border-gray-200">
            {/* tw-border-b tw-border-gray-200 */}
            <div className="tw-max-w-7xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8">
              <div className="tw-flex tw-justify-between tw-h-16">
                <div className="tw-flex">
                  <div className="tw-flex-shrink-0 tw-flex tw-items-center">
                    <Link
                      to={this.props.auth.uid === undefined ? "/" : "/home"}
                    >
                      <img
                        className="tw-block lg:tw-hidden tw-h-8 tw-w-auto"
                        src="/theme/logos/Logomark_White.png"
                        alt="Hived.ai"
                      />
                      <img
                        className="tw-hidden lg:tw-block tw-h-8 tw-w-auto"
                        src="/theme/logos/hived_transparent_black.svg"
                        alt="Hived.ai"
                      />
                    </Link>
                  </div>
                  <div className="tw-hidden sm:tw--my-px sm:tw-ml-6 sm:tw-flex sm:tw-space-x-8">
                    {isAuthenticated ? (
                      <Link
                        to={this.props.auth.uid === undefined ? "/" : "/home"}
                        className="tw-border-blue-500 tw-text-blue-900 tw-inline-flex tw-items-center tw-px-1 tw-pt-1 tw-border-b-2 tw-text-sm tw-font-medium"
                      >
                        {T.translate("header.home")}
                      </Link>
                    ) : (
                      <>
                        {/* Current: "tw-border-blue-500 tw-text-gray-900", Default: "tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700" */}
                        <Link
                          to="/mission"
                          className={
                            (location.pathname.match("/mission")
                              ? "tw-border-blue-500 tw-text-gray-900 tw-inline-flex "
                              : "tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700 tw-inline-flex ") +
                            "tw-items-center tw-px-1 tw-pt-1 tw-border-b-2 tw-text-sm tw-font-medium"
                          }
                        >
                          {T.translate("header.mission")}
                        </Link>

                        <Link
                          to="/price"
                          className={
                            (location.pathname.match("price")
                              ? "tw-border-blue-500 tw-text-gray-900 tw-inline-flex "
                              : "tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700 tw-inline-flex ") +
                            "tw-items-center tw-px-1 tw-pt-1 tw-border-b-2 tw-text-sm tw-font-medium"
                          }
                        >
                          {T.translate("header.prix")}
                        </Link>

                        <Link
                          to="/time"
                          className={
                            (location.pathname.match("time")
                              ? "tw-border-blue-500 tw-text-gray-900 tw-inline-flex "
                              : "tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700 tw-inline-flex ") +
                            "tw-items-center tw-px-1 tw-pt-1 tw-border-b-2 tw-text-sm tw-font-medium"
                          }
                        >
                          {T.translate("header.time")}
                        </Link>

                        <a
                          href="/demo"
                          className={
                            (location.pathname.match("/demo")
                              ? "tw-border-blue-500 tw-text-gray-900 tw-inline-flex "
                              : "tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700 tw-inline-flex ") +
                            "tw-items-center tw-px-1 tw-pt-1 tw-border-b-2 tw-text-sm tw-font-medium"
                          }
                        >
                          {T.translate("header.demo")}
                        </a>

                        <Link
                          to="/faq"
                          className={
                            (location.pathname.match("/faq")
                              ? "tw-border-blue-500 tw-text-gray-900 tw-inline-flex "
                              : "tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700 tw-inline-flex ") +
                            "tw-items-center tw-px-1 tw-pt-1 tw-border-b-2 tw-text-sm tw-font-medium"
                          }
                        >
                          {T.translate("header.faq")}
                        </Link>
                      </>
                    )}
                  </div>
                </div>
                {/* <div className="tw-flex-1 tw-flex tw-items-center tw-justify-center tw-px-2 lg:tw-ml-6 lg:tw-justify-end">
                  <div className="tw-max-w-lg tw-w-full lg:tw-max-w-xs">
                    <label className="tw-sr-only">Search</label>
                    <div className="tw-relative">
                      <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
                        <svg
                          className="tw-h-5 tw-w-5 tw-text-gray-400"
                          x-description="Heroicon name: search"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <input
                        id="search"
                        name="search"
                        className="tw-block tw-w-full tw-pl-10 tw-pr-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-leading-5 tw-bg-white tw-shadow-sm tw-placeholder-gray-500 focus:tw-outline-none focus:tw-placeholder-gray-400 focus:tw-ring-1 focus:tw-ring-blue-600 focus:tw-border-blue-600 sm:tw-text-sm"
                        placeholder="Search"
                        type="search"
                      />
                    </div>
                  </div>
                </div> */}
                <div className="tw-hidden sm:tw-ml-6 sm:tw-flex sm:tw-items-center">
                  {/* <button className="tw-bg-white tw-p-1 tw-rounded-full tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500">
                    <span className="tw-sr-only">View notifications</span>
                    <svg className="tw-h-6 tw-w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                    </svg>
                  </button> */}

                  {/* Profile dropdown */}
                  {isAuthenticated ? (
                    <span className="tw-inline-block tw-relative">
                      <button
                        onClick={() => this.openNotificationsPane()}
                        className="tw-bg-white tw-p-1 tw-rounded-full tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
                      >
                        {this.state.hasNewNotifications === true ? (
                          <span className="tw-absolute tw-top-0 tw-right-0 tw-block tw-h-3.5 tw-w-3.5 tw-rounded-full tw-ring-2 tw-ring-white tw-bg-blue-400"></span>
                        ) : (
                          ""
                        )}
                        <span className="tw-sr-only">View notifications</span>
                        <svg
                          className="tw-h-6 tw-w-6"
                          x-description="Heroicon name: bell"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                          ></path>
                        </svg>
                      </button>
                    </span>
                  ) : (
                    ""
                  )}
                  <Dropdown
                    propRef={this.dropdownRef}
                    authenticated={isAuthenticated}
                    users_infos={this.state.users_infos}
                    uid={this.state.uid}
                    auth={this.props.auth}
                    Logout={this.onLogoutClick}
                  />
                </div>

                <div className="tw--mr-2 tw-flex tw-items-center sm:tw-hidden">
                  {/* Mobile menu button */}
                  <button
                    onClick={this.openMobileMenu}
                    className="tw-bg-white tw-inline-flex tw-items-cnter tw-justify-center tw-p-2 tw-rounded-md tw-text-gray-400 hover:tw-text-gray-500 hover:bg-gray-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
                  >
                    <span className="tw-sr-only">Open main menu</span>
                    {/*
                      Heroicon name: menu

                      Menu open: "tw-hidden", Menu closed: "tw-block"
                    */}
                    <svg
                      className="tw-block tw-h-6 tw-w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                    {/*
                      Heroicon name: x

                      Menu open: "tw-block", Menu closed: "tw-hidden"
                    */}
                    <svg
                      className="tw-hidden tw-h-6 tw-w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <MobileDropdown
                notificationMenu={this.openNotificationsPane}
                stt={this.state.openMobileMenu}
                authenticated={isAuthenticated}
                users_infos={this.state.users_infos}
                uid={this.state.uid}
                auth={this.props.auth}
                Logout={this.onLogoutClick}
                Location={location}
              />
            </div>
          </nav>
          {isAuthenticated ? (
            <Notifications
              open={this.state.notificationsPaneOpen}
              closeCallback={this.openNotificationsPane}
              newNotifications={this.setNewNotifications}
            />
          ) : (
            ""
          )}
          {message ? (
            <Alert
              message={message}
              isOpen={isOpen}
              messageType={messageType}
            />
          ) : null}
        </>
      );
    }
  }
}

Header.propTypes = {
  firebase: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = ({ firebase: { auth } }) => ({
  auth,
});

export default compose(
  firebaseConnect(),
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "users_infos",
      doc: props.auth.uid,
    },
  ]),
  connect((state, props) => ({
    users_infos: state.firestore.data.users_infos,
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    notify: state.notify,
  }))
)(Header);
