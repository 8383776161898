import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import T from "i18n-react";
import { isLoaded } from "react-redux-firebase";
//import Create from "../organisation/Create";
import { notifyUser } from "../../actions/notifyActions";
import Wait from "../layout/Wait";
import { getTranslate } from "../../helpers/project";
import Organisation from "./Organisation";

class Opportunity extends Component {
  createMarkup = (html) => {
    return { __html: html };
  };

  render() {
    if (
      !isLoaded(this.props.campaign_infos) &&
      !isLoaded(this.props.organisation)
    ) {
      return <Wait />;
    }

    return (
      <div
        className="row justify-content-center"
        style={{
          minHeight: "90vh",
          color: "#515357",
          lineHeight: "2",
        }}
      >
        <div className="col-md-8">
          <div className="container">
            <div className="tw-px-4 tw-py-6 tw-max-w-7xl tw-mx-auto tw-border-b tw-border-gray-200 sm:tw-px-6">
              <div className="tw-pb-2  sm:tw-flex sm:tw-items-center sm:tw-justify-between">
                <h3 className="tw-text-3xl tw-leading-6 tw-font-medium tw-text-gray-900">
                  {this.props.campaign_infos.jobTitle}
                </h3>
                <div className="tw-mt-3 sm:tw-mt-0 sm:tw-ml-4">
                  {this.props.opportunity_infos["application"] === undefined ? (
                    <button
                      type="button"
                      className="tw-inline-flex tw-items-center tw-px-4 py-2 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
                    >
                      <div className="text-right d-flex flex-column">
                        <a
                          href={`/apply/${this.props.opportunity}/1Xzfes5`}
                          className="tw-text-white hover:tw-text-white"
                        >
                          {T.translate("apply.apply")}
                        </a>
                      </div>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="tw-mt-1 tw-flex tw-flex-col sm:tw-flex-row sm:tw-flex-wrap sm:tw-mt-0 sm:tw-space-x-6">
                <div className="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500">
                  <svg
                    className="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                  {this.props.campaign_infos.city.charAt(0).toUpperCase() +
                    this.props.campaign_infos.city.slice(1) +
                    ", " +
                    this.props.campaign_infos.country.charAt(0).toUpperCase() +
                    this.props.campaign_infos.country.slice(1)}
                </div>

                <div className="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500">
                  <svg
                    className="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    ></path>
                    <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
                  </svg>
                  {getTranslate(this.props.campaign_infos.fonction)}
                </div>

                <div className="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500">
                  <svg
                    className="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                    />
                  </svg>
                  {getTranslate(this.props.campaign_infos.type)}
                </div>
                <div className="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500">
                  <svg
                    className="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                    <path
                      fill="#fff"
                      d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                    />
                  </svg>
                  {getTranslate(this.props.campaign_infos.studies)}
                </div>
                <div className="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500">
                  <svg
                    className="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  {this.props.campaign_infos.experienceYears + " years"}
                </div>
              </div>
            </div>
            <hr className="tw-py-1" />
            <div className="col-xl-12 tw-pb-8">
              <Organisation
                organisation={this.props.campaign_infos.organisation}
              />
              <h3 className="tw-text-xl tw-mt-5 tw-mb-5 tw-text-gray-900">
                {T.translate("apply.about_opportunity")}
              </h3>
              <div
                className="tw-leading-loose tw-font-light opportunity_reach"
                dangerouslySetInnerHTML={this.createMarkup(
                  this.props.campaign_infos.description
                )}
              />
            </div>

            {this.props.opportunity_infos["application"] === undefined ? (
              <React.Fragment>
                <hr className="" />
                <div className="col-xl-12 text-center mb-4 mt-4">
                  <div className="row d-flex flex-column">
                    <button
                      type="button"
                      className="btn btn-lg tw-text-center tw-inline-flex tw-items-center tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
                    >
                      <a
                        href={`/apply/${this.props.opportunity}/1Xzfes5`}
                        className="tw-min-w-full tw-text-center hover:tw-text-white"
                      >
                        {T.translate("apply.apply")}
                      </a>
                    </button>
                  </div>
                  <div className="text-center mt-2 text-small">
                    <a
                      href={`/apply/${this.props.opportunity}/0`}
                      style={{ color: "#777", textDecoration: "underline" }}
                    >
                      <small> {T.translate("apply.not")}</small>
                    </a>
                  </div>
                </div>{" "}
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect((props) => [
    {
      collection: "projects",
      doc: props.campaign,
      storeAs: props.campaign_id + "_opportunity",
    },
  ]),
  connect(
    (state, props) => ({
      auth: state.firebase.auth,
      campaign_infos: state.firestore.data[props.campaign_id + "_opportunity"],
      notify: state.notify,
    }),
    { notifyUser }
  )
)(Opportunity);
