import React, { Component } from "react";
import { compose } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import LoadingScreen from "../layout/Wait";

class LoggedInRoute extends Component {
  render() {
    const { auth, isInitializing } = this.props;

    if (!auth.isLoaded || isInitializing === true) return <LoadingScreen />;

    if (auth.isLoaded && !auth.isEmpty) {
      const Component = this.props.component;

      return (
        <Component history={this.props.history} match={this.props.match} />
      );
    } else {
      const locationHelper = locationHelperBuilder({});
      /* console.log(this.props); */
      const redirectionPath =
        locationHelper.getRedirectQueryParam(this.props.history) || "/signin";
      return <Redirect to={redirectionPath} />;
    }
  }
}

export default compose(
  firebaseConnect(),
  connect((state, props) => ({
    auth: state.firebase.auth,
    isInitializing: state.firebase.isInitializing,
    notify: state.notify,
  }))
)(LoggedInRoute);
