let FIREBASE_FUNCTION =
  "https://us-central1-swel-39e81.cloudfunctions.net/searchApi/";

if (process.env.NODE_ENV === "development") {
  FIREBASE_FUNCTION = "http://localhost:5001/swel-39e81/us-central1/searchApi/";
}

export async function indexCandidateInCampaigns() {
  return await fetch(FIREBASE_FUNCTION + "indexcandidatesincampaigns", {
    method: "POST",
    //4mode: "no-cors",
    headers: { "Access-Control-Allow-Origin": "*" }
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return JSON.parse(data.body);
    });
}
