import React, { Component } from "react";
import { firebaseConnect, firestoreConnect } from "react-redux-firebase";
import T from "i18n-react";
import { compose } from "redux";
import { connect } from "react-redux";
import { notifyUser } from "../../actions/notifyActions";

class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    // Create refs
    this.newPassword1 = React.createRef();
    this.newPassword2 = React.createRef();
    this.submit = React.createRef();
  }

  onSubmit = (e) => {
    e.preventDefault();

    this.submit.current.setAttribute("disabled", "disabled");

    // Update client in firestore
    const { firebase, notifyUser } = this.props;

    if (this.newPassword1.current.value === this.newPassword2.current.value) {
      firebase
        .auth()
        .currentUser.updatePassword(this.newPassword2.current.value)
        .then(function () {
          notifyUser(T.translate("Password updated"), "success");
          //this.submit.current.removeAttribute("disabled");
        })
        .catch(function (error) {
          switch (error.code) {
            case "auth/weak-password":
              notifyUser(T.translate("settings.weakPassword"), "error");
              return;
            case "auth/requires-recent-login":
              notifyUser(T.translate("settings.reloginError"), "error");
              firebase.logout();
              return;
            case "auth/email-already-in-use":
              notifyUser(T.translate("signup.emailAlreadyExists"), "error");
              return;
            default:
              notifyUser(T.translate("general.error"), "error");
          }
        });
    } else {
      notifyUser(T.translate("settings.notMatchingPasswords"), "error");
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.submit.current.removeAttribute("disabled");
    this.setState({
      notify: {
        message: "",
        messageType: "",
      },
    });
  };

  render() {
    return (
      <div
        className="tab-pane fade"
        role="tabpanel"
        id="password"
        aria-labelledby="password-tab"
      >
        <div className="tw-antialiased tw-font-sans tw-bg-gray-200">
          <div className="">
            <div className="tw-bg-gray-100">
              <div className="tw-max-w-7xl tw-mx-auto tw-py-6 sm:tw-px-6 lg:tw-px-8">
                <div className="tw-shadow sm:tw-rounded-md sm:tw-overflow-hidden">
                  <div className="tw-px-4 tw-py-5 tw-bg-white tw-space-y-6 sm:tw-p-6">
                    <form onSubmit={this.onSubmit}>
                      <div className="form-group row align-items-center">
                        <label className="col-3">
                          {T.translate("settings.newPassword")}
                        </label>
                        <div className="col">
                          <input
                            type="password"
                            autoComplete="on"
                            placeholder={T.translate(
                              "settings.newPassword_hint"
                            )}
                            name="password-new"
                            className="form-control"
                            onChange={this.onChange}
                            ref={this.newPassword1}
                          />
                          <small>
                            {T.translate("settings.newPassword_hint_phrase")}
                          </small>
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label className="col-3">
                          {T.translate("settings.confirmPassword")}
                        </label>
                        <div className="col">
                          <input
                            type="password"
                            autoComplete="on"
                            placeholder={T.translate(
                              "settings.confirmPassword_hint"
                            )}
                            name="password-new-confirm"
                            className="form-control"
                            onChange={this.onChange}
                            ref={this.newPassword2}
                          />
                        </div>
                      </div>
                      <div className="row justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          ref={this.submit}
                        >
                          {T.translate("settings.changePassword")}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  connect(
    (state, props) => ({
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      notify: state.notify,
    }),
    { notifyUser }
  )
)(UpdatePassword);
