const FIREBASE_TWILIO_FUNCTION =
  "https://us-central1-swel-39e81.cloudfunctions.net/twilioApi/";

export async function getToken(uid, room) {
  return await fetch(FIREBASE_TWILIO_FUNCTION + "tgt/", {
    method: "POST",
    //mode: "no-cors",
    headers: { "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({ uid, room })
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return JSON.parse(data.body);
    });
}
