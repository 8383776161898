import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";

class AddEmail extends Component {
  constructor(props) {
    super(props);
    // Create refs
    this.email = React.createRef();
    this.score = React.createRef();
    this.submit = React.createRef();
  }

  formHasError() {
    var hasError = false;
    if (this.email.current.value === "") {
      this.email.current.className = "form-control is-invalid";
      hasError = true;
    } else this.email.current.className = "form-control";
    if (this.score.current.value === "") {
      this.score.current.className = "form-control is-invalid";
      hasError = true;
    } else this.score.current.className = "form-control";
    return hasError;
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { firestore } = this.props;

    const hasErrors = this.formHasError();

    if (!hasErrors) {
      this.submit.current.setAttribute("disabled", "disabled");
      const email_update = {
        emails: [
          { email: this.email.current.value, score: this.score.current.value },
        ],
      };

      firestore.update(
        { collection: "profiles", doc: this.props.candidate },
        email_update
      );
    } else {
      this.submit.current.removeAttribute("disabled");
    }
  };
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <React.Fragment>
        <form onSubmit={this.onSubmit}>
          <div className="tw-bg-white tw-shadow tw-overflow-hidden sm:tw-rounded-lg tw-mb-3">
            <div className="tw-px-4 tw-py-5 sm:tw-px-6">
              <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                Add email
              </h3>
              <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-text-gray-500">
                Add emails to this profile
              </p>
            </div>
            <div className="tw-px-4 tw-divide-y tw-divide-gray-200 sm:tw-px-6">
              <div className="tw-space-y-6 tw-pt-6 tw-pb-5">
                <div>
                  <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-900">
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      type="email"
                      ref={this.email}
                      placeholder="email"
                      className="p-2 border border-gray-200 tw-max-w-lg tw-shadow-sm tw-block tw-w-full focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-900">
                    Score
                  </label>
                  <div className="mt-1">
                    <input
                      type="number"
                      placeholder="0"
                      ref={this.score}
                      className="p-2 border border-gray-200 tw-max-w-lg tw-shadow-sm tw-block tw-w-full focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
                    />
                  </div>
                </div>
              </div>
              <div className="tw-flex-shrink-0 tw-px-4 tw-py-4 tw-flex tw-justify-end">
                <button
                  ref={this.submit}
                  type="submit"
                  className="tw-ml-4 tw-inline-flex tw-justify-center tw-py-2 tw-px-4 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default compose(firestoreConnect(), connect())(AddEmail);
