import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import T from "i18n-react";
import { isLoaded } from "react-redux-firebase";
//import Create from "../organisation/Create";
import { notifyUser } from "../../actions/notifyActions";
import Wait from "../layout/Wait";
import { getBestEmailFromProfile } from "../../helpers/mail";

class Success extends Component {
  constructor(props) {
    super(props);
    // Create refs
    this.firstname = React.createRef();
    this.lastname = React.createRef();
    this.email = React.createRef();
    this.tel = React.createRef();
    this.infos = React.createRef();
    this.submit = React.createRef();
    this.state = {
      applicationSent: false
    };
  }

  formHasError() {
    var hasError = false;
    if (this.firstname.current.value === "") {
      this.firstname.current.className = "form-control is-invalid";
      hasError = true;
    } else this.firstname.current.className = "form-control";
    if (this.lastname.current.value === "") {
      this.lastname.current.className = "form-control is-invalid";
      hasError = true;
    } else this.lastname.current.className = "form-control";

    if (this.email.current.value === "") {
      this.email.current.className = "form-control is-invalid";
      hasError = true;
    } else this.email.current.className = "form-control";
    if (this.tel.current.value === "") {
      this.tel.current.className = "form-control is-invalid";
      hasError = true;
    } else this.tel.current.className = "form-control";
    if (this.infos.current.value === "") {
      this.infos.current.className = "form-control is-invalid";
      hasError = true;
    } else this.infos.current.className = "form-control";
    return hasError;
  }

  onSubmit = e => {
    e.preventDefault();

    const { opportunity, firestore } = this.props;
    const hasErrors = this.formHasError();

    if (!hasErrors) {
      this.submit.current.setAttribute("disabled", "disabled");

      // Updated user
      const updUser = {
        application: {
          firstname: this.firstname.current.value,
          lastname: this.lastname.current.value,
          tel: this.tel.current.value,
          infos: this.infos.current.value,
          email: this.email.current.value,
          interested: true,
          date: new Date()
        },
        candidate_is_interested: true
      };

      firestore
        .update({ collection: "campaign_candidate", doc: opportunity }, updUser)
        .then(() => {
          if (this.submit !== undefined)
            if (this.submit.current) {
              window.scrollTo(0, 0);
              this.submit.current.removeAttribute("disabled");
            }
          this.setState({ applicationSent: true });
        });
    } else {
      notifyUser(T.translate("settings.error"), "error");
      if (this.submit.current) {
        window.scrollTo(0, 0);
        this.submit.current.removeAttribute("disabled");
      }
    }
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });
  render() {
    if (!isLoaded(this.props.success_profile)) {
      return <Wait />;
    }

    const email = getBestEmailFromProfile(this.props.success_profile.emails);

    if (
      this.state.applicationSent === true ||
      this.props.opportunity_infos["application"] !== undefined
    ) {
      return (
        <div className="container">
          <div className="mb-4">{T.translate("apply.success")}</div>
        </div>
      );
    } else
      return (
        <div className="container">
          <div className="mb-4">{T.translate("apply.intro")}</div>
          <form onSubmit={this.onSubmit}>
            <div className="form-group row align-items-center">
              <label className="col-3">{T.translate("apply.name")}</label>
              <div className="col">
                <input
                  type="text"
                  placeholder={T.translate("apply.name")}
                  name="firstname"
                  className="form-control"
                  ref={this.firstname}
                  defaultValue={this.props.success_profile.firstName}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="form-group row align-items-center">
              <label className="col-3">{T.translate("apply.last_name")}</label>
              <div className="col">
                <input
                  type="text"
                  placeholder={T.translate("apply.last_name")}
                  name="lastname"
                  ref={this.lastname}
                  className="form-control"
                  defaultValue={this.props.success_profile.secondName}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="form-group row align-items-center">
              <label className="col-3">{T.translate("apply.email")}</label>
              <div className="col">
                <input
                  type="email"
                  placeholder={T.translate("apply.email")}
                  name="email"
                  ref={this.email}
                  className="form-control"
                  defaultValue={email.email}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="form-group row align-items-center">
              <label className="col-3">{T.translate("apply.tel")}</label>
              <div className="col">
                <input
                  type="tel"
                  placeholder={T.translate("apply.tel")}
                  name="tel"
                  className="form-control"
                  ref={this.tel}
                  defaultValue=""
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-3">{T.translate("apply.infos")}</label>
              <div className="col">
                <textarea
                  type="text"
                  placeholder={T.translate("apply.infos")}
                  name="bio"
                  className="form-control"
                  rows="4"
                  ref={this.infos}
                  defaultValue=""
                  onChange={this.onChange}
                />
                <small>{T.translate("apply.infos_desc")}</small>
              </div>
            </div>
            <div className="row justify-content-end">
              <button
                type="submit"
                className="btn btn-primary btn-lg"
                ref={this.submit}
              >
                {T.translate("apply.save")}
              </button>
            </div>
          </form>
        </div>
      );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(props => [
    {
      collection: "profiles",
      doc: props.profile,
      storeAs: "successProfile"
    }
  ]),
  connect(
    (state, props) => ({
      auth: state.firebase.auth,
      success_profile: state.firestore.data.successProfile,
      notify: state.notify
    }),
    { notifyUser }
  )
)(Success);
