import React from "react";
import Helmet from "react-helmet";
import T from "i18n-react";

const TitleComponent = ({ title, description }) => {
  var defaultTitle = T.translate("landing.title_tag");
  var defaultDescription = T.translate("landing.desc");
  return (
    <Helmet>
      <title>{title ? title : defaultTitle}</title>
      <meta
        name="description"
        content={description ? defaultDescription : defaultDescription}
      />
      <meta
        name="og:title"
        content={defaultTitle ? defaultTitle : defaultTitle}
      />
      <meta
        name="og:description"
        content={description ? defaultDescription : defaultDescription}
      />
    </Helmet>
  );
};

export { TitleComponent };
