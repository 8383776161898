import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import TagsInput from "react-tagsinput";
import { Redirect } from "react-router-dom";

/* import { InfiniteHits } from "react-instantsearch-dom";
 */
class CampaignSearchTerms extends Component {
  constructor(props) {
    super(props);
    // Create refs
    this.keyword = React.createRef();
    this.jobtitles = React.createRef();
    this.localisation = React.createRef();
    this.skills = React.createRef();
    this.industries = React.createRef();
    this.companies = React.createRef();
    this.start = React.createRef();
    this.submit = React.createRef();
  }

  state = {
    skills: [],
    industries: [],
    companies: [],
    jobtitles: [],
    validated: false,
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log(error);
    return <Redirect to="/notfound" />;
    /* return { hasError: true }; */
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { firestore } = this.props;

    console.log("saving => ", this.props.campaign);

    this.submit.current.setAttribute("disabled", "disabled");
    const search = {
      search_keywords: this.keyword.current.value,
      search_localisation: this.localisation.current.value,
      search_skills: this.state.skills,
      search_industries: this.state.industries,
      search_companies: this.state.companies,
      search_jobtitles: this.state.jobtitles,
      skipAccounts: this.start.current.value,
      validated: this.state.validated,
    };

    console.log("saving => ", search);

    firestore
      .update({ collection: "projects", doc: this.props.campaign }, search)
      .then(() => {
        this.submit.current.removeAttribute("disabled");
      });
    /* } else {
      this.submit.current.removeAttribute("disabled");
    } */
  };
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  componentDidMount(prevProps) {
    console.log("mounted");
    if (this.props !== prevProps) {
      if (
        this.props.campaign_infos !== undefined &&
        this.props.campaign_infos.search_skills !== undefined
      )
        this.setState({ skills: this.props.campaign_infos.search_skills });

      if (
        this.props.campaign_infos !== undefined &&
        this.props.campaign_infos.validated !== undefined
      )
        this.setState({ validated: this.props.campaign_infos.validated });

      if (
        this.props.campaign_infos !== undefined &&
        this.props.campaign_infos.search_industries !== undefined
      )
        this.setState({
          industries: this.props.campaign_infos.search_industries,
        });

      if (
        this.props.campaign_infos !== undefined &&
        this.props.campaign_infos.search_companies !== undefined
      )
        this.setState({
          companies: this.props.campaign_infos.search_companies,
        });
      if (
        this.props.campaign_infos !== undefined &&
        this.props.campaign_infos.search_jobtitles !== undefined
      )
        this.setState({
          jobtitles: this.props.campaign_infos.search_jobtitles,
        });
    }
    console.log("state => ", this.state);
  }

  handlecheck = (event) => {
    const target = event.target;

    this.setState({
      validated: target.checked,
    });
  };
  handleSkills = (skills) => {
    this.setState({ skills });
  };
  handleIndustries = (industries) => {
    this.setState({ industries });
  };

  handleCompanies = (companies) => {
    this.setState({ companies });
  };

  handleJobtitles = (jobtitles) => {
    this.setState({ jobtitles });
  };

  render() {
    console.log(this.props.campaign_infos);
    return (
      <React.Fragment>
        <form
          className="tw-space-y-8 tw-divide-y tw-divide-gray-200"
          onSubmit={this.onSubmit}
        >
          <div className="tw-space-y-8 tw-divide-y tw-divide-gray-200 sm:tw-space-y-5">
            <div>
              <div className="sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-items-start sm:tw-border-t sm:tw-border-gray-200 sm:tw-pt-5">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 sm:tw-mt-px sm:tw-pt-2">
                  Mots clefs pour la recherche
                </label>
                <div className="tw-mt-1 sm:tw-mt-0 sm:tw-col-span-2">
                  <input
                    type="text"
                    ref={this.keyword}
                    className="p-2 border border-gray-200 tw-max-w-lg tw-shadow-sm tw-block tw-w-full focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
                    defaultValue={
                      this.props.campaign_infos !== undefined &&
                      this.props.campaign_infos.search_keywords !== undefined
                        ? this.props.campaign_infos.search_keywords
                        : ""
                    }
                    placeholder="keywords"
                  />
                  <p className="tw-mt-2 tw-text-sm tw-text-gray-500">
                    Write a few sentences about yourself.
                  </p>
                </div>
              </div>
              <div className="sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-items-start sm:tw-border-t sm:tw-border-gray-200 sm:tw-pt-5">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 sm:tw-mt-px sm:tw-pt-2">
                  Jobtitles
                </label>
                <div className="tw-mt-1 sm:tw-mt-0 sm:tw-col-span-2">
                  <TagsInput
                    ref={this.jobtitles}
                    className="border border-gray-200 tw-max-w-lg tw-shadow-sm tw-block tw-w-full focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
                    value={
                      this.state.jobtitles.length > 0
                        ? this.state.jobtitles
                        : this.state.jobtitles
                    }
                    onChange={this.handleJobtitles}
                    onChangeInput={this.handleJobtitles}
                    inputProps={{
                      placeholder: "Jobtitles",
                    }}
                  />
                  <p className="tw-mt-2 tw-text-sm tw-text-gray-500">
                    Write a few sentences about yourself.
                  </p>
                </div>
              </div>
              <div className="sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-items-start sm:tw-border-t sm:tw-border-gray-200 sm:tw-pt-5">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 sm:tw-mt-px sm:tw-pt-2">
                  Localisation
                </label>
                <div className="tw-mt-1 sm:tw-mt-0 sm:tw-col-span-2">
                  <input
                    className="p-2 border border-gray-200 tw-max-w-lg tw-shadow-sm tw-block tw-w-full focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
                    type="text"
                    ref={this.localisation}
                    defaultValue={
                      this.props.campaign_infos !== undefined &&
                      this.props.campaign_infos.search_localisation !==
                        undefined
                        ? this.props.campaign_infos.search_localisation
                        : ""
                    }
                    placeholder="localisation"
                  />
                  <p className="tw-mt-2 tw-text-sm tw-text-gray-500">
                    Write a few sentences about yourself.
                  </p>
                </div>
              </div>
              <div className="sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-items-start sm:tw-border-t sm:tw-border-gray-200 sm:tw-pt-5">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 sm:tw-mt-px sm:tw-pt-2">
                  Skills
                </label>
                <div className="tw-mt-1 sm:tw-mt-0 sm:tw-col-span-2">
                  <TagsInput
                    className="border border-gray-200 tw-max-w-lg tw-shadow-sm tw-block tw-w-full focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
                    ref={this.skills}
                    value={this.state.skills}
                    onChange={this.handleSkills}
                    onChangeInput={this.handleSkills}
                    inputProps={{
                      placeholder: "skills",
                    }}
                  />
                  <p className="tw-mt-2 tw-text-sm tw-text-gray-500">
                    Write a few sentences about yourself.
                  </p>
                </div>
              </div>
              <div className="sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-items-start sm:tw-border-t sm:tw-border-gray-200 sm:tw-pt-5">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 sm:tw-mt-px sm:tw-pt-2">
                  Industries
                </label>
                <div className="tw-mt-1 sm:tw-mt-0 sm:tw-col-span-2">
                  <TagsInput
                    className="border border-gray-200 tw-max-w-lg tw-shadow-sm tw-block tw-w-full focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
                    ref={this.industries}
                    value={
                      this.state.industries.length > 0
                        ? this.state.industries
                        : this.state.industries
                    }
                    onChange={this.handleIndustries}
                    onChangeInput={this.handleIndustries}
                    inputProps={{
                      placeholder: "Industries",
                    }}
                  />
                  <p className="tw-mt-2 tw-text-sm tw-text-gray-500">
                    Write a few sentences about yourself.
                  </p>
                </div>
              </div>
              <div className="sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-items-start sm:tw-border-t sm:tw-border-gray-200 sm:tw-pt-5">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 sm:tw-mt-px sm:tw-pt-2">
                  Companies
                </label>
                <div className="tw-mt-1 sm:tw-mt-0 sm:tw-col-span-2">
                  <TagsInput
                    className="border border-gray-200 tw-max-w-lg tw-shadow-sm tw-block tw-w-full focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
                    ref={this.companies}
                    value={
                      this.state.companies.length > 0
                        ? this.state.companies
                        : this.state.companies
                    }
                    onChange={this.handleCompanies}
                    onChangeInput={this.handleCompanies}
                    inputProps={{
                      placeholder: "Companies",
                    }}
                  />
                  <p className="tw-mt-2 tw-text-sm tw-text-gray-500">
                    Write a few sentences about yourself.
                  </p>
                </div>
              </div>
              <div className="sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-items-start sm:tw-border-t sm:tw-border-gray-200 sm:tw-pt-5">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 sm:tw-mt-px sm:tw-pt-2">
                  Profile number to start from (pagination)
                </label>
                <div className="tw-mt-1 sm:tw-mt-0 sm:tw-col-span-2">
                  <input
                    className="p-2 border border-gray-200 tw-max-w-lg tw-shadow-sm tw-block tw-w-full focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
                    type="number"
                    ref={this.start}
                    defaultValue={
                      this.props.campaign_infos !== undefined &&
                      this.props.campaign_infos.skipAccounts !== undefined
                        ? this.props.campaign_infos.skipAccounts
                        : ""
                    }
                    placeholder="0"
                  />
                  <p className="tw-mt-2 tw-text-sm tw-text-gray-500">
                    Write a few sentences about yourself.
                  </p>
                </div>
              </div>
              <div className="sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-items-start sm:tw-border-t sm:tw-border-gray-200 sm:tw-pt-5">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 sm:tw-mt-px sm:tw-pt-2">
                  Activate campaign
                </label>
                <div className="tw-mt-1 sm:tw-mt-0 sm:tw-col-span-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox2"
                    onChange={this.handlecheck}
                    checked={
                      this.state.validated !== null
                        ? this.state.validated
                        : this.state.validated
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="tw-pt-5">
            <div className="tw-flex tw-justify-end">
              <button
                ref={this.submit}
                type="submit"
                className="tw-ml-3 tw-inline-flex tw-justify-center tw-py-2 tw-px-4 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default compose(firestoreConnect(), connect())(CampaignSearchTerms);
