import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import T from "i18n-react";
import { Link } from "react-router-dom";
import { getCampaignPrice, getSeatPrice } from "../../helpers/offers";
import BlogPosts from "./BlogPosts";

class Home extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        <main className="lg:tw-relative  tw-bg-gray-50">
          <div className="tw-relative tw-mx-auto tw-max-w-7xl tw-w-full tw-pt-16 tw-pb-20 tw-text-center lg:tw-py-48 lg:tw-text-left">
            <div className="tw-px-4 lg:tw-w-1/2 sm:tw-px-8 xl:tw-pr-16">
              <span className="tw-text-4xl tw-tracking-tight tw-font-extrabold tw-text-gray-900 sm:tw-text-5xl md:tw-text-6xl lg:tw-text-5xl xl:tw-text-6xl">
                <span className="tw-block xl:tw-inline">
                  {T.translate("landing.title")}
                </span>{" "}
                <span className="tw-block tw-text-blue-600 xl:tw-inline">
                  {T.translate("landing.s_candidates")}
                </span>
              </span>
              <p className="tw-semi-bold tw-mt-3 tw-max-w-md tw-mx-auto tw-text-lg tw-text-gray-700 sm:tw-text-xl md:tw-mt-5 md:tw-max-w-3xl">
                {T.translate("landing.desc")}
              </p>
              <div className="tw-mt-10 sm:tw-flex sm:tw-justify-center lg:tw-justify-start">
                <div className="tw-rounded-md tw-shadow">
                  <a
                    href="/signup"
                    className="tw-w-full tw-flex tw-items-center tw-justify-center tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 hover:tw-text-white md:tw-py-4 md:tw-text-lg md:tw-px-10"
                  >
                    {T.translate("landing.header_action")}
                  </a>
                </div>
                <div className="tw-mt-3 tw-rounded-md tw-shadow sm:tw-mt-0 sm:tw-ml-3">
                  <a
                    href="/signup"
                    className="tw-w-full tw-flex tw-items-center tw-justify-center tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-md tw-text-blue-600 tw-bg-white tw-hover:bg-gray-50 md:tw-py-4 md:tw-text-lg md:tw-px-10"
                  >
                    {T.translate("landing.demo")}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="tw-relative tw-w-full tw-h-64 sm:tw-h-72 md:tw-h-96 lg:tw-absolute lg:tw-inset-y-0 lg:tw-right-0 lg:tw-w-1/2 lg:tw-h-full">
            <img
              className="tw-max-w-2xl tw-absolute tw-inset-0 tw-w-full tw-h-full tw-object-cover"
              src="/theme/img/landing/5.svg"
              alt=""
            />
          </div>
        </main>
        <div className="tw-bg-blue-200 tw-bg-opacity-25">
          <div className="tw-max-w-7xl tw-mx-auto tw-py-16 px-4 sm:tw-py-24 sm:tw-px-6 lg:tw-px-8">
            <div className="lg:tw-grid lg:tw-grid-cols-2 lg:tw-gap-8">
              <h2 className="tw-max-w-md tw-mx-auto tw-text-3xl tw-font-extrabold tw-text-blue-900 tw-text-center lg:tw-max-w-xl lg:tw-text-left">
                {T.translate("landing.theyuse")}
              </h2>
              <div className="tw-flow-root tw-self-center tw-mt-8 tw-lg:mt-0">
                <div className="tw--mt-4 tw-ml-8 tw-flex tw-flex-wrap tw-justify-between lg:tw--ml-4">
                  <div className="tw-mt-4 tw-ml-8 tw-flex tw-flex-grow tw-flex-shrink-0 tw-justify-center lg:tw-flex-grow-0 lg:tw-ml-4">
                    <img
                      className="tw-h-10"
                      src="/theme/img/landing/credispring.svg"
                      alt="Workcation"
                    />
                  </div>
                  <div className="tw-mt-4 tw-ml-8 tw-flex tw-flex-grow tw-flex-shrink-0 tw-justify-center lg:tw-flex-grow-0 lg:tw-ml-4">
                    <img
                      className="tw-h-10"
                      src="/theme/img/landing/casper.svg"
                      alt="Tuple"
                    />
                  </div>
                  <div className="tw-mt-4 tw-ml-8 tw-flex tw-flex-grow tw-flex-shrink-0 tw-justify-center lg:tw-flex-grow-0 lg:tw-ml-4">
                    <img
                      className="tw-h-10"
                      src="/theme/img/landing/qantev.png"
                      alt="Level"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-py-12 tw-bg-white">
          <div className="tw-max-w-7xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8">
            <div className="lg:tw-text-center">
              <p
                className="tw-mt-2 tw-text-3xl tw-leading-8 tw-font-extrabold
            tw-tracking-tight tw-text-gray-900 sm:tw-text-4xl"
              >
                {T.translate("landing.how_")}
              </p>
              <p className="tw-mt-4 tw-max-w-2xl tw-text-xl tw-text-gray-500 lg:tw-mx-auto">
                {T.translate("landing.how_desc_")}
              </p>
            </div>
            <div className="tw-mt-10 tw-max-w-xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-max-w-7xl lg:tw-px-8">
              <dl className="tw-space-y-10 lg:tw-space-y-0 lg:tw-grid lg:tw-grid-cols-3 lg:tw-gap-8">
                <div>
                  <div className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-md tw-bg-blue-500 tw-text-white">
                    <svg
                      className="tw-h-6 tw-w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
                      />
                    </svg>
                  </div>
                  <div className="tw-mt-5">
                    <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {T.translate("landing.step1")}
                    </dt>
                    <dd className="tw-mt-2 tw-text-base tw-text-gray-500">
                      {T.translate("landing.step1_desc")}
                    </dd>
                  </div>
                </div>

                <div>
                  <div className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-md tw-bg-blue-500 tw-text-white">
                    <svg
                      className="tw-h-6 tw-w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                      />
                    </svg>
                  </div>
                  <div className="tw-mt-5">
                    <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {T.translate("landing.step2")}
                    </dt>
                    <dd className="tw-mt-2 tw-text-base tw-text-gray-500">
                      {T.translate("landing.step2_desc")}
                    </dd>
                  </div>
                </div>

                <div>
                  <div className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-md tw-bg-blue-500 tw-text-white">
                    <svg
                      className="tw-h-6 tw-w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                      />
                    </svg>
                  </div>
                  <div className="tw-mt-5">
                    <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {T.translate("landing.step3")}
                    </dt>
                    <dd className="tw-mt-2 tw-text-base tw-text-gray-500">
                      {T.translate("landing.step3_desc")}
                    </dd>
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div className="tw-bg-gray-50 tw-overflow-hidden">
          <div className="tw-max-w-7xl tw-mx-auto tw-text-center tw-py-12 tw-px-4 sm:tw-px-6 lg:tw-py-16 lg:tw-px-8">
            <h2 className="tw-text-3xl tw-font-extrabold tw-tracking-tight tw-text-gray-900 sm:tw-text-4xl">
              <span className="tw-block">{T.translate("landing.dive")}</span>
              <span className="tw-block">
                {T.translate("landing.start_hiring")}
              </span>
            </h2>
            <div className="tw-mt-8 tw-flex tw-justify-center">
              <div className="tw-inline-flex tw-rounded-md tw-shadow">
                <a
                  href="/signup"
                  className="tw-inline-flex tw-items-center tw-justify-center tw-px-5 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-600 hover:tw-text-white hover:tw-bg-blue-700"
                >
                  {T.translate("landing.get_started")}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-py-12 tw-bg-white">
          <div className="tw-max-w-7xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8">
            <div className="lg:tw-text-center">
              <h2 className="tw-text-base tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                {T.translate("landing.Sourcing")}
              </h2>
              <p className="tw-mt-2 tw-text-3xl tw-leading-8 tw-font-extrabold tw-tracking-tight tw-text-gray-900 sm:tw-text-4xl">
                {T.translate("landing.better_way")}
              </p>
              <p className="tw-mt-4 tw-max-w-2xl tw-text-xl tw-text-gray-500 lg:tw-mx-auto">
                {T.translate("landing.better_way_desc")}
              </p>
            </div>

            <div className="tw-mt-10">
              <dl className="tw-space-y-10 md:tw-space-y-0 md:tw-grid md:tw-grid-cols-2 md:tw-gap-x-8 md:tw-gap-y-10">
                <div className="tw-flex">
                  <div className="tw-flex-shrink-0">
                    <div className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-md tw-bg-blue-500 tw-text-white">
                      {/* <!-- Heroicon name: globe-alt --> */}
                      <svg
                        className="tw-h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="tw-ml-4">
                    <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {T.translate("landing.web")}
                    </dt>
                    <dd className="tw-mt-2 tw-text-base tw-text-gray-600">
                      {T.translate("landing.web_desc")}
                    </dd>
                  </div>
                </div>

                <div className="tw-flex">
                  <div className="tw-flex-shrink-0">
                    <div className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-md tw-bg-blue-500 tw-text-white">
                      {/*  <!-- Heroicon name: scale --> */}
                      <svg
                        className="tw-h-6 tw-w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="tw-ml-4">
                    <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {T.translate("landing.fees")}
                    </dt>
                    <dd className="tw-mt-2 tw-text-base tw-text-gray-600">
                      {T.translate("landing.fees_desc")}
                    </dd>
                  </div>
                </div>

                <div className="tw-flex">
                  <div className="tw-flex-shrink-0">
                    <div className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-md tw-bg-blue-500 tw-text-white">
                      {/* <!-- Heroicon name: lightning-bolt --> */}
                      <svg
                        className="tw-h-6 tw-w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 10V3L4 14h7v7l9-11h-7z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="tw-ml-4">
                    <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {T.translate("landing.ai")}
                    </dt>
                    <dd className="tw-mt-2 tw-text-base tw-text-gray-600">
                      {T.translate("landing.ai_desc")}
                    </dd>
                  </div>
                </div>

                <div className="tw-flex">
                  <div className="tw-flex-shrink-0">
                    <div className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-md tw-bg-blue-500 tw-text-white">
                      {/* <!-- Heroicon name: annotation --> */}
                      <svg
                        className="tw-h-6 tw-w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="tw-ml-4">
                    <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {T.translate("landing.ready")}
                    </dt>
                    <dd className="tw-mt-2 tw-text-base tw-text-gray-600">
                      {T.translate("landing.ready_desc")}
                    </dd>
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div className="tw-relative tw-bg-gray-800">
          <div className="tw-h-56 tw-bg-blue-600 tw-sm:h-72 md:tw-absolute md:tw-left-0 md:tw-h-full md:tw-w-1/2">
            <img
              className="tw-w-full tw-h-full tw-object-cover"
              src="https://images.unsplash.com/photo-1557425493-6f90ae4659fc?ixlib=rb-1.2.1&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80"
              alt=""
            />
          </div>
          <div className="tw-relative tw-max-w-7xl tw-mx-auto tw-px-4 tw-py-12 sm:tw-px-6 lg:tw-px-8 lg:tw-py-16">
            <div className="md:tw-ml-auto md:tw-w-1/2 md:tw-pl-10">
              <h2 className="tw-text-base tw-font-semibold tw-uppercase tw-tracking-wider tw-text-gray-300">
                {T.translate("landing.time")}
              </h2>
              <p className="tw-mt-2 tw-text-white tw-text-3xl tw-font-extrabold tw-tracking-tight sm:tw-text-4xl">
                <span className="tw-tw-block">
                  {T.translate("landing.economy")}{" "}
                  <span style={{ textDecoration: "underline" }}>
                    62 {T.translate("landing.hours")}
                  </span>
                </span>
              </p>
              <p className="tw-mt-3 tw-text-lg tw-text-gray-300">
                {T.translate("landing.time_desc")}
              </p>
              <div className="tw-mt-8">
                <div className="tw-inline-flex tw-rounded-md tw-shadow">
                  <Link
                    to="/time"
                    className="tw-inline-flex tw-items-center tw-justify-center tw-px-5 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-md tw-text-gray-900 tw-bg-white hover:tw-bg-gray-50"
                  >
                    {T.translate("landing.link")}
                    {/* <!-- Heroicon name: external-link --> */}
                    <svg
                      className="tw--mr-1 tw-ml-3 tw-h-5 tw-w-5 tw-text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                      <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-bg-gray-50 tw-overflow-hidden">
          <div className="tw-relative tw-max-w-7xl tw-mx-auto tw-py-12 tw-px-4 sm:tw-px-6 lg:tw-px-8">
            <svg
              className="tw-absolute tw-top-0 tw-left-full tw-transform tw--translate-x-1/2 tw--translate-y-3/4 lg:tw-left-auto lg:tw-right-full lg:tw-translate-x-2/3 lg:tw-translate-y-1/4"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="tw-text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
              />
            </svg>

            <div className="tw-relative lg:tw-grid lg:tw-grid-cols-3 lg:tw-gap-x-8">
              <div className="lg:tw-col-span-1">
                <h2 className="tw-text-3xl tw-font-extrabold tw-tracking-tight tw-text-gray-900 sm:tw-text-4xl">
                  {T.translate("landing.better_way")}
                </h2>
              </div>
              <dl className="tw-mt-10 tw-space-y-10 sm:tw-space-y-0 sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-x-8 sm:tw-gap-y-10 lg:tw-mt-0 lg:tw-col-span-2">
                <div>
                  <div className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-md tw-bg-blue-500 tw-text-white">
                    {/* <!-- Heroicon name: globe-alt --> */}
                    <svg
                      className="tw-h-6 tw-w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <div className="tw-mt-3">
                    <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {T.translate("landing.why")}
                    </dt>
                    <dd className="tw-mt-2 tw-text-base tw-text-gray-500">
                      {T.translate("landing.why_desc")}
                    </dd>
                  </div>
                </div>

                <div>
                  <div className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-md tw-bg-blue-500 tw-text-white">
                    {/* <!-- Heroicon name: scale --> */}
                    <svg
                      className="tw-h-6 tw-w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                  </div>
                  <div className="tw-mt-3">
                    <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {T.translate("landing.who")}
                    </dt>
                    <dd className="tw-mt-2 tw-text-base tw-text-gray-500">
                      {T.translate("landing.who_desc")}
                    </dd>
                  </div>
                </div>

                <div>
                  <div className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-md tw-bg-blue-500 tw-text-white">
                    {/* <!-- Heroicon name: lightning-bolt --> */}
                    <svg
                      className="tw-h-6 tw-w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
                      />
                    </svg>
                  </div>
                  <div className="tw-mt-3">
                    <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {T.translate("landing.how")}
                    </dt>
                    <dd className="tw-mt-2 tw-text-base tw-text-gray-500">
                      {T.translate("landing.how_desc")}
                    </dd>
                  </div>
                </div>

                <div>
                  <div className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-md tw-bg-blue-500 tw-text-white">
                    {/* <!-- Heroicon name: mail --> */}
                    <svg
                      className="tw-h-6 tw-w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <div className="tw-mt-3">
                    <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {T.translate("landing.price")}
                    </dt>
                    <dd className="tw-mt-2 tw-text-base tw-text-gray-500">
                      {T.translate("landing.price_desc")}
                    </dd>
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div id="prix" className="tw-bg-gray-100">
          <div className="tw-pt-12 sm:tw-pt-16 lg:tw-pt-20">
            <div className="tw-max-w-7xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8">
              <div className="tw-text-center">
                <h2 className="tw-text-3xl tw-font-extrabold tw-text-gray-900 sm:tw-text-4xl lg:tw-text-5xl">
                  {T.translate("landing.tricks")}
                </h2>
                <p className="tw-mt-4 tw-text-xl tw-text-gray-600">
                  {T.translate("landing.tricks_desc")}
                </p>
              </div>
            </div>
          </div>
          <div className="tw-mt-8 tw-bg-white tw-pb-16 sm:tw-mt-12 sm:tw-pb-20 lg:tw-pb-28">
            <div className="tw-relative">
              <div className="tw-absolute tw-inset-0 tw-h-1/2 tw-bg-gray-100"></div>
              <div className="tw-relative tw-max-w-7xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8">
                <div className="tw-max-w-lg tw-mx-auto tw-rounded-lg tw-shadow-lg tw-overflow-hidden lg:tw-max-w-none lg:tw-flex">
                  <div className="tw-flex-1 tw-bg-white tw-px-6 tw-py-8 lg:tw-p-12">
                    <h3 className="tw-text-2xl tw-font-extrabold tw-text-gray-900 sm:tw-text-3xl">
                      {T.translate("landing.as_you_go")}
                    </h3>
                    <p className="tw-mt-6 tw-text-base tw-text-gray-500">
                      {T.translate("landing.as_you_go_desc")}
                    </p>
                    <div className="tw-mt-8">
                      <div className="tw-flex tw-items-center">
                        <h4 className="tw-flex-shrink-0 tw-pr-4 tw-bg-white tw-text-sm tw-tracking-wider tw-font-semibold tw-uppercase tw-text-blue-600">
                          {T.translate("landing.included")}
                        </h4>
                        <div className="tw-flex-1 tw-border-t-2 tw-border-gray-200"></div>
                      </div>
                      <ul className="tw-mt-8 tw-space-y-5 lg:tw-space-y-0 lg:tw-grid lg:tw-grid-cols-2 lg:tw-gap-x-8 lg:tw-gap-y-5">
                        <li className="tw-flex tw-items-start lg:tw-col-span-1">
                          <div className="tw-flex-shrink-0">
                            {/* <!-- Heroicon name: check-circle --> */}
                            <svg
                              className="tw-h-5 tw-w-5 tw-text-green-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                          <p className="tw-ml-3 tw-text-sm tw-text-gray-700">
                            {T.translate("landing.pay_candidates")}
                          </p>
                        </li>

                        <li className="tw-flex tw-items-start lg:tw-col-span-1">
                          <div className="tw-flex-shrink-0">
                            {/* <!-- Heroicon name: check-circle --> */}
                            <svg
                              className="tw-h-5 tw-w-5 tw-text-green-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                          <p className="tw-ml-3 tw-text-sm tw-text-gray-700">
                            {T.translate("landing.pay_price")}
                          </p>
                        </li>

                        <li className="tw-flex tw-items-start lg:tw-col-span-1">
                          <div className="tw-flex-shrink-0">
                            {/* <!-- Heroicon name: check-circle --> */}
                            <svg
                              className="tw-h-5 tw-w-5 tw-text-green-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                          <p className="tw-ml-3 tw-text-sm tw-text-gray-700">
                            {T.translate("landing.ai_automation")}
                          </p>
                        </li>

                        <li className="tw-flex tw-items-start lg:tw-col-span-1">
                          <div className="tw-flex-shrink-0">
                            {/* <!-- Heroicon name: check-circle --> */}
                            <svg
                              className="tw-h-5 tw-w-5 tw-text-green-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                          <p className="tw-ml-3 tw-text-sm tw-text-gray-700">
                            {T.translate("landing.team_collab")}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tw-py-8 tw-px-6 tw-text-center tw-bg-gray-50 lg:tw-flex-shrink-0 lg:tw-flex lg:tw-flex-col lg:jtw-ustify-center lg:tw-p-12">
                    <p className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      1 {T.translate("landing.campaign")}
                    </p>
                    <div className="tw-mt-4 tw-flex tw-items-center tw-justify-center tw-text-5xl tw-font-extrabold tw-text-gray-900">
                      <span>
                        {T.translate("landing.euro")}
                        {getCampaignPrice()}
                      </span>
                      <span className="tw-ml-3 tw-text-xl tw-font-medium tw-text-gray-500">
                        / {T.translate("landing.month")}
                      </span>
                    </div>
                    <p className="tw-mt-4 tw-text-sm">
                      <a
                        href="/price"
                        className="font-medium tw-text-gray-500 tw-underline"
                      >
                        {T.translate("landing.estimate")}
                      </a>
                    </p>
                    <div className="tw-mt-6">
                      <div className="tw-rounded-md tw-shadow">
                        <a
                          href="/signup"
                          className="tw-flex tw-items-center tw-justify-center tw-px-5 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-md tw-text-white tw-bg-gray-800 hover:tw-text-white hover:tw-bg-gray-900"
                        >
                          {T.translate("landing.start")}
                        </a>
                      </div>
                    </div>
                    <div className="tw-mt-4 tw-text-sm tw-font-medium tw-text-gray-900">
                      {getSeatPrice() + T.translate("landing.pay_seat")}
                      <span className="tw-font-normal tw-text-gray-500">
                        ({T.translate("landing.user")})
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BlogPosts />
      </React.Fragment>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  connect((state, props) => ({
    auth: state.firebase.auth,
  }))
)(Home);
