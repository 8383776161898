import React from "react";
import PropTypes from "prop-types";
import { Transition } from "@headlessui/react";
import { closeNotification } from "../../actions/notifyActions";
import { compose } from "redux";
import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";

const Alert = (props) => {
  const { message, messageType, isOpen } = props;
  /*   const [isOpen, setIsOpen] = useState(storeIsOpen);
   */
  var svg = (
    <svg
      className="tw-h-6 tw-w-6 tw-text-red-400"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
      />
    </svg>
  );
  var title = "Error!";

  if (messageType === "success") {
    title = "Success!";
    svg = (
      <svg
        className="tw-h-6 tw-w-6 tw-text-green-400"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    );
  }

  return (
    <div
      className={`${
        !isOpen ? "tw-hidden" : ""
      } tw-z-50 tw-fixed tw-inset-0 tw-flex tw-items-end tw-justify-center tw-px-4 tw-py-6 tw-pointer-events-none sm:tw-p-6 sm:tw-items-start sm:tw-justify-end`}
    >
      <Transition
        show={isOpen}
        enter="tw-transform tw-ease-out tw-duration-300 tw-transition"
        enterFrom="tw-translate-y-2 tw-opacity-0 sm:tw-translate-y-0 sm:tw-translate-x-2"
        enterTo="tw-translate-y-0 tw-opacity-100 sm:tw-translate-x-0"
        leave="tw-transition tw-ease-in tw-duration-100"
        leaveFrom="tw-opacity-100"
        leaveTo="tw-opacity-0"
      ></Transition>
      <div className="tw-max-w-sm tw-w-full tw-bg-white tw-shadow-lg tw-rounded-lg tw-pointer-events-auto tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-overflow-hidden">
        <div className="tw-p-4">
          <div className="tw-flex tw-items-start">
            <div className="tw-flex-shrink-0">{svg}</div>
            <div className="tw-ml-3 tw-w-0 tw-flex-1 tw-pt-0.5">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              <p className="tw-mt-1 tw-text-sm tw-text-gray-500">{message}</p>
            </div>
            <div className="tw-ml-4 tw-flex-shrink-0 tw-flex">
              <button
                onClick={() => props.closeNotification()}
                className="tw-bg-white tw-rounded-md tw-inline-flex tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
              >
                <span className="tw-sr-only">Close</span>
                <svg
                  className="tw-h-5 tw-w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Alert.propTypes = {
  messageType: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default compose(
  firebaseConnect(),
  connect(
    (state, props) => ({
      auth: state.firebase.auth,
      notify: state.notify,
    }),
    { closeNotification }
  )
)(Alert);
