import React, { Component } from "react";
import PropTypes from "prop-types";
import { isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
/* import ProjectCard from "../loaders/ProjectCard";
 */
import T from "i18n-react";
import { candidateStepPercent } from "../../helpers/candidates";
import { getColorFromString } from "../../helpers/global";
import Contributors from "./Contributors";

class Project extends Component {
  state = {
    project: null,
  };

  render() {
    const key = this.props.project;
    if (
      !isLoaded(this.props.user_projects) &&
      !isLoaded(this.props.project_candidate) &&
      !isLoaded(this.props.contributors) &&
      this.props.user_projects !== undefined &&
      this.props.user_projects[key] !== undefined
    ) {
      return "";
    }

    if (
      this.props.user_projects !== undefined &&
      this.props.user_projects[key] !== undefined &&
      this.props.user_projects[key].archived !== true
    ) {
      const project_infos = this.props.user_projects[key];

      var active = false;
      if (
        project_infos.stripe_subscription !== undefined &&
        project_infos.stripe_subscription !== null &&
        project_infos.stripe_subscription !== ""
      ) {
        active = true;
      }

      var numberOfCandidates = 0;
      var progression = 10;
      var tmp_prog = 0;

      if (
        this.props.project_candidate !== undefined &&
        this.props.project_candidate !== null
      ) {
        const candidate_ids = Object.keys(this.props.project_candidate);
        const numberOfUnreached =
          this.props.notContactedYetCandidates !== undefined &&
          this.props.notContactedYetCandidates !== null
            ? Object.keys(this.props.notContactedYetCandidates).length
            : 0;
        numberOfCandidates = candidate_ids.length - numberOfUnreached;
        candidate_ids.map((candidate_id) => {
          tmp_prog = candidateStepPercent(
            this.props.project_candidate[candidate_id].step
          );
          if (tmp_prog > progression) progression = tmp_prog;
          return true;
        });
      }

      var timeframe = "";
      if (project_infos.date !== null && project_infos.date !== undefined) {
        var d = project_infos.date.split("-");
        timeframe = Date(d[0], d[1], d[2]) - Date();
        var s = new Date(d[0], d[1], d[2]);
        var date1 = new Date();
        var timeDiff = s.getTime() - date1.getTime();
        timeframe = Math.ceil(timeDiff / (1000 * 3600 * 24));
      }

      var contributors = [];
      if (
        this.props.contributors !== undefined &&
        this.props.contributors !== null
      ) {
        contributors = Object.keys(this.props.contributors);
      }

      var bgColor = getColorFromString(project_infos.jobTitle);

      return (
        <React.Fragment>
          <div key={key} className="col-lg-6">
            <div className="card card-project">
              <div className="progress">
                <div
                  className={`progress-bar `}
                  role="progressbar"
                  style={{
                    width: `${progression}%`,
                    backgroundColor: "#" + bgColor,
                  }}
                  aria-valuenow="60"
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>

              <div className="card-body">
                {this.props.profileView !== true ? (
                  <div className="dropdown card-options">
                    <button
                      className="btn-options"
                      type="button"
                      id="project-dropdown-button-1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="material-icons">more_vert</i>
                    </button>

                    <div className="dropdown-menu dropdown-menu-right">
                      <a
                        href={`/campaign/dashboard/${key}`}
                        className="dropdown-item"
                      >
                        {T.translate("home.view")}
                      </a>
                      <a
                        href={`/campaign/edit/${key}`}
                        className="dropdown-item"
                      >
                        {T.translate("home.edit")}
                      </a>
                      {/*
                  <a className="dropdown-item" href={`/campaign/edit/${key}`}>
                    {T.translate("home.edit")}
                  </a>
                  <a className="dropdown-item" href="#">
                    {T.translate("home.share")}
                  </a>*/}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="card-title">
                  <a href={`/campaign/dashboard/${key}`}>
                    <h5 data-filter-by="text" className="ft4 H5-filter-by-text">
                      {project_infos.jobTitle.toUpperCase()}
                    </h5>
                  </a>
                </div>
                <hr />
                <div className="mb-3">
                  {/* project_infos.description.substring(0, 60) + "..." */}
                </div>

                <ul className="avatars">
                  {contributors.map((project_rel) => {
                    const p = this.props.contributors[project_rel];
                    if (p.project === key) {
                      return (
                        <Contributors key={p.user_id} id_user={p.user_id} />
                      );
                    }
                    return false;
                  })}
                </ul>

                <div className="card-meta d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    {numberOfCandidates === 0 && active === true ? (
                      <i className="material-icons mr-1">cached</i>
                    ) : (
                      <i className="material-icons mr-1">assignment_ind</i>
                    )}

                    <span className="text-small">
                      {numberOfCandidates === 0 && active === true
                        ? "Sourcing en cours..."
                        : numberOfCandidates + " Candidates"}
                    </span>
                    {active === false ? (
                      <a
                        className="ml-4 badge badge-danger"
                        href={`/campaign/dashboard/${key}`}
                      >
                        Campagne Inactive !
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                  <span
                    className="text-small SPAN-filter-by-text"
                    data-filter-by="text"
                  >
                    {timeframe < 0
                      ? Math.abs(timeframe) + " days late"
                      : `Closes in ${Math.abs(timeframe)} days`}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return "";
    }
  }
}

Project.propTypes = {
  project: PropTypes.string.isRequired,
};

export default compose(
  firebaseConnect(),
  firestoreConnect((props) => [
    {
      collection: "projects",
      doc: props.project,
    },
    {
      collection: "campaign_candidate",
      where: ["campaign", "==", props.project],
      storeAs: props.project,
    },
    {
      collection: "campaign_candidate",
      where: [
        ["campaign", "==", props.project],
        ["step", "==", "found"],
      ],
      storeAs: props.project + "_found",
    },
    {
      collection: "users_projects",
      where: ["project", "==", props.project],
      storeAs: "contributors_" + props.project,
    },
  ]),
  connect((state, props) => ({
    user_projects: state.firestore.data.projects,
    contributors: state.firestore.data["contributors_" + props.project],
    notContactedYetCandidates: state.firestore.data[props.project + "_found"],
    project_candidate: state.firestore.data[props.project],
    auth: state.firebase.auth,
    notify: state.notify,
  }))
)(Project);
