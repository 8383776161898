import {
  CLOSE_NOTIFICATION,
  NOTIFY_USER,
  SET_PROGRESS_BAR,
} from "../actions/types";

const initialState = {
  message: null,
  messageType: null,
  isOpen: false,
  progressBarStatus: "OPEN",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NOTIFY_USER:
      return {
        ...state,
        message: action.message,
        messageType: action.messageType,
        isOpen: true,
      };
    case CLOSE_NOTIFICATION:
      return {
        ...state,
        isOpen: false,
      };
    case SET_PROGRESS_BAR:
      //return Object.assign({}, state, { progressBarStatus: action.isOpen });
      return {
        ...state,
        progressBarStatus: action.isOpen,
      };
    default:
      return state;
  }
}
