import React, { Component } from "react";
import T from "i18n-react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isLoaded } from "react-redux-firebase";
import { notifyUser } from "../../actions/notifyActions";
import { AddUserToNewProject } from "../../helpers/project";
import Wait from "../layout/Wait";
import { Redirect } from "react-router-dom";
import TagsInput from "react-tagsinput";
import RichTextEditor from "react-rte";

class CreateProject extends Component {
  state = {
    skills: [],
    description: RichTextEditor.createEmptyValue(),
  };
  constructor(props) {
    super(props);
    // Create refs
    this.jobTitle = React.createRef();
    this.level = React.createRef();
    this.fonction = React.createRef();
    this.city = React.createRef();
    this.country = React.createRef();
    this.type = React.createRef();
    this.description_desc = React.createRef();
    this.experienceYears = React.createRef();
    this.date = React.createRef();
    //this.skills = React.createRef();
    this.studies = React.createRef();
    this.language = React.createRef();
    this.submit = React.createRef();
    this.organisation_id = "";
  }

  formHasError() {
    var hasError = false;
    if (this.date.current.value === "") {
      this.date.current.className = "form-control is-invalid";
      hasError = true;
    } else this.date.current.className = "form-control";
    if (this.jobTitle.current.value === "") {
      this.jobTitle.current.className = "form-control is-invalid";
      hasError = true;
    } else this.jobTitle.current.className = "form-control";
    if (this.level.current.value === "") {
      this.level.current.className = "form-control is-invalid";
      hasError = true;
    } else this.level.current.className = "form-control";

    if (this.fonction.current.value === "") {
      this.fonction.current.className = "form-control is-invalid";
      hasError = true;
    } else this.fonction.current.className = "form-control";
    if (this.city.current.value === "") {
      this.city.current.className = "form-control is-invalid";
      hasError = true;
    } else this.city.current.className = "form-control";
    if (this.type.current.value === "") {
      this.type.current.className = "form-control is-invalid";
      hasError = true;
    } else this.type.current.className = "form-control";
    if (this.experienceYears.current.value === "") {
      this.experienceYears.current.className = "form-control is-invalid";
      hasError = true;
    } else this.experienceYears.current.className = "form-control";
    if (this.country.current.value === "") {
      this.country.current.className = "form-control is-invalid";
      hasError = true;
    } else this.country.current.className = "form-control";
    if (
      this.state.description.toString("html").replace(/<\/?[^>]+(>|$)/g, "") ===
      ""
    ) {
      this.description_desc.current.className = "form-control is-invalid";
      hasError = true;
    } else this.description_desc.current.className = "form-control";
    if (this.studies.current.value === "") {
      this.studies.current.className = "form-control is-invalid";
      hasError = true;
    } else this.studies.current.className = "form-control";
    if (this.language.current.value === "") {
      this.language.current.className = "form-control is-invalid";
      hasError = true;
    } else this.language.current.className = "form-control";
    return hasError;
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { notifyUser, firestore } = this.props;

    const hasErrors = this.formHasError();

    if (!hasErrors) {
      this.submit.current.setAttribute("disabled", "disabled");

      let d = new Date();
      let creationDate =
        d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
      // Updated user
      const organisation = {
        jobTitle: this.jobTitle.current.value,
        level: this.level.current.value,
        fonction: this.fonction.current.value,
        city: this.city.current.value,
        country: this.country.current.value,
        date: this.date.current.value,
        type: this.type.current.value,
        description: this.state.description.toString("html"),
        experienceYears: this.experienceYears.current.value,
        studies: this.studies.current.value,
        language: this.language.current.value,
        organisation: this.organisation_id,
        archived: false,
        skills: this.state.skills,
        created: creationDate,
        validated: false,
        createdBy: this.props.auth.uid,
      };
      firestore.add({ collection: "projects" }, organisation).then((infos) => {
        this.submit.current.removeAttribute("disabled");
        AddUserToNewProject(
          firestore,
          infos.id,
          this.props.auth.uid,
          "creator",
          organisation.organisation
        );
        this.props.history.push(`/campaign/dashboard/` + infos.id);
      });
    } else {
      notifyUser(T.translate("settings.error"), "error");
      this.submit.current.removeAttribute("disabled");
    }
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onTextChange = (value) => {
    this.setState({ description: value });
  };

  handleSkills = (skills) => {
    this.setState({ skills });
  };

  render() {
    if (
      !isLoaded(this.props.users_infos) ||
      !isLoaded(this.props.roles) ||
      this.props.users_infos === undefined ||
      !isLoaded(this.props.users_organisations)
    ) {
      return <Wait />;
    }

    const organisation_rel_id = Object.keys(this.props.users_organisations)[0];
    this.organisation_id = this.props.users_organisations[
      organisation_rel_id
    ].organisation;

    const access = this.props.users_organisations[organisation_rel_id].role;

    if (!this.props.roles[access].create_project)
      return <Redirect to="/home" />;

    const toolbarConfig = {
      // Optionally specify the groups to display (displayed in the order listed).
      display: [
        "INLINE_STYLE_BUTTONS",
        "BLOCK_TYPE_BUTTONS",
        "LINK_BUTTONS",
        "BLOCK_TYPE_DROPDOWN",
        "HISTORY_BUTTONS",
      ],
      INLINE_STYLE_BUTTONS: [
        { label: "Bold", style: "BOLD", className: "custom-css-class" },
        { label: "Italic", style: "ITALIC" },
        { label: "Underline", style: "UNDERLINE" },
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: "Normal", style: "unstyled" },
        { label: "Heading Large", style: "header-one" },
        { label: "Heading Medium", style: "header-two" },
        { label: "Heading Small", style: "header-three" },
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: "UL", style: "unordered-list-item" },
        { label: "OL", style: "ordered-list-item" },
      ],
    };

    var language =
      (navigator.languages && navigator.languages[0]) || navigator.language;
    if (language.length > 2) {
      language = language.split("-")[0];
      language = language.split("_")[0];
    }
    return (
      <>
        <div className="tw-bg-gray-100">
          <div className="tw-max-w-7xl tw-mx-auto tw-py-6 sm:tw-px-6 lg:tw-px-8">
            <div>
              <div className="md:tw-grid md:tw-grid-cols-3 tw-p-8 md:tw-gap-6">
                <div className="md:tw-col-span-3">
                  <div className="tw-px-4 sm:tw-px-0">
                    <h1 className="tw-text-2xl tw-font-medium tw-leading-6 tw-text-gray-900">
                      {T.translate("project.welcome")}
                    </h1>
                    <p className="tw-mt-1 tw-text-sm tw-text-gray-600">
                      {T.translate("project.welcomeDescription")}
                    </p>
                  </div>
                </div>

                <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
                  <form onSubmit={this.onSubmit}>
                    <div className="tw-shadow sm:tw-rounded-md sm:tw-overflow-hidden">
                      <div className="tw-px-4 tw-py-5 tw-bg-white tw-space-y-6 sm:tw-p-6">
                        <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                          <div className="tw-col-span-3 sm:tw-col-span-2">
                            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                              {T.translate("project.jobtitle")}
                            </label>
                            <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                              <input
                                type="text"
                                placeholder={T.translate("project.jobtitle")}
                                name="jobTitle"
                                className="form-control"
                                ref={this.jobTitle}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                            {T.translate("project.level")}
                          </label>
                          <div className="tw-mt-1">
                            <select
                              placeholder={T.translate("project.level")}
                              name="level"
                              className="form-control"
                              ref={this.level}
                              onChange={this.onChange}
                            >
                              <option value="">
                                {T.translate("project.level")}
                              </option>
                              <option value="INTERNSHIP">
                                {T.translate("seniority.internship")}
                              </option>
                              <option value="ENTRY_LEVEL">
                                {T.translate("seniority.entry")}
                              </option>
                              <option value="ASSOCIATE">
                                {T.translate("seniority.associate")}
                              </option>
                              <option value="MID_SENIOR_LEVEL">
                                {T.translate("seniority.senior")}
                              </option>
                              <option value="DIRECTOR">
                                {T.translate("seniority.director")}
                              </option>
                              <option value="EXECUTIVE">
                                {T.translate("seniority.executive")}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div>
                          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                            {T.translate("project.fonction")}
                          </label>
                          <div className="tw-mt-1">
                            <select
                              placeholder={T.translate("project.fonction")}
                              name="fonction"
                              className="form-control"
                              ref={this.fonction}
                              onChange={this.onChange}
                            >
                              <option value="">
                                {T.translate("project.fonction")}
                              </option>
                              <option value="PURSHASING">
                                {T.translate("function.purshasing")}
                              </option>
                              <option value="ADM">
                                {T.translate("function.adm")}
                              </option>
                              <option value="ANALYSTE">
                                {T.translate("function.analyste")}{" "}
                              </option>
                              <option value="ART">
                                {T.translate("function.art")}{" "}
                              </option>
                              <option value="LOSGISTICS">
                                {T.translate("function.logistics")}
                              </option>
                              <option value="TRADE">
                                {T.translate("function.trade")}{" "}
                              </option>
                              <option value="ACCOUNTING">
                                {T.translate("function.accounting")}{" "}
                              </option>
                              <option value="CONSULTING">
                                {T.translate("function.consulting")}{" "}
                              </option>
                              <option value="QUALITY">
                                {T.translate("function.quality")}{" "}
                              </option>
                              <option value="DESIGN">
                                {T.translate("function.design")}{" "}
                              </option>
                              <option value="BUSINESS">
                                {T.translate("function.business")}
                              </option>
                              <option value="DISTRIBUTION">
                                {T.translate("function.distribution")}{" "}
                              </option>
                              <option value="EDUCATION">
                                {T.translate("function.education")}{" "}
                              </option>
                              <option value="RESEARCH">
                                {T.translate("function.research")}{" "}
                              </option>
                              <option value="FINANCE">
                                {T.translate("function.finance")}{" "}
                              </option>
                              <option value="TRAINING">
                                {T.translate("function.training")}{" "}
                              </option>
                              <option value="MANAGEMENT">
                                {T.translate("function.management")}{" "}
                              </option>
                              <option value="ENGINEERING">
                                {T.translate("function.engineering")}{" "}
                              </option>
                              <option value="PROJECT">
                                {T.translate("function.project")}{" "}
                              </option>
                              <option value="MARKETING">
                                {T.translate("function.marketing")}{" "}
                              </option>
                              <option value="HEALTH">
                                {T.translate("function.health")}
                              </option>
                              <option value="PRODUCTION">
                                {T.translate("function.production")}{" "}
                              </option>
                              <option value="ADVERTISING">
                                {T.translate("function.advertising")}{" "}
                              </option>
                              <option value="PUBLISHING">
                                {T.translate("function.publishing")}{" "}
                              </option>
                              <option value="PUBLIC_RELATIONS">
                                {T.translate("function.public_relations")}{" "}
                              </option>
                              <option value="PRODUCT">
                                {T.translate("function.product")}{" "}
                              </option>
                              <option value="HR">
                                {T.translate("function.hr")}{" "}
                              </option>
                              <option value="SCIENCE">
                                {T.translate("function.science")}{" "}
                              </option>
                              <option value="INDUSTRIAL">
                                {T.translate("function.industrial")}{" "}
                              </option>
                              <option value="CUSTOMER">
                                {T.translate("function.customer")}{" "}
                              </option>
                              <option value="LEGAL">
                                {T.translate("function.legal")}{" "}
                              </option>
                              <option value="STRATEGY">
                                {T.translate("function.strategy")}{" "}
                              </option>
                              <option value="SALES">
                                {T.translate("function.sales")}{" "}
                              </option>
                              <option value="OTHER">
                                {T.translate("function.other")}{" "}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div>
                          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                            {T.translate("project.type")}
                          </label>
                          <div className="tw-mt-1">
                            <select
                              placeholder={T.translate("project.type")}
                              name="type"
                              className="form-control"
                              ref={this.type}
                              onChange={this.onChange}
                            >
                              <option value="">
                                {T.translate("project.type")}
                              </option>
                              <option value="FULL_TIME">
                                {T.translate("contract.full_time")}
                              </option>
                              <option value="PART_TIME">
                                {T.translate("contract.part_time")}
                              </option>
                              <option value="CONTRACT">
                                {T.translate("contract.contract")}
                              </option>
                              <option value="TEMPORARY">
                                {T.translate("contract.temporary")}
                              </option>
                              <option value="VOLUNTEER">
                                {T.translate("contract.volunteer")}
                              </option>
                              <option value="INTERNSHIP">
                                {T.translate("contract.internship")}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div>
                          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                            {T.translate("project.description")}
                          </label>
                          <div className="tw-mt-1">
                            <RichTextEditor
                              toolbarConfig={toolbarConfig}
                              //type="text"
                              placeholder={T.translate("project.description")}
                              name="description"
                              //className="form-control"
                              //rows="6"
                              value={this.state.description} //{project.description}
                              //ref={this.description}
                              onChange={this.onTextChange}
                              ref={this.description_desc}
                            />
                          </div>
                          <p className="tw-mt-2 tw-text-sm tw-text-gray-500">
                            {T.translate("project.description_desc")}
                          </p>
                        </div>
                        <div>
                          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                            {T.translate("project.studies")}
                          </label>
                          <div className="tw-mt-1">
                            <select
                              placeholder={T.translate("project.studies")}
                              name="studies"
                              className="form-control"
                              ref={this.studies}
                              onChange={this.onChange}
                            >
                              <option value="">
                                {T.translate("project.studies")}
                              </option>
                              <option value="HIGH_SCHOOL">
                                {T.translate("studies.high_school")}
                              </option>
                              <option value="BACHELOR">
                                {T.translate("studies.bachelor")}
                              </option>
                              <option value="UNDER_GRAD">
                                {T.translate("studies.under_grad")}
                              </option>
                              <option value="MASTER">
                                {T.translate("studies.master")}
                              </option>
                              <option value="PHD">
                                {T.translate("studies.phd")}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div>
                          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                            {T.translate("project.experienceYears")}
                          </label>
                          <div className="tw-mt-1">
                            <select
                              placeholder={T.translate(
                                "project.experienceYears"
                              )}
                              name="experienceYears"
                              className="form-control"
                              ref={this.experienceYears}
                              onChange={this.onChange}
                            >
                              <option value="">
                                {T.translate("project.experienceYears")}
                              </option>
                              <option>0 - 3</option>
                              <option>3 - 6</option>
                              <option>6 - 10</option>
                              <option>10 - 15</option>
                              <option>15 - 20</option>
                              <option>20 - 25</option>
                              <option>25 - 30</option>
                            </select>
                          </div>
                        </div>
                        <div>
                          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                            {T.translate("project.skills")}
                          </label>
                          <div className="tw-mt-1">
                            <TagsInput
                              value={this.state.skills}
                              onChange={this.handleSkills}
                              onChangeInput={this.handleSkills}
                              inputProps={{
                                placeholder: T.translate("project.skills_desc"),
                              }}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                            {T.translate("project.city")}
                          </label>
                          <div className="tw-mt-1">
                            <input
                              type="text"
                              placeholder={T.translate("project.city")}
                              name="city"
                              className="form-control"
                              ref={this.city}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                            {T.translate("project.country")}
                          </label>
                          <div className="tw-mt-1">
                            <input
                              type="text"
                              placeholder={T.translate("project.country")}
                              name="country"
                              className="form-control"
                              ref={this.country}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                            {T.translate("project.date")}
                          </label>
                          <div className="tw-mt-1">
                            <input
                              type="date"
                              placeholder={T.translate("project.date")}
                              name="date"
                              ref={this.date}
                              className="form-control"
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                        <div className="tw-mt-1">
                          <select
                            placeholder={T.translate("project.language")}
                            name="language"
                            className="form-control"
                            ref={this.language}
                            onChange={this.onChange}
                            defaultValue={
                              language && language === "fr" ? "FR_fr" : "EN_en"
                            }
                          >
                            <option value="">
                              {T.translate("project.language")}
                            </option>
                            <option value={"FR_fr"}>
                              {T.translate("project.language_french")}
                            </option>
                            <option value="EN_en">
                              {T.translate("project.language_english")}
                            </option>
                          </select>
                          <p className="tw-mt-2 tw-text-sm tw-text-gray-500">
                            {T.translate("project.language_desc")}
                          </p>
                        </div>
                        <div className="ptw-x-4 tw-py-3 tw-bg-gray-50 tw-text-right sm:tw-px-6">
                          <button
                            type="submit"
                            ref={this.submit}
                            className="tw-inline-flex tw-justify-center tw-py-2 px-4 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
                          >
                            {T.translate("settings.save")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="tw-hidden sm:tw-block" aria-hidden="true">
            <div className="tw-py-5">
              <div className="tw-border-t tw-border-gray-200"></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

CreateProject.propTypes = {
  firestore: PropTypes.object.isRequired,
  notify: PropTypes.object.isRequired,
  notifyUser: PropTypes.func.isRequired,
};

const mapStateToProps = ({ firebase: { auth }, firestore: { ordered } }) => ({
  auth,
});

export default compose(
  firebaseConnect(),
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "users_infos",
      doc: props.auth.uid,
    },
    {
      collection: "users_organisations",
      where: ["user_id", "==", props.auth.uid],
      storeAs: "current_users_organisations",
    },
    {
      collection: "roles",
    },
  ]),
  connect(
    (state, props) => ({
      users_infos: state.firestore.data.users_infos,
      roles: state.firestore.data.roles,
      users_organisations: state.firestore.data.current_users_organisations,
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      notify: state.notify,
    }),
    { notifyUser }
  )
)(CreateProject);
