import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { getColorFromString } from "../../helpers/global";

class ListProjetcs extends Component {
  render() {
    if (!isLoaded(this.props.organisation)) {
      return (
        <tr>
          <th>Loading...</th>
        </tr>
      );
    }

    const { project } = this.props;
    var color = getColorFromString(project.jobTitle);
    return (
      <tr>
        <td className="tw-px-6 tw-py-3 tw-max-w-0 tw-w-full tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900">
          <div className="tw-flex tw-items-center tw-space-x-3 lg:tw-pl-2">
            <div
              className="tw-flex-shrink-0 tw-w-2.5 tw-h-2.5 tw-rounded-full"
              style={{ backgroundColor: `#${color}` }}
              aria-hidden="true"
            ></div>
            <a
              href={"/adm/X/campaign/" + this.props.project_id}
              //onClick={() => this.props.modalCallback(this.props.project_id)}
              /* data-toggle="modal"
              data-target="#task-modal" */
              className="tw-truncate hover:tw-text-gray-600"
            >
              <span>{project.jobTitle}</span>
            </a>
          </div>
        </td>
        <td className="tw-px-6 tw-py-3 tw-text-sm tw-text-gray-500 tw-font-medium">
          {this.props.project_id}
        </td>
        <td className="tw-px-6 tw-py-3 tw-text-sm tw-text-gray-500 tw-font-medium">
          {project.type}
        </td>
        <td className="tw-px-6 tw-py-3 tw-text-sm tw-text-gray-500 tw-font-medium">
          {/* <div className="tw-flex tw-items-center tw-space-x-2">
            <div className="tw-flex tw-flex-shrink-0 tw--space-x-1">
              <img
                className="tw-max-w-none tw-h-6 tw-w-6 tw-rounded-full tw-ring-2 tw-ring-white"
                src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                alt=""
              />

              <img
                className="tw-max-w-none tw-h-6 tw-w-6 tw-rounded-full tw-ring-2 tw-ring-white"
                src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                alt=""
              />

              <img
                className="tw-max-w-none tw-h-6 tw-w-6 tw-rounded-full tw-ring-2 tw-ring-white"
                src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                alt=""
              />

              <img
                className="tw-max-w-none tw-h-6 w-6 tw-rounded-full tw-ring-2 tw-ring-white"
                src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                alt=""
              />
            </div>

            <span className="tw-flex-shrink-0 tw-text-xs tw-leading-5 tw-font-medium">
              +8
            </span>
          </div> */}
          {this.props.organisation[project.organisation].name}
        </td>
        <td className="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-right">
          {project.city}
          {", "}
          {project.country}
        </td>
        <td className="tw-pr-6">
          <div className="tw-relative tw-flex tw-justify-end tw-items-center">
            <button
              id="project-options-menu-0"
              aria-haspopup="true"
              data-toggle="dropdown"
              aria-expanded="false"
              className="dropdown-toggletw-w-8 h-8 tw-bg-white tw-inline-flex tw-items-center tw-justify-center tw-text-gray-400 tw-rounded-full hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
            >
              <span className="tw-sr-only">Open options</span>
              <svg
                className="tw-w-5 tw-h-5"
                x-description="Heroicon name: dots-vertical"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
              </svg>
            </button>

            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <a
                className="dropdown-item"
                href={"/adm/X/campaign/" + this.props.project_id}
              >
                View
              </a>
              <a
                className="dropdown-item"
                href={"/campaign/edit/" + this.props.project_id}
              >
                edit
              </a>
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

export default compose(
  firestoreConnect((props) => [
    {
      collection: "organisations",
      doc: props.project.organisation,
    },
  ]),
  connect((state, props) => ({
    organisation: state.firestore.data.organisations,
  }))
)(ListProjetcs);
