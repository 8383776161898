import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";

import { connect } from "react-redux";
import T from "i18n-react";
import UserInfos from "../settings/UserInfos";

//import Create from "../organisation/Create";
import { notifyUser, setProgressBar } from "../../actions/notifyActions";

class Home extends Component {
  render() {
    const user_infos = this.props.users_infos[this.props.auth.uid];
    return (
      <div className="tw-bg-gray-100">
        <div className="tw-max-w-7xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8">
          <div className="tw-max-w-3xl tw-mx-auto tw-bg-gray-100">
            <div className="tw-max-w-7xl tw-mx-auto tw-py-6 sm:tw-px-6 lg:tw-px-8 border-b border-gray-100">
              <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                {T.translate("settings.new_profile_title")}
              </h3>
              <p className="tw-mt-2 tw-max-w-4xl tw-text-sm tw-text-gray-500">
                {T.translate("settings.new_profile_description")}
              </p>
            </div>
            <UserInfos
              email={
                user_infos !== undefined && user_infos.email !== undefined
                  ? user_infos.email
                  : ""
              }
              user_id={this.props.auth.uid}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ firebase: { auth }, firestore: { ordered } }) => ({
  auth,
});

export default compose(
  firebaseConnect(),
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "users_infos",
      doc: props.auth.uid,
    },
  ]),
  connect(
    (state, props) => ({
      users_infos: state.firestore.data.users_infos,
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      notify: state.notify,
    }),
    { notifyUser, setProgressBar }
  )
)(Home);
