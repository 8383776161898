import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";

class Skills extends Component {
  render() {
    /*  if (!isLoaded(this.props.skill)) {
      return <Wait />;
    } */
    return (
      <React.Fragment>
        <p className="tw-px-2 tw-mr-2 tw-inline-flex tw-text-xs tw-leading-5 tw-font-semibold tw-rounded-full tw-bg-gray-100 tw-text-gray-800">
          {this.props.skill}{" "}
        </p>
      </React.Fragment>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  /*  props => [
    {
      collection: "skills",
      doc: props.skill,
      storeAs: props.skill
    }
  ] */
  connect((state, props) => ({
    /*     skill: state.firestore.data[props.skill], */
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    notify: state.notify,
  }))
)(Skills);
