import React, { Component } from "react";

import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import { getColorFromString } from "../../helpers/global";
import { getTranslate } from "../../helpers/project";
import Wait from "../layout/Wait";
import { isLoaded } from "react-redux-firebase";
import ShowMoreText from "react-show-more-text";
import Skills from "../candidates/Skills";
//import ShowUser from "./ShowUser";
import Applications from "./Applications";
import Candidates from "./Candidates";
import CampaignSearchTerms from "./CampaignSearchTerms";

class CampaignModal extends Component {
  constructor(props) {
    super(props);
    this.state = { campaign: "" };
  }
  componentDidUpdate(nextProps) {
    if (this.state.campaign !== nextProps.campaignx)
      this.setState({ campaign: nextProps.campaignx });
  }

  handleColDragEnd(e) {}
  render() {
    if (
      !isLoaded(this.props.campaign_infos) ||
      !isLoaded(this.props.campaign_users)
    ) {
      return <Wait />;
    }

    var avatarTitle = "UK";
    var name = "";

    if (
      this.props.campaign_infos !== undefined &&
      this.props.campaign_infos.jobTitle !== undefined
    ) {
      avatarTitle =
        this.props.campaign_infos.jobTitle.charAt(0) +
        this.props.campaign_infos.jobTitle.charAt(1);
      name = this.props.campaign_infos.jobTitle;
    }

    const skill_keys =
      this.props.campaign_infos.skills === null ||
      this.props.campaign_infos.skills === undefined
        ? []
        : Object.keys(this.props.campaign_infos.skills);

    /* const users_keys =
      this.props.campaign_users === null ||
      this.props.campaign_users === undefined
        ? []
        : Object.keys(this.props.campaign_users); */

    const contacted =
      this.props.contacted !== null && this.props.contacted !== undefined
        ? Object.keys(this.props.contacted).length
        : 0;
    const validated =
      this.props.validated_candidates !== undefined &&
      this.props.validated_candidates !== null
        ? Object.keys(this.props.validated_candidates).length
        : 0;
    const interested =
      this.props.interested !== undefined && this.props.interested !== null
        ? Object.keys(this.props.interested).length
        : 0;

    const waiting =
      this.props.not_validated !== undefined &&
      this.props.not_validated !== null
        ? Object.keys(this.props.not_validated).length
        : 0;

    return (
      <div
        className="modal modal-task"
        id="task-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="task-modal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {this.props.campaign_infos !== undefined
                  ? this.props.campaign_infos.jobTitle
                  : ""}
                {this.props.all_candidates !== null &&
                this.props.all_candidates !== undefined
                  ? "(" + Object.keys(this.props.all_candidates).length + ")"
                  : ""}
              </h5>
              <button
                type="button"
                className="close btn btn-round"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="material-icons">close</i>
              </button>
            </div>
            <div className="modal-body">
              <div className="page-header mb-4">
                <div className="media">
                  <Avatar
                    className="avatar"
                    style={{
                      color: "#fff",
                      width: "72px",
                      height: "72px",
                      backgroundColor: "#" + getColorFromString(name),
                    }}
                  >
                    {avatarTitle.toUpperCase()}
                  </Avatar>

                  <div className="media-body ml-3">
                    <h1 className="mb-0">
                      {this.props.campaign_infos !== undefined
                        ? this.props.campaign_infos.jobTitle
                        : ""}
                      <a href={"/campaign/edit/" + this.props.campaignx}>
                        <i className=" material-icons">edit</i>
                      </a>
                    </h1>
                    <p className="lead d-none d-md-block">
                      <span className="project_resume">
                        <i className="fas project_resume_ico fa-file-signature" />{" "}
                        {"   "}
                        {this.props.campaign_infos !== undefined
                          ? getTranslate(this.props.campaign_infos.type)
                          : ""}
                      </span>
                      <span className="project_resume">
                        <i className="fas project_resume_ico fa-graduation-cap" />{" "}
                        {"   "}
                        {this.props.campaign_infos !== undefined
                          ? getTranslate(this.props.campaign_infos.studies)
                          : ""}
                      </span>
                      <span className="project_resume">
                        <i className="fas project_resume_ico fa-briefcase" />{" "}
                        {"   "}
                        {this.props.campaign_infos !== undefined
                          ? this.props.campaign_infos.experienceYears + " years"
                          : ""}
                      </span>

                      <span className="project_resume">
                        <i className="fas project_resume_ico fa-globe-africa" />
                        {"   "}
                        {this.props.campaign_infos !== undefined &&
                        this.props.campaign_infos.city !== undefined &&
                        this.props.campaign_infos.country !== undefined
                          ? this.props.campaign_infos.city
                              .charAt(0)
                              .toUpperCase() +
                            this.props.campaign_infos.city.slice(1) +
                            ", " +
                            this.props.campaign_infos.country
                              .charAt(0)
                              .toUpperCase() +
                            this.props.campaign_infos.country.slice(1)
                          : ""}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="page-header">
                <div className="lead small">
                  {this.props.campaign_infos !== undefined &&
                  this.props.campaign_infos.description !== undefined ? (
                    <ShowMoreText
                      lines={3}
                      more="Show more"
                      less="Show less"
                      anchorClass=""
                      //onClick={this.executeOnClick}
                      expanded={false}
                    >
                      {this.props.campaign_infos.description}
                    </ShowMoreText>
                  ) : (
                    ""
                  )}
                </div>
                <div className="content-list-body row small">
                  {skill_keys.map((id) => {
                    return (
                      <Skills
                        key={id}
                        //years={this.props.skills[id].years}
                        skill={this.props.campaign_infos.skills[id]}
                      />
                    );
                  })}
                </div>
                <div>
                  <div className="progress">
                    <div
                      className="progress-bar bg-success"
                      style={{
                        width:
                          contacted /
                            ((waiting + validated + contacted) / 100) +
                          "%",
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-between text-small">
                    <div className="d-flex align-items-center">
                      <i className="material-icons">playlist_add_check</i>
                      <span>
                        {((contacted + validated) / 100) * interested + "%"}
                      </span>
                    </div>
                    <span>
                      {contacted / ((waiting + validated + contacted) / 100) +
                        "% completed"}
                    </span>
                  </div>
                </div>
              </div>
              <ul className="nav nav-tabs nav-fill">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#search"
                    role="tab"
                    aria-controls="search"
                    aria-selected="true"
                  >
                    Search{" "}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="true"
                  >
                    Waiting for validation{" "}
                    {this.props.not_validated !== null &&
                    this.props.not_validated !== undefined
                      ? "(" + Object.keys(this.props.not_validated).length + ")"
                      : ""}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#task"
                    role="tab"
                    aria-controls="task"
                    aria-selected="true"
                  >
                    Validated
                    {this.props.validated_candidates !== null &&
                    this.props.validated_candidates !== undefined
                      ? "(" +
                        Object.keys(this.props.validated_candidates).length +
                        ")"
                      : ""}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#files"
                    role="tab"
                    aria-controls="files"
                    aria-selected="false"
                  >
                    Contacted
                    {this.props.contacted !== null &&
                    this.props.contacted !== undefined
                      ? "(" + Object.keys(this.props.contacted).length + ")"
                      : ""}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#activity"
                    role="tab"
                    aria-controls="activity"
                    aria-selected="false"
                  >
                    Applications
                    {this.props.interested !== null &&
                    this.props.interested !== undefined
                      ? "(" + Object.keys(this.props.interested).length + ")"
                      : ""}
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div
                  className="tab-pane fade"
                  id="search"
                  role="tabpanel"
                  aria-labelledby="search-tab"
                >
                  <CampaignSearchTerms
                    campaign_infos={this.props.campaign_infos}
                    campaign={this.props.campaignx}
                  />
                </div>
                <div
                  className="tab-pane fade show active"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  {/*   <ol className="list-group list-group-activity">
                    {users_keys.map(id => {
                      return (
                        <ShowUser
                          key={id}
                          //years={this.props.skills[id].years}
                          user_id={this.props.campaign_users[id].user_id}
                        />
                      );
                    })}
                  </ol> */}
                  <Candidates interested={this.props.not_validated} />
                </div>
                <div
                  className="tab-pane fade"
                  id="task"
                  role="tabpanel"
                  aria-labelledby="task-tab"
                >
                  <Candidates interested={this.props.validated_candidates} />
                </div>
                <div
                  className="tab-pane fade"
                  id="files"
                  role="tabpanel"
                  aria-labelledby="files-tab"
                  data-filter-list="dropzone-previews"
                >
                  <Candidates interested={this.props.contacted} />
                </div>
                <div
                  className="tab-pane fade"
                  id="activity"
                  role="tabpanel"
                  aria-labelledby="activity-tab"
                  data-filter-list="list-group-activity"
                >
                  <Applications
                    interested={this.props.interested}
                    campaign={this.props.campaign_infos}
                    creator={this.props.campaign_users}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (
  { firebase: { auth }, firestore: { ordered } },
  state
) => ({
  auth,
  campaignx: state.idCampain,
});

export default compose(
  firebaseConnect(),
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "projects",
      doc: props.campaignx,
      storeAs: "campaign_infos",
    },
    {
      collection: "users_projects",
      where: ["project", "==", props.campaignx],
      storeAs: "campaign_users",
    },
    {
      collection: "campaign_candidate",
      where: [
        ["match_has_been_humanly_validated", "==", false],
        ["campaign", "==", props.campaignx],
      ],
      storeAs: "not_validated_" + props.campaignx,
    },
    {
      collection: "campaign_candidate",
      where: [
        ["match_has_been_humanly_validated", "==", true],
        ["match_is_valid", "==", true],
        ["candidate_has_been_contacted", "==", false],
        ["campaign", "==", props.campaignx],
      ],
      storeAs: "validated_candidates_" + props.campaignx,
    },
    {
      collection: "campaign_candidate",
      where: [["campaign", "==", props.campaignx]],
      storeAs: "all_candidates_" + props.campaignx,
    },
    {
      collection: "campaign_candidate",
      where: [
        ["campaign", "==", props.campaignx],
        ["candidate_has_been_contacted", "==", true],
      ],
      storeAs: "contacted_" + props.campaignx,
    },
    {
      collection: "campaign_candidate",
      where: [
        ["campaign", "==", props.campaignx],
        ["application.interested", "==", true],
        ["step", "==", "found"],
      ],
      storeAs: "interested_" + props.campaignx,
    },
  ]),
  connect((state, props) => ({
    campaign_infos: state.firestore.data["campaign_infos"],
    campaign_users: state.firestore.data.campaign_users,
    not_validated: state.firestore.data["not_validated_" + props.campaignx],
    validated_candidates:
      state.firestore.data["validated_candidates_" + props.campaignx],
    all_candidates: state.firestore.data["all_candidates_" + props.campaignx],
    contacted: state.firestore.data["contacted_" + props.campaignx],
    interested: state.firestore.data["interested_" + props.campaignx],
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    notify: state.notify,
  }))
)(CampaignModal);
