import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import T from "i18n-react";
import { isLoaded } from "react-redux-firebase";
//import Create from "../organisation/Create";
import { notifyUser } from "../../actions/notifyActions";
import Wait from "../layout/Wait";

class Fail extends Component {
  constructor(props) {
    super(props);
    // Create refs
    this.infos = React.createRef();
    this.submit = React.createRef();
    this.state = {
      applicationSent: false
    };
  }

  formHasError() {
    var hasError = false;
    if (this.infos.current.value === "") {
      this.infos.current.className = "form-control is-invalid";
      hasError = true;
    } else this.infos.current.className = "form-control";
    return hasError;
  }

  onSubmit = e => {
    e.preventDefault();

    const { opportunity, firestore } = this.props;
    const hasErrors = this.formHasError();

    if (!hasErrors) {
      this.submit.current.setAttribute("disabled", "disabled");

      // Updated user
      const updUser = {
        application: {
          infos: this.infos.current.value,
          interested: false,
          date: new Date()
        },
        candidate_is_interested: false
      };

      firestore
        .update({ collection: "campaign_candidate", doc: opportunity }, updUser)
        .then(() => {
          if (this.submit !== undefined)
            if (this.submit.current) {
              window.scrollTo(0, 0);
              this.submit.current.removeAttribute("disabled");
            }
          this.setState({ applicationSent: true });
        });
    } else {
      notifyUser(T.translate("settings.error"), "error");
      if (this.submit.current) {
        window.scrollTo(0, 0);
        this.submit.current.removeAttribute("disabled");
      }
    }
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });
  render() {
    if (!isLoaded(this.props.success_profile)) {
      return <Wait />;
    }

    if (
      this.state.applicationSent === true ||
      this.props.opportunity_infos["application"] !== undefined
    ) {
      return (
        <div className="container">
          <div className="mb-4">{T.translate("apply.fail")}</div>
        </div>
      );
    } else
      return (
        <div className="container">
          <div className="mb-4">{T.translate("apply.failIntro")}</div>
          <form onSubmit={this.onSubmit}>
            <div className="form-group row">
              <label className="col-3">{T.translate("apply.infos")}</label>
              <div className="col">
                <textarea
                  type="text"
                  placeholder={T.translate("apply.infos")}
                  name="bio"
                  className="form-control"
                  rows="4"
                  ref={this.infos}
                  defaultValue=""
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="row justify-content-end">
              <button
                type="submit"
                className="btn btn-primary btn-lg"
                ref={this.submit}
              >
                {T.translate("apply.save")}
              </button>
            </div>
          </form>
        </div>
      );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(props => [
    {
      collection: "profiles",
      doc: props.profile,
      storeAs: "successProfile"
    }
  ]),
  connect(
    (state, props) => ({
      auth: state.firebase.auth,
      success_profile: state.firestore.data.successProfile,
      notify: state.notify
    }),
    { notifyUser }
  )
)(Fail);
