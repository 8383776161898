import React, { Component } from "react";
//import PropTypes from "prop-types";
import { compose } from "redux";
import { firebaseConnect, firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import T from "i18n-react";
import ChatWidget from "@papercups-io/chat-widget";
import Cookies from "./Cookies";
import { Storytime } from "@papercups-io/storytime";

Storytime.init({
  accountId: "740cbb36-4451-4e92-ad29-3ed024cc3ce3",

  // Optionally pass in metadata to identify the customer
  // customer: {
  //  name: 'Test User',
  //  email: 'test@test.com',
  //  external_id: '123',
  // },

  // Optionally specify the base URL
  baseUrl: "https://app.papercups.io",
});

class Footer extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://cdn.headwayapp.co/widget.js";
    document.head.appendChild(script);
    var config = {
      selector: ".wdgtIcon",
      /* trigger: ".toggleWidget", */
      account: "yZO96x",
    };
    script.onload = function () {
      window.Headway.init(config);
    };
  }

  render() {
    var date = new Date();
    const year = date.getFullYear();
    const { location } = this.props;
    var logoUrl = "/theme/logos/hived_transparent_black.svg";
    if (
      location.pathname.match("/reach") ||
      location.pathname.match("/apply")
    ) {
      return (
        <div className="">
          <div className="text-center container footer py-3">
            <a href="/" className="tw-mx-auto d-block">
              <img src={logoUrl} alt="Hived.ai" />
            </a>
          </div>
        </div>
      );
    } else {
      return (
        <footer className="tw-bg-white">
          <div className="tw-max-w-7xl tw-mx-auto tw-py-12 tw-px-4 sm:tw-px-6 md:tw-flex md:tw-items-center md:tw-justify-between lg:tw-px-8">
            <div className="tw-flex tw-justify-center tw-space-x-6 md:tw-order-3">
              <a
                href="/mission"
                className="tw-text-gray-400 hover:tw-text-gray-500"
              >
                <span className="tw-sr-only">
                  {T.translate("footer.about")}
                </span>
                {T.translate("footer.about")}
              </a>

              <a
                href="/cgu"
                className="tw-text-gray-400 hover:tw-text-gray-500"
              >
                <span className="tw-sr-only">
                  {T.translate("footer.terms")}
                </span>
                {T.translate("footer.terms")}
              </a>

              <a
                href="/confidentialite"
                className="tw-text-gray-400 hover:tw-text-gray-500"
              >
                <span className="tw-sr-only">
                  {T.translate("footer.privacy")}
                </span>
                {T.translate("footer.privacy")}
              </a>
              {/* <span className="toggleWidget">What's New</span> */}
              <span className="wdgtIcon tw-ml-1"></span>
            </div>
            <div className="tw-mt-8 md:tw-mt-0 md:tw-order-1">
              <p className="tw-text-center tw-text-base tw-text-gray-400">
                &copy; {year} {T.translate("footer.copy")}
              </p>
            </div>
          </div>
          <ChatWidget
            title={T.translate("chat.welcome")}
            subtitle={T.translate("chat.welcome_desc")}
            primaryColor="#1890ff"
            greeting={T.translate("chat.start_message")}
            requireEmailUpfront
            emailInputPlaceholder={T.translate("chat.email")}
            newMessagePlaceholder={T.translate("chat.start")}
            accountId="740cbb36-4451-4e92-ad29-3ed024cc3ce3"
            baseUrl="https://app.papercups.io"
          />
          {this.props.auth && this.props.auth.isEmpty === true ? (
            <Cookies />
          ) : (
            ""
          )}
        </footer>
      );
    }
  }
}
export default compose(
  firebaseConnect(),
  connect(),
  firestoreConnect(),
  connect((state, props) => ({
    auth: state.firebase.auth,
    notify: state.notify,
  }))
)(Footer);
