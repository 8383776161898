import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import T from "i18n-react";
import Slider from "rc-slider";
import { TitleComponent } from "../layout/Title";

class Time extends Component {
  state = {
    isAuthenticated: true,
    candidates: 12,
    responses: 8,
    recruitments: 2,
    savedTime: 52,
    spentTime: 52,
    hivedTime: 0
  };

  static getDerivedStateFromProps(props, state) {
    const { auth } = props;
    if (auth.uid) {
      return { isAuthenticated: true };
    } else {
      return { isAuthenticated: false };
    }
  }

  onCandidateSliderChange = candidates => {
    this.setState({ candidates });
  };

  onResponseSliderChange = responses => {
    this.setState({ responses });
  };

  onRecruitmentSliderChange = recruitments => {
    this.setState({ recruitments });
  };

  onAfterChange = e => {
    var spentTime = 0;
    spentTime =
      ((100 / this.state.recruitments) * 1 * (100 / this.state.responses)) /
      this.state.candidates;
    var savedTime = spentTime - this.state.hivedTime;
    this.setState({ spentTime, savedTime });
  };

  render() {
    const { isAuthenticated } = this.state;
    if (isAuthenticated) {
      return <Redirect to={{ pathname: "/home" }} />;
    } else
      return (
        <React.Fragment>
          <TitleComponent title={"Hived.ai | " + T.translate("time.title_")} />
          <div className="tw-bg-white">
        <div className="tw-relative tw-overflow-hidden">
          <div className="tw-absolute tw-inset-y-0 h-full tw-w-full" aria-hidden="true">
            <div className="tw-relative tw-h-full">
              <svg className="tw-absolute tw-right-full tw-transform tw-translate-y-1/3 tw-translate-x-1/4 md:tw-translate-y-1/2 sm:tw-translate-x-1/2 lg:tw-translate-x-full" width="404" height="784" fill="none" viewBox="0 0 404 784">
                <defs>
                  <pattern id="e229dbec-10e9-49ee-8ec3-0286ca089edf" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" className="tw-text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="784" fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)" />
              </svg>
              <svg className="tw-absolute tw-left-full tw-transform tw--translate-y-3/4 tw--translate-x-1/4 sm:tw--translate-x-1/2 md:tw--translate-y-1/2 lg:tw--translate-x-3/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
                <defs>
                  <pattern id="d2a68204-c383-44b1-b99f-42ccff4e5365" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" className="tw-text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="784" fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)" />
              </svg>
            </div>
          </div>
          <div className="tw-mt-16 tw-mx-auto tw-max-w-7xl tw-px-4 sm:tw-mt-24 sm:tw-px-6">
        <div className="tw-text-center">
          <h1 className="tw-text-4xl tw-tracking-tight tw-font-extrabold tw-text-gray-900 sm:tw-text-5xl md:tw-text-6xl">
            <span className="tw-block">{T.translate("time.title")}</span>
            <span className="tw-block tw-text-blue-600"> {T.translate("time.title_")}</span>
          </h1>
          <p className="tw-mt-3 tw-max-w-md tw-mx-auto tw-text-base tw-text-gray-500 sm:tw-text-lg md:tw-mt-5 md:tw-text-xl md:tw-max-w-3xl">
          {T.translate("time.desc")}
          </p>
        </div>
      </div>
    </div>
          
          <main id="content" style={{}}>

            <h3 className="tw-mt-16 tw-mb-16 tw-text-center tw-text-2xl tw-tracking-tight tw-font-extrabold tw-text-gray-900 sm:tw-text-2xl md:tw-text-3xl">
              {T.translate("time.calc")}
            </h3>

            <div className="container space-2 space-md-3">
              <div className="card-group card-group-md-break rounded shadow-soft">
                <div className="card">
                  <div className="card-body text-center pt-9 px-7">
                    <div id="pricingMonthly" data-target-group="idPricing">
                      <div className=" font-weight-semi-bold mb-5">
                        <div className="mt-3">
                          <div>
                            <span className="ml-n2 h3">
                              {T.translate("time.calc_title")}
                            </span>
                          </div>
                          <div className="mt-3">
                            <span className="tw-text-blue-600 ml-n2 display-1">
                              {this.state.savedTime.toFixed(0)}
                            </span>
                          </div>
                          <div>
                            <span className="tw-text-blue-600 ml-n2 p">
                              {T.translate("time.hours")}
                            </span>
                          </div>
                          <div className="mt-3 mb-3">
                            <span className="ml-n2 h3">
                              {T.translate("time.calc_title_")}
                            </span>
                          </div>
                        </div>
                        <hr />
                        <div className="mt-4">
                          <span className="ml-n2 h6">
                            {T.translate("time.mean")}{" "}
                            <span
                              className="tw-text-blue-600"
                              style={{ textDecoration: "underline" }}
                            >
                              {this.state.spentTime.toFixed(0)}{" "}
                              {T.translate("time.hours")}
                            </span>{" "}
                            {T.translate("time.search")}
                          </span>
                        </div>
                        <div className="mt-4">
                          <span className="ml-n2  h6">
                            {T.translate("time.search_desc")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer border-0 pt-0 pb-9 px-7">
                    <div className="media align-items-center">
                      <div className="media-body mt-1">
                        <h4 className="h6 mb-1">
                          {T.translate("time.eco")}{" "}
                          {this.state.savedTime.toFixed(0)}{" "}
                          {T.translate("time.hours")}
                        </h4>
                        <p className="small mb-0">
                          <a href="/signup">{T.translate("time.eco_desc")}</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body py-9 px-7">
                    <div className="media align-items-center border-bottom pb-4 mb-4">
                      <div className="media-body">
                        <h3 className="h5"> {T.translate("time.candidates")} </h3>
                        <p className="mb-0">
                          {T.translate("time.candidates_question")}
                        </p>
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            minHeight: "1px",
                            paddingRight: "12px",
                            paddingLeft: "12px",
                            maxWidth: "100%",
                            marginBottom: "2rem",
                            marginTop: "2rem"
                          }}
                        >
                          <Slider
                            step={1}
                            max={30}
                            dots
                            value={this.state.candidates}
                            onChange={this.onCandidateSliderChange}
                            trackStyle={{
                              backgroundColor: "rgb(36,99,235)",
                              height: 10
                            }}
                            railStyle={{ height: 10 }}
                            onAfterChange={this.onAfterChange}
                            handleStyle={{
                              borderColor: "rgb(36,99,235)",
                              height: 28,
                              width: 28,
                              marginLeft: -14,
                              marginTop: -9
                            }}
                          />
                        </div>
                        <p className="mb-0 text-center">
                          <span className="h3">{this.state.candidates}</span>{" "}
                          {T.translate("time.candidates_hour")}
                        </p>
                      </div>
                    </div>

                    <div className="media align-items-center border-bottom pb-4 mb-4">
                      <div className="media-body">
                        <h3 className="h5">{T.translate("time.answers")}</h3>
                        <p className="mb-0">
                          {T.translate("time.answers_question")}
                        </p>
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            minHeight: "1px",
                            paddingRight: "12px",
                            paddingLeft: "12px",
                            maxWidth: "100%",
                            marginBottom: "2rem",
                            marginTop: "2rem"
                          }}
                        >
                          <Slider
                            step={1}
                            max={15}
                            dots
                            value={this.state.responses}
                            onChange={this.onResponseSliderChange}
                            trackStyle={{
                              backgroundColor: "rgb(36,99,235)",
                              height: 10
                            }}
                            railStyle={{ height: 10 }}
                            onAfterChange={this.onAfterChange}
                            handleStyle={{
                              borderColor: "rgb(36,99,235)",
                              height: 28,
                              width: 28,
                              marginLeft: -14,
                              marginTop: -9
                            }}
                          />
                        </div>
                        <p className="mb-0 text-center">
                          <span className="h3">{this.state.responses}</span>
                          {T.translate("time.positive")}
                        </p>
                      </div>
                    </div>

                    <div className="media align-items-center">
                      <div className="media-body">
                        <h3 className="h5">{T.translate("time.recruited")}</h3>
                        <p className="mb-0">
                          {T.translate("time.recruited_question")}
                        </p>
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            minHeight: "1px",
                            paddingRight: "12px",
                            paddingLeft: "12px",
                            maxWidth: "100%",
                            marginBottom: "2rem",
                            marginTop: "2rem"
                          }}
                        >
                          <Slider
                            step={1}
                            max={15}
                            dots
                            value={this.state.recruitments}
                            onChange={this.onRecruitmentSliderChange}
                            trackStyle={{
                              backgroundColor: "rgb(36,99,235)",
                              height: 10
                            }}
                            railStyle={{ height: 10 }}
                            onAfterChange={this.onAfterChange}
                            handleStyle={{
                              borderColor: "rgb(36,99,235)",
                              height: 28,
                              width: 28,
                              marginLeft: -14,
                              marginTop: -9
                            }}
                          />
                        </div>
                        <p className="mb-0 text-center">
                          <span className="h3">{this.state.recruitments}</span>
                          {T.translate("time.recruited_question_percent")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="container"
              style={{
                paddingBottom: "8rem",
                paddingTop: "4.5rem"
              }}
            >
              <h3 className=" tw-mb-16 tw-text-center tw-text-2xl tw-tracking-tight tw-font-extrabold tw-text-gray-600 sm:tw-text-2xl md:tw-text-3xl">
              {T.translate("landing.start_now")}
            </h3>
              <div className="cmb-3 col-4-medium-and-up offset-4-medium-and-up">
              <a href="signup" className="tw-flex tw-items-center tw-justify-center tw-px-5 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-600 hover:tw-text-white hover:tw-bg-blue-700">
              {T.translate("landing.start")}
          </a>
              </div>
            </div>
          </main>
          </div>
        </React.Fragment>
      );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  connect((state, props) => ({
    auth: state.firebase.auth
  }))
)(Time);
