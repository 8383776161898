import React from "react";

export default () => {
  return (
    <div>
      <h1 className="display-4">
        <span className="text-danger">Error</span> Something is wrong
      </h1>
      <p className="lead">
        Sorry, it seems like something is wrong. Please contact us!
      </p>
    </div>
  );
};
