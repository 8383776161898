import { cancelSubscription } from "./payment";
import T from "i18n-react";

const FIREBASE_FUNCTION =
  "https://us-central1-swel-39e81.cloudfunctions.net/project/";

let BACKEND_API =
  "https://us-central1-swel-39e81.cloudfunctions.net/backendApi/";
/* if (process.env.NODE_ENV === "development") {
  BACKEND_API = "http://localhost:5001/swel-39e81/us-central1/backendApi/";
} */

export async function notifyCampaignOwnerNewApplication(
  id_candidate_campaign,
  jobtitle,
  creators
) {
  return await fetch(FIREBASE_FUNCTION + "notifyapplication/", {
    method: "POST",
    //4mode: "no-cors",
    headers: { "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({
      id_candidate_campaign,
      jobtitle,
      creators,
    }),
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      return JSON.parse(data.body);
    });
}

export function getCampaignColumns() {
  return ["sourced", "called", "interviewed", "offer", "hired", "rejected"];
}

export function checkIfUserAlreadyInProject(firestore, doc) {
  firestore
    .get({
      collection: "users_projects",
      doc: doc,
    })
    .then((project) => {
      return project.data();
    });
}

export function AddUser(
  firestore,
  id_project,
  id_user,
  role,
  id_invite,
  id_organisation,
  id_sub = null
) {
  var done = false;
  const id = getProjectUserId(id_project, id_user);

  if (role !== "admin" && id_sub === null) {
    return false;
  }
  const users_project = {
    project: id_project,
    role: role,
    user_id: id_user,
    organisation: id_organisation,
    stripe_subscription: id_sub,
  };
  firestore.set({ collection: "users_projects", doc: id }, users_project).then(
    id_invite !== null
      ? firestore
          .update(
            { collection: "invites", doc: id_invite },
            { rsvp_date: new Date(), rsvp: true }
          )
          .then((infos) => {
            done = true;
          })
      : ""
  );

  return done;
}

export function AddUserToNewProject(
  firestore,
  id_project,
  id_user,
  role,
  id_organisation,
  id_sub = null
) {
  var done = false;
  const id = getProjectUserId(id_project, id_user);

  const users_project = {
    project: id_project,
    role: role,
    user_id: id_user,
    organisation: id_organisation,
    stripe_subscription: id_sub,
  };
  firestore.set({ collection: "users_projects", doc: id }, users_project);

  return done;
}

export function getProjectUserId(id_project, id_user) {
  return id_project + "_" + id_user;
}

export function getProjectCandidateId(id_project, id_candidate) {
  return id_project + "_" + id_candidate;
}

export function getOrganisationUserId(id_organisation, id_user) {
  return id_organisation + "_" + id_user;
}

export async function GetProjectInfos(firestore, id_project) {
  firestore
    .get({
      collection: "projects",
      doc: id_project,
    })
    .then((project) => {
      return project.data();
    });
}

export async function GetLastActivity(firestore, opportunity) {
  await firestore
    .get({
      collection: "campaign_candidate",
      doc: opportunity,
      /*subcollections: [
        { collection: "history" }
      ] ,
      orderBy: ["date", "desc"] */
    })
    .then((activity) => {
      return activity;
    });
}

export function AddUserOrganisation(firestore, id_organisation, id_user, role) {
  var done = false;
  const id = getOrganisationUserId(id_organisation, id_user);
  const users_organisations = {
    organisation: id_organisation,
    role: role,
    user_id: id_user,
  };

  firestore.set(
    { collection: "users_organisations", doc: id },
    users_organisations
  );
  return done;
}

function delP(firestore, id_project) {
  firestore
    .update({ collection: "projects", doc: id_project }, { archived: true })
    .then((update) => {
      return true;
    });
}

export async function CancelOtherUsersSubscriptions(firestore, id_project) {
  await firestore
    .get({ collection: "users_projects", where: ["project", "==", id_project] })
    .then((OtherUsers) => {
      OtherUsers.forEach(function (doc) {
        const data = doc.data();
        if (
          data.role !== "creator" &&
          data.stripe_subscription !== undefined &&
          data.stripe_subscription !== null
        ) {
          cancelSubscription(data.stripe_subscription);
        }
      });
    });
}

export async function deleteProject(
  firestore,
  id_project,
  subscription = false
) {
  if (subscription !== false) {
    cancelSubscription(subscription).then((res) => {
      if (res.message !== undefined && res.message === "Success")
        CancelOtherUsersSubscriptions(firestore, id_project).then(
          delP(firestore, id_project)
        );
    });
  } else {
    CancelOtherUsersSubscriptions(firestore, id_project).then(
      delP(firestore, id_project)
    );
  }
}

export function getCompanyDissmissalReasons() {
  const reasons = [
    { id: "missing_skills", text: T.translate("dissmissed.missing_skills") },
    { id: "cultural_fit", text: T.translate("dissmissed.cultural_fit") },
    {
      id: "not_experienced_enough",
      text: T.translate("dissmissed.not_experienced_enough"),
    },
    {
      id: "no_experience_in_industry",
      text: T.translate("dissmissed.no_experience_in_industry"),
    },
    //{ id: "doesn_fit", text: T.translate("dissmissed.too_qualified") }
  ];
  return reasons;
}

export function getCandidateDissmissalReasons() {
  const reasons = [
    { id: "growth", text: T.translate("dissmissed.growth") },
    { id: "better_offer", text: T.translate("dissmissed.better_offer") },
    { id: "job", text: T.translate("dissmissed.job") },
    { id: "salary", text: T.translate("dissmissed.salary") },
  ];
  return reasons;
}

export function isStepValid(step) {
  const steps = [
    "sourced",
    "called",
    "interviewed",
    "offer",
    "hired",
    "rejected",
  ];
  if (steps.indexOf(step) > -1) return true;
  return false;
}

export function getTranslate(exp) {
  switch (exp) {
    case "INTERNSHIP":
      return T.translate("seniority.internship");
    case "ENTRY_LEVEL":
      return T.translate("seniority.entry");
    case "ASSOCIATE":
      return T.translate("seniority.associate");
    case "MID_SENIOR_LEVEL":
      return T.translate("seniority.senior");
    case "DIRECTOR":
      return T.translate("seniority.director");
    case "EXECUTIVE":
      return T.translate("seniority.executive");
    case "PURSHASING":
      return T.translate("function.purshasing");
    case "ADM":
      return T.translate("function.adm");
    case "ANALYSTE":
      return T.translate("function.analyste");

    case "ART":
      return T.translate("function.art");
    case "LOSGISTICS":
      return T.translate("function.logistics");

    case "TRADE":
      return T.translate("function.trade");

    case "ACCOUNTING":
      return T.translate("function.accounting");

    case "CONSULTING":
      return T.translate("function.consulting");
    case "QUALITY":
      return T.translate("function.quality");

    case "DESIGN":
      return T.translate("function.design");

    case "BUSINESS": {
      return T.translate("function.business");
    }

    case "DISTRIBUTION":
      return T.translate("function.distribution");

    case "EDUCATION":
      return T.translate("function.education");

    case "RESEARCH":
      return T.translate("function.research");

    case "FINANCE":
      return T.translate("function.finance");

    case "TRAINING":
      return T.translate("function.training");

    case "MANAGEMENT":
      return T.translate("function.management");

    case "ENGINEERING":
      return T.translate("function.engineering");

    case "PROJECT":
      return T.translate("function.project");

    case "MARKETING":
      return T.translate("function.marketing");

    case "HEALTH":
      return T.translate("function.health");

    case "PRODUCTION":
      return T.translate("function.production");

    case "ADVERTISING":
      return T.translate("function.advertising");

    case "PUBLISHING":
      return T.translate("function.publishing");

    case "PUBLIC_RELATIONS":
      return T.translate("function.public_relations");

    case "PRODUCT":
      return T.translate("function.product");

    case "HR":
      return T.translate("function.hr");
    case "SCIENCE":
      return T.translate("function.science");

    case "INDUSTRIAL":
      return T.translate("function.industrial");

    case "CUSTOMER":
      return T.translate("function.customer");

    case "LEGAL":
      return T.translate("function.legal");

    case "STRATEGY":
      return T.translate("function.strategy");

    case "SALES":
      return T.translate("function.sales");

    case "OTHER":
      return T.translate("function.other");

    case "FULL_TIME":
      return T.translate("contract.full_time");

    case "PART_TIME":
      return T.translate("contract.part_time");

    case "CONTRACT":
      return T.translate("contract.contract");

    case "TEMPORARY":
      return T.translate("contract.temporary");

    case "VOLUNTEER":
      return T.translate("contract.volunteer");

    case "HIGH_SCHOOL":
      return T.translate("studies.high_school");

    case "BACHELOR":
      return T.translate("studies.bachelor");

    case "UNDER_GRAD":
      return T.translate("studies.under_grad");

    case "MASTER":
      return T.translate("studies.master");

    case "PHD":
      return T.translate("studies.phd");
    default:
      return null;
  }
}

export function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + " " + T.translate("_time.years");
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " " + T.translate("_time.months");
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " " + T.translate("_time.days");
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " " + T.translate("_time.hours");
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " " + T.translate("_time.minutes");
  }
  return Math.floor(seconds) + " " + T.translate("_time.seconds");
}

export async function invite(project_id, email) {
  return await fetch(BACKEND_API + "invite/", {
    method: "POST",
    //4mode: "no-cors",
    headers: { "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({
      project_id,
      email,
    }),
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      return JSON.parse(data.body);
    });
}

export async function addActivityMove(
  firestore,
  rel,
  type,
  step,
  old_step,
  user,
  campaign,
  candidate,
  contributors
) {
  if (old_step === step) return;
  const step_update = {
    step: step,
  };

  const updateDate = new Date();
  const batch = firestore.batch();

  const campaignCandidateRef = firestore
    .collection("campaign_candidate")
    .doc(rel);

  batch.update(campaignCandidateRef, step_update);

  /////
  const campaignCandidateHistoryRef = firestore
    .collection("campaign_candidate")
    .doc(rel)
    .collection("history")
    .doc();

  batch.set(campaignCandidateHistoryRef, {
    date: updateDate,
    type: type,
    step: step,
    user: user,
    old_step: old_step,
  });

  ///////// send notifications to all contributors
  const rel_contributors = Object.keys(contributors);

  rel_contributors.map((contributor) => {
    const p = contributors[contributor];
    if (p.user_id !== user) {
      let contributorsNotifications = firestore
        .collection("users")
        .doc(p.user_id)
        .collection("notifications")
        .doc();

      batch.set(contributorsNotifications, {
        type: type,
        date: updateDate,
        step: step,
        user: user,
        old_step: old_step,
        campaign: campaign,
        candidate: candidate,
        seen: false,
      });
      return true;
    }
    return false;
  });

  return await batch.commit();
}

export async function addActivityNote(
  firestore,
  opportunity,
  note,
  user,
  campaign,
  candidate,
  contributors
) {
  const updateDate = new Date();
  const batch = firestore.batch();

  const noteObj = {
    note: note,
    user: user,
    opportunity: opportunity,
    date: updateDate,
    archived: false,
  };

  const noteRef = firestore.collection("notes").doc();

  batch.set(noteRef, noteObj);

  /////
  const campaignCandidateHistoryRef = firestore
    .collection("campaign_candidate")
    .doc(opportunity)
    .collection("history")
    .doc();

  batch.set(campaignCandidateHistoryRef, {
    date: updateDate,
    type: "note",
    user: user,
  });

  ///////// send notifications to all contributors
  const rel_contributors = Object.keys(contributors);

  rel_contributors.map((contributor) => {
    const p = contributors[contributor];
    if (p.user_id !== user) {
      let contributorsNotifications = firestore
        .collection("users")
        .doc(p.user_id)
        .collection("notifications")
        .doc();

      batch.set(contributorsNotifications, {
        type: "note",
        date: updateDate,
        user: user,
        campaign: campaign,
        candidate: candidate,
        seen: false,
      });
      return true;
    }
    return false;
  });

  return await batch.commit();
}
