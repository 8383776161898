import React, { Component } from "react";
import { firebaseConnect, firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import { notifyUser, setProgressBar } from "../../actions/notifyActions";
import { isLoaded } from "react-redux-firebase";
import Wait from "../layout/Wait";
import { getSubscriptions } from "../../helpers/payment";

class Payment extends Component {
  state = {
    subscriptions: [],
  };

  componentDidMount() {
    if (
      this.props.stripe_infos !== undefined &&
      this.props.stripe_infos[this.props.auth.uid] !== undefined
    ) {
      try {
        getSubscriptions(
          this.props.stripe_infos[this.props.auth.uid].id_customer
        ).then((subscriptions) => this.setState({ subscriptions }));
      } catch (error) {}
    }
  }

  render() {
    if (!isLoaded(this.props.stripe_infos)) {
      return <Wait />;
    }
    if (
      this.state.subscriptions.subscriptions !== undefined &&
      this.state.subscriptions.subscriptions.data !== undefined
    ) {
      const subscriptions = this.state.subscriptions.subscriptions.data;

      const sub_ids = Object.keys(subscriptions);
      var members = 1;
      var amount = 0;
      var projects = 0;
      sub_ids.map((id) => {
        if (subscriptions[id].items.data !== null) {
          const plans_ids = Object.keys(subscriptions[id].items.data);
          plans_ids.map((id_item) => {
            amount += subscriptions[id].items.data[id_item].plan.amount / 100;
            if (
              subscriptions[id].items.data[id_item].plan.id ===
                "plan_GDjTSVrLZNyc62" ||
              subscriptions[id].items.data[id_item].plan.id ===
                "plan_G2pmCer4rgyhiC"
            )
              projects += 1;
            if (
              subscriptions[id].items.data[id_item].plan.id ===
                "plan_GDmsLlqqPdTyr2" ||
              subscriptions[id].items.data[id_item].plan.id ===
                "plan_GDohEBFezD5XDD"
            )
              members += 1;
            return null;
          });
        }
        return null;
      });
      return (
        <form>
          <div className="tw-px-4 tw-py-5 sm:tw-px-6">
            <p className="text-lg leading-6 font-medium text-gray-900">
              Plan Details
            </p>
          </div>
          <div className="tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-px-6">
            <div className="card text-center">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="mb-4">
                      <h6>Pay as you go</h6>
                      <h5 className="display-4 d-block mb-2 font-weight-normal">
                        €{amount}
                        <small style={{ fontSize: "14px" }}>HT</small>
                      </h5>
                      <span className="text-muted text-small">Per Month</span>
                    </div>
                    <ul className="list-unstyled">
                      <li> {projects} projects</li>
                      <li>1 team</li>
                      <li>{members} team members</li>
                    </ul>
                    <div className="custom-control custom-radio d-inline-block" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      );
    } else return "";
  }
}
const mapStateToProps = ({ firebase: { auth }, firestore: { ordered } }) => ({
  auth,
});

export default compose(
  firebaseConnect(),
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "stripe",
      doc: props.auth.uid,
    },
    {
      collection: "users_infos",
      doc: props.auth.uid,
    },
  ]),
  connect(
    (state, props) => ({
      user_infos: state.firestore.data.users_infos,
      stripe_infos: state.firestore.data.stripe,
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      notify: state.notify,
    }),
    { notifyUser, setProgressBar }
  )
)(Payment);
