import React from "react";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
/* import { EditorState } from "draft-js";
 */ import Editor, { createEditorStateWithText } from "draft-js-plugins-editor";
import createHashtagPlugin from "draft-js-hashtag-plugin";
import createLinkifyPlugin from "draft-js-linkify-plugin";
import createEmojiPlugin from "draft-js-emoji-plugin";
import createCounterPlugin from "draft-js-counter-plugin";

import createInlineToolbarPlugin, {
  Separator,
} from "draft-js-inline-toolbar-plugin";

import "draft-js-emoji-plugin/lib/plugin.css";
import "draft-js-inline-toolbar-plugin/lib/plugin.css";
import "draft-js-counter-plugin/lib/plugin.css";
import "./editorStyles.css";

import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
  /* CodeBlockButton, */
} from "draft-js-buttons";

class HeadlinesPicker extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      window.addEventListener("click", this.onWindowClick);
    });
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onWindowClick);
  }

  onWindowClick = () =>
    // Call `onOverrideContent` again with `undefined`
    // so the toolbar can show its regular content again.
    this.props.onOverrideContent(undefined);

  render() {
    const buttons = [HeadlineOneButton, HeadlineTwoButton, HeadlineThreeButton];
    return (
      <div>
        {buttons.map((
          Button,
          i // eslint-disable-next-line
        ) => (
          <Button key={i} {...this.props} />
        ))}
      </div>
    );
  }
}

class HeadlinesButton extends React.Component {
  // When using a click event inside overridden content, mouse down
  // events needs to be prevented so the focus stays in the editor
  // and the toolbar remains visible  onMouseDown = (event) => event.preventDefault()
  onMouseDown = (event) => event.preventDefault();

  onClick = () =>
    // A button can call `onOverrideContent` to replace the content
    // of the toolbar. This can be useful for displaying sub
    // menus or requesting additional information from the user.
    this.props.onOverrideContent(HeadlinesPicker);

  render() {
    return (
      <div
        onMouseDown={this.onMouseDown}
        className="draftJsToolbar__buttonWrapper__1Dmqh"
      >
        <button
          onClick={this.onClick}
          style={{
            background: "#fbfbfb",
            color: "#888",
            fontSize: "18px",
            border: "0",
            paddingTop: "5px",
            verticalAlign: "bottom",
            height: "34px",
            width: "36px",
          }}
          className="draftJsToolbar__button__qi1gf"
        >
          H
        </button>
      </div>
    );
  }
}

const hashtagPlugin = createHashtagPlugin();
const linkifyPlugin = createLinkifyPlugin();
const inlineToolbarPlugin = createInlineToolbarPlugin();
const emojiPlugin = createEmojiPlugin();
const counterPlugin = createCounterPlugin();
const { CharCounter } = counterPlugin;

const plugins = [
  linkifyPlugin,
  hashtagPlugin,
  inlineToolbarPlugin,
  emojiPlugin,
  counterPlugin,
];

const { InlineToolbar } = inlineToolbarPlugin;
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

class CampaignCreation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: createEditorStateWithText(
        "Once you click into the text field the sidebar plugin will show up …"
      ),
    };
  }

  onChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  focus = () => {
    this.editor.focus();
  };

  queryDate() {
    var last_contacted = new Date();
    last_contacted.setDate(last_contacted.getDate() - 5);
    let queryDate = this.props.firestore.Timestamp.fromDate(last_contacted);
    const query = this.props.firestore
      .collection("campaign_candidate")
      .where("step", "==", "found")
      .where("candidate_has_been_contacted", "==", true)
      .where("match_is_valid", "==", true)
      .where("contact_sequence", "==", 2)
      .where("step", "==", "found")
      .where("last_contacted", "<", queryDate);
    return query.get().then((querySnapshot) => {
      /* console.log(
        "query results => ",
        querySnapshot.docs.map((doc) => doc.data())
      ); */
    });
  }

  render() {
    return (
      <div className="main-container">
        <div className="container">
          <div className="row justify-content-center mt-5">
            <div className="col col-lg-12 editor" onClick={this.focus}>
              <Editor
                editorState={this.state.editorState}
                onChange={this.onChange}
                plugins={plugins}
                ref={(element) => {
                  this.editor = element;
                }}
              />
              <InlineToolbar>
                {
                  // may be use React.Fragment instead of div to improve perfomance after React 16
                  (externalProps) => (
                    <div>
                      <BoldButton {...externalProps} />
                      <ItalicButton {...externalProps} />
                      <UnderlineButton {...externalProps} />
                      <CodeButton {...externalProps} />
                      <Separator {...externalProps} />
                      <HeadlinesButton {...externalProps} />
                      <UnorderedListButton {...externalProps} />
                      <OrderedListButton {...externalProps} />
                      <BlockquoteButton {...externalProps} />
                    </div>
                  )
                }
              </InlineToolbar>
              <EmojiSelect />
              <EmojiSuggestions />
            </div>
          </div>
          <CharCounter editorState={this.state.editorState} limit={200} />
        </div>
      </div>
    );
  }
}

export default compose(firestoreConnect(), connect())(CampaignCreation);
