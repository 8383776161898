import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import T from "i18n-react";
import { TitleComponent } from "../layout/Title";

class Cgu extends Component {
  state = {};

  render() {
    const { isAuthenticated } = this.state;
    if (isAuthenticated) {
      return <Redirect to={{ pathname: "/home" }} />;
    } else
      return (
        <React.Fragment>
          <TitleComponent />
          <div className="tw-py-16 tw-bg-gray-50 tw-overflow-hidden">
            <div className="tw-max-w-7xl tw-mx-auto tw-px-4 tw-space-y-8 sm:tw-px-6 lg:tw-px-8">
              <div className="tw-text-base tw-max-w-prose tw-mx-auto lg:tw-max-w-none">
                <h2 className="tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                  Conditions générales
                </h2>
                <p className="tw-mt-2 tw-text-3xl tw-leading-normal tw-font-extrabold tw-tracking-tight tw-text-gray-900 sm:tw-text-4xl">
                  Conditions générales d’utilisation du service applicables au
                  15 janvier 2021
                </p>
              </div>
              <div className="lg:tw-grid lg:tw-grid-cols-2 lg:tw-gap-8 lg:tw-items-start">
                <div className="tw-relative tw-z-10">
                  <div className="tw-prose tw-prose-blue tw-text-gray-500 tw-mx-auto lg:tw-max-w-none">
                    <p>
                      Ces conditions générales d’utilisation du service (les «
                      CGU ») sont un accord juridique et contraignant entre
                      Hived.ai (« Hived » ou « Nous » ou le « Prestataire ») et
                      vous, ou, si vous représentez un employeur ou un client,
                      cet employeur ou ce client (ci-après « Vous » ou le «
                      Client ») régissant votre utilisation du site Web de
                      hived.ai localisé à l’adresse https://hived.ai, les
                      services relatifs au sourcing de candidats et tous les
                      autres services connexes que Hived fournit (les « Services
                      »). Hived se réserve le droit de changer ou de mettre à
                      jour ces CGU à tout moment et à sa seule discrétion en
                      publiant sur son site Web ou en fournissant toute autre
                      notification. Vos droits sous ces CGU seront soumis à la
                      version en vigueur publiée sur notre site Web au moment de
                      l’utilisation, et pour quelconque changement majeur nous
                      vous informerons au moins 30 jours avant sa mise en
                      application. VEUILLEZ LIRE ATTENTIVEMENT CES CONDITIONS.
                      EN SOUSCRIVANT OU EN ACCÉDANT OU UTILISANT LE SITE OU LES
                      SERVICES, VOUS DÉCLAREZ AVOIR LU ET ACCEPTEZ, SANS
                      RÉSERVES, LA VERSION EN VIGUEUR DU CONTRAT CONSTITUÉE DES
                      DOCUMENTS SUIVANTS, PAR ORDRE DE PRIORITÉ :
                    </p>
                    <p>
                      <ul>
                        <li>
                          Les conditions particulières (définis ci-après à
                          l’article 1), le cas échéant ;{" "}
                        </li>
                        <li>Ces CGU ;</li>
                      </ul>
                    </p>
                    <p>
                      Le services sont exclusivement réservés pour un usage
                      professionnel et ne sont pas autorisés pour les mineurs de
                      moins de 18 ans.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      Article 1. Définitions
                    </h2>
                    <p>
                      Les termes débutant avec une majuscule dans les présentes
                      ont la signification suivante, sauf s’il en est
                      expressément stipulé autrement :
                    </p>
                    <p>
                      « Donnée » désigne les données numériques du Client, que
                      celles-ci soient ou non personnelles, qui sont
                      recueillies, manipulées, traitées, et/ou communiquées
                      entre le Client et le Prestataire, dans le cadre des
                      droits d’accès et d’utilisation du Service de sourcing.
                    </p>
                    <p>
                      « Services » désigne la solution applicative externalisée
                      Hived, hébergée sur les serveurs et/ou le cloud désignés
                      par le Prestataire et exécutable à distance par le Client.
                      Le Service de sourcing offre différentes fonctionnalités,
                      parmi lesquelles, mais sans y limiter, l’administration de
                      campagnes de recrutement incluant le suivi, la gestion de
                      candidats. Le Prestataire se réserve expressément le
                      droit, à tout moment au cours de la durée de validité du
                      présent Contrat, d’adapter, arranger et/ou de modifier
                      l’un des composants permettant l’accès et l’utilisation du
                      Service, ainsi que la documentation associée, sous réserve
                      du respect, pendant ces opérations, des engagements en
                      matière de maintenance et de support. De la même manière,
                      Hived peut, à tout moment, cesser de maintenir une
                      plateforme jugée indésirable et/ou obsolète et migrer les
                      Services sur une nouvelle infrastructure ; en pareil cas,
                      Hived s’efforcera de Vous en informer le plus en amont
                      possible et de Vous proposer de migrer votre compte sur la
                      nouvelle infrastructure.
                    </p>
                    <p>
                      « Droit de Propriété Intellectuelle » désigne tous les
                      droits de propriété industrielle et de propriété
                      littéraire et artistique; comprenant les brevets, marques,
                      dessins et modèles, ainsi que le droit d’auteur (effets
                      audiovisuels, les interfaces graphiques, matériel de
                      conception préparatoire, les logiciels et codes sources,
                      et les cahiers des charges ainsi que la documentation
                      auxiliaire).
                    </p>
                    <p>
                      « Politiques Opérationnelles » désigne l’ensemble des
                      règles, instructions et procédures suivies par le
                      Prestataire dans le cadre de l’exploitation du Service de
                      sourcing et dont le respect s’impose également au Client
                      pour certaines d’entre elles. Ces Politiques
                      Opérationnelles sont composées de la Politique de
                      Confidentialité telles que régulièrement mises à jour.
                    </p>
                    <p>
                      « Conditions Particulières » désigne l’ensemble des
                      conditions spécifiques conclues avec le Client qui
                      précisent notamment les termes financiers et les exigences
                      de conformité.
                    </p>
                    <p>
                      « Site » désigne les pages Internet associées aux
                      fonctionnalités électroniques décrites dans la
                      documentation du Servicesourcing accessibles et
                      configurées pour et/ou par le Client, en accord avec les
                      CGU.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      Article 2. Support et niveaux de service
                    </h2>
                    <p>
                      2.1 Nous mettons en œuvre tous les efforts raisonnables,
                      conformément aux règles de l’art communément admises, pour
                      assurer la disponibilité d'un nombre pertinent de
                      candidats pour vos campagnes. L'intérêt est fortement lié
                      à la description qui est faite du poste à pourvoir. A
                      cette fin, Nous mettons en ligne à votre disposition une
                      interface vous permettant de mettre à jour le cas échéant
                      votre descriptif de poste et de l'adapter aux attentes des
                      candidats à qui il peut être adresser.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      Article 3. Utilisation du Service de sourcing
                    </h2>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      3.1 Utilisation des services
                    </h2>
                    <p>
                      Vous vous engagez à respecter les termes et les
                      restrictions d’utilisation, telles qu’elles sont énoncées
                      dans les présentes conditions et dans les Politiques
                      Opérationnelles. Vous êtes responsable de l’utilisation
                      conforme auxdites conditions et restrictions d’utilisation
                      de vos utilisateurs.
                    </p>
                    <p>
                      Vous ne devez pas interférer ou perturber le service de
                      sourcing et devez respecter les conditions d’accès et
                      d’utilisation du Service.
                    </p>
                    <p>
                      Vous vous abonnez à nos services en Votre nom pour votre
                      compte. Vous n’êtes pas autorisé à créer des campagnes via
                      notre service pour le compte d'une autre personne ou
                      entreprise, même gratuitement.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      3.2 Identifiants personnels
                    </h2>
                    <p>
                      Quand Vous créez votre compte, Vous vous engagez à
                      partager les informations nécessaires au traitement de vos
                      commandes tel que demandé en ligne, y compris, votre nom,
                      prénom, nom de votre société, adresse et adresse email
                      valide. Les données seront traitées en respect avec
                      notre Politique de Confidentialité. Vous êtes responsable
                      des conséquences des fausses informations ou erronées que
                      Vous nous avez partagé.
                    </p>
                    <p>
                      Vous devez garder confidentiel et sécuriser tous les
                      identifiants, comme les logins et mots de passe associés à
                      votre compte (compris ceux du compte maître et tout les
                      comptes qui y sont attachés) et Vous devez nous informer
                      immédiatement dans le cas d’une utilisation non autorisée
                      de votre compte ou de tout vol ou perte d’informations
                      d’identification permettant l’accès au compte. Chaque
                      compte est strictement personnel, son accès et son
                      utilisation sont réservés aux personnes dûment autorisées
                      par Vous. Vous reconnaissez et admettez votre
                      responsabilité pour toute utilisation illégale, illicite
                      ou frauduleuse de Votre compte. Vous nous indemniserez et
                      nous protégerez contre toute action ou réclamation
                      découlant d’une telle utilisation. Nous nous réservons le
                      droit de demander une preuve de votre identité avant
                      d’accorder l’accès à votre compte.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      3.3 Contenu
                    </h2>
                    <p>
                      Vous demeurez le seul et unique responsable (i) de toute
                      Donnée, information et autre contenu, y compris dans le
                      cas où ce contenu provient d’un tiers, ainsi que (ii) de
                      la conformité du Contenu à la réglementation en vigueur
                      conformément à l’article 4 ci-dessous.
                    </p>
                    <p>
                      Vous reconnaissez et garantissez qu’en aucun cas tout ou
                      partie du Contenu :
                    </p>
                    <ul>
                      <li>
                        n’enfreint, ne détourne ou ne viole quelque droit,
                        notamment de Droit de Propriété Intellectuelle de
                        quelque tiers que ce soit ;
                      </li>
                      <li>
                        ne contient de virus ou programme informatique ayant
                        pour but de causer des dommages, d’intercepter ou
                        exproprier de façon frauduleuse tout système ou les
                        Données ;
                      </li>
                      <li>
                        ne contient aucune information diffamatoire, ni
                        dangereuse pour les mineurs, ni contraire aux bonnes
                        mœurs ou enfreignant aucune autre loi applicable ;
                      </li>
                      <li>
                        ne contient aucune information sur la santé,
                        l’assurance-maladie;
                      </li>
                      <li>n’est faux, trompeur ou inexact ; ou,</li>
                      <li>ne respecte pas les Politiques Opérationnelles.</li>
                    </ul>
                    <p>
                      Vous reconnaissez que Hived a le droit, mais pas
                      l’obligation de contrôler le Service et les Données
                      soumises. Pour se conformer aux obligations légales à ce
                      sujet. Hived peut prendre des mesures (y compris la
                      suppression du Contenu ) si Nous considérons
                      raisonnablement que ces mesures sont nécessaires pour
                      prévenir toute activité illégale relative au Service.
                    </p>
                    <p>
                      Vous reconnaissez expressément que Hived ne peut être tenu
                      responsable de toute perte ou destruction du Contenu, ceci
                      incluant les Données, et qu’il Vous appartient donc d’en
                      assurer la sauvegarde.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      Article 4. Conformité à la loi
                    </h2>
                    <p>
                      Vous (i) vous engagez à Vous conformer à toutes les lois
                      en vigueur, applicables tant en France que dans tout les
                      pays depuis lequel Vous accédez au Site et/ou utilisez le
                      Service et (ii) garantissez et vous engagez à Nous
                      indemniser pour toute action, condamnation ou préjudice
                      que Nous subirions du fait de Votre non-respect de la
                      réglementation applicable.
                    </p>
                    <p>
                      Vous acceptez en outre de Nous rembourser de toute
                      intervention ou démarche que Nous pourrions être amenées à
                      effectuer afin de répondre à une procédure judiciaire ou
                      une requête émanant d’une autorité administrative ou
                      judiciaire en relation avec votre utilisation du Service.
                    </p>
                    <p>
                      Dans le cas où Vous auriez connaissance d’une violation
                      par un tiers d’une des dispositions des présentes CGU ou
                      de toute loi ou règlement, et en particulier dans le cas
                      où Vous auriez connaissance d’une faille de sécurité par
                      un tiers, ou si Vous identifiez vous-mêmes une éventuelle
                      faille de sécurité, Vous Nous en aviserez immédiatement
                      par tout moyen de communication, notamment via l’envoi
                      d’un courriel à l’adresse contact@hived.ai.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      Article 5. Abonnement- Modalités financières
                    </h2>
                    <p>
                      Nous proposons des options de plans variées à nos clients,
                      comme des plans gratuits et payants, avec ou sans
                      engagement. Vous pouvez trouver ces options et les tarifs
                      correspondants sur notre Site : http://www.hived.ai/#prix.
                      Les tarifs de nos plans sont disponible en Euros.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      5.1 Plans payants (Pay as you go)
                    </h2>
                    <p>
                      Les plans payants sont sans engagement et soumis à des
                      restrictions d’utilisation. Des fonctions supplémentaires
                      peuvent également être ajoutées à ces plans.
                    </p>
                    <p>
                      Votre abonnement payé sera dû et payable mensuellement ou
                      annuellement à l’avance à compter de la date de votre
                      premier abonnement payé et sera automatiquement renouvelé
                      par la suite sur la même base, sous réserve du paiement
                      intégral à l’avance des frais exigibles.
                    </p>
                    <p>
                      Vous pouvez à tout moment résilier votre plan payant.
                      Néanmoins nous attirons votre attention sur le fait que
                      Hived ne rembourse pas les frais avancés pour un mois ou
                      une année commencée et non utilisés, même au prorata. Il
                      relève de Votre responsabilité d’anticiper la résiliation
                      de Votre plan pour le rendre effectif au moment le plus
                      opportun.
                    </p>
                    <p>
                      Le paiement peut se faire par carte bancaire. Vous
                      autorisez Hived à débiter la carte bancaire Vous Nous avez
                      fourni pour tous les frais et toutes sommes que Vous Nous
                      devez au titre des Services.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      5.2 Plans payant Entreprise
                    </h2>
                    <p>
                      Les plans payants Entreprise comportant une durée minimale
                      d’engagement (en général 12 mois), sont soumis à des
                      restrictions d’utilisation réduites par rapport à nos
                      autres plans payants et offrent des solutions sur-mesure.
                      Les fonctions supplémentaires peuvent également être
                      ajoutées ainsi que des Services Compte Client.
                    </p>
                    <p>
                      Lorsque Vous souscrivez à un plan payant les conditions
                      financières qui vous sont applicables (frais, calendrier
                      et modalités de paiement) sont énoncées sur les Conditions
                      Particulières que Vous avez signé. Les frais pour le
                      service consistent en un montant fixe (“minimum fixe”) et
                      un montant variable (“frais variables”). En cas de retard
                      ou de suspension de routage de votre part au-delà d’une
                      période de deux mois, le Client se verra facturer des
                      frais minimums qui comprendront 10% des frais de routage
                      mensuels pendant ladite période. Sauf indication
                      contraire, les frais minimums sont non résiliables et non
                      remboursables quelle que soit la raison.
                    </p>
                    <p>
                      Nous ou Vous-même, peuvent résilier le plan à chaque fin
                      de terme (date d’anniversaire) en moyennant un préavis de
                      trois (3) mois. Vous resterez tenu au paiement de tous les
                      montants qui Nous sont dues jusqu’à ce que la résiliation
                      prenne effet. En l’absence d’une telle résiliation, le
                      plan sera renouvelé de plein droit pour la même durée
                      fixe, et vous serez facturés selon le calendrier de
                      paiement initial hors surcoût éventuel.
                    </p>
                    <p>
                      Le fournisseur de services ou Vous-même pouvez aussi
                      résilier le plan en avance dans le cas où l’autres partie
                      enfreindrait de façon substantielle le Contrat et
                      omettrait de remédier à cette violation matérielle dans
                      les trente (30) jours civils suivant la réception d’un
                      avis de cette violation, envoyé par courrier certifié avec
                      accusé de réception. Nonobstant ce qui précède, la
                      résiliation peut être immédiate dans les circonstances
                      suivantes:
                    </p>
                    <ul>
                      <li>
                        en cas de violation des lois et règlements; en cas de
                        violation ou de tentative de violation de la sécurité du
                        Site Web;
                      </li>
                      <li>
                        en cas de fraude ou de tentative de fraude liée à
                        l’utilisation du Site Web ou du Service.
                      </li>
                    </ul>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      5.3 Dispositions communes pour tous les plans payants
                    </h2>
                    <p>
                      Les factures sont exprimées en Euros, sont payables sans
                      escompte et à réception (sauf mention contraire sur la
                      facture), de préférence par virement bancaire au numéro de
                      compte dont les coordonnées sont mentionnées sur les
                      factures.
                    </p>
                    <p>
                      Tout retard de paiement (i) nous permettra de recouvrer
                      sans préavis les intérêts de retard, les coûts de
                      recouvrement et autres montants prévus par la loi et (ii)
                      pourra immédiatement, après préavis, suspendre
                      temporairement l’accès au Service et son utilisation et/ou
                      résilier votre abonnement. Tous les montants à payer sont
                      indiqués hors taxes. Vous devrez payer toutes les taxes
                      imposées par tout gouvernement sur les montants payables
                      pour les Services, ou nous rembourser dans le cas où Nous
                      aurions payé ces montants, pour lesquels Vous êtes
                      personnellement responsable.
                    </p>
                    <p>
                      Hived se réserve le droit de modifier les frais en vous le
                      notifiant par email avec un préavis de trente (30) jours.
                      Si Vous n’acceptez pas les nouveaux frais que nous avons
                      notifiés, vous devez cesser toute utilisation du service
                      au plus tard le dernier jour de la période mensuelle déjà
                      payée à la date de la notification et résilier ensuite
                      votre plan. Pour les plans à durée déterminée, les frais
                      applicables sont ceux en vigueur à la date de votre
                      commande. Les nouveaux frais ne seront applicables qu’à la
                      date du renouvellement de votre plan.
                    </p>
                    <p>
                      Vous acceptez de ne pas mettre en cause la responsabilité
                      de Hived et de ne pas contester le paiement correspondant
                      à votre utilisation du service.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      Article 6. Résiliation
                    </h2>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      6.1 Droit de rétractation
                    </h2>
                    <p>
                      Les services sont exclusivement réservés pour un usage
                      professionnel. En vertu des articles 221-18 à 221-28 du
                      Code de la Consommation, les dispositions concernant les
                      droits des consommateurs et de rétraction ne sont pas
                      applicables à nos services.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      6.2 Résiliation et/ou suppression du compte à l’initiative
                      de Hived
                    </h2>
                    <p>
                      Hived peut résilier votre Compte et l’exécution des
                      Services à sa seule discrétion dans les circonstances
                      suivantes:
                    </p>
                    <ul>
                      <li>
                        A tout moment et pour toute raison légitime pour un
                        compte inactif sous réserve d’un préavis raisonnable ;
                        ou
                      </li>
                      <li>
                        après notification que Hived Vous aura adressé suite à
                        tout manquement aux lois ou règlements ou aux présentes
                        CGU, et notamment sans limitation : en cas de défaut de
                        paiement ou paiement partiel de toute somme qui serait
                        due par Vous ; en cas d’incident de paiement ; en cas de
                        violation ou tentative de violation de la sécurité du
                        Site ; ou, en cas de fraude ou tentative de fraude
                        relative à l’utilisation du Site.
                      </li>
                    </ul>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      6.3 Conséquences de la résiliation
                    </h2>
                    <p>
                      A l’expiration ou la résiliation de votre compte pour
                      quelque motif que ce soit, tous les montants dus
                      deviendront exigibles et payables immédiatement. Nous ne
                      procéderons à aucun remboursement pour les sommes déjà
                      payées et Nous vous facturons le montant que Nous aurions
                      été autorisés à facturer pour la durée restante.
                    </p>
                    <p>
                      Nous vous informons qu’à compter de la date d’entrée en
                      vigueur de la résiliation de votre compte Vous ne pourrez
                      plus accéder à Votre compte ni aux Données qui y sont
                      stockées, sous réserve des dispositions de la Politique de
                      Confidentialité et des dispositions légales, à compter de
                      la date de résiliation effective.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      Article 7. Propriété intellectuelle – Droits d’accès et
                      d’utilisation
                    </h2>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      7.1 Droit des marques
                    </h2>
                    <p>
                      La dénomination “Hived” et les marques référencées sur le
                      Site Web, sans limitation, sont des marques déposées du
                      Prestataire. Vous n’êtes pas autorisé à utiliser ces
                      marques sans l’autorisation expresse préalable du
                      Prestataire. Tout le contenu du site internet, compris les
                      graphiques, logos, les en-têtes de pages, icônes et noms
                      de service, sont la propriété du Prestataire et de ses
                      affiliés. Les autres marques sur le Site sont la propriété
                      du Prestataire et ses affiliés. Les autres marques qui
                      apparaissent sur le Site sont la propriété de leurs
                      déposants respectifs, qui peuvent ou non être affiliés,
                      connexes ou sponsorisés par le Prestataire.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      7.2 Droits d’auteurs
                    </h2>
                    <p>
                      Tous les éléments présents sur le site, tels que les
                      éléments graphiques (compris interface graphique), les
                      logos, en-têtes, icônes, noms de services, contenu
                      littéraire, programme d’ordinateur… sont protégés par le
                      droit d’auteur, et restent la propriété pleine et entière
                      de leurs auteurs. Vous n’êtes pas autorisé à utiliser le
                      contenu ou la conception du Site sans l’accord écrit du
                      Prestataire. Vous ne pouvez pas utiliser, copier,
                      modifier, créer et diffuser une oeuvre dérivée. Concernant
                      le programme informatique utilisé, Vous ne pouvez pas
                      également procéder au reverse engineering, à la
                      décompilation dans le but d’en extraire le code source.
                      Toute activité qui enfreint les termes des présentes, et
                      qui viole le droit d’auteur seront poursuivis conformément
                      aux lois applicables en vigueur et sanctionnée au titre du
                      droit de la propriété intellectuelle.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      7.3 Droit d’accès
                    </h2>
                    <p>
                      Sous réserve du complet paiement du prix en cas
                      d’abonnement payant, Hived Vous autorise/concède un droit
                      d’accès et d’utilisation du Service limité, non-exclusif,
                      incessible, personnel et temporaire. Cette concession ne
                      saurait être considérée comme une cession de droit de
                      propriété intellectuelle.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      7.4 Droit exclusif
                    </h2>

                    <p>
                      Hived dispose du droit exclusif d’intervenir pour adapter,
                      arranger, et/ou modifier l’un des composants du Service et
                      notamment pour en corriger les erreurs. Hived reste
                      titulaire de tout droit de propriété intellectuelle,
                      protégeant, le cas échéant des travaux et prestations
                      réalisées par Nous dans le cadre de nos Services en
                      particulier, ainsi que toute la documentation associée;
                      mais aussi toutes les copies de ces travaux et
                      prestations, leurs dérivés, adaptations et améliorations.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      Article 8. Garanties
                    </h2>
                    <p>
                      Hived met en œuvre tous les efforts raisonnables pour
                      délivrer un Service fonctionnel et substantiellement
                      conforme à sa documentation et/ou son SLA, mais ne
                      garantit pas l’absence d’erreur ou de « bug ». Si Vous
                      constatez une non-conformité, Vous devez le notifier à
                      Hived dans les plus brefs délais et Hived s’engage à faire
                      tous les efforts raisonnables pour remédier à la
                      non-conformité, ceci étant Votre seul recours.
                    </p>
                    <p>
                      Hived garantit en outre que les Services d’Accompagnement
                      seront fournis, le cas échéant, conformément au descriptif
                      indiqué dans les Conditions Particulières que vous avez
                      signé. Toutefois le Prestataire n’étant ici tenu que d’une
                      obligation de moyens.
                    </p>
                    <p>
                      Vous déclarez, garantissez et convenez au bénéfice de
                      Hived que: (1) d’une part, Vous avez le droit et
                      l’autorité pour souscrire et utiliser les Services et,
                      d’autre part que vous acceptez les présentes CGU.
                    </p>
                    <p>
                      Si vous acceptez ce Contrat au nom d’une société ou autre
                      entité, de lier la société ou autre entité aux termes des
                      présentes CGU ; (2) Vous avez le droit et l’autorité
                      d’exécution de vos obligations en vertu des présentes CGU
                      ; et (3) toutes les données, informations ou contenu que
                      Vous fournissez à Hived dans le cadre de votre accès au
                      Site et de l’utilisation des Services sont exactes et à
                      jour.
                    </p>
                    <p>
                      Vous reconnaissez en outre que Hived ne contrôle pas le
                      transfert des Données via Internet, et ne peut donc être
                      tenue responsable des retards ou des problèmes de
                      livraison résultant d’internet ou toute autre problème de
                      connexion.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      Article 9. Réclamation de tiers
                    </h2>
                    <p>
                      Vous vous engagez à Nous défendre et Nous indemniser de
                      tout dommage, y compris les dépens et honoraires
                      d’avocats, résultant de toute réclamation ou allégation
                      d’un tiers ayant pour cause l’accès ou l’utilisation du
                      Site ou du Service, ou de toute violation des présentes
                      dispositions, à condition que Nous (a) Vous informions
                      sans délai et par écrit d’une telle réclamation. (b) Nous
                      Vous autorisons à contrôler et diriger les recherches, la
                      préparation, la défense et le règlement de ladite
                      réclamation ; et (c) Nous vous assistons et coopérons
                      pleinement à la défense de la réclamation. Vous acceptez
                      de payer les dommages et intérêts auxquels Hived pourrait
                      être condamnée au titre de la réclamation (ou accordés par
                      Vous dans une transaction). Vous ne serez pas responsable
                      d’un règlement pour lequel Vous n’auriez pas donné au
                      préalable Votre accord écrit.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      Article 10. Limitation de responsabilité
                    </h2>
                    <p>
                      Le Service comme Le Site peuvent inclure des liens vers
                      d’autres sites web ou d’autres sources Internet. Dans la
                      mesure où Nous ne pouvons pas contrôler ces sites et ces
                      sources externes, Hived ne peut être tenue pour
                      responsable de la mise à disposition de ces sites et des
                      sources externes, et ne peut supporter aucune
                      responsabilité quant au contenu, publicités, produits,
                      services ou tout autre matériel disponible sur ou à partir
                      de ces sites ou sources externes. Hived fournit des liens
                      uniquement à titre de commodité, et cette inclusion de
                      tout lien ne signifie pas que Nous endossons les sites Web
                      liés ou toute partie de leur contenu. En outre, Hived ne
                      peut être tenu responsable du comportement ou des actions
                      d’autres utilisateurs. Ni d’ailleurs de tout dommage ou
                      perte qu’il/elle soit avéré(e) ou allégué(e) consécutif,
                      ou de dommage en relation avec l’accès, l’utilisation ou
                      le fait d’avoir compté sur le contenu, les produits ou les
                      services disponibles sur de tels sites ou sources
                      externes.
                    </p>
                    <p>
                      Hived sera déchargée de l’exécution des Services, en cas
                      d’événement constitutif de force majeure, définis à
                      l’article 12.5 des présentes CGU.
                    </p>
                    <p>
                      Par ailleurs, pour des raisons de maintenance, Hived
                      pourra interrompre temporairement l’accès aux Services ;
                      en pareil cas, Hived s’efforcera de Vous en avertir
                      préalablement et de minimiser la durée de cette
                      interruption.
                    </p>
                    <p>
                      En tout état de cause, en aucun cas Hived (y compris un de
                      ses fournisseurs tiers, un de ses employés ou
                      représentants) ne sera responsable des dommages indirects
                      ou consécutifs de toute nature, y compris et sans
                      limitation, la perte de revenus, de profits, de chance,
                      interruption d’activité, ou la perte de Données, et même
                      si les Parties ont été informées de la possibilité de tels
                      dommages.
                    </p>
                    <p>
                      De plus, pour n’importe quel abonné indirect à Hived, en
                      aucun cas Hived ne sera tenu responsable pour quelconque
                      Utilisateur Final, en particulier en cas de suspension
                      temporaire ou permanente des Services par Vous ou par
                      l’Utilisateur Final.
                    </p>
                    <p>
                      Dans tous les cas, la responsabilité totale de Hived est
                      limitée, tous dommages confondus, aux sommes versées par
                      Vous à Hived, le cas échéant, pour l’utilisation du Site
                      et des Services, au titre des douze (12) derniers mois
                      précédant la date de survenance du dommage. Cette
                      limitation ne s’applique pas aux dommages dus en raison de
                      dommages corporels (y compris le décès), ni au dol ou
                      faute lourde.
                    </p>
                    <p>
                      Vous reconnaissez souscrire aux Services en considération
                      (i) des risques qui y sont liés et des présentes
                      dispositions relatives à la responsabilité de Hived, et
                      (ii) du niveau de risque que Vous déclarez accepter. Les
                      prix applicables le cas échéant ont été convenus en
                      considération de la présente clause, laquelle fait partie
                      de l’équilibre économique du Contrat.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      Article 11. Confidentialité
                    </h2>
                    <p>
                      Le Prestataire et Vous, vous engagez à traiter de manière
                      confidentielle et à ne pas reproduire ou divulguer, sauf
                      aux seules fins de l’exécution du Contrat, les
                      informations et documents échangés entre eux ainsi que
                      toute divulgation obtenue dans le cadre du présent
                      Contrat.
                    </p>
                    <p>
                      Les deux parties s’engagent également à faire en sorte que
                      toutes les parties contractantes, sous-traitants, clients
                      ou tiers maintiennent la confidentialité avec le même soin
                      raisonnable que leurs propres informations
                      confidentielles.
                    </p>
                    <p>
                      Les deux Parties reconnaissent qu’un manquement à leurs
                      obligations concernant les Informations Confidentielles
                      peut causer à l’autre Partie un préjudice irréparable pour
                      lequel il n’existe aucun recours légal approprié et que la
                      Partie Divulgatrice pourra demander une mesure
                      conservatoire en plus des autres recours dont elle
                      dispose. Le Client s’interdit en outre de divulguer à un
                      tiers les résultats de tous tests de performance réalisés
                      sur le Service, sans l’accord écrit préalable du
                      Prestataire.
                    </p>
                    <p>
                      Nous excluons de l’obligation de confidentialité les
                      informations qui sont généralement accessibles au public
                      ou dont la divulgation est requise pour des raisons
                      juridiques, ou en raison d’une décision judiciaire ou
                      administrative.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      Article 12. Divers
                    </h2>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      12.1 Transfert – Changement de contrôle
                    </h2>
                    <p>
                      En tant que de besoin, il Vous est rappelé que Vous
                      bénéficiez d’un droit d’accès et d’utilisation personnel,
                      temporaire, incessible et non exclusif du Service.
                    </p>
                    <p>
                      Dans ces conditions, il est expressément convenu que Vous
                      ne pourrez en aucun cas transférer votre abonnement, ni
                      aucun droit en découlant pour l’accès et l’utilisation du
                      Service à un tiers, par voie d’apport, de cession, de
                      concession, de fusion, de scission, de prêt ou autrement,
                      même à titre gratuit ou à l’intérieur du groupe auquel
                      Vous appartenez, sauf avec Notre accord préalable et
                      écrit.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      12.2 Sous-traitance
                    </h2>
                    <p>
                      Hived se réserve le droit de faire appel au(x)
                      sous-traitant(s) de son choix pour l’exécution des
                      prestations liées aux Services et restera responsable de
                      leur exécution à votre égard dans les conditions et sous
                      les réserves stipulées aux présentes CGU.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      12.3 Intégralité
                    </h2>
                    <p>
                      Les présentes CGU constituent, avec tout bon de commande
                      et/ou conditions particulières, l’intégralité des
                      engagements entre Vous et Nous, établissent l’ensemble de
                      nos droits et obligations réciproques et annulent tous les
                      autres engagements verbaux ou écrits antérieurs concernant
                      directement ou indirectement l’objet du contrat nous
                      liant. Ce contrat nous liant ne pourra être modifié que
                      par un avenant écrit, signé et désigné comme tel par les
                      deux Parties (Vous et Nous).
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      12.4 Indépendance des clauses
                    </h2>
                    <p>
                      Si n’importe quelle disposition de l’Accord est réputée
                      nulle en vertu d’un principe juridique, d’une loi ou d’un
                      règlement ou est invalidée par une décision judiciaire,
                      elle sera dissociée de l’Accord, mais les autres
                      dispositions de l’Accord resteront pleinement en vigueur.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      12.5 Dissociation
                    </h2>
                    <p>
                      Sauf dispositions contraires, le fait que l’une des
                      Parties n’ait pas exigé l’application d’une quelconque des
                      présentes CGU ne pourra en aucun cas être considéré comme
                      une renonciation aux droits de cette Partie au titre de
                      ladite clause.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      12.6 Force Majeure
                    </h2>
                    <p>
                      Hived n’est pas responsable de tout manquement ou retard
                      dû à des événements extraordinaires indépendants de sa
                      volonté, y compris, mais sans se limiter, les grèves, les
                      lock-out, l’arrêt des connexions Internet par le
                      fournisseur d’accès, les cyber-attaques sur le Site
                      Internet (« Force Majeure »).
                    </p>
                    <p>
                      En cas de survenance d’un cas de Force Majeure, le Contrat
                      nous liant sera automatiquement suspendu pendant toute la
                      période de Force Majeure, et aucune des Parties ne pourra
                      être tenue responsable par l’autre Partie d’un quelconque
                      retard ou inexécution contractuels du fait d’un tel
                      événement. A condition néanmoins que la Partie défaillante
                      informe sans délai l’autre Partie de la survenance du cas
                      de Force Majeure, et fasse des efforts raisonnables pour
                      reprendre l’exécution de son obligation dès que possible.
                      Il est convenu que pendant le cas de Force Majeure,
                      l’autre Partie pourra de la même manière suspendre
                      l’exécution de ses obligations, jusqu’à ce que la Partie
                      défaillante reprenne l’exécution de ses propres
                      obligations. Les Parties se réuniront afin de déterminer
                      d’un commun accord les conditions d’aménagement ou de
                      remplacement permettant une reprise de l’exécution du
                      Contrat dans les plus brefs délais. En cas de Force
                      Majeure empêchant la poursuite des relations
                      contractuelles pendant une durée supérieure à soixante
                      (60) jours, le Contrat pourra être résilié par lettre
                      recommandée avec accusé de réception avec effet immédiat
                      par l’une ou l’autre des Parties. Nonobstant ce qui
                      précède, un événement de Force Majeure ne peut en aucun
                      cas excuser ou retarder les obligations d’une Partie
                      relatives à la Confidentialité ou aux Droits de propriété
                      intellectuelle.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      12.7 Loi applicable – Juridiction compétente
                    </h2>
                    <p>
                      Les présentes CGU sont soumises à la loi française. A
                      défaut de règlement amiable, toute difficulté liée à leur
                      interprétation ou validation ainsi que tout différend nous
                      opposant seront de la compétence exclusive du tribunal de
                      Paris, nonobstant pluralité de défendeurs ou appel en
                      garantie.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      MENTIONS LEGALES
                    </h2>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      Hived SAS
                    </h2>
                    {/* <p>13-13 bis, rue de l’Aubrac – 75012 Paris, France</p>
              <p>Société par actions simplifiée au capital de 69.367,55 €</p>
              <p>RCS Paris 524 536 992 00059</p>
              <p>TVA intracommunautaire FR 675 245 369 92</p> */}
                  </div>
                  <div className="tw-mt-10 tw-flex tw-text-base tw-max-w-prose tw-mx-auto lg:tw-max-w-none">
                    <div className="tw-rounded-md tw-shadow">
                      <a
                        href="/signup"
                        className="tw-w-full tw-flex tw-items-center tw-justify-center tw-px-5 tw-py-3 tw-border tw-border-transparent tw-tw-text-base tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700"
                      >
                        {T.translate("landing.start")}
                      </a>
                    </div>
                    <div className="tw-rounded-md tw-shadow tw-ml-4">
                      <a
                        href="/signup"
                        className="tw-w-full tw-flex tw-items-center tw-justify-center tw-px-5 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-md tw-text-blue-600 tw-bg-white hover:tw-bg-gray-50"
                      >
                        {T.translate("landing.demo")}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="tw-mt-12 tw-relative tw-text-base tw-max-w-prose tw-mx-auto lg:tw-mt-0 lg:tw-max-w-none">
                  <svg
                    className="tw-absolute tw-top-0 tw-right-0 tw--mt-20 tw--mr-20 lg:tw-top-auto lg:tw-right-auto lg:tw-bottom-1/2 lg:tw-left-1/2 lg:tw-mt-0 lg:tw-mr-0 xl:tw-top-0 xl:tw-right-0 xl:tw--mt-20 xl:tw--mr-20"
                    width="404"
                    height="384"
                    fill="none"
                    viewBox="0 0 404 384"
                    aria-hidden="true"
                  >
                    <defs>
                      <pattern
                        id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          className="text-gray-200"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width="404"
                      height="384"
                      fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)"
                    />
                  </svg>
                  {/* <blockquote className="tw-relative tw-bg-white tw-rounded-lg tw-shadow-lg">
                    <div className="tw-rounded-t-lg tw-px-6 tw-py-8 sm:tw-px-10 sm:tw-pt-10 sm:tw-pb-8">
                      <img
                        src="https://tailwindui.com/img/logos/workcation-logo-blue-600-mark-gray-800-and-blue-600-text.svg"
                        alt="Workcation"
                        className="h-8"
                      />
                      <div className="tw-relative tw-text-lg tw-text-gray-700 tw-font-medium tw-mt-8">
                        <svg
                          className="tw-absolute tw-top-0 tw-left-0 tw-transform tw--translate-x-3 tw--translate-y-2 tw-h-8 tw-w-8 tw-text-gray-200"
                          fill="currentColor"
                          viewBox="0 0 32 32"
                          aria-hidden="true"
                        >
                          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                        </svg>
                        <p className="tw-relative">
                          Tincidunt integer commodo, cursus etiam aliquam neque,
                          et. Consectetur pretium in volutpat, diam. Montes,
                          magna cursus nulla feugiat dignissim id lobortis amet.
                          Laoreet sem est phasellus eu proin massa, lectus.
                        </p>
                      </div>
                    </div>
                    <cite className="tw-relative tw-flex tw-items-center sm:tw-items-start tw-bg-blue-600 tw-rounded-b-lg tw-not-italic tw-py-5 tw-px-6 sm:tw-py-5 sm:tw-pl-12 sm:tw-pr-10 sm:tw-mt-10">
                      <div className="tw-relative tw-rounded-full tw-border-2 tw-border-white sm:tw-absolute sm:tw-top-0 sm:tw-transform sm:tw--translate-y-1/2">
                        <img
                          className="tw-w-12 tw-h-12 sm:tw-w-20 sm:tw-h-20 tw-rounded-full tw-bg-blue-300"
                          src="https://images.unsplash.com/photo-1500917293891-ef795e70e1f6?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2.5&w=160&h=160&q=80"
                          alt=""
                        />
                      </div>
                      <span className="tw-relative tw-ml-4 tw-text-blue-300 tw-font-semibold tw-leading-6 sm:tw-ml-24 sm:tw-pl-1">
                        <p className="tw-text-white tw-font-semibold sm:tw-inline">
                          Judith Black
                        </p>
                        <p className="sm:tw-inline">CEO at Workcation</p>
                      </span>
                    </cite>
                  </blockquote> */}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  connect((state, props) => ({
    auth: state.firebase.auth,
  }))
)(Cgu);
