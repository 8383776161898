import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import T from "i18n-react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Notification from "./notification";

const NotificationsPan = (dt) => {
  var newNotifications = false;
  var htmlNotifications = [];

  if (dt.notifications) {
    for (const [key, value] of Object.entries(dt.notifications)) {
      if (key && value) {
        if (value.seen === false) newNotifications = true;
        htmlNotifications.push(<Notification key={key} id={key} dt={value} />);
      }
    }
  }

  htmlNotifications.reverse();

  if (!(htmlNotifications.length > 0))
    htmlNotifications = [
      <li key="0" className="tw-relative tw-pb-3 tw-pt-3">
        <div className="tw-flex tw-items-start">
          <div className="tw-flex-shrink-0">
            <svg
              className="tw-h-6 tw-w-6 tw-text-gray-400"
              x-description="Heroicon name: inbox"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
              ></path>
            </svg>
          </div>
          <div className="tw-ml-3 tw-w-0 tw-flex-1 tw-pt-0.5">
            <p className="tw-text-sm tw-font-medium tw-text-gray-900">
              {T.translate("notifications.notifications")}
            </p>
            <p className="tw-mt-1 tw-text-sm tw-text-gray-500">
              {T.translate("notifications.notifications_desc")}
            </p>
            {/* <div className="tw-mt-2">
              <button className="tw-rounded-md tw-text-sm tw-font-medium tw-text-indigo-600 hover:tw-text-indigo-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500">
                Undo
              </button>
              <button className="tw-ml-6 tw-rounded-md tw-text-sm tw-font-medium tw-text-gray-700 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500">
                Dismiss
              </button>
            </div> */}
          </div>
        </div>
      </li>,
    ];
  ///
  dt.newNotifications(newNotifications);

  return (
    <div>
      <SlidingPane
        className="tw-z-50 tw-bg-white"
        overlayClassName="tw-z-50 tw-bg-gray-100"
        title="Notifications"
        isOpen={dt.open}
        width="400px"
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          dt.closeCallback();
        }}
      >
        <ul className="tw-divide-y tw-divide-white-200 tw-overflow-y-auto">
          {htmlNotifications}
        </ul>
      </SlidingPane>
    </div>
  );
};

class Notifications extends Component {
  state = {
    notificationsPaneOpen: false,
  };

  componentDidUpdate(prevProps) {
    if (this.state.notificationsPaneOpen !== this.props.open)
      this.setState({ notificationsPaneOpen: this.props.open });
  }

  render() {
    if (!isLoaded(this.props.notifications)) {
      return "";
    }

    return (
      <NotificationsPan
        notifications={this.props.notifications}
        open={this.state.notificationsPaneOpen}
        closeCallback={this.props.closeCallback}
        newNotifications={this.props.newNotifications}
      />
    );
  }
}

const mapStateToProps = ({ firebase: { auth }, firestore: { ordered } }) => ({
  auth,
});

export default compose(
  firebaseConnect(),
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "users",
      doc: props.auth.uid,
      subcollections: [
        {
          collection: "notifications",
          where: ["seen", "==", false],
          orderBy: ["date", "desc"],
        },
      ],
      storeAs: "notifications_" + props.auth.uid,
    },
  ]),
  connect((state, props) => ({
    notifications: state.firestore.data["notifications_" + props.auth.uid],
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    notify: state.notify,
  }))
)(Notifications);
