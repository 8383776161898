import React, { Component } from "react";
import { firebaseConnect, firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import { syncGmail } from "../../helpers/mail";
import { getEvents } from "../../helpers/calendar";

class integration extends Component {
  state = { labels: null, events: null };

  componentDidUpdate(nextProps) {
    if (this.state.events === null) {
      getEvents(this.props.user_id).then((events) => {
        if (
          events.response !== undefined &&
          events.response.items !== undefined
        ) {
          this.setState({ events: events.response.items });
          /* console.log(events.response.items); */
        }
      });
    }
    if (this.state.labels === null) {
      syncGmail(this.props.user_id).then((labels) => {
        if (
          labels.response !== undefined &&
          labels.response.labels !== undefined
        ) {
          this.setState({ labels: labels.response.labels });
          /* console.log(labels.response.labels); */
        }
      });
    }
  }

  render() {
    return (
      <div
        className="tab-pane fade"
        role="tabpanel"
        id="email-integration"
        aria-labelledby="email-integration-tab"
      >
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col">
                <div className="media align-items-center">
                  <img
                    alt="Gmail"
                    style={{ width: "64px" }}
                    src="theme/img/logos-inte/gmail.png"
                  />
                  <div className="media-body ml-2">
                    <span className="h6 mb-0 d-block">Gmail</span>
                    <span className="text-small text-muted">
                      Permissions: Read, Write, Delete
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="col-auto">
                <button className="btn btn-sm btn-danger">Revoke</button>
              </div> */}
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col">
                <div className="media align-items-center">
                  <img
                    alt="Calendar"
                    style={{ width: "64px" }}
                    src="theme/img/logos-inte/calendar.png"
                  />
                  <div className="media-body ml-2">
                    <span className="h6 mb-0 d-block">Calendar</span>
                    <span className="text-small text-muted">
                      Permissions: Read, Write, Delete
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="col-auto">
                <button className="btn btn-sm btn-danger">Revoke</button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  connect()
)(integration);
