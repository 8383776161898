import React, { Component } from "react";

import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import CampaignSearchTerms from "./CampaignSearchTerms";
import Wait from "../layout/Wait";
import { isLoaded } from "react-redux-firebase";
import Applications from "./Applications";

import {
  InstantSearch,
  SearchBox,
  /*   RefinementList,
   */ Configure,
  connectHits,
  Pagination,
} from "react-instantsearch-dom";
import algoliasearch from "algoliasearch/lite";

const ProfileMatch = (
  id,
  selection,
  validated,
  not_validated,
  interested,
  contacted,
  campaign
) => {
  switch (selection) {
    case "default":
      return true;
    case "interested":
      return interested[campaign + "_" + id];
    case "validated":
      return validated[campaign + "_" + id];
    case "contacted":
      return contacted[campaign + "_" + id];
    case "waiting":
      return not_validated[campaign + "_" + id];
    default:
      return true;
  }
};

const Hits = ({
  hits,
  selection,
  validated,
  not_validated,
  interested,
  contacted,
  campaign,
  /*   profiles,
  firestore, */
}) => {
  return (
    <table className="min-w-full tw-mt-3">
      <thead>
        <tr className="tw-border-t tw-border-gray-200">
          <th className="tw-w-24 tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
            <span className="lg:tw-pl-2">Name</span>
          </th>
          <th className="tw-max-w-sm tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
            Job Title
          </th>
          <th className="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
            Contacted
          </th>
          <th className="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-text-right tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
            Location
          </th>
          <th className="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-text-right tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
            Options
          </th>
        </tr>
      </thead>
      <tbody className="tw-bg-white tw-divide-y tw-divide-gray-100">
        {hits.map((hit) => {
          /*           await firestore.get({
            collection: "events",
            doc: hit.objectID,
          }); */

          /*           console.log("profile => ", profiles[hit.objectID]);
           */ if (
            ProfileMatch(
              hit.objectID,
              selection,
              validated,
              not_validated,
              interested,
              contacted,
              campaign
            )
          )
            return (
              <tr key={hit.objectID}>
                <td className="tw-px-6 tw-py-3 tw-max-w-0 tw-w-full tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900">
                  <div className="tw-flex tw-items-center tw-flex tw-items-center tw-space-x-3">
                    <div className="">
                      <div className="tw-ext-sm tw-font-medium tw-text-gray-900 truncate">
                        <a
                          href={"/candidate/edit/" + hit.objectID}
                          rel="noopener noreferrer"
                          target="_blank"
                          className="tw-overflow-ellipsis tw-overflow-hidden"
                        >
                          {hit.profile.name}
                        </a>
                      </div>

                      {hit.profile.emails[0] ? (
                        <div className="tw-text-sm tw-text-gray-500">
                          {hit.profile.emails[0].email}
                        </div>
                      ) : (
                        <div className="tw-text-sm tw-text-red-500">
                          No emails address
                        </div>
                      )}
                    </div>
                  </div>
                </td>
                <td className="tw-max-w-sm tw-px-6 tw-py-4 tw-whitespace-nowrap truncate">
                  <div className="tw-text-sm tw-text-gray-900 tw-overflow-ellipsis tw-overflow-hidden">
                    {hit.profile.position}
                  </div>
                  <div className="tw-text-sm tw-text-gray-500">
                    {hit.profile.company}
                  </div>
                </td>
                <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">
                  {hit.profile.candidate_has_been_contacted ? (
                    <span className="tw-px-2 tw-inline-flex tw-text-xs tw-leading-5 tw-font-semibold tw-rounded-full tw-bg-green-100 tw-text-green-800">
                      Contacted
                    </span>
                  ) : (
                    <span className="tw-px-2 tw-inline-flex tw-text-xs tw-leading-5 tw-font-semibold tw-rounded-full tw-bg-red-100 tw-text-red-800">
                      Not contacted
                    </span>
                  )}
                </td>
                <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 truncate">
                  {hit.profile.location}
                </td>
                <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-right tw-text-sm tw-font-medium">
                  <a
                    href={"/candidate/edit/" + hit.objectID}
                    className="text-blue-600 hover:text-blue-900"
                  >
                    Edit
                  </a>
                </td>
              </tr>
            );
          return "";
        })}
      </tbody>
    </table>
  );
};

class CampaignModal extends Component {
  constructor(props) {
    super(props);
    this.state = { query: "", selection: "default", hitsPerPage: 20 };
  }
  componentDidUpdate(nextProps) {
    if (this.state.campaign !== nextProps.match.params.id_campaign)
      this.setState({ campaign: nextProps.match.params.id_campaign });
  }

  allContacted = () => {
    this.setState({ query: "AND profile.candidate_has_been_contacted:true" });
  };

  notContacted = () => {
    this.setState({
      query: "AND NOT profile.candidate_has_been_contacted:true",
    });
  };
  all = () => {
    this.setState({ query: "" });
  };

  componentDidMount() {
    const { firestore } = this.props;
    firestore.setListener("profiles");
  }

  componentWillUnmount() {
    const { firestore } = this.props;
    firestore.unsetListener("profiles");
  }

  handleColDragEnd(e) {}
  render() {
    if (
      !isLoaded(this.props.campaign_infos) ||
      !isLoaded(this.props.campaign_users)
    ) {
      return <Wait />;
    }
    /* 
    const skill_keys =
      this.props.campaign_infos.skills === null ||
      this.props.campaign_infos.skills === undefined
        ? []
        : Object.keys(this.props.campaign_infos.skills); */

    /* const users_keys =
      this.props.campaign_users === null ||
      this.props.campaign_users === undefined
        ? []
        : Object.keys(this.props.campaign_users); */

    const contacted =
      this.props.contacted !== null && this.props.contacted !== undefined
        ? Object.keys(this.props.contacted).length
        : 0;
    const validated =
      this.props.validated_candidates !== undefined &&
      this.props.validated_candidates !== null
        ? Object.keys(this.props.validated_candidates).length
        : 0;
    const interested =
      this.props.interested !== undefined && this.props.interested !== null
        ? Object.keys(this.props.interested).length
        : 0;

    const waiting =
      this.props.not_validated !== undefined &&
      this.props.not_validated !== null
        ? Object.keys(this.props.not_validated).length
        : 0;

    const searchClient = algoliasearch(
      "WZVXTOBRTL",
      "98f5c49d2c7c867fcb4171c648375e80"
    );

    const CustomHits = connectHits(Hits);

    return (
      <>
        <div className="tw-mx-auto tw-p-8 tw-bg-white">
          <div className="tw-max-w-7xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8">
            <div className="tw-border-b tw-border-t tw-border-gray-200 sm:tw-border sm:tw-rounded-lg tw-overflow-hidden">
              <div className="tw-px-4 tw-py-2 tw-border-b tw-border-gray-200 tw-flex tw-justify-between tw-items-center tw-bg-white sm:tw-py-4 sm:tw-px-6 sm:tw-items-baseline">
                <div className="tw-flex-1 tw-min-w-0">
                  <h1 className="tw-text-2xl tw-4 tw-font-medium tw-leading-6 tw-text-gray-900">
                    {this.props.campaign_infos !== undefined
                      ? this.props.campaign_infos.jobTitle
                      : ""}
                  </h1>
                </div>
              </div>
              <div className="tw-bg-gray-100 tw-8 tw-p-8">
                <dl className="tw-mt-5 tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2 lg:tw-grid-cols-3">
                  <div className="tw-bg-white tw-overflow-hidden tw-shadow tw-rounded-lg">
                    <div className="tw-px-4 tw-py-5 sm:tw-p-6">
                      <div className="tw-flex tw-items-center">
                        <div className="tw-flex-shrink-0 tw-bg-blue-500 tw-rounded-md tw-p-3">
                          <svg
                            className="tw-h-6 tw-w-6 tw-text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                            />
                          </svg>
                        </div>
                        <div className="tw-ml-5 tw-w-0 tw-flex-1">
                          <dt className="tw-text-sm tw-font-medium tw-text-gray-500 tw-truncate">
                            All Candidates
                          </dt>
                          <dd className="tw-flex tw-items-baseline">
                            <div className="tw-text-4xl tw-font-semibold tw-text-gray-900">
                              {this.props.all_candidates !== null &&
                              this.props.all_candidates !== undefined
                                ? Object.keys(this.props.all_candidates).length
                                : ""}
                            </div>

                            <div className="tw-ml-2 tw-flex tw-items-baseline tw-text-sm tw-font-semibold tw-text-green-600">
                              <svg
                                className="tw-self-center tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-green-500"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <span className="tw-sr-only">Increased by</span>
                              122
                            </div>
                          </dd>
                        </div>
                      </div>
                    </div>
                    <div className="tw-bg-gray-50 tw-px-4 tw-py-4 sm:tw-px-6">
                      <div className="tw-text-sm">
                        <a
                          href="#l"
                          onClick={() => {
                            this.setState({
                              selection: "default",
                              hitsPerPage: 20,
                            });
                          }}
                          className="tw-font-medium tw-text-blue-600 hover:tw-text-blue-500"
                        >
                          {" "}
                          View all
                          <span className="tw-sr-only">
                            {" "}
                            Total Subscribers stats
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="tw-bg-white tw-overflow-hidden tw-shadow tw-rounded-lg">
                    <div className="tw-px-4 tw-py-5 sm:tw-p-6">
                      <div className="tw-flex tw-items-center">
                        <div className="tw-flex-shrink-0 tw-bg-blue-500 tw-rounded-md tw-p-3">
                          <svg
                            className="tw-h-6 tw-w-6 tw-text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                            />
                          </svg>
                        </div>
                        <div className="tw-ml-5 tw-w-0 tw-flex-1">
                          <dt className="tw-text-sm tw-font-medium tw-text-gray-500 tw-truncate">
                            Approved Candidates
                          </dt>
                          <dd className="tw-flex tw-items-baseline">
                            <div className="tw-text-4xl tw-font-semibold tw-text-gray-900">
                              {validated}
                            </div>

                            <div className="tw-ml-2 tw-flex tw-items-baseline tw-text-sm tw-font-semibold tw-text-green-600">
                              <svg
                                className="tw-self-center tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-green-500"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <span className="tw-sr-only">Increased by</span>
                              {this.props.all_candidates !== null &&
                              this.props.all_candidates !== undefined
                                ? validated /
                                  (Object.keys(this.props.all_candidates)
                                    .length /
                                    100)
                                : 0}
                              %
                            </div>
                          </dd>
                        </div>
                      </div>
                    </div>
                    <div className="tw-bg-gray-50 tw-px-4 tw-py-4 sm:tw-px-6">
                      <div className="tw-text-sm">
                        <a
                          onClick={() => {
                            this.setState({
                              selection: "validated",
                              hitsPerPage: 300,
                            });
                          }}
                          href="#l"
                          className="tw-font-medium tw-text-blue-600 hover:tw-text-blue-500"
                        >
                          {" "}
                          View all
                          <span className="tw-sr-only">
                            {" "}
                            Avg. Open Rate stats
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="tw-bg-white tw-overflow-hidden tw-shadow tw-rounded-lg">
                    <div className="tw-px-4 tw-py-5 sm:tw-p-6">
                      <div className="tw-flex tw-items-center">
                        <div className="tw-flex-shrink-0 tw-bg-blue-500 tw-rounded-md tw-p-3">
                          <svg
                            className="tw-h-6 tw-w-6 tw-text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76"
                            />
                          </svg>
                        </div>
                        <div className="tw-ml-5 tw-w-0 tw-flex-1">
                          <dt className="tw-text-sm tw-font-medium tw-text-gray-500 tw-truncate">
                            Contacted Candidated
                          </dt>
                          <dd className="tw-flex tw-items-baseline">
                            <div className="tw-text-4xl tw-font-semibold tw-text-gray-900">
                              {contacted}
                            </div>

                            <div className="tw-ml-2 tw-flex tw-items-baseline tw-text-sm tw-font-semibold tw-text-red-600">
                              <svg
                                className="tw-self-center tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-red-500"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <span className="tw-sr-only">Decreased by</span>
                              {contacted /
                                (validated > 0 ? validated : 1 / 100)}
                              %
                            </div>
                          </dd>
                        </div>
                      </div>
                    </div>
                    <div className="tw-bg-gray-50 tw-px-4 tw-py-4 sm:tw-px-6">
                      <div className="tw-text-sm">
                        <a
                          onClick={() => {
                            this.setState({
                              selection: "contacted",
                              hitsPerPage: 300,
                            });
                          }}
                          href="#l"
                          className="tw-font-medium tw-text-blue-600 hover:tw-text-blue-500"
                        >
                          {" "}
                          View all
                          <span className="tw-sr-only">
                            {" "}
                            Avg. Click Rate stats
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </dl>
                <dl className="tw-mt-5 tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2 lg:tw-grid-cols-3">
                  <div className="tw-bg-white tw-overflow-hidden tw-shadow tw-rounded-lg">
                    <div className="tw-px-4 tw-py-5 sm:tw-p-6">
                      <div className="tw-flex tw-items-center">
                        <div className="tw-flex-shrink-0 tw-bg-blue-500 tw-rounded-md tw-p-3">
                          <svg
                            className="tw-h-6 tw-w-6 tw-text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                            />
                          </svg>
                        </div>
                        <div className="tw-ml-5 tw-w-0 tw-flex-1">
                          <dt className="tw-text-sm tw-font-medium tw-text-gray-500 tw-truncate">
                            Interested Candidates
                          </dt>
                          <dd className="tw-flex tw-items-baseline">
                            <div className="tw-text-4xl tw-font-semibold tw-text-gray-900">
                              {interested}
                            </div>

                            <div className="tw-ml-2 tw-flex tw-items-baseline tw-text-sm tw-font-semibold tw-text-green-600">
                              <svg
                                className="tw-self-center tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-green-500"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <span className="tw-sr-only">Increased by</span>
                              {interested / (contacted / 100)}%
                            </div>
                          </dd>
                        </div>
                      </div>
                    </div>
                    <div className="tw-bg-gray-50 tw-px-4 tw-py-4 sm:tw-px-6">
                      <div className="tw-text-sm">
                        <a
                          onClick={() => {
                            this.setState({
                              selection: "interested",
                              hitsPerPage: 300,
                            });
                          }}
                          href="#l"
                          className="tw-font-medium tw-text-blue-600 hover:tw-text-blue-500"
                        >
                          {" "}
                          View all
                          <span className="tw-sr-only">
                            {" "}
                            Total Subscribers stats
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="tw-bg-white tw-overflow-hidden tw-shadow tw-rounded-lg">
                    <div className="tw-px-4 tw-py-5 sm:tw-p-6">
                      <div className="tw-flex tw-items-center">
                        <div className="tw-flex-shrink-0 tw-bg-blue-500 tw-rounded-md tw-p-3">
                          <svg
                            className="tw-h-6 tw-w-6 tw-text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                            />
                          </svg>
                        </div>
                        <div className="tw-ml-5 tw-w-0 tw-flex-1">
                          <dt className="tw-text-sm tw-font-medium tw-text-gray-500 tw-truncate">
                            Hired Candidates
                          </dt>
                          <dd className="tw-flex tw-items-baseline">
                            <div className="tw-text-4xl tw-font-semibold tw-text-gray-900">
                              0
                            </div>

                            <div className="tw-ml-2 tw-flex tw-items-baseline tw-text-sm tw-font-semibold tw-text-green-600">
                              <svg
                                className="tw-self-center tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-green-500"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <span className="tw-sr-only">Increased by</span>
                              5.4%
                            </div>
                          </dd>
                        </div>
                      </div>
                    </div>
                    <div className="tw-bg-gray-50 tw-px-4 tw-py-4 sm:tw-px-6">
                      <div className="tw-text-sm">
                        <a
                          href="#l"
                          className="tw-font-medium tw-text-blue-600 hover:tw-text-blue-500"
                        >
                          {" "}
                          View all
                          <span className="tw-sr-only">
                            {" "}
                            Avg. Open Rate stats
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="tw-bg-white tw-overflow-hidden tw-shadow tw-rounded-lg">
                    <div className="tw-px-4 tw-py-5 sm:tw-p-6">
                      <div className="tw-flex tw-items-center">
                        <div className="tw-flex-shrink-0 tw-bg-blue-500 tw-rounded-md tw-p-3">
                          <svg
                            className="tw-h-6 tw-w-6 tw-text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </div>
                        <div className="tw-ml-5 tw-w-0 tw-flex-1">
                          <dt className="tw-text-sm tw-font-medium tw-text-gray-500 tw-truncate">
                            Waiting Candidates
                          </dt>
                          <dd className="tw-flex tw-items-baseline">
                            <div className="tw-text-4xl tw-font-semibold tw-text-gray-900">
                              {waiting}
                            </div>

                            <div className="tw-ml-2 tw-flex tw-items-baseline tw-text-sm tw-font-semibold tw-text-red-600">
                              <svg
                                className="tw-self-center tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-red-500"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <span className="tw-sr-only">Decreased by</span>
                              {this.props.all_candidates !== null &&
                              this.props.all_candidates !== undefined
                                ? Math.round(
                                    waiting /
                                      (Object.keys(this.props.all_candidates)
                                        .length /
                                        100)
                                  )
                                : ""}
                              %
                            </div>
                          </dd>
                        </div>
                      </div>
                    </div>
                    <div className="tw-bg-gray-50 tw-px-4 tw-py-4 sm:tw-px-6">
                      <div className="tw-text-sm">
                        <a
                          onClick={() => {
                            this.setState({
                              selection: "waiting",
                              hitsPerPage: 300,
                            });
                          }}
                          href="#l"
                          className="tw-font-medium tw-text-blue-600 hover:tw-text-blue-500"
                        >
                          {" "}
                          View all
                          <span className="tw-sr-only">
                            {" "}
                            Avg. Click Rate stats
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </dl>
              </div>
            </div>
            <div className="tw-p-8 tw-relative">
              <div
                className="tw-absolute tw-inset-0 tw-flex tw-items-center"
                aria-hidden="true"
              >
                <div className="tw-w-full tw-border-t tw-border-gray-300"></div>
              </div>
              <div className="tw-relative tw-flex tw-justify-center">
                <span className="tw-px-2 tw-bg-white tw-text-sm tw-text-gray-500">
                  Search{" "}
                </span>
              </div>
            </div>

            <div className="tw-pb-3 tw-border-b tw-border-gray-200 tw-px-4 tw-py-4 sm:tw-flex sm:tw-items-center sm:tw-justify-between sm:tw-px-6 lg:tw-px-8">
              <div className="tw-flex-1 tw-min-w-0">
                <h3 className="tw-text-xl tw-font-medium tw-leading-6 tw-text-gray-900 ">
                  Search Section
                </h3>
              </div>
              <div className="tw-mt-4 tw-flex sm:tw-mt-0 sm:tw-ml-4">
                <button
                  type="button"
                  className="tw-order-0 tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-1 sm:tw-ml-3"
                >
                  Create
                </button>
                <span className="tw-relative tw-z-0 tw-inline-flex tw-shadow-sm tw-rounded-md">
                  <button
                    onClick={() => {
                      this.all();
                    }}
                    type="button"
                    className="tw-relative tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-l-md tw-border tw-border-gray-300 tw-bg-white tw-text-sm tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-z-10 focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-blue-500 focus:tw-border-blue-500"
                  >
                    All
                  </button>
                  <button
                    onClick={() => {
                      this.notContacted();
                    }}
                    type="button"
                    className="tw--ml-px tw-relative tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-bg-white tw-text-sm tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-z-10 focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-blue-500 focus:tw-border-blue-500"
                  >
                    Not contacted
                  </button>
                  <button
                    onClick={() => {
                      this.allContacted();
                    }}
                    type="button"
                    className="tw--ml-px tw-relative tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-r-md tw-border tw-border-gray-300 tw-bg-white tw-text-sm tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-z-10 focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-blue-500 focus:tw-border-blue-500"
                  >
                    Contacted
                  </button>
                </span>
              </div>
            </div>
            <div className="tw-pt-3 tw-pb-3">
              <InstantSearch
                showMore
                indexName="prod_Candidates"
                searchClient={searchClient}
              >
                <Configure
                  hitsPerPage={this.state.hitsPerPage}
                  filters={`profile.campaign_id:${this.props.match.params.id_campaign} ${this.state.query}`}
                />
                <SearchBox
                  className="tw-block tw-w-full tw-pl-10 tw-pr-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-leading-5 tw-bg-white tw-shadow-sm tw-placeholder-gray-500 focus:tw-outline-none focus:tw-placeholder-gray-400 focus:tw-ring-1 focus:tw-ring-blue-600 focus:tw-border-blue-600 sm:tw-text-sm"
                  showLoadingIndicator
                />
                {/* <RefinementList
                  attribute="profile.campaign_id"
                  defaultRefinement={[this.props.match.params.id_campaign]}
                /> */}
                {/* <RefinementList
                    attribute="skills.skills"
                    limit={5}
                    showMore
                    searchable
                  /> */}
                <CustomHits
                  className="min-w-full"
                  validated={this.props.validated_candidates}
                  interested={this.props.interested}
                  not_validated={this.props.not_validated}
                  contacted={this.props.contacted}
                  selection={this.state.selection}
                  campaign={this.props.match.params.id_campaign}
                  /*  profiles={this.props.profiles}
                  firestore={this.props.firestore} */
                />
                <div className="tw-bg-white tw-px-4 tw-py-3 tw-flex tw-items-center tw-justify-between tw-border-t tw-border-gray-200 sm:tw-px-6">
                  <div className="tw-hidden sm:tw-flex-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between">
                    <div>Pagination</div>
                    <div className="tw-relative tw-z-0 tw-inline-flex tw-shadow-sm tw--space-x-px">
                      <Pagination />
                    </div>
                  </div>
                </div>
              </InstantSearch>
              <div className="tw-p-8 tw-relative">
                <div
                  className="tw-absolute tw-inset-0 tw-flex tw-items-center"
                  aria-hidden="true"
                >
                  <div className="tw-w-full tw-border-t tw-border-gray-300"></div>
                </div>
                <div className="tw-relative tw-flex tw-justify-center">
                  <span className="tw-px-2 tw-bg-white tw-text-sm tw-text-gray-500">
                    Crawl information{" "}
                  </span>
                </div>
              </div>
              <CampaignSearchTerms
                campaign_infos={this.props.campaign_infos}
                campaign={this.props.match.params.id_campaign}
              />
              <Applications
                interested={this.props.interested}
                campaign={this.props.campaign_infos}
                creator={this.props.campaign_users}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (
  { firebase: { auth }, firestore: { ordered } },
  state
) => ({
  auth,
  campaignx: state.idCampain,
});

export default compose(
  firebaseConnect(),
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "projects",
      doc: props.match.params.id_campaign,
      storeAs: "campaign_infos",
    },
    {
      collection: "users_projects",
      where: ["project", "==", props.match.params.id_campaign],
      storeAs: "campaign_users",
    },
    {
      collection: "campaign_candidate",
      where: [
        ["match_has_been_humanly_validated", "==", false],
        ["campaign", "==", props.match.params.id_campaign],
      ],
      storeAs: "not_validated_" + props.match.params.id_campaign,
    },
    {
      collection: "campaign_candidate",
      where: [
        /* ["match_has_been_humanly_validated", "==", true], */
        ["match_is_valid", "==", true],
        /* ["candidate_has_been_contacted", "==", false], */
        ["campaign", "==", props.match.params.id_campaign],
      ],
      storeAs: "validated_candidates_" + props.match.params.id_campaign,
    },
    {
      collection: "campaign_candidate",
      where: [["campaign", "==", props.match.params.id_campaign]],
      storeAs: "all_candidates_" + props.match.params.id_campaign,
    },
    {
      collection: "campaign_candidate",
      where: [
        ["campaign", "==", props.match.params.id_campaign],
        ["candidate_has_been_contacted", "==", true],
      ],
      storeAs: "contacted_" + props.match.params.id_campaign,
    },
    {
      collection: "campaign_candidate",
      where: [
        ["campaign", "==", props.match.params.id_campaign],
        ["application.interested", "==", true],
        ["step", "==", "found"],
      ],
      storeAs: "interested_" + props.match.params.id_campaign,
    },
  ]),
  connect((state, props) => ({
    campaign_infos: state.firestore.data["campaign_infos"],
    campaign_users: state.firestore.data.campaign_users,
    profiles: state.firestore.data.profiles,
    not_validated:
      state.firestore.data["not_validated_" + props.match.params.id_campaign],
    validated_candidates:
      state.firestore.data[
        "validated_candidates_" + props.match.params.id_campaign
      ],
    all_candidates:
      state.firestore.data["all_candidates_" + props.match.params.id_campaign],
    contacted:
      state.firestore.data["contacted_" + props.match.params.id_campaign],
    interested:
      state.firestore.data["interested_" + props.match.params.id_campaign],
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    notify: state.notify,
  }))
)(CampaignModal);
