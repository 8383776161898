import { applyMiddleware, createStore, compose, combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";

import { firestoreReducer } from "redux-firestore";

import notifyReducer from "./reducers/notifyReducer";
import * as SWRTC from "@andyet/simplewebrtc";
import Thunk from "redux-thunk";

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  simplewebrtc: SWRTC.reducer,
  notify: notifyReducer,
});

const initialState = { simplewebrtc: {} };

let store = null;
if (process.env.NODE_ENV === "development") {
  store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(Thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  );
} else {
  store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(Thunk))
  );
}
export default store;
