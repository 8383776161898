import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import Project from "./Project";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
/* import TeamList from "../organisation/TeamList"*/
class ListProjetcs extends Component {
  render() {
    const { users_projects, role } = this.props;

    if (
      users_projects === undefined ||
      users_projects === null ||
      users_projects.length < 1
    ) {
      return (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-7 row py-5">
              <div className="text-center">
                <h1 className="h2">
                  Bienvenue{" "}
                  <span role="img" aria-label="Waving hand">
                    👋
                  </span>
                </h1>
                <p className="lead">
                  Vous n'avez pas encore créé de campagne de recrutement!
                  Commencez maintenant !
                </p>
                <Link
                  to="/campaign/create"
                  className="cmb-3 btn-large center-block btn btn-pill
                  btn-primary mb-2"
                >
                  {" "}
                  Créer une campagne de recrutement !
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      const project_ids = Object.keys(users_projects);
      return (
        <div className="tab-content">
          <div
            className="tab-pane fade show active"
            id="projects"
            role="tabpanel"
            aria-labelledby="tasks-tab"
            data-filter-list="content-list-body"
          >
            <div className="content-list">
              <div className="row content-list-head">
                <div className="col-auto">
                  <h3 className="tw-text-2xl">Campagnes </h3>
                  {role.create_project === true &&
                  this.props.profileView !== true ? (
                    <button
                      type="button"
                      className="tw-ml-3 tw-inline-flex tw-items-center tw-p-3 tw-border tw-border-transparent tw-rounded-full tw-shadow-sm tw-text-white tw-bg-blue-500 tw-hover:bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
                    >
                      <a
                        href="/campaign/create"
                        className="hover:tw-text-white"
                      >
                        <svg
                          className="tw-h-5 tw-w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                          />
                        </svg>
                      </a>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="content-list-body row">
                {project_ids.map((project_id) => (
                  <Project
                    location={this.props.location}
                    key={users_projects[project_id].project}
                    project={users_projects[project_id].project}
                    profileView={this.props.profileView}
                  />
                ))}
                {this.props.profileView !== true ? (
                  <div className="col-lg-6">
                    <div className="NP">
                      <button className="btn btn-round">
                        <a href="/campaign/create">
                          <i className="material-icons">add</i>
                        </a>
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {/* <TeamList
            organisation={this.props.organisation}
            role={this.props.role}
          />*/}
        </div>
      );
    }
  }
}

ListProjetcs.propTypes = {
  firestore: PropTypes.object.isRequired,
  users_projects: PropTypes.object.isRequired,
};

export default compose(firestoreConnect(), connect())(ListProjetcs);
