import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import T from "i18n-react";
import { TitleComponent } from "../layout/Title";

class Demo extends Component {
  state = {};

  componentDidMount() {
    const script = document.createElement("script");

    script.src =
      "https://cdn.pipedriveassets.com/web-form-assets/webforms.min.js";
    script.async = true;

    document.body.appendChild(script);
  }

  render() {
    const { isAuthenticated } = this.state;
    if (isAuthenticated) {
      return <Redirect to={{ pathname: "/home" }} />;
    } else
      return (
        <React.Fragment>
          <TitleComponent title={"Hived.ai | " + T.translate("demo.title")} />
          <div className="tw-bg-white tw-py-16 px-4 tw-overflow-hidden tw-sm:px-6 lg:tw-px-8 lg:tw-py-24">
              <div className="tw-relative tw-max-w-xl tw-mx-auto">
                <svg className="tw-absolute tw-left-full tw-transform tw-translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
                  <defs>
                    <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                      <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
                </svg>
                <svg className="tw-absolute tw-right-full tw-bottom-0 tw-transform tw--translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
                  <defs>
                    <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                      <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
                </svg>
                <div className="tw-text-center">
                  <h2 className="tw-text-3xl tw-font-extrabold tw-tracking-tight tw-text-gray-900 sm:tw-text-4xl">
                  {T.translate("demo.title")}
                  </h2>
                  <p className="tw-mt-4 tw-text-lg tw-leading-6 tw-text-gray-500">
                    {T.translate("demo.description")}
                  </p>
                </div>
                <div className="tw-mt-12">
                <div
                            style={{ minWidth: "340px", height: "800px" }}
                            className="text-center pipedriveWebForms"
                            data-pd-webforms={T.translate("demo.form")}
                          />
                </div>
              </div>
            </div>
        </React.Fragment>
      );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  connect((state, props) => ({
    auth: state.firebase.auth
  }))
)(Demo);
