import React, { Component } from "react";
import { connect } from "react-redux";
import T from "i18n-react";
import { Redirect } from "react-router-dom";
//TODO:https://github.com/rcdexta/react-trello/issues/362
import Board from "react-trello/dist";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { notifyUser } from "../../actions/notifyActions";
import { isLoaded } from "react-redux-firebase";
import Contributors from "./Contributors";
import Invite from "./Invite";
import {
  getProjectCandidateId,
  getProjectUserId,
  addActivityMove,
  getTranslate,
  isStepValid,
} from "../../helpers/project";
import Wait from "../layout/Wait";
import { getSubscription } from "../../helpers/payment";
import LoadingOverlay from "react-loading-overlay";
import Card from "./board/Card";
import Confetti from "react-confetti";
import Dissmissal from "./board/Dissmissal";
import Hired from "./board/Hired";
import CandidatePane from "../candidates/CandidatePane";
import Payment from "../Payment/Payment";
import Notvalid from "../Payment/Notvalid";
/* import CandidatePoolPane from "./Pool/CandiatePoolPane"; */

class ProjectTest extends Component {
  constructor(props) {
    super(props);
    this.hasSub = false;
    this._isMounted = false;
  }
  state = {
    pipeSize: 0,
    isPaneOpen: false,
    isPoolPaneOpen: false,
    dissCard: null,
    application: null,
    dissmissed: false,
    celebrate: false,
    addingCards: false,
    ValidInviteSubscription: null,
    numberOfCandidates: 0,
    isLoading: true,
    modal_candidate: "XXXXXXXXXXXXX",
    status: null,
    id_rel: null,
    subscriptionIsActive: true,
    setup: false,
    hasCandidates: false,
    cancelDrag: null,
    data: {
      lanes: [
        {
          id: "sourced",
          title: "Sourced",
          label: "",
          style: {
            borderBottom: "0px",
            color: "rgba(0,0,0,0.9)",
            backgroundColor: "#ebecf2", //"rgb(182, 184, 200, 0.6)",
            maxWidth: "19.5rem",
            borderRadius: "0.5rem",
            userSelect: "none",
            lex: "1 0 auto",
            width: "calc(100vw - 24px)",
            maxHeight: "100%",
            paddingRight: "0.75rem",
            paddingBottom: "0.75rem",
            outline: "none !important",
            boxSizing: "border-box",
            padding: "1rem",
          },
          cards: [],
        },
        {
          id: "called",
          title: "Called",
          label: "",
          style: {
            borderBottom: "0px",
            color: "rgba(0,0,0,0.9)",
            backgroundColor: "#ebecf2", //"rgb(182, 184, 200, 0.6)",
            maxWidth: "19.5rem",
            borderRadius: "0.5rem",
            userSelect: "none",
            lex: "1 0 auto",
            width: "calc(100vw - 24px)",
            maxHeight: "100%",
            paddingRight: "0.75rem",
            paddingBottom: "0.75rem",
            outline: "none !important",
            boxSizing: "border-box",
            padding: "1rem",
          },
          cards: [],
        },
        {
          id: "interviewed",
          title: "Interviewed",
          label: "",
          style: {
            borderBottom: "0px",
            color: "rgba(0,0,0,0.9)",
            backgroundColor: "#ebecf2", //"rgb(182, 184, 200, 0.6)",
            maxWidth: "19.5rem",
            borderRadius: "0.5rem",
            userSelect: "none",
            lex: "1 0 auto",
            width: "calc(100vw - 24px)",
            maxHeight: "100%",
            paddingRight: "0.75rem",
            paddingBottom: "0.75rem",
            outline: "none !important",
            boxSizing: "border-box",
            padding: "1rem",
          },
          cards: [],
        },
        {
          id: "offer",
          title: "Offer Made",
          label: "",
          style: {
            borderBottom: "0px",
            color: "rgba(0,0,0,0.9)",
            backgroundColor: "#ebecf2", //"rgb(182, 184, 200, 0.6)",
            maxWidth: "19.5rem",
            borderRadius: "0.5rem",
            userSelect: "none",
            lex: "1 0 auto",
            width: "calc(100vw - 24px)",
            maxHeight: "100%",
            paddingRight: "0.75rem",
            paddingBottom: "0.75rem",
            outline: "none !important",
            boxSizing: "border-box",
            padding: "1rem",
          },
          cards: [],
        },
        {
          id: "hired",
          title: "Hired",
          label: "",
          style: {
            borderBottom: "0px",
            color: "rgba(0,0,0,0.9)",
            backgroundColor: "#ebecf2", //"rgb(182, 184, 200, 0.6)",
            maxWidth: "19.5rem",
            borderRadius: "0.5rem",
            userSelect: "none",
            lex: "1 0 auto",
            width: "calc(100vw - 24px)",
            maxHeight: "100%",
            paddingRight: "0.75rem",
            paddingBottom: "0.75rem",
            outline: "none !important",
            boxSizing: "border-box",
            padding: "1rem",
          },
          cards: [],
        },
        {
          id: "rejected",
          title: "Rejected",
          label: "",
          style: {
            borderBottom: "0px",
            color: "rgba(0,0,0,0.9)",
            backgroundColor: "#ebecf2", //"rgb(182, 184, 200, 0.6)",
            maxWidth: "19.5rem",
            borderRadius: "0.5rem",
            userSelect: "none",
            lex: "1 0 auto",
            width: "calc(100vw - 24px)",
            maxHeight: "100%",
            paddingRight: "0.75rem",
            paddingBottom: "0.75rem",
            outline: "none !important",
            boxSizing: "border-box",
            padding: "1rem",
          },
          cards: [],
        },
      ],
    },
  };

  shouldCancelDrag = (value) => {
    this.setState({ cancelDrag: value });
  };

  modalCallback = (candidate, status, id_rel, openPool = false) => {
    this.setState({
      modal_candidate: candidate,
      status: status,
      id_rel: id_rel,
      isPaneOpen: true,
    });
    if (openPool) {
      this.ModalPoolPaneStatus(true);
    }
  };

  ModalPaneStatus = (value) => {
    this.setState({
      isPaneOpen: value,
    });
  };

  ModalPoolPaneStatus = (value) => {
    this.setState({
      isPoolPaneOpen: value,
    });
  };

  pipeSizeUpdate = (value) => {
    this.setState({
      pipeSize: value,
    });
  };

  addCard = (candidate, rel, activity) => {
    const id = getProjectCandidateId(rel.campaign, rel.profile);
    if (this.state.eventBus !== undefined) {
      var avatarTitle = "";
      if (
        candidate.firstName !== undefined &&
        candidate.secondName !== undefined
      ) {
        avatarTitle =
          candidate.firstName.charAt(0) + candidate.secondName.charAt(0);
      }

      var picture = null;
      if (candidate.profilePicture) picture = candidate.profilePicture;
      var location = null;
      if (candidate.location)
        location =
          candidate.location.substring(0, 23) +
          (candidate.location.length > 23 ? "..." : "");
      var summary = null;
      if (candidate.summary) summary = candidate.summary;
      var industry = null;
      if (candidate.industry) industry = candidate.industry;

      if (!isStepValid(rel.step)) return false;
      var lastContact = null;
      if (rel.last_contacted !== null && rel.last_contacted !== undefined)
        lastContact = rel.last_contacted;

      var indicators = [];
      if (rel.smart_indicators !== undefined && rel.smart_indicators !== null)
        indicators = rel.smart_indicators;

      var last_activity =
        activity !== null && activity.date !== undefined ? activity.date : null;
      if (
        last_activity === null &&
        rel.last_activity !== undefined &&
        rel.last_activity !== null
      )
        last_activity = rel.last_activity;

      //last activity is more than 10 days
      var tenDays = new Date();
      tenDays.setDate(tenDays.getDate() - 10);
      var slipping = false;
      if (
        last_activity !== null &&
        tenDays > new Date(last_activity.seconds * 1000)
      )
        slipping = true;

      this.setState({ numberOfCandidates: this.state.numberOfCandidates + 1 });
      this.state.eventBus.publish({
        type: "ADD_CARD",
        laneId: rel.step,
        card: {
          id: id,
          status: rel.step,
          modalCallback: this.modalCallback,
          name: candidate.firstName + " " + candidate.secondName,
          jobtitle:
            candidate.position !== undefined
              ? candidate.position.substring(0, 23) +
                (candidate.position.length > 23 ? "..." : "")
              : "",
          dueOn: "due now",
          step: rel.step,
          indicators: indicators,
          slipping: slipping,
          last_activity: last_activity,
          lastContact: lastContact,
          picture: picture,
          initials: avatarTitle,
          subTitle: "Email received at 1:14pm",
          body: "Is the estimate free, and can someone call me soon?",
          escalationText: "Escalated to Admin",
          cardColor: "#ffffff",
          candidate: rel.profile,
          campaign: rel.campaign,
          location: location,
          summary: summary,
          industry: industry,
          cardStyle: {
            borderBottom: "0px",
            backgroundColor: "no",
            borderRadius: "0.5rem",
            minWidth: "270px",
          },
        },
      });
    }
  };

  addCandidate = (candidate, rel) => {
    this.props.firestore
      .get({
        collection: "profiles",
        doc: candidate,
      })
      .then((candidate_profile) => {
        if (candidate_profile.exists) {
          this.getLastActivity(candidate_profile.data(), rel);
        }
      });
  };

  getLastActivity = (candidate, rel) => {
    const id = getProjectCandidateId(rel.campaign, rel.profile);
    this.props.firestore
      .get({
        collection: "campaign_candidate",
        doc: id,
        subcollections: [{ collection: "history" }],
        where: ["type", "==", "move"],
        orderBy: ["date", "desc"],
        limit: 1,
      })
      .then((activity) => {
        var lastActivity = null;
        activity.forEach(function (doc) {
          lastActivity = doc.data();
        });
        this.addCard(candidate, rel, lastActivity);
      });
  };

  updateCards = (prevProps) => {
    if (
      JSON.stringify(prevProps.project_candidate) !==
      JSON.stringify(this.props.project_candidate)
    ) {
      var candidates_rel = Object.keys(this.props.project_candidate);
      candidates_rel.reverse();

      candidates_rel.map((key) => {
        if (
          prevProps.project_candidate[key] &&
          !this.props.project_candidate[key]
        ) {
          this.state.eventBus.publish({
            type: "REMOVE_CARD",
            laneId: prevProps.project_candidate[key].step,
            cardId: key,
          });
          return true;
        }

        if (
          prevProps.project_candidate[key] !== undefined &&
          this.props.project_candidate[key] !== undefined &&
          this.props.project_candidate[key].step !== undefined &&
          this.props.project_candidate[key].step !==
            prevProps.project_candidate[key].step
        ) {
          this.state.eventBus.publish({
            type: "MOVE_CARD",
            fromLaneId: prevProps.project_candidate[key].step,
            toLaneId: this.props.project_candidate[key].step,
            cardId: key,
            index: 0,
          });
          return true;
        }
        if (
          prevProps.project_candidate[key] === undefined &&
          this.props.project_candidate[key]
        ) {
          this.addCandidate(
            this.props.project_candidate[key].profile,
            this.props.project_candidate[key]
          );
          return true;
        }

        return true;
      });
    }
  };

  cardMoved(cardId, fromLaneId, toLaneId, index, cardDetails) {
    if (this.props.project_candidate[cardId]) {
      //TODO: Only update card position when forms have been filled this logic should be moved to dissmissal.js and Hired.js
      this.updateCardPosition(
        this.props.project_candidate[cardId].profile,
        this.props.project_candidate[cardId].campaign,
        toLaneId,
        fromLaneId,
        index
      );

      if (fromLaneId !== "hired" && toLaneId === "hired") {
        this.setState({
          celebrate: true,
          dissCard: this.props.project_candidate[cardId],
          application: getProjectCandidateId(
            this.props.project_candidate[cardId].campaign,
            this.props.project_candidate[cardId].profile
          ),
        });
      }
      if (fromLaneId !== "rejected" && toLaneId === "rejected") {
        this.setState({
          dissmissed: true,
          dissCard: this.props.project_candidate[cardId],
          application: getProjectCandidateId(
            this.props.project_candidate[cardId].campaign,
            this.props.project_candidate[cardId].profile
          ),
        });
      }
    }
  }

  sortCards(card1, card2) {
    //doesn't seem to be working
    if (this.state.setup === true && card1.last_activity && card2.last_activity)
      if (card1.last_activity.seconds < card2.last_activity.seconds)
        return card2;
  }

  componentDidUpdate(prevProps) {
    ///////candidates
    var candidates_rel = [];
    try {
      if (
        this.props !== undefined &&
        this.props.project_candidate !== undefined &&
        this.props.project_candidate !== null
      ) {
        candidates_rel = Object.keys(this.props.project_candidate);
        candidates_rel.reverse();
        if (this.state.setup === false) {
          if (this.state.addingCards === false)
            this.setState({ addingCards: true });
          candidates_rel.map((key) => {
            return this.addCandidate(
              this.props.project_candidate[key].profile,
              this.props.project_candidate[key]
            );
          });
        } else {
          this.updateCards(prevProps);
        }
        if (
          this.state.addingCards === true &&
          (this.state.numberOfCandidates >= candidates_rel.length ||
            this.state.numberOfCandidates >= candidates_rel.length - 1)
        ) {
          this.setState({ addingCards: false });
        }
        if (candidates_rel.length > 0 && this._isMounted === true) {
          if ((this.state.setup || this.state.hasCandidates) !== true)
            this.setState({ hasCandidates: true, setup: true });
        }
      }
    } catch (error) {}

    //////
  }

  async updateCardPosition(
    candidate_id,
    campaign_id,
    step,
    oldStep,
    index = null
  ) {
    const { firestore } = this.props;
    const rel_id = getProjectCandidateId(campaign_id, candidate_id);
    await addActivityMove(
      firestore,
      rel_id,
      "move",
      step,
      oldStep,
      this.props.auth.uid,
      campaign_id,
      candidate_id,
      this.props.users_project
    );
  }

  setEventBus = (eventBus) => {
    this.setState({ eventBus });
  };

  findCardOldPosition(oldData, idCandidate) {
    for (var key = 0; oldData["lanes"][key] !== undefined; key++) {
      for (
        var keyCard = 0;
        oldData["lanes"][key].cards[keyCard] !== undefined;
        keyCard++
      ) {
        if (oldData["lanes"][key].cards[keyCard].candidate === idCandidate)
          return oldData["lanes"][key].cards[keyCard];
      }
    }
    return null;
  }

  handleCard(data, oldData, key) {
    const card_keys = Object.keys(data["lanes"][key].cards);
    card_keys.map((card_key) => {
      var oldPosition = this.findCardOldPosition(
        oldData,
        data["lanes"][key].cards[card_key].candidate
      );
      if (
        oldPosition !== null &&
        oldPosition.laneId !== data["lanes"][key].cards[card_key].laneId
      ) {
        return this.updateCardPosition(
          data["lanes"][key].cards[card_key].candidate,
          data["lanes"][key].cards[card_key].campaign,
          data["lanes"][key].id,
          oldPosition.laneId
        );
      }
      return [];
    });
  }

  handleColDragEnd(data) {
    const keys = Object.keys(data["lanes"]);
    //var oldData = this.state.data;
    if (data !== this.state.data) {
      this.setState({ data: data }, () => {
        keys.map((key) => {
          return ""; //this.handleCard(data, oldData, key);
        });
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.firestore
      .get({
        collection: "projects",
        doc: this.props.match.params.id_campaign,
      })
      .then((project) => {
        const project_data = project.data();
        if (
          project_data !== undefined &&
          project_data !== null &&
          project_data.stripe_subscription !== undefined &&
          project_data.stripe_subscription !== "" &&
          project_data.stripe_subscription !== null
        ) {
          getSubscription(project_data.stripe_subscription).then(
            (subscription) => {
              if (
                subscription.message !== undefined &&
                subscription.message === "Success"
              ) {
                if (
                  subscription.sub !== undefined &&
                  subscription.sub.status !== undefined &&
                  this._isMounted === true
                ) {
                  this.setState({ isLoading: false });
                  if (subscription.sub.status === "active") {
                    this.hasSub = true;
                  } else {
                    this.hasSub = false;
                    const prjct = {
                      stripe_subscription: null,
                      waiting_for_profiles: false,
                    };
                    //sets the projects as unpaid and redirects to payment page
                    this.props.firestore
                      .update(
                        {
                          collection: "projects",
                          doc: this.props.match.params.id_campaign,
                        },
                        prjct
                      )
                      .then(function (response) {
                        return response;
                      })
                      .then(() => {
                        return this.setState({ subscriptionIsActive: false });
                      });
                  }
                } else {
                  this.hasSub = false;
                  return this.setState({ subscriptionIsActive: false });
                }
              } else {
                //stripe error
              }
            }
          );
        } else {
          this.hasSub = false;
          return this.setState({ subscriptionIsActive: false });
          //return this.redirectUser();
        }
      });
  }

  checkInviteSubscription(rel_id, id_sub) {
    if (this.state.ValidInviteSubscription === null) {
      getSubscription(id_sub).then((subscription) => {
        if (
          subscription.message !== undefined &&
          subscription.message === "Success" &&
          subscription.sub !== undefined &&
          subscription.sub.status !== undefined &&
          subscription.sub.status === "active"
        ) {
          this.setState({ ValidInviteSubscription: true });
        } //delete relation to project if subscription is not valid anymore
        /* else {
          this.setState({ ValidInviteSubscription: false });
          this.props.firestore.delete({
            collection: "users_projects",
            doc: rel_id
          });
        } */
      });
    }
  }

  hasPaidSubsription = (hasPaid) => {
    this.setState({ subscriptionIsActive: hasPaid });
  };

  render() {
    if (
      !isLoaded(this.props.project) ||
      !isLoaded(this.props.users_project) ||
      !isLoaded(this.props.roles) ||
      !isLoaded(this.props.project_candidate) ||
      !isLoaded(
        this.props.projects_plans
      ) /* ||
      this.state.addingCards === true */
    ) {
      return <Wait />;
    }

    /* if (this.state.isLoading === true) {
      return <Wait />;
    } */

    const projects_rel_ids = Object.keys(this.props.users_project);
    const role_id = getProjectUserId(
      this.props.match.params.id_campaign,
      this.props.auth.uid
    );

    let user_role = null;

    if (this.state.subscriptionIsActive === false) {
      return (
        <Payment
          hasPaidSubsription={this.hasPaidSubsription}
          id_campaign={this.props.match.params.id_campaign}
        />
      );
    }

    if (this.state.ValidInviteSubscription === false) {
      return <Notvalid reason="invit" />;
    }

    if (
      this.props.roles !== undefined &&
      this.props.users_project[role_id] !== undefined
    ) {
      user_role = this.props.roles[this.props.users_project[role_id].role];
      if (this.props.users_project[role_id].role !== "creator") {
        //user has sub
        if (
          this.props.users_project[role_id].stripe_subscription !== undefined &&
          this.props.users_project[role_id].stripe_subscription !== null
        ) {
          if (this.state.ValidInviteSubscription === null)
            this.checkInviteSubscription(
              role_id,
              this.props.users_project[role_id].stripe_subscription
            );
        } //user has no sub
        else return <Redirect to={{ pathname: "/home" }} />;
      }
    } else if (this.props.users_infos[this.props.auth.uid]["admin"] === true)
      user_role = this.props.roles["admin"];
    else return <Redirect to={{ pathname: "/home" }} />;

    //if (this.hasSub === false && user_role["name"] !== "creator") {
    //  return <Redirect to={{ pathname: "/notactive" }} />;
    //}

    if (
      this.props.users_project === undefined &&
      (this.props.users_infos[this.props.auth.uid]["admin"] === undefined ||
        this.props.users_infos[this.props.auth.uid]["admin"] !== true)
    )
      return <Redirect to="/notfound" />;

    if (
      this.props.users_project[role_id] === undefined &&
      (this.props.users_infos[this.props.auth.uid]["admin"] === undefined ||
        this.props.users_infos[this.props.auth.uid]["admin"] !== true)
    )
      return <Redirect to={{ pathname: "/home" }} />;

    const id_project = this.props.match.params.id_campaign;
    if (this.props.project[id_project] === undefined)
      return <Redirect to={{ pathname: "/notfound" }} />;

    if (this.props.project[id_project].archived === true)
      return <Redirect to={{ pathname: "/home" }} />;

    const project = this.props.project[id_project];
    const organisation = project.organisation;

    return (
      <React.Fragment>
        <LoadingOverlay
          active={this.state.addingCards}
          spinner
          text={T.translate("project.loading")}
        >
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            numberOfPieces="500"
            //tweenDuration="10000"
            recycle={false}
            style={{ pointerEvents: "none" }}
            gravity={0.5}
            run={this.state.celebrate}
            onConfettiComplete={(confetti) => {
              this.setState({ celebrate: false });
              confetti.reset();
            }}
          />
          {/* <div className="navbar bg-white breadcrumb-bar">
            <div className="container">
              <div className="dropdown">
                <button
                  className="btn btn-round"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="material-icons">settings</i>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  {user_role["name"] === "creator" ? (
                    <span>
                      <a
                        className="dropdown-item"
                        href={`/campaign/edit/${id_project}`}
                      >
                        Edit Campaign
                      </a>

                      <a className="dropdown-item" href="l#">
                        Mark as Complete
                      </a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item text-danger" href="l#">
                        Archive
                      </a>
                    </span>
                  ) : (
                    ""
                  )}
                  <a className="dropdown-item" href="l#">
                    Share
                  </a>
                </div>
              </div>
              <button
                onClick={() => {
                  this.ModalPoolPaneStatus(true);
                }}
                type="button"
                className="btn btn-sm btn-secondary"
              >
                Pool of candidates{" "}
                <span className="badge badge-pill badge-danger">
                  {this.state.pipeSize}
                </span>
                <span className="sr-only">unread messages</span>ß
              </button>
            </div>
          </div> */}
          <div className="w-min-h-screen tw-flex-1 flex-col">
            <div className="tw-p-8 d-flex justify-content-between align-items-start">
              <div>
                <h2 className="tw-text-2xl tw-font-bold tw-leading-7 tw-text-gray-900 sm:tw-text-3xl sm:tw-truncate">
                  {project.jobTitle.charAt(0).toUpperCase() +
                    project.jobTitle.slice(1)}{" "}
                  <button
                    type="button"
                    className="tw-inline-flex tw-items-center tw-p-2 tw-border tw-border-transparent tw-rounded-full tw-shadow-sm tw-text-white tw-bg-gray-400 hover:tw-bg-gray-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-500"
                  >
                    <a
                      className="hover:tw-text-white"
                      href={`/campaign/edit/${id_project}`}
                    >
                      <svg
                        className="tw-h-5 tw-w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                        />
                      </svg>
                    </a>
                  </button>
                </h2>
                <div className="tw-mt-1 tw-flex tw-flex-col sm:tw-flex-row sm:tw-flex-wrap sm:tw-mt-0 sm:tw-space-x-6">
                  <div className="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500">
                    <svg
                      className="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      />
                      <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
                    </svg>
                    {getTranslate(project.type)}
                  </div>
                  <div className="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500">
                    <svg
                      className="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                      <path
                        fill="#fff"
                        d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                      />
                    </svg>
                    {getTranslate(project.studies)}
                  </div>
                  <div className="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500">
                    <svg
                      className="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    {project.experienceYears + " years"}
                  </div>
                  <div className="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500">
                    <svg
                      className="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    {"   "}
                    {project.city.charAt(0).toUpperCase() +
                      project.city.slice(1) +
                      ", " +
                      project.country.charAt(0).toUpperCase() +
                      project.country.slice(1)}
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center">
                {
                  <ul className="avatars">
                    {projects_rel_ids.map((project_rel) => {
                      const p = this.props.users_project[project_rel];
                      return (
                        <Contributors key={p.user_id} id_user={p.user_id} />
                      );
                    })}
                  </ul>
                }
                {user_role["name"] === "creator" ? (
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#user-invite-modal"
                    className="tw-ml-1 tw-flex-shrink-0 tw-bg-white tw-inline-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-dashed tw-border-gray-200 tw-text-gray-400 hover:tw-text-gray-500 hover:tw-border-gray-300 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500"
                  >
                    <span className="tw-sr-only">Add team member</span>
                    <svg
                      className="tw-h-5 tw-w-5"
                      x-description="Heroicon name: plus"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className="tw-min-h-screen	kanban-board container-fluid btk"
              tabIndex="0"
            >
              {this.state.hasCandidates === true ? (
                <Board
                  style={{ backgroundColor: "#FFF" }}
                  data={this.state.data}
                  draggable
                  laneDraggable={false}
                  onDataChange={this.handleColDragEnd.bind(this)}
                  //onCardMoveAcrossLanes={this.cardMoved.bind(this)}
                  handleDragEnd={this.cardMoved.bind(this)}
                  //onCardClick={this.cardClicked.bind(this)}
                  laneSortFunction={this.sortCards.bind(this)}
                  eventBusHandle={this.setEventBus}
                  components={{ Card: Card }}
                ></Board>
              ) : (
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="row py-5">
                      <div className="text-center">
                        <h2 className="tw-text-3xl tw-mb-2 ft4">
                          {T.translate("dashboard.welcome")}{" "}
                          <span role="img" aria-label="Waving hand">
                            👋
                          </span>{" "}
                          {T.translate("dashboard.welcome_")}
                        </h2>
                        <p className="lead ft4">
                          {T.translate("dashboard.searching")}
                          <br />
                          {T.translate("dashboard.notification")}
                          <br />
                          {T.translate("dashboard.create")}
                        </p>
                        <a
                          href="/campaign/create"
                          className="cmb-3 btn-large center-block btn btn-pill
                  btn-primary mb-2"
                        >
                          {" "}
                          {T.translate("dashboard.btn")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <Modal
            candidate={this.state.modal_candidate}
            status={this.state.status}
            id_rel={this.state.id_rel}
            campaign={this.props.match.params.id_campaign}
            edition={false}
          /> */}
          <CandidatePane
            isPaneOpen={this.state.isPaneOpen}
            PaneStatus={this.ModalPaneStatus}
            candidate={this.state.modal_candidate}
            status={this.state.status}
            contributors={this.props.users_project}
            id_rel={this.state.id_rel}
            campaign={this.props.match.params.id_campaign}
            edition={false}
          />

          {/*           <CandidatePoolPane
            isPaneOpen={this.state.isPoolPaneOpen}
            PaneStatus={this.ModalPoolPaneStatus}
            campaign={this.props.match.params.id_campaign}
            pipeSize={this.pipeSizeUpdate}
            openProfile={this.modalCallback}
          /> */}

          <Dissmissal
            dissmissed={this.state.dissmissed}
            done={(diss) => {
              this.setState({
                dissmissed: diss,
              });
            }}
            candidate={this.state.dissCard}
            application={this.state.application}
            cancelDrag={this.shouldCancelDrag}
          />

          <Hired
            dissmissed={this.state.celebrate}
            done={(diss) => {
              this.setState({ celebrate: diss });
            }}
            candidate={this.state.dissCard}
            application={this.state.application}
            cancelDrag={this.shouldCancelDrag}
          />

          <Invite
            users_project={this.props.users_project}
            user_id={this.props.auth.uid}
            roles={this.props.roles}
            project={this.props.match.params.id_campaign}
            organisation={organisation}
          />
        </LoadingOverlay>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ firebase: { auth }, firestore: { ordered } }) => ({
  auth,
});

export default compose(
  firebaseConnect(),
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "projects",
      doc: props.match.params.id_campaign,
    },
    {
      collection: "users_projects",
      where: [["project", "==", props.match.params.id_campaign]],
      storeAs: props.match.params.id_campaign + "_user_projects",
    },
    {
      collection: "projects_plans",
      where: ["project", "==", props.match.params.id_campaign],
    },
    {
      collection: "roles",
    },
    {
      collection: "users_organisations",
      where: ["user_id", "==", props.auth.uid],
    },
    {
      collection: "campaign_candidate",
      where: [
        ["campaign", "==", props.match.params.id_campaign],
        ["match_has_been_humanly_validated", "==", true],
        ["candidate_is_interested", "==", true],
      ],
      storeAs: props.match.params.id_campaign + "_candidates",
    },
  ]),
  connect(
    (state, props) => ({
      users_project:
        state.firestore.data[props.match.params.id_campaign + "_user_projects"],
      projects_plans: state.firestore.data.projects_plans,
      project_candidate:
        state.firestore.data[props.match.params.id_campaign + "_candidates"],

      roles: state.firestore.data.roles,
      project: state.firestore.data.projects,
      users_infos: state.firestore.data.users_infos,
      organisations: state.firestore.data.organisations,
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      notify: state.notify,
    }),
    { notifyUser }
  )
)(ProjectTest);
