import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import Wait from "../layout/Wait";
import { checkUserInfos } from "../../helpers/users";

class ListUsers extends Component {
  myOnClick(id_user, firestore) {
    /* firestore.delete({ collection: "users_infos", doc: id_user }).then(() => {
    });

    firestore
      .delete({ collection: "users_email_settings", doc: id_user })
      .then(() => {
      }); */
  }

  render() {
    if (!isLoaded(this.props.users)) {
      return <Wait />;
    }

    const { users } = this.props;

    const users_ids = Object.keys(users);

    return (
      <div className="row justify-content-center">
        <div className="col-lg-11 col-xl-10">
          <div className="container-fluid page-header d-flex justify-content-between align-items-start">
            <div>
              <h1>
                <span role="img" aria-label="cup of coffee">
                  ☕
                </span>{" "}
                admin
              </h1>
              <p className="lead">this is the administration </p>
            </div>
            <div>
              <button
                className="btn btn-round"
                data-toggle="tooltip"
                data-placement="top"
                title="Edit Organisation"
              >
                <a href={`/admin/users`}>
                  <i className="material-icons">settings</i>
                </a>
              </button>
            </div>
          </div>
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">JobTitle</th>
                <th scope="col">Contract</th>
                <th scope="col">Company</th>
                <th scope="col">Options</th>
              </tr>
            </thead>
            <tbody>
              {users_ids.map(users_id_rel => {
                var user = users[users_id_rel];
                user = checkUserInfos(user);
                return (
                  <tr key={users_id_rel}>
                    <td>{user.firstname}</td>
                    <td>{user.lastname}</td>
                    <td>{user.email}</td>
                    <td>
                      <a
                        className="nav-link dropdown-toggle"
                        href="#l"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Actions
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a
                          className="dropdown-item"
                          data-toggle="modal"
                          data-target="#user-delete-modal"
                          href="#l"
                        >
                          Delete
                        </a>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item" href="#l">
                          Make admin
                        </a>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div
          className="modal fade"
          id="user-delete-modal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="user-delete-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete User</h5>
                <button
                  type="button"
                  className="close btn btn-round"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="material-icons">close</i>
                </button>
              </div>
              <div className="modal-body">
                <h4>Are you sure you want to delete this user ?</h4>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    this.myOnClick();
                  }}
                >
                  Delete User
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  firestoreConnect(props => [
    {
      collection: "users_infos",
      limit: 10,
      storeAs: "users"
    }
  ]),
  connect((state, props) => ({
    users: state.firestore.data.users
  }))
)(ListUsers);
