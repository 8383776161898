import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import T from "i18n-react";
import ReactMarkdown from "react-markdown";

class Edu extends Component {
  render() {
    /*     if (!isLoaded(this.props.education)) {
      return <Wait />;
    } */
    let date = "";
    if (
      this.props.education.dateRange.dateEnd !== undefined &&
      this.props.education.dateRange.dateEnd !== null &&
      this.props.education.dateRange.dateStart !== undefined &&
      this.props.education.dateRange.dateStart !== null
    ) {
      date =
        T.translate("profile.from") +
        " " +
        this.props.education.dateRange.dateStart +
        " " +
        T.translate("profile.to") +
        " " +
        this.props.education.dateRange.dateEnd;
    } else {
      if (
        this.props.education.dateRange.dateStart !== undefined &&
        this.props.education.dateRange.dateStart !== null
      )
        date =
          T.translate("profile.since") +
          " " +
          this.props.education.dateRange.dateStart;
      else if (
        this.props.education.dateRange.datedateEndStart !== undefined &&
        this.props.education.dateRange.datedateEndStart !== null
      )
        date =
          T.translate("profile.graduated") +
          " " +
          this.props.education.dateRange.dateEnd;
    }
    return (
      <li>
        <div className="tw-px-4 tw-py-4 sm:tw-px-6">
          <div className="tw-flex tw-items-center tw-justify-between">
            <p className="tw-font-medium tw-truncate">
              {this.props.education.faculty}
            </p>
            <div className="tw-ml-2 tw-flex-shrink-0 tw-flex">
              <p className="tw-px-2 tw-inline-flex tw-text-xs tw-leading-5 tw-font-semibold tw-rounded-full tw-bg-green-100tw- text-green-800">
                {date}
              </p>
            </div>
          </div>
          <div className="tw-text-sm tw-leading-relaxed tw-mt-2 sm:tw-flex sm:tw-justify-between">
            <p>
              <ReactMarkdown>{this.props.education.activities}</ReactMarkdown>
            </p>
          </div>
          <div className="tw-mt-2 sm:tw-flex sm:tw-justify-between">
            <div className="sm:tw-flex">
              <p className="tw-flex tw-items-center tw-text-sm tw-text-gray-500">
                <svg
                  className="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                  <path
                    fill="#fff"
                    d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                  />
                </svg>
                {this.props.education.institution}
              </p>
            </div>
            {/* <div className="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500 sm:tw-mt-0">
              <svg
                className="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-400"
                x-description="Heroicon name: calendar"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <p>
                Closing on
                <time datetime="2020-01-07">January 7, 2020</time>
              </p>
            </div> */}
          </div>
        </div>
      </li>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  /*     props => [
    {
      collection: "educations",
      doc: props.edu,
      storeAs: props.edu
    }
  ] */
  connect((state, props) => ({
    //education: state.firestore.data[props.edu],
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    notify: state.notify,
  }))
)(Edu);
