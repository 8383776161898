export function getCampaignPrice(numberOfAccounts = 1) {
  return 350;
  /*   switch (true) {
    case numberOfAccounts < 3:
      return 350;
    case numberOfAccounts < 5:
      return 330;
    case numberOfAccounts < 8:
      return 300;
    case numberOfAccounts >= 8:
      return 280;
  } */
}

export function getSeatPrice(numberOfAccounts = 1) {
  return 80;
  /*   switch (true) {
    case numberOfAccounts < 5:
      return 80;
    case numberOfAccounts < 8:
      return 60;
    case numberOfAccounts >= 8:
      return 50;
  } */
}

export function getProPrice() {
  return 789.89;
}
