import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
//import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import ListProjects from "../projects/ListProjects";
import Create from "../organisation/Create";
import T from "i18n-react";
import Wait from "../layout/Wait";
import UserInfos from "./UserInfos";
import Avatar from "@material-ui/core/Avatar";
import { AddUser, AddUserOrganisation } from "../../helpers/project";

//import Create from "../organisation/Create";
import { notifyUser, setProgressBar } from "../../actions/notifyActions";

class Home extends Component {
  render() {
    if (
      !isLoaded(this.props.users_infos) ||
      !isLoaded(this.props.users_organisations) ||
      !isLoaded(this.props.roles) ||
      !isLoaded(this.props.invites) ||
      !isLoaded(this.props.current_users_projects)
    ) {
      return <Wait />;
    }

    //user infos completion
    const user_infos = this.props.users_infos[this.props.auth.uid];
    if (
      user_infos === undefined ||
      user_infos.jobtitle === undefined ||
      user_infos.jobtitle === "" ||
      user_infos.firstname === "" ||
      user_infos.lastname === ""
    )
      return <UserInfos />;

    //check if user has invites
    if (this.props.invites) {
      //if user has been Invited to projects

      const invites_rel_id = Object.keys(this.props.invites);
      invites_rel_id.map((invite_id) => {
        const invite = this.props.invites[invite_id];
        if (invite.rsvp === false) {
          AddUser(
            this.props.firestore,
            invite.project,
            this.props.auth.uid,
            invite.role,
            invite_id,
            invite.organisation,
            invite.subscription
          );
          if (
            this.props.users_organisations === undefined ||
            this.props.users_organisations === null ||
            this.props.users_organisations === ""
          ) {
            AddUserOrganisation(
              this.props.firestore,
              invite.organisation,
              this.props.auth.uid,
              invite.role
            );
          }
        }
        return null;
      });
    }
    ////

    //check if user has organisation
    if (
      this.props.users_organisations === undefined ||
      this.props.users_organisations === null ||
      this.props.users_organisations === ""
    ) {
      return <Create user_id={this.props.auth.uid} new={true} />;
    }

    //if (this.props.current_users_projects == undefined)
    //  return <CreateProject />;
    //return <Redirect to={{ pathname: "/campaign/create" }} />;

    /*     if (
      this.props.users_organisations !== null &&
      Object.keys(this.props.users_organisations).length > 1
    ) {
      return <Redirect to={{ pathname: "/error" }} />;
    } */

    const organisation_rel_id = Object.keys(this.props.users_organisations)[0];
    const role = this.props.users_organisations[organisation_rel_id].role;
    const organisation_id = this.props.users_organisations[organisation_rel_id]
      .organisation;

    const role_auth = this.props.roles[role];

    const validated =
      this.props.current_users_projects !== undefined &&
      this.props.current_users_projects !== null
        ? Object.keys(this.props.current_users_projects).length
        : 0;

    var users_projects =
      this.props.current_users_projects !== null
        ? this.props.current_users_projects
        : {};
    return (
      <React.Fragment>
        <div className="tw-mx-auto tw-bg-gray-800">
          <div className="tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8 tw-border-b tw-border-gray-200 ">
            <div className="tw-max-w-7xl tw-mx-auto tw-p-8 tw-pb-5 tw-pt-5 sm:tw-flex sm:tw-items-center sm:tw-justify-between">
              <div className="tw-flex tw-items-center">
                {this.props.auth.photoURL ? (
                  <img
                    className="tw-hidden tw-h-16 tw-w-16 tw-rounded-full sm:tw-block"
                    src={this.props.auth.photoURL}
                    alt=""
                  />
                ) : (
                  <Avatar
                    alt={this.props.auth.displayName}
                    className="tw-h-16 tw-w-16"
                    src="/theme/img/user.png"
                  />
                )}

                <div>
                  <div className="tw-flex tw-items-center">
                    <img
                      className="tw-h-16 tw-w-16 tw-rounded-full sm:tw-hidden"
                      src={this.props.auth.photoURL}
                      alt=""
                    />
                    <h1 className="tw-text-white tw-ml-3 tw-text-2xl tw-font-bold tw-leading-7 sm:tw-leading-9 sm:tw-truncate">
                      {T.translate("home.welcome")}
                      {", "}
                      {this.props.auth.displayName}
                    </h1>
                  </div>
                  <dl className="tw-mt-6 tw-flex tw-flex-col sm:tw-ml-3 sm:tw-mt-1 sm:tw-flex-row sm:tw-flex-wrap">
                    <dt className="tw-sr-only">Account status</dt>
                    <dd className="tw-mt-3 tw-flex tw-items-center tw-text-sm tw-text-gray-200 tw-font-medium sm:tw-mr-6 sm:tw-mt-0 tw-capitalize">
                      <svg
                        className={`tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5  ${
                          validated > 0 ? "tw-text-blue-400" : "tw-text-red-400"
                        }`}
                        x-description="Heroicon name: check-circle"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      {validated > 0
                        ? T.translate("home.verified")
                        : T.translate("home.not_verified")}
                    </dd>
                    {role_auth.company_management === true ? (
                      <>
                        <dt className="tw-sr-only">Company</dt>
                        <dd className="tw-flex tw-items-center tw-text-sm tw-text-gray-200 tw-font-medium tw-capitalize sm:tw-mr-6">
                          <svg
                            className="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-200"
                            x-description="Heroicon name: office-building"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                              clipRule="evenodd"
                            ></path>
                          </svg>

                          <a
                            className="tw-underline hover:tw-text-white"
                            href={`/organisation/edit/${organisation_id}`}
                          >
                            {T.translate("home.edit_org")}
                          </a>
                        </dd>
                      </>
                    ) : (
                      ""
                    )}
                  </dl>
                </div>
              </div>
              <div className="tw-mt-6 tw-flex tw-space-x-3 md:tw-mt-0 md:tw-ml-4">
                <button
                  type="button"
                  className="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
                >
                  <svg
                    className="tw--ml-1 tw-mr-3 tw-h-5 tw-w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
                    />
                  </svg>
                  <a
                    href="/campaign/create"
                    className="tw-text-white hover:tw-text-white"
                  >
                    {T.translate("home.create")}
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-mx-auto tw-p-8 tw-bg-white">
          <div className="tw-max-w-7xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8">
            <div className="tw-bg-white">
              <ListProjects
                organisation={organisation_id}
                users_projects={users_projects}
                role={role_auth}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ firebase: { auth }, firestore: { ordered } }) => ({
  auth,
});

export default compose(
  firebaseConnect(),
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "users_infos",
      doc: props.auth.uid,
    },
    {
      collection: "users_organisations",
      storeAs: "current_users_organisations",
      where: [
        "user_id",
        "==",
        props.auth.uid && props.auth.uid !== undefined ? props.auth.uid : "",
      ],
    },
    {
      collection: "invites",
      where: [
        [
          "email",
          "==",
          props.auth.email && props.auth.email !== undefined
            ? props.auth.email
            : "",
        ],
        ["rsvp", "==", false],
      ],
    },
    {
      collection: "roles",
    },
    {
      collection: "users_projects",
      storeAs: "current_users_projects",
      where: [
        "user_id",
        "==",
        props.auth.uid !== undefined ? props.auth.uid : "",
      ],
    },
  ]),
  connect(
    (state, props) => ({
      users_infos: state.firestore.data.users_infos,
      invites: state.firestore.data.invites,
      current_users_projects: state.firestore.data.current_users_projects,
      roles: state.firestore.data.roles,
      users_organisations: state.firestore.data.current_users_organisations,
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      notify: state.notify,
    }),
    { notifyUser, setProgressBar }
  )
)(Home);
