export function checkCandidateInfos(candidate_infos) {
  if (candidate_infos === undefined) {
    return {
      firstname: "deleted Candidate",
      lastname: "",
      picture: null
    };
  }
  if (candidate_infos.deleted === true) {
    candidate_infos.firstname = "deleted Candidate";
    candidate_infos.lastname = "";
  }
  if (candidate_infos.picture === "") candidate_infos.picture = null; //avatar;
  return candidate_infos;
}

export function candidateStepPercent(step) {
  switch (step) {
    case "sourced":
      return 10;
    case "called":
      return 30;
    case "interviewed":
      return 60;
    case "offer":
      return 80;
    case "rejected":
      return 0;
    case "hired":
      return 100;
    default:
      return null;
  }
}
