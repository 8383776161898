import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";

class Create extends Component {
  constructor(props) {
    super(props);
    // Create refs
    this.firstname = React.createRef();
    this.lastname = React.createRef();
    this.city = React.createRef();
    this.country = React.createRef();
    this.jobtitle = React.createRef();
    this.submit = React.createRef();
  }

  onSubmit = e => {
    e.preventDefault();

    const { firestore } = this.props;
    this.submit.current.setAttribute("disabled", "disabled");

    // Updated user
    const candidate = {
      firstname: this.firstname.current.value,
      lastname: this.lastname.current.value,
      jobtitle: this.jobtitle.current.value,
      city: this.city.current.value,
      country: this.country.current.value

      //skills: this.skills.current.value
    };

    firestore.add({ collection: "candidates" }, candidate).then(infos => {
      this.submit.current.removeAttribute("disabled");
    });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });
  render() {
    return (
      <React.Fragment>
        <form
          onSubmit={this.onSubmit}
          className="modal fade"
          id="create-modal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="create-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">New Candidate</h5>
                <button
                  type="button"
                  className="close btn btn-round"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="material-icons">close</i>
                </button>
              </div>
              <div className="modal-body">
                <div className="page-header">
                  <div className="form-group row align-items-center">
                    <label className="col-3">Firstname</label>
                    <div className="col">
                      <input
                        type="text"
                        placeholder="Firstname"
                        name="firstname"
                        className="form-control"
                        ref={this.firstname}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                  <div className="form-group row align-items-center">
                    <label className="col-3">Lastname</label>
                    <div className="col">
                      <input
                        type="text"
                        placeholder="Lastname"
                        name="lastname"
                        className="form-control"
                        ref={this.lastname}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                  <div className="form-group row align-items-center">
                    <label className="col-3">City</label>
                    <div className="col">
                      <input
                        type="text"
                        placeholder="City"
                        name="city"
                        className="form-control"
                        ref={this.city}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                  <div className="form-group row align-items-center">
                    <label className="col-3">Country</label>
                    <div className="col">
                      <input
                        type="text"
                        placeholder="Country"
                        name="country"
                        className="form-control"
                        ref={this.country}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                  <div className="form-group row align-items-center">
                    <label className="col-3">Jobtitle</label>
                    <div className="col">
                      <input
                        type="text"
                        placeholder="Jobtitle"
                        name="jobtitle"
                        className="form-control"
                        ref={this.jobtitle}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  ref={this.submit}
                  className="btn btn-primary"
                  type="submit"
                >
                  Create Candidate
                </button>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
export default compose(
  firebaseConnect(),
  firestoreConnect(),
  connect()
)(Create);
