import React from "react";
import spinner from "./hived_loader.gif";

export default () => {
  return (
    <div className="container-fluid page-header d-flex justify-content-between align-items-start">
      <img
        src={spinner}
        alt="Loading..."
        style={{ width: "200px", margin: "auto", display: "block" }}
      />
    </div>
  );
};
