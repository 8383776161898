import React, { Component } from "react";
/* import T from "i18n-react";
 */ import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isLoaded } from "react-redux-firebase";
import Wait from "../layout/Wait";
import ListProjects from "../projects/ListProjects";

import { notifyUser } from "../../actions/notifyActions";
/* import Alert from "../layout/Alert";
 */
class Profile extends Component {
  state = {
    id: "",
    email: "",
  };

  static getDerivedStateFromProps(props, state) {
    const { auth } = props;
    return { email: auth.email, id: auth.uid };
  }

  render() {
    if (
      !isLoaded(this.props.users_infos_pr) ||
      !isLoaded(this.props.users_organisations) ||
      !isLoaded(this.props.roles) ||
      !isLoaded(this.props.current_users_projects)
    ) {
      return <Wait />;
    }

    const userinfos = this.props.users_infos_pr[this.props.auth.uid];
    const organisation_rel_id = Object.keys(this.props.users_organisations)[0];
    const role = this.props.users_organisations[organisation_rel_id].role;
    const organisation_id = this.props.users_organisations[organisation_rel_id]
      .organisation;

    const role_auth = this.props.roles[role];

    const users_projects = this.props.current_users_projects;

    return (
      <>
        <div className="tw-min-h-screen tw-bg-gray-100">
          <div className="tw-max-w-7xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8">
            <div className="tw-max-w-4xl tw-mx-auto">
              <div className="tw-max-w-3xl tw-mx-auto tw-px-4 sm:tw-px-6 md:tw-flex md:tw-items-center md:tw-justify-between md:tw-space-x-5 lg:tw-max-w-7xl lg:tw-px-8">
                <div className="tw-mt-5 tw-flex tw-items-center tw-space-x-5">
                  <div className="tw-flex-shrink-0">
                    <div className="tw-relative">
                      <img
                        className="tw-h-16 tw-w-16 tw-rounded-full"
                        src={userinfos.picture}
                        alt=""
                      />
                      <span
                        className="tw-absolute tw-inset-0 tw-shadow-inner tw-rounded-full"
                        aria-hidden="true"
                      ></span>
                    </div>
                  </div>
                  <div>
                    <h1 className="tw-text-2xl tw-font-bold tw-text-gray-900">
                      {userinfos.firstname + " " + userinfos.lastname}
                    </h1>
                    <p className="tw-text-sm tw-font-medium tw-text-gray-500">
                      {userinfos.jobtitle} | {userinfos.location}
                    </p>
                  </div>
                </div>
                {/* <div className="tw-mt-6 tw-flex tw-flex-col-reverse tw-justify-stretch tw-space-y-4 tw-space-y-reverse sm:tw-flex-row-reverse sm:tw-justify-end sm:tw-space-x-reverse sm:tw-space-y-0 sm:tw--x-3 md:tw-mt-0 md:tw-flex-row md:tw-space-x-3">
                  <button
                    type="button"
                    className="tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-offset-gray-100 focus:tw-ring-blue-500"
                  >
                    Disqualify
                  </button>
                  <button
                    type="button"
                    className="tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-text-sm tw-font-medium tw-rounded-md tw-shadow-sm tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-offset-gray-100 focus:tw-ring-blue-500"
                  >
                    Advance to offer
                  </button>
                </div> */}
              </div>
              <div className="tw-mt-8 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <p className="text-sm text-gray-500">{userinfos.bio}</p>
              </div>
              <div className="tw-mt-8 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <ListProjects
                  organisation={organisation_id}
                  users_projects={users_projects !== null ? users_projects : []}
                  role={role_auth}
                  profileView={true}
                />
                <div />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 xol-xl-9">
              <div className="page-header mb-4">
                <div className="media">
                  <img
                    src={userinfos.picture}
                    alt=""
                    className="avatar avatar-lg mt-1"
                  />
                  <div className="media-body ml-3">
                    <h1 className="mb-0">
                      {userinfos.firstname + " " + userinfos.lastname}
                    </h1>
                    <p className="lead">
                      {userinfos.jobtitle} | {userinfos.location}
                    </p>
                  </div>
                </div>
              </div>
              <p className="lead">{userinfos.bio}</p>
              <div className="content-list-body">
                <ListProjects
                  organisation={organisation_id}
                  users_projects={users_projects !== null ? users_projects : []}
                  role={role_auth}
                  profileView={true}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Profile.propTypes = {
  firebase: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  notify: PropTypes.object.isRequired,
  notifyUser: PropTypes.func.isRequired,
};

const mapStateToProps = ({ firebase: { auth }, firestore: { ordered } }) => ({
  auth,
});

export default compose(
  firebaseConnect(),
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "users_infos", //"users_infos",
      doc: props.user_id, //"ToAdAEdT27bnSC8nEtrn7BqBVaI3"]
    },
    {
      collection: "users_projects",
      storeAs: "current_users_projects",
      where: [
        "user_id",
        "==",
        props.auth.uid && props.auth.uid !== undefined ? props.auth.uid : "",
      ],
    },
    {
      collection: "users_organisations",
      storeAs: "current_users_organisations",
      where: [
        "user_id",
        "==",
        props.auth.uid && props.auth.uid !== undefined ? props.auth.uid : "",
      ],
    },
    {
      collection: "roles",
    },
  ]),
  connect(
    (state, props) => ({
      roles: state.firestore.data.roles,
      current_users_projects: state.firestore.data.current_users_projects,
      users_infos_pr: state.firestore.data.users_infos,
      users_organisations: state.firestore.data.current_users_organisations,
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      notify: state.notify,
    }),
    { notifyUser }
  )
)(Profile);
