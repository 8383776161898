import React, { Component } from "react";
import { firebaseConnect, firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import { notifyUser, setProgressBar } from "../../actions/notifyActions";
import { isLoaded } from "react-redux-firebase";
import Wait from "../layout/Wait";

import Subscriptions from "./Subscriptions";
import Cards from "./Cards";

class Payment extends Component {
  render() {
    if (!isLoaded(this.props.stripe_infos)) {
      return <Wait />;
    }

    return (
      <div
        className="tab-pane fade"
        role="tabpanel"
        id="billing"
        aria-labelledby="billing-tab"
      >
        <div className="tw-antialiased tw-font-sans tw-bg-gray-200">
          <div className="tw-bg-gray-100">
            <div className="tw-max-w-7xl tw-mx-auto tw-py-6 sm:tw-px-6 lg:tw-px-8">
              <div className="tw-shadow sm:tw-rounded-md sm:tw-overflow-hidden">
                <div className="tw-px-4 tw-py-5 tw-bg-white tw-space-y-6 sm:tw-p-6">
                  <Subscriptions />
                  <Cards />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ firebase: { auth }, firestore: { ordered } }) => ({
  auth,
});

export default compose(
  firebaseConnect(),
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "stripe",
      doc: props.auth.uid,
    },
    {
      collection: "users_infos",
      doc: props.auth.uid,
    },
  ]),
  connect(
    (state, props) => ({
      user_infos: state.firestore.data.users_infos,
      stripe_infos: state.firestore.data.stripe,
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      notify: state.notify,
    }),
    { notifyUser, setProgressBar }
  )
)(Payment);
