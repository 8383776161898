import React, { Component } from "react";
import { firebaseConnect, firestoreConnect } from "react-redux-firebase";
import T from "i18n-react";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { notifyUser } from "../../actions/notifyActions";

class ProfileImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      url: "",
      progress: 0
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.imageUploader = React.createRef();
    this.imagePlaceHolder = React.createRef();
  }
  handleChange = e => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState();
      this.setState(
        () => ({ image }),
        function stateUpdateComplete() {
          this.handleUpload();
        }.bind(this)
      );
    }
  };
  handleUpload = () => {
    this.imageUploader.current.setAttribute("disabled", "disabled");
    const { image } = this.state;
    const storage = this.props.firebase.storage();
    const uploadTask = storage
      .ref(`${this.props.location}/${this.props.user_id}`)
      .put(image);
    const imageExtention = image.name.split(".").pop();
    if (imageExtention === "png" || imageExtention === "jpg") {
      //loading image
      this.imagePlaceHolder.current.setAttribute(
        "src",
        "/theme/img/loading.png"
      );
      uploadTask.on(
        "state_changed",
        snapshot => {
          // progrss function ....
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          this.setState({ progress });
        },
        error => {
          // error function ....
        },
        () => {
          // complete function ....
          storage
            .ref("images")
            .child(this.props.user_id)
            .getDownloadURL()
            .then(url => {
              this.setState({ url });

              // Update image in firestore
              this.props.firebase.auth().currentUser.updateProfile({
                photoURL: url
              });
              this.props.firestore.update(
                { collection: "users_infos", doc: this.props.user_id },
                { picture: url }
              );
              this.imageUploader.current.removeAttribute("disabled");
              this.imagePlaceHolder.current.setAttribute("src", url);
            });
        }
      );
    } else {
      this.props.notifyUser(T.translate("settings.imageError"), "error");
    }
  };
  render() {
    return (
      <div className="media mb-4">
        <img
          alt="avatar"
          src={this.props.auth.photoURL || "theme/img/user.png"}
          className="avatar avatar-lg"
          ref={this.imagePlaceHolder}
        />
        <div className="media-body ml-3">
          <div className="custom-file custom-file-naked d-block mb-1">
            <input
              type="file"
              onChange={this.handleChange}
              className="custom-file-input d-none"
              id="avatar-file"
            />
            <label
              className="custom-file-label position-relative"
              htmlFor="avatar-file"
            >
              <span className="btn btn-primary" ref={this.imageUploader}>
                {T.translate("settings.avatar")}
              </span>
            </label>
          </div>
          <small>{T.translate("settings.avatar_desc")}</small>
        </div>
      </div>
    );
  }
}

ProfileImage.propTypes = {
  firestore: PropTypes.object.isRequired,
  user_id: PropTypes.string.isRequired
};

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  connect(
    (state, props) => ({
      notify: state.notify,
      auth: state.firebase.auth
    }),
    { notifyUser }
  )
)(ProfileImage);
