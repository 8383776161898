const FIREBASE_FUNCTION =
  "https://us-central1-swel-39e81.cloudfunctions.net/charge/";

export async function charge(token, amount, currency) {
  return await fetch(FIREBASE_FUNCTION, {
    method: "POST",
    //4mode: "no-cors",
    headers: { "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({
      token,
      charge: {
        amount,
        currency
      }
    })
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return JSON.parse(data.body);
    });
}

export async function createCustomer(token, email, name) {
  return await fetch(FIREBASE_FUNCTION + "customer/", {
    method: "POST",
    //4mode: "no-cors",
    headers: { "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({
      token,
      customer: {
        email,
        name
      }
    })
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return JSON.parse(data.body);
    });
}

export async function subscribe(customer, plan, tax_percent, quantity) {
  return await fetch(FIREBASE_FUNCTION + "subscribe/", {
    method: "POST",
    //4mode: "no-cors",
    headers: { "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({
      customer,
      plan,
      tax_percent,
      quantity
    })
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return JSON.parse(data.body);
    });
}

export async function multiSubscribe(customer, items, tax) {
  //items = {{plan: "plan_E8r0jwvxeWfar0", metada:{tax_percent: 20}, quantity: 1}, {plan: "plan_E8r0jwvxeWfar0", metada:{tax_percent: 20}, quantity: 1}}
  return await fetch(FIREBASE_FUNCTION + "multisubscribe/", {
    method: "POST",
    //4mode: "no-cors",
    headers: { "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({ customer, items, tax })
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return JSON.parse(data.body);
    });
}

export async function getCards(customer) {
  return await fetch(FIREBASE_FUNCTION + "getcards/", {
    method: "POST",
    //4mode: "no-cors",
    headers: { "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({
      customer
    })
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return JSON.parse(data.body);
    });
}

export async function deleteCard(customer, card) {
  return await fetch(FIREBASE_FUNCTION + "deletecard/", {
    method: "POST",
    //4mode: "no-cors",
    headers: { "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({
      customer,
      card
    })
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return JSON.parse(data.body);
    });
}

export async function getSubscriptions(customer) {
  return await fetch(FIREBASE_FUNCTION + "getsubscriptions/", {
    method: "POST",
    //4mode: "no-cors",
    headers: { "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({
      customer
    })
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return JSON.parse(data.body);
    });
}

export async function getSubscription(subscription) {
  return await fetch(FIREBASE_FUNCTION + "getsubscription/", {
    method: "POST",
    //4mode: "no-cors",
    headers: { "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({
      subscription
    })
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return JSON.parse(data.body);
    });
}

export async function cancelSubscription(subscription) {
  return await fetch(FIREBASE_FUNCTION + "cancelsubscription/", {
    method: "POST",
    //4mode: "no-cors",
    headers: { "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({
      subscription
    })
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return JSON.parse(data.body);
    });
}

export async function addCard(token, customer) {
  await fetch(FIREBASE_FUNCTION + "card/", {
    method: "POST",
    //4mode: "no-cors",
    headers: { "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({
      token,
      customer
    })
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return JSON.parse(data.body);
    });
}

export function getPlanId(plan) {
  var id_offer = "";
  switch (
    plan //plan_GDohEBFezD5XDD
  ) {
    case "invite":
      id_offer = "plan_GDmsLlqqPdTyr2";
      if (process.env.NODE_ENV === "development")
        id_offer = "plan_GDohEBFezD5XDD";
      return id_offer;
    case "hived_pro":
      return "prod_DyHD34BIR5mGeT";
    case "hived_as_you_go":
      id_offer = "plan_G2pmCer4rgyhiC";
      if (process.env.NODE_ENV === "development")
        id_offer = "plan_GDjTSVrLZNyc62";
      return id_offer;
    case "hived_premium":
      return "plan_EB2lkgoIsSt6TV";
    default:
      return null;
  }
}

export async function storeCustomerId(firestore, uid, customer) {
  const stripeInfos = {
    id_customer: customer.customer.id
  };

  return await firestore
    .set({ collection: "stripe", doc: uid }, stripeInfos)
    .then(infos => {
      return customer.customer.id;
    });
}

export async function createCustomerStripe(
  uid,
  email,
  token,
  user_infos,
  firestore
) {
  if (token.id !== undefined) {
    return await createCustomer(token.id, email, uid).then(function(customer) {
      if (
        customer.customer !== undefined &&
        customer.customer.id !== undefined
      ) {
        return storeCustomerId(firestore, uid, customer);
      } else {
      }
    });
  }
}

export async function storeProjectSubscription(
  firestore,
  project,
  subscription
) {
  const stripeInfos = {
    stripe_subscription: subscription,
    waiting_for_profiles: true
  };
  return await firestore
    .update({ collection: "projects", doc: project }, stripeInfos)
    .then(infos => {
      return infos;
    });
}

export function getCampaignPrice(numberOfAccounts = 1) {
  return 350;
  /*   switch (true) {
    case numberOfAccounts < 3:
      return 350;
    case numberOfAccounts < 5:
      return 330;
    case numberOfAccounts < 8:
      return 300;
    case numberOfAccounts >= 8:
      return 280;
  } */
}

export function getSeatPrice(numberOfAccounts = 1) {
  //return 80;
  switch (true) {
    case numberOfAccounts < 5:
      return 80;
    case numberOfAccounts < 8:
      return 60;
    case numberOfAccounts >= 8:
      return 50;
    default:
      return null;
  }
}

export function getProPrice() {
  return 789.89;
}
