import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import T from "i18n-react";
import { TitleComponent } from "../layout/Title";

class Confidentialite extends Component {
  state = {};

  render() {
    const { isAuthenticated } = this.state;
    if (isAuthenticated) {
      return <Redirect to={{ pathname: "/home" }} />;
    } else
      return (
        <React.Fragment>
          <TitleComponent />
          <div className="tw-py-16 tw-bg-gray-50 tw-overflow-hidden">
            <div className="tw-max-w-7xl tw-mx-auto tw-px-4 tw-space-y-8 sm:tw-px-6 lg:tw-px-8">
              <div className="tw-text-base tw-max-w-prose tw-mx-auto lg:tw-max-w-none">
                <h2 className="tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                  Politique de confidentialité
                </h2>
                <p className="tw-mt-2 tw-text-3xl tw-leading-normal tw-font-extrabold tw-tracking-tight tw-text-gray-900 sm:tw-text-4xl">
                  Conditions générales d’utilisation du service applicables au
                  15 janvier 2021
                </p>
              </div>
              <div className="tw-relative tw-z-10 tw-text-base tw-max-w-prose tw-mx-auto lg:tw-max-w-5xl lg:tw-mx-0 lg:tw-pr-72">
                <p className="tw-text-lg tw-text-gray-500">
                  <p>Consentement</p>
                </p>
              </div>
              <div className="lg:tw-grid lg:tw-grid-cols-2 lg:tw-gap-8 lg:tw-items-start">
                <div className="tw-relative tw-z-10">
                  <div className="tw-prose tw-prose-blue tw-text-gray-500 tw-mx-auto lg:tw-max-w-none">
                    <p>
                      La confidentialité et la sécurité sont de la plus haute
                      importance pour Hived.ai et nous nous évertuons d’assurer
                      que nos mesures techniques et organisationnelles en place
                      respectent vos droits relatifs à la protection des
                      données. :
                    </p>
                    <p>
                      Cette Politique de confidentialité décrit nos règles
                      concernant la gestion, le traitement et le stockage des
                      données personnelles soumises dans le cadre de nos
                      services. Le terme « données personnelles » désigne les
                      informations relatives à une personne physique identifiée
                      ou qui peut être identifiée.
                    </p>

                    <p>
                      En s’inscrivant à nos services ou en remplissant un
                      formulaire de contact sur notre site (www.Hived.ai) ou
                      autres sites appartenant à Hived.ai, vous convenez et
                      acceptez que Hived.ai puisse recueillir, traiter, stocker
                      et/ou utiliser les données personnelles soumises en
                      conformité avec les règles exposées ci-après.
                    </p>
                    <p>
                      En nous donnant votre consentement, vous conservez
                      également le droit de rectification, le droit à l’oubli
                      et/ou le droit à la suppression de vos données
                      personnelles.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      Données personnelles collectées
                    </h2>
                    <p>
                      Les données personnelles sont collectées sur notre site
                      par la société Hived.ai, société par actions simplifiée
                      immatriculée au RCS de Paris sous le numéro 524 536 992 et
                      dont le siège social est sis 13-13 bis, rue de l’Aubrac –
                      75012 Paris.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      Déclaration CNIL
                    </h2>
                    <p>
                      Les traitements automatiques d’informations ont fait
                      l’objet de déclarations à la CNIL sous les numéros 1527459
                      et 1527474.
                    </p>
                    <p>
                      Vous conservez le droit de déposer une réclamation pour
                      atteinte à la protection de vos données, auprès de cette
                      autorité de contrôle.
                    </p>
                    <ul>
                      <li>
                        <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                          Données collectées sur le site
                        </h2>
                      </li>
                    </ul>
                    <p>
                      Lorsque vous vous inscrivez à nos services, les données
                      suivantes sont collectées et traitées : email, civilité,
                      prénom, nom, pays, numéro de TVA intracommunautaire le cas
                      échéant, mot de passe, adresse postale, numéro de
                      téléphone, adresse(s) IP et nom de domaine. En utilisant
                      nos services, les données suivantes sont collectées et
                      traitées : données de connexion et données de navigation
                      lorsque vous l’autorisez, historiques de commandes,
                      réclamations, incidents, informations relatives aux
                      abonnements et correspondances sur notre site. Certaines
                      données sont collectées automatiquement du fait de vos
                      actions sur le site (voir le paragraphe relatif aux
                      cookies ci-dessous).
                    </p>
                    <p>
                      Les données soumises ne doivent pas inclure de données
                      personnelles sensibles, du type identifiants
                      gouvernementaux (comme les numéros de sécurité sociale, de
                      permis de conduire, ou les numéros d’identification de
                      contribuable), numéros complets de carte de crédit ou de
                      compte bancaire personnel, dossiers médicaux ou
                      informations relatives à des demandes de soins associées à
                      des personnes.
                    </p>
                    <ul>
                      <li>
                        <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                          Finalités des traitements et base juridique
                        </h2>
                      </li>
                    </ul>
                    <p>
                      L’objectif principal de la collecte de vos données
                      personnelles est de vous offrir une expérience sûre,
                      optimale, efficace et personnalisée. A cette fin, vous
                      acceptez que nous puissions utiliser vos données
                      personnelles pour :
                    </p>
                    <ul>
                      <li>
                        fournir nos services et en faciliter le fonctionnement,
                        y compris en menant pour ce faire des vérifications vous
                        concernant ;
                      </li>
                      <li>
                        résoudre les éventuels problèmes afin d’améliorer
                        l’utilisation de notre site et de nos services ;
                      </li>
                      <li>
                        personnaliser, évaluer, améliorer nos services, contenus
                        et documentation ;
                      </li>
                      <li>
                        analyser le volume et l’historique de votre utilisation
                        de nos services ;
                      </li>
                      <li>
                        vous informer sur nos services et ceux de nos sociétés
                        partenaires ;
                      </li>
                      <li>
                        empêcher, détecter et enquêter sur toutes activités
                        potentiellement interdites et illégales ou contraires
                        aux bonnes pratiques, et assurer la conformité avec nos
                        conditions générales d’utilisation et notre politique
                        d’envoi ;
                      </li>
                      <li>
                        respecter nos obligations légales et réglementaires.
                      </li>
                    </ul>
                    <p>
                      Pour les clients qui se sont inscrits directement sur le
                      site, nous traitons leurs données pour l’exécution du
                      contrat de prestation de services.
                    </p>
                    <p>
                      Pour nos newsletters, utilisez les études de cas et le
                      matériel marketing, nous traitons vos
                      données personnelles sur la base du consentement explicite
                      que vous avez donné à cet effet.
                    </p>
                    <ul>
                      <li>
                        <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                          Newsletter et emails marketing
                        </h2>
                      </li>
                    </ul>
                    <p>
                      Un lien de désinscription est inclus dans chaque
                      newsletter et email marketing que nous envoyons.
                    </p>
                    <ul>
                      <li>
                        <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                          Témoignages
                        </h2>
                      </li>
                    </ul>
                    <p>
                      Hived.ai publie sur son site une liste de Clients &
                      Témoignages comportant des informations sur le nom de nos
                      clients et leurs titres. Hived.ai s’engage à obtenir
                      l’autorisation de chaque client avant publication sur le
                      site de tout témoignage. Si vous souhaitez être retiré de
                      la liste, vous pouvez nous envoyer un email à
                      l’adresse contact@hived.ai et nous supprimerons vos
                      informations très rapidement.
                    </p>

                    <ul>
                      <li>
                        <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                          Destinataires de vos données
                        </h2>
                      </li>
                    </ul>
                    <p>
                      Les données personnelles vous concernant collectées sur le
                      site sont destinées pour la propre utilisation par
                      Hived.ai et peuvent être transmises aux sociétés
                      sous-traitantes auxquelles Hived.ai peut faire appel dans
                      le cadre de l’exécution de ses services. Hived.ai assure
                      la conformité avec les exigences de protection des données
                      pour toutes ses sociétés sous-traitantes.
                    </p>

                    <p>
                      Hived.ai ne vend ni ne loue vos données personnelles à des
                      tiers à des fins de marketing, en aucun cas.
                    </p>
                    <p>
                      En outre, Hived.ai ne divulgue pas vos données
                      personnelles à des tiers, excepté si (1) vous (ou votre
                      administrateur de compte agissant en votre nom) en
                      formulez la demande ou autorisez la divulgation ; (2) la
                      divulgation est requise pour traiter des transactions ou
                      fournir des services que vous avez demandés (i.e., aux
                      fins de vérification de vos bonnes pratiques d’envoi ou
                      dans le cadre du traitement d’une carte d’achat avec des
                      sociétés émettrices de cartes de crédit) ; (3) Hived.ai y
                      est contrainte par une autorité gouvernementale ou un
                      organisme de réglementation, en cas de réquisition
                      judiciaire, de citation à comparaître ou de toute autre
                      exigence gouvernementale ou judiciaire similaire, ou pour
                      établir ou défendre une demande légale ; ou (4) le tiers
                      agit en tant que qu’agent ou de sous-traitant de Mailjet
                      dans l’exécution des services (par exemple, Hived.ai
                      utilise les services d’une société de télécommunications).
                    </p>
                    <ul>
                      <li>
                        <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                          Vos droits à la protection des données
                        </h2>
                      </li>
                    </ul>
                    <p>
                      Conformément à la loi Informatique et Libertés et le
                      Règlement Européen Général sur la Protection des Données
                      2016/679 (RGPD), vous disposez des droits d’accès, de
                      rectification et de suppression des données personnelles
                      vous concernant que vous pouvez exercer en nous envoyant
                      un mail à contact@hived.ai. Vos requêtes seront traitées
                      dans les 30 jours. Nous pouvons vous demander à ce que
                      votre demande soit accompagnée d’une photocopie de preuve
                      d’identité ou d’autorité.
                    </p>
                    <p>
                      Vous pouvez également à tout moment modifier vous-mêmes
                      les données personnelles vous concernant en vous loguant à
                      votre compte et en cliquant sur “éditer mon profil”.
                    </p>
                    <ul>
                      <li>
                        <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                          Cookies/Tracking
                        </h2>
                      </li>
                    </ul>
                    <p>
                      En règle générale, Hived.ai utilise les cookies et le
                      tracking aux fins d’améliorer et personnaliser le site
                      et/ou en mesurer l’audience. Les cookies sont des fichiers
                      enregistrés sur le disque dur de votre ordinateur lors de
                      votre navigation sur Internet et notamment sur le site. Un
                      cookie ne sert pas à recueillir des données personnelles à
                      votre insu mais à enregistrer des informations relatives à
                      votre navigation sur le site qui pourront être directement
                      lues par Hived.ai lors de vos visites et requêtes
                      ultérieures sur le site.
                    </p>
                    <p>
                      Si vous choisissez de refuser l’ensemble des cookies,
                      votre navigation pourra être réduite pour accéder à
                      certaines pages du site. Les cookies utilisés par Hived.ai
                      ont pour finalité de permettre ou faciliter la
                      communication, de permettre la fourniture des services
                      demandés par les utilisateurs, de reconnaître les
                      utilisateurs lors d’une prochaine visite, de sécuriser les
                      paiements que les utilisateurs peuvent être amenés à
                      réaliser, d’enregistrer la langue parlée par les
                      utilisateurs ou autres préférences nécessaires à la
                      fourniture du service demandé.
                    </p>
                    <p>
                      Hived.ai utilise aussi des outils d’analyse et de suivi
                      afin de mesurer les données sur les sites Web et les
                      données numériques pour d’obtenir un aperçu de la
                      clientèle, réaliser des analyses sur l’expérience de
                      navigation afin d’améliorer le contenu et d’envoyer des
                      publicités ciblées.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      Article 5. Abonnement- Modalités financières
                    </h2>
                    <p>
                      Par défaut, les cookies ne sont pas automatiquement
                      installés (à l’exception des cookies nécessaires au
                      fonctionnement du site et des services de Hived.ai, dont
                      l’installation vous est signalée par un bandeau).
                      Conformément à la réglementation applicable, Hived.ai
                      requerra votre autorisation avant d’implanter tout autre
                      type de cookies sur votre disque dur. Afin d’éviter toute
                      gêne occasionnée par ces demandes d’autorisation
                      systématiques et profiter d’une navigation ininterrompue,
                      vous pouvez configurer votre ordinateur afin d’accepter
                      les cookies de Hived.ai ou nous pouvons mémoriser votre
                      refus ou acceptation relatif à certains cookies. Par
                      défaut les navigateurs acceptent tous les cookies.
                    </p>
                    <p>
                      Lorsque vous accédez à des sites tiers faisant de la
                      publicité sur le site de Hived.ai, ou éventuellement
                      lorsque vous visualisez ces publicités, des cookies
                      peuvent être créés par les sociétés diffusant ces
                      publicités. Ces tiers pouvant utiliser des cookies dans le
                      cadre des services de Hived.ai (partenaires ou autres
                      tiers fournissant du contenu ou des services disponibles
                      sur le site de Hived.ai) sont responsables des cookies
                      qu’ils mettent en place et ce sont leurs dispositions
                      relatives aux cookies qui s’appliquent. Hived.ai n’assume
                      aucune responsabilité quant à l’éventuelle utilisation de
                      cookies par ces tiers. Pour plus de précisions, il vous
                      est conseillé de consulter directement sur ces sites
                      annonceurs ou tiers leur politique en matière
                      d’utilisation de cookies.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      DONNÉES DE TIERS
                    </h2>

                    <p>
                      Dans le cadre de l’utilisation de nos services, Hived.ai a
                      accès à toutes les informations contenues que vous créez
                      sous votre compte via nos services.
                    </p>
                    <p>
                      Les données sont stockées sur des serveurs sécurisés et
                      seul un nombre restreint de personnes autorisées peuvent
                      avoir accès à vos listes de distribution, notamment à des
                      fins de service de support.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      CONSERVATION DES DONNÉES
                    </h2>
                    <p>
                      Hived.ai collecte vos données personnelles pour les
                      besoins de l’exécution de ses obligations contractuelles
                      ainsi que des informations sur la façon et la fréquence
                      d’utilisation de nos services et nous conservons ces
                      données dans des bases de données actives, des fichiers
                      journaux ou d’autres types de fichiers tant que Vous
                      utilisez nos services.
                    </p>

                    <p>
                      Hived.ai ne stocke vos données que le temps nécessaire à
                      la fourniture du service, et pas plus longtemps que trois
                      mois après la fermeture de votre compte ( à moins que la
                      loi en exige autrement). Vous pourrez accéder à vos
                      données personnelles tant que votre compte reste actif et
                      pour une période qui peut varier selon le type de données
                      concernées. Vos données événementielles (statistiques par
                      exemple), seront supprimés tous les 13 mois pendant que le
                      compte reste actif. Le contenu de votre message sera
                      stocké uniquement pour une période de 6 jours. Les autres
                      données peuvent être supprimées à tout moment pendant
                      l’utilisation active de votre compte, en conformité avec
                      les dispositions énoncées ci-dessus.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      LIEU DE STOCKAGE DES DONNÉES ET TRANSFERTS
                    </h2>
                    <p>
                      Les serveurs d’hébergement sur lesquels Hived.ai traite et
                      stocke les bases de données sont exclusivement situés au
                      sein de l’Union Européenne.
                    </p>
                    <p>
                      Hived.ai s’engage à vous informer immédiatement, dans la
                      mesure où nous y sommes légalement autorisés, en cas de
                      requête provenant d’une autorité administrative ou
                      judiciaire relative à vos données.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      SÉCURITÉ
                    </h2>
                    <p>
                      Ainsi, et conformément au RGPD, Hived.ai s’engage à
                      prendre toutes précautions utiles afin de préserver la
                      sécurité des données et notamment de les protéger contre
                      toute destruction accidentelle ou illicite, perte
                      accidentelle, altération, diffusion ou accès non
                      autorisés, ainsi que contre toute autre forme de
                      traitement illicite ou communication à des personnes non
                      autorisées.
                    </p>
                    <p>
                      A cette fin, Hived.ai met en œuvre les mesures de sécurité
                      standard de l’industrie pour protéger les données
                      personnelles d’une divulgation non autorisée. En utilisant
                      les méthodes d’encodage recommandées par l’industrie,
                      Hived.ai prend les mesures nécessaires pour protéger les
                      informations liées aux règlements et aux cartes de crédit.
                    </p>
                    <p>
                      Par ailleurs, afin d’éviter notamment tout accès non
                      autorisé, de garantir l’exactitude et une bonne
                      utilisation des données, Hived.ai a mis en place des
                      procédures électroniques, physiques et d’encadrement
                      appropriées en vue de sauvegarder et préserver les données
                      recueillies à travers ses services.
                    </p>
                    <p>
                      Malgré tout, personne ne peut se considérer comme étant
                      complètement à l’abri d’une attaque de pirates. C’est
                      pourquoi dans le cas où une faille de sécurité venait à
                      vous impacter, Hived.ai s’engage à vous en informer dès
                      que possible et à faire ses meilleurs efforts pour prendre
                      toutes les mesures possibles pour neutraliser l’intrusion
                      et en minimiser les impacts. Dans le cas où vous subiriez
                      un dommage du fait de l’exploitation d’une faille de
                      sécurité par un tiers, Hived.ai s’engage à vous fournir
                      toute l’assistance nécessaire afin que vous puissiez faire
                      valoir vos droits. Par ailleurs si, par exception, le
                      dommage subi résultait en partie d’une faute ou négligence
                      grave de la part de Hived.ai, vous pourriez obtenir
                      réparation dans la limite du plafond de responsabilité
                      visé dans nos Conditions Générales d’Utilisation.
                    </p>

                    <p>
                      Il convient de garder à l’esprit que tout utilisateur,
                      client ou pirate découvrant une faille de sécurité et
                      l’exploitant s’expose à des sanctions pénales et que
                      Hived.ai prendra toutes mesures, y compris par le biais
                      d’un dépôt de plainte et/ou d’une action en justice, pour
                      préserver les données et droits de ses utilisateurs et les
                      siens et d’en limiter l’impact au maximum.
                    </p>
                    <p>
                      Mots de passe de Comptes. En adoptant les recommandations
                      de la CNIL concernant le niveau de sécurité des mots de
                      passes, le mot de passe de votre compte doit contenir au
                      moins 8 caractères, 1 chiffre, 1 nombre et 1 caractère
                      spécial.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ
                    </h2>
                    <p>
                      Hived.ai se réserve le droit de faire évoluer la présente
                      Politique de confidentialité à tout moment, notamment en
                      application des changements apportés aux lois et
                      réglementations en vigueur. Les modifications apportées
                      vous seront notifiées via notre site web ou par email,
                      dans la mesure du possible trente (30) jours au moins
                      avant que ceux-ci n’entrent en application. Nous vous
                      recommandons de vérifier ces règles de temps en temps pour
                      rester informé de nos procédures et règles concernant vos
                      informations personnelles.
                    </p>
                    <h2 className="tw-mb-3 tw-mt-3 tw-leading-normal tw-text-blue-600 tw-font-semibold tw-tracking-wide tw-uppercase">
                      CONTACTEZ NOUS
                    </h2>
                    <p>
                      Si vous avez des questions ou vous pouvez directement
                      contacter par email le Délégué à la Protection des Données
                      à contact@hived.ai.
                    </p>
                  </div>
                  <div className="tw-mt-10 tw-flex tw-text-base tw-max-w-prose tw-mx-auto lg:tw-max-w-none">
                    <div className="tw-rounded-md tw-shadow">
                      <a
                        href="/signup"
                        className="tw-w-full tw-flex tw-items-center tw-justify-center tw-px-5 tw-py-3 tw-border tw-border-transparent tw-tw-text-base tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700"
                      >
                        {T.translate("landing.start")}
                      </a>
                    </div>
                    <div className="tw-rounded-md tw-shadow tw-ml-4">
                      <a
                        href="/signup"
                        className="tw-w-full tw-flex tw-items-center tw-justify-center tw-px-5 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-md tw-text-blue-600 tw-bg-white hover:tw-bg-gray-50"
                      >
                        {T.translate("landing.demo")}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="tw-mt-12 tw-relative tw-text-base tw-max-w-prose tw-mx-auto lg:tw-mt-0 lg:tw-max-w-none">
                  <svg
                    className="tw-absolute tw-top-0 tw-right-0 tw--mt-20 tw--mr-20 lg:tw-top-auto lg:tw-right-auto lg:tw-bottom-1/2 lg:tw-left-1/2 lg:tw-mt-0 lg:tw-mr-0 xl:tw-top-0 xl:tw-right-0 xl:tw--mt-20 xl:tw--mr-20"
                    width="404"
                    height="384"
                    fill="none"
                    viewBox="0 0 404 384"
                    aria-hidden="true"
                  >
                    <defs>
                      <pattern
                        id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          className="text-gray-200"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width="404"
                      height="384"
                      fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)"
                    />
                  </svg>
                  {/* <blockquote className="tw-relative tw-bg-white tw-rounded-lg tw-shadow-lg">
                    <div className="tw-rounded-t-lg tw-px-6 tw-py-8 sm:tw-px-10 sm:tw-pt-10 sm:tw-pb-8">
                      <img
                        src="https://tailwindui.com/img/logos/workcation-logo-blue-600-mark-gray-800-and-blue-600-text.svg"
                        alt="Workcation"
                        className="h-8"
                      />
                      <div className="tw-relative tw-text-lg tw-text-gray-700 tw-font-medium tw-mt-8">
                        <svg
                          className="tw-absolute tw-top-0 tw-left-0 tw-transform tw--translate-x-3 tw--translate-y-2 tw-h-8 tw-w-8 tw-text-gray-200"
                          fill="currentColor"
                          viewBox="0 0 32 32"
                          aria-hidden="true"
                        >
                          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                        </svg>
                        <p className="tw-relative">
                          Tincidunt integer commodo, cursus etiam aliquam neque,
                          et. Consectetur pretium in volutpat, diam. Montes,
                          magna cursus nulla feugiat dignissim id lobortis amet.
                          Laoreet sem est phasellus eu proin massa, lectus.
                        </p>
                      </div>
                    </div>
                    <cite className="tw-relative tw-flex tw-items-center sm:tw-items-start tw-bg-blue-600 tw-rounded-b-lg tw-not-italic tw-py-5 tw-px-6 sm:tw-py-5 sm:tw-pl-12 sm:tw-pr-10 sm:tw-mt-10">
                      <div className="tw-relative tw-rounded-full tw-border-2 tw-border-white sm:tw-absolute sm:tw-top-0 sm:tw-transform sm:tw--translate-y-1/2">
                        <img
                          className="tw-w-12 tw-h-12 sm:tw-w-20 sm:tw-h-20 tw-rounded-full tw-bg-blue-300"
                          src="https://images.unsplash.com/photo-1500917293891-ef795e70e1f6?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2.5&w=160&h=160&q=80"
                          alt=""
                        />
                      </div>
                      <span className="tw-relative tw-ml-4 tw-text-blue-300 tw-font-semibold tw-leading-6 sm:tw-ml-24 sm:tw-pl-1">
                        <p className="tw-text-white tw-font-semibold sm:tw-inline">
                          Judith Black
                        </p>
                        <p className="sm:tw-inline">CEO at Workcation</p>
                      </span>
                    </cite>
                  </blockquote> */}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  connect((state, props) => ({
    auth: state.firebase.auth,
  }))
)(Confidentialite);
