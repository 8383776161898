export function checkUserInfos(users_infos) {
  const avatar = "/theme/img/user.png";

  if (users_infos === undefined) {
    return {
      firstname: "deleted User",
      lastname: "",
      admin: false,
      deleted: true,
      picture: avatar
    };
  }
  if (users_infos.deleted === true) {
    users_infos.firstname = "deleted User";
    users_infos.lastname = "";
  }
  if (users_infos.picture === "") users_infos.picture = null; //avatar;
  return users_infos;
}
