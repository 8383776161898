import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import Wait from "../../layout/Wait";
import { timeSince } from "../../../helpers/project";
import { getColorFromString } from "../../../helpers/global";
import Avatar from "@material-ui/core/Avatar";
import T from "i18n-react";

class Note extends Component {
  createMarkup = (html) => {
    return { __html: html };
  };

  deleteNote(idNote) {
    const infos = {
      archived: true,
    };
    this.props.firestore.update({ collection: "notes", doc: idNote }, infos);
  }

  render() {
    if (!isLoaded(this.props.activity_user_infos)) {
      return <Wait />;
    }

    var user_infos = this.props.activity_user_infos;
    if (this.props.activity_user === "w2FJu9tlJjNLJ7z4dJio6WAf7Ct1")
      user_infos = {
        picture: null,
        firstname: T.translate("activity.user"),
        lastname: "team",
      };
    var language =
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage;
    if (language.length > 2) {
      language = language.split("-")[0];
      language = language.split("_")[0];
    }

    var picture =
      user_infos.picture === "" ||
      user_infos.picture === null ||
      user_infos.picture === undefined ? (
        <Avatar
          className="avatar"
          style={{
            color: "#fff",
            backgroundColor:
              "#" +
              getColorFromString(
                user_infos.firstname + " " + user_infos.lastname
              ),
            fontSize: "small",
            fontWeight: "bold",
          }}
        >
          {user_infos.firstname[0].toUpperCase() +
            user_infos.lastname[0].toUpperCase()}
        </Avatar>
      ) : (
        <img
          alt="Peggy"
          src={user_infos.picture}
          className="avatar filter-by-alt"
          data-filter-by="alt"
        />
      );
    return (
      <React.Fragment>
        <li>
          <div className="tw-flex tw-space-x-3">
            <div className="tw-flex-shrink-0">{picture}</div>
            <div>
              <div className="tw-text-sm tw-font-medium tw-text-gray-900">
                {user_infos.firstname}
              </div>
              <div className="tw-mt-1 tw-text-sm tw-text-gray-700">
                <div
                  className="DIV-filter-by-text"
                  dangerouslySetInnerHTML={this.createMarkup(this.props.note)}
                  data-filter-by="text"
                ></div>
              </div>
              <div className="tw-mt-2 tw-text-sm tw-space-x-2">
                <span className="tw-text-gray-500 tw-font-medium">
                  {language === "fr"
                    ? T.translate("activity.ago") +
                      " " +
                      timeSince(new Date(this.props.date.seconds * 1000))
                    : timeSince(new Date(this.props.date.seconds * 1000)) +
                      " " +
                      T.translate("activity.ago")}
                </span>
                {/*                 <span className="tw-text-gray-500 tw-font-medium">·</span>
                <button
                  type="button"
                  className="tw-text-gray-900 tw-font-medium"
                >
                  Reply
                </button> */}
              </div>
            </div>
          </div>
        </li>
      </React.Fragment>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect((props) => [
    {
      collection: "users_infos",
      doc: props.activity_user,
    },
  ]),
  connect((state, props) => ({
    activity_user_infos: state.firestore.data.users_infos[props.activity_user],
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    notify: state.notify,
  }))
)(Note);
