import React from "react";
import T from "i18n-react";

export default () => {
  return (
    <div className="main-container fullscreen">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-6 col-md-7">
            <div className="text-center">
              <h1 className="display-1 text-primary">
                4
                <span role="img" aria-label="head">
                  🤦‍
                </span>
                4
              </h1>
              <p>
                <h3>{T.translate("404.title")}</h3>
                {T.translate("404.explanation")}
              </p>

              {/*   <p>
                <iframe
                  src={T.translate("404.form")}
                  width="600"
                  height="500"
                  frameborder="0"
                  marginheight="0"
                  marginwidth="0"
                  title="beta form"
                >
                  Loading...
                </iframe>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
