import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { getCampaignColumns, addActivityMove } from "../../helpers/project";
import { isLoaded } from "react-redux-firebase";

class Status extends Component {
  constructor(props) {
    super(props);
    // Create refs
    this.select = React.createRef();
    this.state = { previousStatus: "" };
  }

  componentDidUpdate(nextProps) {
    if (nextProps.opportunity_infos)
      if (this.state.previousStatus !== this.props.opportunity_infos.step)
        this.setState({ previousStatus: this.props.opportunity_infos.step });
  }

  change = async (e) => {
    const { firestore } = this.props;

    this.select.current.setAttribute("disabled", "disabled");
    await addActivityMove(
      firestore,
      this.props.id_rel,
      "move",
      this.select.current.value,
      this.state.previousStatus,
      this.props.auth.uid,
      this.props.campaign,
      this.props.candidate,
      this.props.contributors
    );
    this.select.current.removeAttribute("disabled");
  };

  render() {
    if (!isLoaded(this.props.opportunity_infos)) {
      return "";
    }
    const campaignSteps = getCampaignColumns();

    return (
      <React.Fragment>
        <select
          className="custom-select custom-select-lg"
          onChange={this.change}
          value={this.props.opportunity_infos.step}
          ref={this.select}
        >
          {campaignSteps.map((item) => {
            return (
              <option key={item} value={item}>
                {item[0].toUpperCase() + item.substring(1)}
              </option>
            );
          })}
        </select>
      </React.Fragment>
    );
  }
}

export default compose(
  firestoreConnect((props) => [
    {
      collection: "campaign_candidate",
      doc: props.id_rel,
      storeAs: "opp_" + props.id_rel,
    },
  ]),
  connect((state, props) => ({
    opportunity_infos: state.firestore.data["opp_" + props.id_rel],
    auth: state.firebase.auth,
  }))
)(Status);

//export default compose(firestoreConnect(), connect())(Status);
