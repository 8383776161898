import React from "react";
import { Link } from "react-router-dom";
import T from "i18n-react";

export default () => {
  return (
    <div className="main-container fullscreen">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-6 col-md-7">
            <div className="text-center">
              <h1 className="display-1 text-primary">
                <span aria-label="" role="img">
                  {" "}
                  😓
                </span>
              </h1>
              <p>
                Cette campagne n'est pas encore active. Veuillez contacter son
                administateur pour l'activer.
                <Link to="/" className="nav-link">
                  {T.translate("404.click")}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
