import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import T from "i18n-react";
import ReactModal from "react-modal";

class Hired extends Component {
  constructor(props) {
    super(props);
    // Create refs
    this.start = React.createRef();
    this.salary = React.createRef();
    this.submit = React.createRef();
  }
  state = {
    dissmissed: this.props.dissmissed,
    cdiss: null,
    salary: null,
    start: null
  };

  componentDidUpdate(nextProps) {
    if (
      nextProps.dissmissed !== this.state.dissmissed ||
      this.props.candidate !== nextProps.candidate
    ) {
      this.setState({ dissmissed: nextProps.dissmissed });
    }
  }

  formHasError() {
    var hasError = false;
    if (this.start.current.value === "") {
      this.start.current.className = "form-control col-6 is-invalid";
      hasError = true;
    } else this.start.current.className = "form-control col-6";
    if (this.salary.current.value === "") {
      this.salary.current.className = "form-control col-6 is-invalid";
      hasError = true;
    } else this.salary.current.className = "form-control  col-6";
    return hasError;
  }

  onSubmit = e => {
    e.preventDefault();

    const { firestore } = this.props;

    const hasErrors = this.formHasError();

    if (!hasErrors) {
      this.submit.current.setAttribute("disabled", "disabled");
      const application_update = {
        final_decision: {
          status: "hired",
          start: this.start.current.value,
          salary: this.salary.current.value,
          date: new Date()
        }
      };

      firestore
        .update(
          { collection: "campaign_candidate", doc: this.props.application },
          application_update
        )
        .then(() => {
          this.props.done(false);
          this.setState({ dissmissed: false });
        });
    } else {
      this.submit.current.removeAttribute("disabled");
    }
  };

  render() {
    if (
      !isLoaded(this.props.cdiss) &&
      !isLoaded(this.props.application_infos)
    ) {
      return "";
    }
    let date = "";
    let salary = "";
    if (
      this.props.application_infos !== undefined &&
      this.props.application_infos.final_decision !== undefined &&
      this.props.application_infos.final_decision.status !== undefined &&
      this.props.application_infos.final_decision.status === "hired"
    ) {
      date =
        this.props.application_infos.final_decision.start !== undefined
          ? this.props.application_infos.final_decision.start
          : "";
      salary =
        this.props.application_infos.final_decision.salary !== undefined
          ? this.props.application_infos.final_decision.salary
          : "";
    }
    let firstname =
      this.props.cdiss !== undefined &&
      this.props.cdiss.firstName !== undefined &&
      this.props.cdiss.firstname !== null
        ? this.props.cdiss.firstName
        : "";

    return (
      <ReactModal
        ariaHideApp={false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)"
          },
          overlay: {
            backgroundColor: "rgba(0,0,0,0.6)"
          }
        }}
        isOpen={this.state.dissmissed}
        /*    onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles} */
        contentLabel="Celebration"
      >
        <div className="content-list col-12" style={{ maxWidth: "700px" }}>
          {/* <h3>
            {this.props.cdiss.firstName + " " + this.props.cdiss.secondName}
          </h3> */}
          {/* <div style={{ textAlign: "center" }} className="col-12">
            <h4 className="mb-3">Congratulations!</h4>
            <img
              alt="Google"
              src="/theme/img/components/congrats.svg"
              style={{ maxHeight: "250px" }}
            />
          </div> */}
          <h5 className="mt-3">
            {T.translate("hired.title", { firstname: firstname })}
          </h5>
          <div className="mt-3">
            <form onSubmit={this.onSubmit} className="col-12">
              <div className="form-group row align-items-center mt-1">
                <label className="col-5">
                  {T.translate("hired.join", { firstname: firstname })}
                </label>
                <input
                  name="start"
                  className="form-control col-6"
                  ref={this.start}
                  id="start"
                  type="date"
                  defaultValue={date}
                ></input>
              </div>

              <div className="form-group row align-items-center mt-1">
                <label className="col-5">{T.translate("hired.salary")} </label>

                <input
                  name="salary"
                  className="form-control col-6"
                  ref={this.salary}
                  id="salary"
                  type="number"
                  defaultValue={salary}
                ></input>
              </div>

              <div className="d-flex justify-content-between mt-5">
                <div></div>
                <button
                  ref={this.submit}
                  disabled={
                    this.state.who !== null && this.state.reason !== null
                      ? false
                      : "disabled"
                  }
                  className="btn btn-primary"
                  type="submit"
                >
                  {T.translate("dissmissed.save")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(props => [
    {
      collection: "profiles",
      doc:
        props.candidate !== null &&
        props.candidate.profile !== undefined &&
        props.candidate.profile !== null
          ? props.candidate.profile
          : "",
      storeAs: "cdiss"
    },
    {
      collection: "campaign_candidate",
      doc:
        props.application !== undefined && props.application !== null
          ? props.application
          : "",
      storeAs: "app_" + props.application
    }
  ]),
  connect((state, props) => ({
    cdiss: state.firestore.data.cdiss,
    application_infos: state.firestore.data["app_" + props.application],
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    notify: state.notify
  }))
)(Hired);
