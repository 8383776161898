import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import ListNewCandidates from "./ListNewCandidates";

class Applications extends Component {
  checkUpdatedElement(count, candidate_id) {
    if (this.props.interested[candidate_id] !== null) {
      return (
        <ListNewCandidates
          key={candidate_id}
          candidate_infos={this.props.interested[candidate_id]}
          number={count}
        />
      );
    }
  }

  render() {
    const candidates_ids =
      this.props.interested !== null && this.props.interested !== undefined
        ? Object.keys(this.props.interested)
        : [];
    let count = 1;

    return (
      <React.Fragment>
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Firstname</th>
              <th scope="col">Lastname</th>
              <th scope="col">Job title</th>
              <th scope="col">options</th>
              <th scope="col">options</th>
            </tr>
          </thead>
          <tbody>
            {candidates_ids.map(candidate_id =>
              this.checkUpdatedElement(count++, candidate_id)
            )}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export default compose(firestoreConnect(), connect())(Applications);
