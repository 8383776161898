import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import Wait from "../../layout/Wait";
import Note from "./Note";
import T from "i18n-react";
import RichTextEditor from "react-rte";
import Avatar from "@material-ui/core/Avatar";
import { getColorFromString } from "../../../helpers/global";
import { addActivityNote } from "../../../helpers/project";

class Notes extends Component {
  state = {
    description: RichTextEditor.createEmptyValue(),
    showForm: "none",
  };

  constructor(props) {
    super(props);
    // Create refs
    this.comment = React.createRef();
    this.submit = React.createRef();
  }

  formHasError() {
    var hasError = false;
    if (
      this.state.description.toString("html").replace(/<\/?[^>]+(>|$)/g, "") ===
      ""
    ) {
      this.comment.current.className = "form-control is-invalid";
      hasError = true;
    } else this.comment.current.className = "form-control";
    return hasError;
  }

  onSubmit = async (e) => {
    e.preventDefault();

    const { firestore } = this.props;

    const hasErrors = this.formHasError();

    if (!hasErrors) {
      this.submit.current.setAttribute("disabled", "disabled");
      await addActivityNote(
        firestore,
        this.props.opportunity,
        this.state.description.toString("html"),
        this.props.auth.uid,
        this.props.campaign,
        this.props.candidate,
        this.props.contributors
      );
      this.submit.current.removeAttribute("disabled");
    }
    /*  firestore.add({ collection: "notes" }, note).then(() => {
        this.submit.current.removeAttribute("disabled");
        this.setState({ description: RichTextEditor.createEmptyValue() });
        firestore
          .collection("campaign_candidate")
          .doc(this.props.opportunity)
          .collection("history")
          .add({
            date: new Date(),
            type: "note",
            action: "creation",
            user: this.props.auth.uid,
          });
      });
    } else {
      this.submit.current.removeAttribute("disabled");
    } */
  };

  onClick = function () {
    var value = this.state.showForm === "none" ? "block" : "none";
    this.setState({ showForm: value });
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onTextChange = (value) => {
    this.setState({ description: value });
  };

  componentDidUpdate(nextProps) {
    const notes_keys =
      nextProps.notes === null || nextProps.notes === undefined
        ? []
        : Object.keys(nextProps.notes);
    if (notes_keys.length === 0 && this.state.showForm !== "block") {
      this.setState({ showForm: "block" });
    }
  }

  render() {
    if (!isLoaded(this.props.notes)) {
      return <Wait />;
    }
    const toolbarConfig = {
      // Optionally specify the groups to display (displayed in the order listed).
      display: [
        "INLINE_STYLE_BUTTONS",
        "BLOCK_TYPE_BUTTONS",
        "LINK_BUTTONS",
        "BLOCK_TYPE_DROPDOWN",
        "HISTORY_BUTTONS",
      ],
      INLINE_STYLE_BUTTONS: [
        { label: "Bold", style: "BOLD", className: "custom-css-class" },
        { label: "Italic", style: "ITALIC" },
        { label: "Underline", style: "UNDERLINE" },
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: "Normal", style: "unstyled" },
        { label: "Heading Large", style: "header-one" },
        { label: "Heading Medium", style: "header-two" },
        { label: "Heading Small", style: "header-three" },
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: "UL", style: "unordered-list-item" },
        { label: "OL", style: "ordered-list-item" },
      ],
    };

    var picture =
      this.props.currentUser.picture === "" ||
      this.props.currentUser.picture === null ||
      this.props.currentUser.picture === undefined ? (
        <Avatar
          className="avatar"
          style={{
            color: "#fff",
            backgroundColor:
              "#" +
              getColorFromString(
                this.props.currentUser.firstname +
                  " " +
                  this.props.currentUser.lastname
              ),
            fontSize: "small",
            fontWeight: "bold",
          }}
        >
          {this.props.currentUser.firstname[0].toUpperCase() +
            this.props.currentUser.lastname[0].toUpperCase()}
        </Avatar>
      ) : (
        <img
          alt="Peggy"
          src={this.props.currentUser.picture}
          className="avatar filter-by-alt"
          data-filter-by="alt"
        />
      );

    const notes_keys =
      this.props.notes === null || this.props.notes === undefined
        ? []
        : Object.keys(this.props.notes);
    return (
      <>
        <div className="tw-bg-gray-100 tw-py-6">
          <div className="tw-max-w-7xl tw-mx-auto sm:tw-px-6 lg:tw-px-8">
            <div className="tw-bg-white tw-shadow sm:tw-rounded-lg sm:tw-overflow-hidden">
              <div className="tw-divide-y tw-divide-gray-200">
                <div className="tw-x-4 tw-py-5 sm:tw-px-6">
                  <h2
                    id="notes-title"
                    className="tw-text-lg tw-font-medium tw-text-gray-900"
                  >
                    Notes
                  </h2>
                </div>

                {notes_keys.length > 0 ? (
                  <div className="tw-px-4 tw-py-6 sm:tw-px-6">
                    <ul className="tw-space-y-8">
                      {notes_keys.map((id) => {
                        if (this.props.notes[id].user === "Hived.ai")
                          this.props.notes[id].user =
                            "w2FJu9tlJjNLJ7z4dJio6WAf7Ct1"; //saad to switch to someone or Hived in UI
                        return (
                          <Note
                            key={id}
                            id={id}
                            activity_user={this.props.notes[id].user}
                            candidate={this.props.candidate_name}
                            date={this.props.notes[id].date}
                            note={this.props.notes[id].note}
                          />
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="tw-bg-gray-50 tw-px-4 tw-py-6 sm:tw-px-6">
                <div className="tw-flex tw-space-x-3">
                  <div className="tw-flex-shrink-0">{picture}</div>
                  <div className="tw-min-w-0 tw-flex-1">
                    <form onSubmit={this.onSubmit}>
                      <div>
                        <label className="sr-only">About</label>
                        <RichTextEditor
                          className="rich-text-editor "
                          toolbarConfig={toolbarConfig}
                          placeholder={T.translate("notes.comment")}
                          name="description"
                          value={this.state.description}
                          ref={this.comment}
                          onChange={this.onTextChange}
                        />
                      </div>
                      <div className="tw-mt-3 tw-flex tw-items-center tw-justify-between">
                        <span className="tw-group tw-inline-flex tw-items-start tw-text-sm tw-space-x-2 tw-text-gray-500 hover:tw-text-gray-900">
                          <svg
                            className="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
                            x-description="Heroicon name: question-mark-circle"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span>Some HTML is okay.</span>
                        </span>
                        <button
                          ref={this.submit}
                          type="submit"
                          className="tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-text-sm tw-font-medium tw-rounded-md tw-shadow-sm tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
                        >
                          {T.translate("notes.create")}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ firebase: { auth } }) => ({
  auth,
});

export default compose(
  firebaseConnect(),
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "notes",
      where: [
        ["opportunity", "==", props.opportunity],
        ["archived", "==", false],
      ],
      orderBy: ["date"],
      storeAs: "notes_" + props.opportunity,
    },
    {
      collection: "users_infos",
      doc: props.auth.uid,
    },
  ]),
  connect((state, props) => ({
    notes: state.firestore.data["notes_" + props.opportunity],
    currentUser: state.firestore.data.users_infos[props.auth.uid],
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    notify: state.notify,
  }))
)(Notes);
