import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import Wait from "../../layout/Wait";
import { timeSince } from "../../../helpers/project";
import T from "i18n-react";

class Activty extends Component {
  render() {
    if (!isLoaded(this.props.activity_user_infos)) {
      return <Wait />;
    }

    var user_infos = this.props.activity_user_infos;
    var language =
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage;
    if (language.length > 2) {
      language = language.split("-")[0];
      language = language.split("_")[0];
    }
    /* if (this.props.activity_user === "w2FJu9tlJjNLJ7z4dJio6WAf7Ct1")
      user_infos = {
        picture: null,
        firstname: T.translate("activity.user"),
        lastname: "team",
      };
    

    var picture =
      user_infos.picture === "" ||
      user_infos.picture === null ||
      user_infos.picture === undefined ? (
        <Avatar
          className="avatar"
          style={{
            color: "#fff",
            backgroundColor:
              "#" +
              getColorFromString(
                user_infos.firstname + " " + user_infos.lastname
              ),
            fontSize: "small",
            fontWeight: "bold",
          }}
        >
          {user_infos.firstname[0].toUpperCase() +
            user_infos.lastname[0].toUpperCase()}
        </Avatar>
      ) : (
        <img
          alt="Peggy"
          src={user_infos.picture}
          className="avatar filter-by-alt"
          data-filter-by="alt"
        />
      );
 */
    var activity_text = "";
    var createSvg = "";
    var bblcolor = "";
    switch (this.props.activity.type) {
      case "note":
        bblcolor = "tw-bg-yellow-500";
        createSvg = (
          <svg
            className="tw-h-5 tw-w-5 text-white "
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
            />
          </svg>
        );
        activity_text = (
          <React.Fragment>
            <span className="h6 SPAN-filter-by-text" data-filter-by="text">
              {user_infos.firstname}
            </span>
            <span data-filter-by="text" className="SPAN-filter-by-text">
              {" "}
              {T.translate("activity.created")}
            </span>
          </React.Fragment>
        );
        break;
      case "move":
        bblcolor = "tw-bg-blue-500";
        createSvg = (
          <svg
            className="tw-h-5 tw-w-5 text-white tw-bg-blue-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
            />
          </svg>
        );
        activity_text = (
          <React.Fragment>
            <span
              className="tw-font-medium tw-text-gray-900"
              data-filter-by="text"
            >
              {user_infos.firstname}
            </span>
            <span data-filter-by="text" className="SPAN-filter-by-text">
              {" "}
              {T.translate("activity.moved")}{" "}
            </span>
            <a href="#l" data-filter-by="text" className="A-filter-by-text">
              {this.props.candidate}
            </a>{" "}
            {T.translate("activity.to")} "
            <span
              className="tw-font-medium tw-text-gray-900"
              data-filter-by="text"
            >
              {this.props.step[0].toUpperCase() + this.props.step.slice(1)}
            </span>
            "
          </React.Fragment>
        );
        break;
      default:
        activity_text = "";
    }

    return (
      <React.Fragment>
        <li>
          <div className="tw-relative tw-pb-8">
            <span
              className="tw-absolute tw-top-4 tw-left-4 tw--ml-px tw-h-full tw-w-0.5 tw-bg-gray-200"
              aria-hidden="true"
            ></span>
            <div className="tw-relative tw-flex tw-space-x-3">
              <div>
                <span
                  className={`${bblcolor} tw-h-8 tw-w-8 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-ring-8 tw-ring-white`}
                >
                  {createSvg}
                </span>
              </div>
              <div className="tw-min-w-0 tw-flex-1 tw-pt-1.5 tw-flex tw-justify-between tw-space-x-4">
                <div>
                  <p className="tw-text-sm tw-text-gray-500">{activity_text}</p>
                </div>
                <div className="tw-text-right tw-text-sm tw-whitespace-nowrap tw-text-gray-500">
                  <time datetime="2020-09-20">
                    {language === "fr"
                      ? T.translate("activity.ago") +
                        " " +
                        timeSince(new Date(this.props.date.seconds * 1000))
                      : timeSince(new Date(this.props.date.seconds * 1000)) +
                        " " +
                        T.translate("activity.ago")}
                  </time>
                </div>
              </div>
            </div>
          </div>
        </li>
      </React.Fragment>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect((props) => [
    {
      collection: "users_infos",
      doc: props.activity_user,
    },
  ]),
  connect((state, props) => ({
    activity_user_infos: state.firestore.data.users_infos[props.activity_user],
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    notify: state.notify,
  }))
)(Activty);
