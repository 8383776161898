import { NOTIFY_USER, SET_PROGRESS_BAR, CLOSE_NOTIFICATION } from "./types";
import { actionTypes } from "react-redux-firebase";

export const notifyUser = (message, messageType) => {
  return {
    type: NOTIFY_USER,
    message,
    messageType,
    isOpen: true,
  };
};

export const closeNotification = () => {
  return {
    type: CLOSE_NOTIFICATION,
    isOpen: false,
  };
};

export const setProgressBar = (isOpen) => ({
  type: SET_PROGRESS_BAR,
  isOpen: isOpen,
});

export const clearState = () => ({
  type: actionTypes.CLEAR_DATA,
});
