import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";

class ListNewCandidates extends Component {
  profileHasEmail(emails) {
    let hasEmail = false;
    if (emails !== null && emails !== undefined) {
      Object.keys(emails).map(email => {
        if (emails[email].email !== null && emails[email].email !== undefined) {
          hasEmail = true;
        }
        return hasEmail;
      });
    }
    return hasEmail;
  }

  render() {
    if (!isLoaded(this.props.profile)) {
      return (
        <tr>
          <th>Loading...</th>
        </tr>
      );
    }

    const hasEmail = this.profileHasEmail(this.props.profile.emails);
    const el =
      hasEmail === true ? (
        <span className="badge badge-success">{this.props.number}</span>
      ) : (
        <span className="badge badge-danger">{this.props.number}</span>
      );
    return (
      <tr>
        <th>{el}</th>
        <td>{this.props.profile.firstName}</td>
        <td>{this.props.profile.secondName}</td>
        <td>{this.props.profile.position}</td>
        <td>{this.props.profile.company}</td>
        <td>
          <a
            className="dropdown-item"
            href={`/candidate/edit/${this.props.candidate_infos.profile}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Profile
          </a>
        </td>
      </tr>
    );
  }
}

export default compose(
  firestoreConnect(props => [
    {
      collection: "profiles",
      doc: props.candidate_infos.profile
    }
  ]),
  connect((state, props) => ({
    profile: state.firestore.data.profiles[props.candidate_infos.profile]
  }))
)(ListNewCandidates);
