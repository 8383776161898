import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import Wait from "../layout/Wait";
import { isLoaded } from "react-redux-firebase";
import { syncGmail } from "../../helpers/mail";
import T from "i18n-react";

class OpportunityInModal extends Component {
  state = {
    labels: null,
  };

  componentDidUpdate(nextProps) {
    if (this.state.labels === null) {
      syncGmail(this.props.auth.uid).then((labels) => {
        if (
          labels.response !== undefined &&
          labels.response.labels !== undefined
        ) {
          this.setState({ labels: labels.response.labels });
          /* console.log(labels.response.labels); */
        }
      });
    }
  }
  render() {
    if (
      !isLoaded(this.props.opportunity_infos) ||
      !isLoaded(this.props.candidate_infos)
    ) {
      return <Wait />;
    }

    let email = null;
    let tel = null;
    if (
      this.props.opportunity_infos !== null &&
      this.props.opportunity_infos.application !== undefined
    ) {
      if (this.props.opportunity_infos.application.email !== undefined) {
        email = this.props.opportunity_infos.application.email;
      }

      if (this.props.opportunity_infos.application.tel !== undefined) {
        tel = this.props.opportunity_infos.application.tel;
      }
    }

    if (
      this.props.opportunity_infos !== null &&
      this.props.opportunity_infos.candidate_is_unreachable === true
    ) {
      return (
        <React.Fragment>
          <div className="tw-bg-gray-100">
            <div className="tw-max-w-7xl tw-mx-auto tw-py-6 sm:tw-px-6 lg:tw-px-8">
              <div className="tw-max-w-4xl tw-mx-auto">
                <div className="tw-bg-white tw-shadow tw-overflow-hidden sm:tw-rounded-lg">
                  <div className="tw-px-4 tw-py-5 sm:tw-px-6">
                    <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {T.translate("opportunityInModal.contactTitle")}
                    </h3>
                    <p className="tw-mt-1 tw-max-w-3xl tw-text-sm tw-text-gray-500">
                      {T.translate("opportunityInModal.contactIntro")}
                    </p>
                  </div>
                  <div className="tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-px-6">
                    <dl className="tw-grid tw-grid-cols-1 tw-gap-x-4 tw-gap-y-8 sm:tw-grid-cols-2">
                      <div className="sm:tw-col-span-1">
                        <dt className="tw-text-sm tw-font-medium tw-text-gray-500">
                          Resposiveness
                        </dt>
                        <dd className="tw-mt-1 tw-text-sm tw-text-gray-900">
                          <span className="tw-inline-flex tw-items-center tw-px-3 tw-py-0.5 tw-rounded-full tw-text-sm tw-font-medium tw-bg-yellow-100 tw-text-yellow-800">
                            Low
                          </span>
                        </dd>
                      </div>
                      <div className="sm:tw-col-span-1">
                        <dt className="tw-text-sm tw-font-medium tw-text-gray-500">
                          Email
                        </dt>
                        <dd className="tw-mt-1 tw-text-sm tw-text-gray-900">
                          <a href={"mailto:" + email}> {email}</a>
                        </dd>
                      </div>
                      <div className="sm:tw-col-span-1">
                        <dt className="tw-text-sm tw-font-medium tw-text-gray-500">
                          Phone
                        </dt>
                        <dd className="tw-mt-1 tw-text-sm tw-text-gray-900">
                          <a href={"tel:" + tel}>{tel}</a>
                        </dd>
                      </div>
                      {/* <div className="sm:tw-col-span-1">
                      <dt className="tw-text-sm tw-font-medium tw-text-gray-500">
                        Email address
                      </dt>
                      <dd className="tw-mt-1 tw-text-sm tw-text-gray-900">
                        margotfoster@example.com
                      </dd>
                    </div>
                    <div className="sm:tw-col-span-1">
                      <dt className="tw-text-sm tw-font-medium tw-text-gray-500">
                        Salary expectation
                      </dt>
                      <dd className="tw-t-1 tw-text-sm tw-text-gray-900">
                        $120,000
                      </dd>
                    </div>
                    <div className="sm:tw-col-span-2">
                      <dt className="tw-text-sm tw-font-medium tw-text-gray-500">
                        About
                      </dt>
                      <dd className="tw-mt-1 tw-text-sm tw-text-gray-900">
                        Fugiat ipsum ipsum deserunt culpa aute sint do nostrud
                        anim incididunt cillum culpa consequat. Excepteur qui
                        ipsum aliquip consequat sint. Sit id mollit nulla mollit
                        nostrud in ea officia proident. Irure nostrud pariatur
                        mollit ad adipisicing reprehenderit deserunt qui eu.
                      </dd>
                    </div> */}
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div className="tw-bg-gray-100">
          <div className="tw-max-w-7xl tw-mx-auto tw-py-6 sm:tw-px-6 lg:tw-px-8">
            <div className="tw-max-w-4xl tw-mx-auto">
              <div className="tw-bg-white tw-shadow tw-overflow-hidden sm:tw-rounded-lg">
                <div className="tw-px-4 tw-py-5 sm:tw-px-6">
                  <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                    {T.translate("opportunityInModal.contactTitle")}
                  </h3>
                  <p className="tw-mt-1 tw-max-w-3xl tw-text-sm tw-text-gray-500">
                    {T.translate("opportunityInModal.contactIntro")}
                  </p>
                </div>
                <div className="tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-px-6">
                  <dl className="tw-grid tw-grid-cols-1 tw-gap-x-4 tw-gap-y-8 sm:tw-grid-cols-2">
                    <div className="sm:tw-col-span-1">
                      <dt className="tw-text-sm tw-font-medium tw-text-gray-500">
                        Email
                      </dt>
                      <dd className="tw-mt-1 tw-text-sm tw-text-gray-900">
                        <a href={"mailto:" + email}> {email}</a>
                      </dd>
                    </div>
                    <div className="sm:tw-col-span-1">
                      <dt className="tw-text-sm tw-font-medium tw-text-gray-500">
                        Phone
                      </dt>
                      <dd className="tw-mt-1 tw-text-sm tw-text-gray-900">
                        <a href={"tel:" + tel}>{tel}</a>
                      </dd>
                    </div>
                    {/* <div className="sm:tw-col-span-1">
                      <dt className="tw-text-sm tw-font-medium tw-text-gray-500">
                        Email address
                      </dt>
                      <dd className="tw-mt-1 tw-text-sm tw-text-gray-900">
                        margotfoster@example.com
                      </dd>
                    </div>
                    <div className="sm:tw-col-span-1">
                      <dt className="tw-text-sm tw-font-medium tw-text-gray-500">
                        Salary expectation
                      </dt>
                      <dd className="tw-t-1 tw-text-sm tw-text-gray-900">
                        $120,000
                      </dd>
                    </div>
                    <div className="sm:tw-col-span-2">
                      <dt className="tw-text-sm tw-font-medium tw-text-gray-500">
                        About
                      </dt>
                      <dd className="tw-mt-1 tw-text-sm tw-text-gray-900">
                        Fugiat ipsum ipsum deserunt culpa aute sint do nostrud
                        anim incididunt cillum culpa consequat. Excepteur qui
                        ipsum aliquip consequat sint. Sit id mollit nulla mollit
                        nostrud in ea officia proident. Irure nostrud pariatur
                        mollit ad adipisicing reprehenderit deserunt qui eu.
                      </dd>
                    </div> */}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (
  { firebase: { auth }, firestore: { ordered } },
  state
) => ({
  auth,
});

export default compose(
  firebaseConnect(),
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "campaign_candidate",
      doc: props.opportunity,
      storeAs: "opp_" + props.opportunity,
    },
    {
      collection: "profiles",
      doc: props.candidate,
    },
    {
      collection: "users_infos",
      doc: props.auth.uid,
    },
  ]),
  connect((state, props) => ({
    opportunity_infos: state.firestore.data["opp_" + props.opportunity],
    candidate_infos: state.firestore.data.profiles[props.candidate],
    users_infos: state.firestore.data.profiles[props.candidate],
    auth: state.firebase.auth,
    notify: state.notify,
  }))
)(OpportunityInModal);
