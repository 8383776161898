import React, { Component } from "react";
import T from "i18n-react";
import { compose } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import UserInfos from "./UserInfos";
import UpdatePassword from "./UpdatePassword";
import Notifications from "./Notifications";
import Integration from "./Integration";
import Payment from "./Payment";
import { notifyUser } from "../../actions/notifyActions";

class Settings extends Component {
  /*   state = {
    id: "",
    email: "",
  };

  static getDerivedStateFromProps(props, state) {
    const { auth } = props;
    return { email: auth.email, id: auth.uid };
  } */

  render() {
    const { email, uid } = this.props.auth;
    return (
      <div className="main-container">
        <div className="container">
          <div className="row justify-content-center mt-5">
            <div className="col-lg-3 mb-3">
              <ul className="nav nav-tabs flex-lg-column">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="true"
                  >
                    {T.translate("settings.profile")}
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    className="nav-link"
                    id="email-integration-tab"
                    data-toggle="tab"
                    href="#email-integration"
                    role="tab"
                    aria-controls="Email integration"
                    aria-selected="false"
                  >
                    {T.translate("settings.email_integration")}
                  </a>
                </li> */}
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="password-tab"
                    data-toggle="tab"
                    href="#password"
                    role="tab"
                    aria-controls="password"
                    aria-selected="false"
                  >
                    {T.translate("settings.password")}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="notifications-tab"
                    data-toggle="tab"
                    href="#notifications"
                    role="tab"
                    aria-controls="notifications"
                    aria-selected="false"
                  >
                    {T.translate("settings.email")}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="billing-tab"
                    data-toggle="tab"
                    href="#billing"
                    role="tab"
                    aria-controls="billing"
                    aria-selected="false"
                  >
                    {T.translate("settings.billing")}
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    className="nav-link"
                    id="integrations-tab"
                    data-toggle="tab"
                    href="#integrations"
                    role="tab"
                    aria-controls="integrations"
                    aria-selected="false"
                  >
                    {T.translate("settings.integrations")}
                  </a>
                </li>*/}
              </ul>
            </div>
            <div className="col-xl-8 col-lg-9">
              <div className="card">
                <div className="">
                  <div className="tab-content">
                    <UserInfos email={email} user_id={uid} />
                    <UpdatePassword />
                    <Notifications user_id={uid} />
                    <Payment />
                    <Integration user_id={uid} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Settings.propTypes = {
  firebase: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  notify: PropTypes.object.isRequired,
  notifyUser: PropTypes.func.isRequired,
};

export default compose(
  firebaseConnect(),
  connect(
    (state, props) => ({
      auth: state.firebase.auth,
      notify: state.notify,
    }),
    { notifyUser }
  )
)(Settings);
