import React, { Component } from "react";
import PropTypes from "prop-types";
import { firebaseConnect, firestoreConnect } from "react-redux-firebase";
import T from "i18n-react";
import { compose } from "redux";
import { notifyUser } from "../actions/notifyActions";
import { connect } from "react-redux";
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-google";
import { getLanguage } from "../helpers/global";

class Signup extends Component {
  constructor(props, context) {
    super(props, context);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  state = {
    email: "",
    password: "",
  };

  componentDidMount() {
    loadReCaptcha();
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  }

  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  }

  verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!!
  }
  onSubmit = (e) => {
    e.preventDefault();

    const { firebase, firestore, notifyUser } = this.props;

    const newUser = this.state;

    var language = getLanguage();

    firebase
      .createUser({ ...newUser })
      .then(() => {
        var user = firebase.auth().currentUser;
        user.sendEmailVerification();
      })
      .then(() => {
        var user = firebase.auth().currentUser;
        //pre register default user settings
        const userSettings = {
          task: true,
          mention: true,
          project: true,
          activity: true,
          newsletter: true,
          feature: true,
          minor: true,
        };

        firestore.set(
          { collection: "users_email_settings", doc: user.uid },
          userSettings
        );
      })
      .then(() => {
        var user = firebase.auth().currentUser;
        //pre register default user settings
        const userSettings = {
          firstname: "",
          lastname: "",
          location: "",
          picture: "",
          jobtitle: "",
          bio: "",
          email: newUser.email,
          //check if user has been invited then => role = user
          role: "user",
          organisation: "",
          language: language,
        };

        firestore.set(
          { collection: "users_infos", doc: user.uid },
          userSettings
        );
      })
      .catch((e) => {
        switch (e.code) {
          case "auth/email-already-in-use":
            notifyUser(T.translate("signup.emailAlreadyExists"), "error");
            return;
          case "auth/weak-password":
            notifyUser(T.translate("settings.weakPassword"), "error");
            return;
          default:
            notifyUser(T.translate("general.error"), "error");
        }
      });
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    //return <Redirect to="/notfound" />;

    return (
      <>
        <div className="tw-min-tw-h-screen tw-bg-gray-50 tw-flex tw-flex-col tw-justify-center tw-py-12 sm:tw-px-6 lg:tw-px-8">
          <div className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-md">
            <h2 className="tw-mt-6 tw-text-center tw-text-3xl tw-font-extrabold tw-text-gray-900">
              {T.translate("signup.welcome")}{" "}
              <span aria-label="working recruiter" role="img">
                👩‍💻
              </span>
            </h2>
            <p className="tw-mb-2 tw-mt-2 tw-text-center tw-text-sm tw-text-gray-600 tw-max-w">
              {T.translate("signup.description")}
            </p>
          </div>

          <div className="tw-mt-8 sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-md">
            <div className="tw-bg-white tw-py-8 px-4 tw-shadow sm:tw-rounded-lg sm:tw-px-10">
              <form onSubmit={this.onSubmit} className="tw-space-y-6">
                <div>
                  <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                    {T.translate("signup.email")}
                  </label>
                  <div className="tw-mt-1">
                    <input
                      placeholder={T.translate("signup.email")}
                      value={this.state.email}
                      onChange={this.onChange}
                      id="email"
                      name="email"
                      type="email"
                      autocomplete="email"
                      required
                      className="tw-appearance-none tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-placeholder-gray-400 focus:tw-outline-none focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                    {T.translate("signup.password")}
                  </label>
                  <div className="tw-mt-1">
                    <input
                      id="password"
                      placeholder={T.translate("signup.password")}
                      value={this.state.password}
                      onChange={this.onChange}
                      name="password"
                      type="password"
                      autocomplete="current-password"
                      required
                      className="tw-appearance-none tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-placeholder-gray-400 focus:tw-outline-none focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="tw-w-full tw-flex tw-justify-center tw-py-2 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
                  >
                    {T.translate("signup.create_account")}
                  </button>
                  <p className="tw-mt-2 tw-text-center tw-text-sm tw-text-gray-600 tw-max-w">
                    {T.translate("signup.creation_hint")}{" "}
                    <a href="/cgu">
                      {T.translate("signup.creation_link_hint")}{" "}
                    </a>
                  </p>
                </div>
                <ReCaptcha
                  ref={(el) => {
                    this.captchaDemo = el;
                  }}
                  size="invisible"
                  render="explicit"
                  sitekey="6Lew7YsUAAAAAJAVuBHDHzIxWLYvP4EPQREKxNU5"
                  onloadCallback={this.onLoadRecaptcha}
                  verifyCallback={this.verifyCallback}
                />
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Signup.propTypes = {
  firebase: PropTypes.object.isRequired,
  notify: PropTypes.object.isRequired,
  notifyUser: PropTypes.func.isRequired,
};

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  connect(
    (state, props) => ({
      auth: state.firebase.auth,
      notify: state.notify,
    }),
    { notifyUser }
  )
)(Signup);
