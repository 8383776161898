import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import Wait from "../layout/Wait";

class ListCampaigns extends Component {
  state = {
    isValidated: false,
  };

  componentDidUpdate(nextProps) {
    if (
      this.state.isValidated === false &&
      this.props.opportunity_infos !== undefined &&
      this.props.opportunity_infos.match_has_been_humanly_validated !==
        undefined &&
      this.props.opportunity_infos.match_has_been_humanly_validated !== null &&
      this.props.opportunity_infos.match_has_been_humanly_validated === true
    ) {
      this.setState({ isValidated: true });
    }
  }

  validateMatch = (e) => {
    const { firestore } = this.props;
    const valid = {
      match_has_been_humanly_validated: true,
      match_is_valid: true,
    };
    firestore.update(
      { collection: "campaign_candidate", doc: this.props.opportunity },
      valid
    );
  };

  deleteMatch = (e) => {
    const { firestore } = this.props;
    const notValid = {
      match_has_been_humanly_validated: true,
      match_is_valid: false,
    };
    firestore.update(
      { collection: "campaign_candidate", doc: this.props.opportunity },
      notValid
    );
  };

  stripHtml(html) {
    // Create a new div element
    var temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  render() {
    if (
      !isLoaded(this.props.project_infos) ||
      !isLoaded(this.props.opportunity_infos)
    ) {
      return <Wait />;
    }
    let status = false;

    this.props.opportunity_infos.match_is_valid !== undefined &&
    this.props.opportunity_infos.match_is_valid === true
      ? (status = true)
      : (status = false);

    return (
      <React.Fragment>
        <div className="tw-bg-white tw-shadow tw-overflow-hidden sm:tw-rounded-lg tw-mb-3">
          <div className="tw-px-4 tw-py-5 sm:tw-px-6">
            <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
              <a href={`/campaign/dashboard/${this.props.project}`}>
                {this.props.project_infos.jobTitle}
              </a>
            </h3>
            <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-text-gray-500">
              {this.props.project_infos.level} / exp :{" "}
              {this.props.project_infos.experienceYears}
              {", "} {this.props.project_infos.fonction}
            </p>
          </div>
          <div className="tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-px-6">
            <dl className="tw-grid tw-grid-cols-1 tw-gap-x-4 tw-gap-y-8 sm:tw-grid-cols-2">
              <div className="sm:tw-col-span-1">
                <dt className="tw-text-sm tw-font-medium tw-text-gray-500">
                  Step
                </dt>
                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900">
                  <span className="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-md tw-text-sm tw-font-medium tw-bg-blue-100 tw-text-blue-800">
                    {this.props.opportunity_infos.step}
                  </span>
                </dd>
              </div>
              <div className="sm:tw-col-span-1">
                <dt className="tw-text-sm tw-font-medium tw-text-gray-500">
                  Valid:
                </dt>
                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900">
                  {status ? (
                    <span className="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-md tw-text-sm tw-font-medium tw-bg-green-100 tw-text-green-800">
                      Yes
                    </span>
                  ) : (
                    <span className="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-md tw-text-sm tw-font-medium tw-bg-red-100 tw-text-red-800">
                      No
                    </span>
                  )}
                </dd>
              </div>
              <div className="sm:tw-col-span-1">
                <dt className="tw-text-sm tw-font-medium tw-text-gray-500">
                  Contacted:
                </dt>
                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900">
                  {this.props.opportunity_infos.candidate_has_been_contacted ===
                  true ? (
                    <span className="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-md tw-text-sm tw-font-medium tw-bg-green-100 tw-text-green-800">
                      Yes
                    </span>
                  ) : (
                    <span className="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-md tw-text-sm tw-font-medium tw-bg-red-100 tw-text-red-800">
                      No
                    </span>
                  )}
                </dd>
              </div>
              <div className="sm:tw-col-span-1">
                <dt className="tw-text-sm tw-font-medium tw-text-gray-500">
                  Interested
                </dt>
                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900">
                  {this.props.opportunity_infos.candidate_is_interested ===
                  true ? (
                    <span className="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-md tw-text-sm tw-font-medium tw-bg-green-100 tw-text-green-800">
                      Yes
                    </span>
                  ) : (
                    <span className="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-md tw-text-sm tw-font-medium tw-bg-red-100 tw-text-red-800">
                      No
                    </span>
                  )}
                </dd>
              </div>
              <div className="sm:tw-col-span-2">
                <dt className="tw-text-sm tw-font-medium tw-text-gray-500">
                  About
                </dt>
                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900">
                  {this.stripHtml(
                    this.props.project_infos.description.substring(0, 350)
                  )}
                  {"..."}
                </dd>
              </div>
              <div className="sm:tw-col-span-2">
                <dt className="tw-text-sm tw-font-medium tw-text-gray-500">
                  Options
                </dt>
                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900">
                  <ul className="tw-border tw-border-gray-200 tw-rounded-md tw-divide-y tw-divide-gray-200">
                    <li className="tw-pl-3 tw-pr-4 tw-py-3 tw-flex tw-items-center tw-justify-between tw-text-sm">
                      <button
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to validate this match?"
                            )
                          )
                            this.validateMatch();
                        }}
                        type="button"
                        className="tw-text-white tw-w-full tw-flex tw-items-center tw-justify-center tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-md tw-text-white tw-bg-green-600 hover:tw-bg-green-700 hover:tw-text-white md:tw-py-2 md:tw-text-lg md:tw-px-10"
                      >
                        <span className="tw-text-white">Validate</span>
                      </button>
                    </li>
                    <li className="tw-pl-3 tw-pr-4 tw-py-3 tw-flex tw-items-center tw-justify-between tw-text-sm">
                      <button
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to detele this match?"
                            )
                          )
                            this.deleteMatch();
                        }}
                        type="button"
                        className="tw-text-white tw-w-full tw-flex tw-items-center tw-justify-center tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-md tw-text-white tw-bg-red-600 hover:tw-bg-red-700 hover:tw-text-white md:tw-py-2 md:tw-text-lg md:tw-px-10"
                      >
                        <span className="tw-text-white">Delete match</span>
                      </button>
                    </li>
                    <li className="tw-pl-3 tw-pr-4 tw-py-3 tw-flex tw-items-center tw-justify-between tw-text-sm">
                      <div className="tw-w-0 tw-flex-1 tw-flex tw-items-center">
                        <svg
                          className="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-gray-400"
                          x-description="Heroicon name: paper-clip"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="tw-ml-2 tw-flex-1 tw-w-0 truncate">
                          <a
                            href={
                              "https://www.hived.ai/reach/" +
                              this.props.opportunity
                            }
                            rel="noopener noreferrer"
                            target="_blank"
                            className="tw-font-medium tw-text-blue-600 hover:tw-text-blue-500"
                          >
                            Reach link
                          </a>
                        </span>
                      </div>
                    </li>
                  </ul>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect((props) => [
    {
      collection: "projects",
      doc: props.project,
      storeAs: props.project,
    },
    {
      collection: "campaign_candidate",
      doc: props.opportunity,
      storeAs: props.opportunity,
    },
  ]),
  connect((state, props) => ({
    project_infos: state.firestore.data[props.project],
    opportunity_infos: state.firestore.data[props.opportunity],
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    notify: state.notify,
  }))
)(ListCampaigns);
