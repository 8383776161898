import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
//import Create from "../organisation/Create";
import { notifyUser } from "../../actions/notifyActions";
import Wait from "../layout/Wait";
import T from "i18n-react";

class Organisation extends Component {
  render() {
    if (!isLoaded(this.props.organisation)) {
      return <Wait />;
    }

    if (
      this.props.organisation === null ||
      this.props.organisation === undefined ||
      this.props.organisation.description === null ||
      this.props.organisation.description === undefined
    )
      return "";

    return (
      <div className="tw-mt-5 tw-prose tw-prose-blue">
        <h3 className="tw-text-xl tw-mb-5 tw-text-gray-900">
          {`${T.translate("apply.about")} ${this.props.organisation.name}`}
        </h3>
        <div className="tw-leading-loose tw-font-light opportunity_reach">
          {this.props.organisation.description}
        </div>
      </div>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect((props) => [
    {
      collection: "organisations",
      doc: props.organisation,
      storeAs: "org_" + props.organisation,
    },
  ]),
  connect(
    (state, props) => ({
      auth: state.firebase.auth,
      organisation: state.firestore.data["org_" + props.organisation],
      notify: state.notify,
    }),
    { notifyUser }
  )
)(Organisation);
