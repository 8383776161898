import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { notifyUser } from "../../actions/notifyActions";
import Wait from "../layout/Wait";
/* import InlineEdit from "react-edit-inplace";
import CreateExperience from "./CreateExperience"; */
import Exp from "./Exp";
/* import CreateEducation from "./CreateEducation";
 */ import Edu from "./Edu";
/* import CreateSkill from "./CreateSkill";
 */ import Skills from "./Skills";
/* import Campaigns from "./Campaigns";
 */ import ListCampaigns from "./ListCampaigns";
import { Redirect } from "react-router-dom";
import { getProjectCandidateId } from "../../helpers/project";
/* import AddEmail from "../candidates/AddEmail";
 */ import T from "i18n-react";
import Avatar from "@material-ui/core/Avatar";
import { getColorFromString } from "../../helpers/global";
import AddEmail from "./AddEmail";

class Edit extends Component {
  constructor(props) {
    super(props);
    // Create refs
    this.name = React.createRef();
    this.date = React.createRef();
    this.size = React.createRef();
    this.city = React.createRef();
    this.country = React.createRef();
    this.address = React.createRef();
    this.phone = React.createRef();
    this.description = React.createRef();
    this.submit = React.createRef();
  }

  JobtitleChanged = (data) => {
    const { firestore } = this.props;
    const candidate_id = this.props.match.params.id_candidate;
    const jobtitle = { jobtitle: data.jobtitle };
    firestore.update({ collection: "candidates", doc: candidate_id }, jobtitle);
  };

  render() {
    if (
      !isLoaded(this.props.profiles) //||
      //!isLoaded(this.props.exp) ||
      //!isLoaded(this.props.edu) ||
      //!isLoaded(this.props.skill)
    ) {
      return <Wait />;
    }
    if (
      this.props.users_infos[this.props.auth.uid] === undefined ||
      this.props.users_infos[this.props.auth.uid].admin !== true
    ) {
      return <Redirect to="/notfound" />;
    }

    const exp_keys =
      this.props.exp === null ||
      this.props.exp === undefined ||
      this.props.exp.experience === null ||
      this.props.exp.experience === undefined
        ? []
        : Object.keys(this.props.exp.experience);
    const skill_keys =
      this.props.skill === null || this.props.skill === undefined
        ? []
        : Object.keys(this.props.skill.skills);
    const edu_keys =
      this.props.edu === null || this.props.edu === undefined
        ? []
        : Object.keys(this.props.edu.education);
    const campaigns_keys =
      this.props.profiles[this.props.match.params.id_candidate].campaign_id ===
        null ||
      this.props.profiles[this.props.match.params.id_candidate].campaign_id ===
        undefined
        ? []
        : Object.keys(
            this.props.profiles[this.props.match.params.id_candidate]
              .campaign_id
          );

    const { profiles } = this.props;

    const emails_keys =
      profiles[this.props.match.params.id_candidate] !== null &&
      profiles[this.props.match.params.id_candidate] !== undefined
        ? Object.keys(profiles[this.props.match.params.id_candidate].emails)
        : [];

    var avatarTitle = "UK";
    var name = "";
    if (
      this.props.candidate_infos !== undefined &&
      this.props.candidate_infos.firstName !== undefined &&
      this.props.candidate_infos.secondName !== undefined
    ) {
      avatarTitle =
        this.props.candidate_infos.firstName.charAt(0) +
        this.props.candidate_infos.secondName.charAt(0);
      name =
        this.props.candidate_infos.firstName +
        " " +
        this.props.candidate_infos.secondName;
    }

    return (
      <div className="tw-min-h-screen tw-bg-gray-100">
        <div className="tw-py-10">
          <div className="tw-max-w-3xl tw-mx-auto tw-px-4 sm:tw-px-6 md:tw-flex md:tw-items-center md:tw-justify-between md:tw-space-x-5 lg:tw-max-w-7xl lg:tw-px-8">
            <div className="tw-flex tw-items-center tw-space-x-5">
              <div className="tw-flex-shrink-0">
                <div className="relative">
                  <Avatar
                    className="avatar"
                    style={{
                      color: "#fff",
                      width: "72px",
                      height: "72px",
                      backgroundColor: "#" + getColorFromString(name),
                    }}
                  >
                    {avatarTitle.toUpperCase()}
                  </Avatar>
                </div>
              </div>
              <div>
                <h1 className="tw-text-2xl tw-font-bold tw-text-gray-900">
                  {profiles[this.props.match.params.id_candidate].firstName +
                    " " +
                    profiles[this.props.match.params.id_candidate].secondName}
                </h1>
                <p className="tw-text-sm tw-font-medium tw-text-gray-500">
                  {profiles.jobtitle
                    ? profiles.jobtitle
                    : profiles[this.props.match.params.id_candidate].position}
                  {" - "}
                  {profiles[this.props.match.params.id_candidate].company}
                </p>
              </div>
            </div>
            <div className="tw-mt-6 tw-flex tw-flex-col-reverse tw-justify-stretch tw-space-y-4 tw-space-y-reverse sm:tw-flex-row-reverse sm:tw-justify-end sm:tw-space-x-reverse sm:tw-space-y-0 sm:tw-space-x-3 md:tw-mt-0 md:tw-flex-row md:tw-space-x-3">
              <button
                type="button"
                className="tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-offset-gray-100 focus:tw-ring-blue-500"
              >
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={profiles[this.props.match.params.id_candidate].url}
                >
                  External Profile
                </a>
              </button>
              <button
                type="button"
                className="tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-text-sm tw-font-medium tw-rounded-md tw-shadow-sm tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-offset-gray-100 focus:tw-ring-blue-500"
              >
                Advance to offer
              </button>
            </div>
          </div>
          <div className="tw-mt-8 tw-max-w-3xl tw-mx-auto tw-grid tw-grid-cols-1 tw-gap-6 sm:tw-px-6 lg:tw-max-w-7xl lg:tw-grid-flow-col-dense lg:tw-grid-cols-3">
            <div className="tw-space-y-6 lg:tw-col-start-1 lg:tw-col-span-2">
              {emails_keys.length ? (
                <div className="tw-bg-gray-100">
                  <div className="tw-max-w-7xl tw-mx-auto">
                    <div className="tw-bg-white tw-shadow tw-overflow-hidden sm:tw-rounded-md">
                      <div className="tw-bg-white tw-px-4 tw-py-5 tw-border-b tw-border-gray-200 sm:tw-px-6">
                        <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                          Contact emails
                        </h3>
                      </div>

                      <ul className="tw-divide-y tw-divide-gray-200">
                        {emails_keys.map((id) => {
                          return (
                            <li>
                              <div className="tw-px-4 tw-py-4 sm:tw-px-6">
                                <div className="tw-flex tw-items-center tw-justify-between">
                                  {
                                    profiles[
                                      this.props.match.params.id_candidate
                                    ].emails[id].email
                                  }
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="tw-bg-gray-100">
                <div className="tw-max-w-7xl tw-mx-auto">
                  <div className="tw-bg-white tw-shadow tw-overflow-hidden sm:tw-rounded-md">
                    <div className="tw-bg-white tw-px-4 tw-py-5 tw-border-b tw-border-gray-200 sm:tw-px-6">
                      <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                        {T.translate("profile.experience")}
                      </h3>
                    </div>

                    <ul className="tw-divide-y tw-divide-gray-200">
                      {exp_keys.map((id) => {
                        return (
                          <Exp key={id} exp={this.props.exp.experience[id]} />
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="tw-bg-gray-100 tw-py-6">
                <div className="tw-max-w-7xl tw-mx-auto">
                  <div className="tw-bg-white tw-shadow tw-overflow-hidden sm:tw-rounded-md">
                    <div className="tw-bg-white tw-px-4 tw-py-5 tw-border-b tw-border-gray-200 sm:tw-px-6">
                      <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                        {T.translate("profile.education")}
                      </h3>
                    </div>

                    <ul className="tw-divide-y tw-divide-gray-200">
                      {edu_keys.map((id) => {
                        //edu={this.props.edu.education[id]}
                        return (
                          <Edu
                            key={id}
                            education={this.props.edu.education[id]}
                          />
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="tw-bg-gray-100 tw-py-6">
                <div className="tw-max-w-7xl tw-mx-auto">
                  <div className="tw-bg-white tw-shadow tw-overflow-hidden sm:tw-rounded-md">
                    <div className="tw-bg-white tw-px-4 tw-py-5 tw-border-b tw-border-gray-200 sm:tw-px-6">
                      <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                        {T.translate("profile.skills")}
                      </h3>
                    </div>

                    <ul className="tw-divide-y tw-divide-gray-200">
                      <li>
                        <div className="tw-px-4 tw-py-4 sm:tw-px-6">
                          {skill_keys.map((id) => {
                            return (
                              <Skills
                                key={id}
                                //years={this.props.skills[id].years}
                                skill={this.props.skill.skills[id]}
                              />
                            );
                          })}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:tw-col-start-3 lg:tw-col-span-1">
              {campaigns_keys.map((id) => {
                return (
                  <ListCampaigns
                    key={id}
                    profile={this.props.match.params.id_candidate}
                    project={
                      profiles[this.props.match.params.id_candidate]
                        .campaign_id[id]
                    }
                    opportunity={getProjectCandidateId(
                      profiles[this.props.match.params.id_candidate]
                        .campaign_id[id],
                      this.props.match.params.id_candidate
                    )}
                  />
                );
              })}
              <AddEmail candidate={this.props.match.params.id_candidate} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Edit.propTypes = {};

export default compose(
  firebaseConnect(),
  firestoreConnect((props) => [
    {
      collection: "profiles",
      doc: props.match.params.id_candidate,
    },
    {
      collection: "experience",
      doc: props.match.params.id_candidate,
      storeAs: "exp",
    },
    {
      collection: "education",
      doc: props.match.params.id_candidate,
      storeAs: "edu",
    },
    {
      collection: "skills",
      doc: props.match.params.id_candidate,
      storeAs: "skill",
    } /* ,
    {
      collection: "project_candidate",
      where: ["candidate", "==", props.match.params.id_candidate],
      storeAs: "ps"
    } */,
  ]),
  connect(
    (state, props) => ({
      profiles: state.firestore.data.profiles,
      //campaigns: state.firestore.data.ps,
      exp: state.firestore.data.exp,
      edu: state.firestore.data.edu,
      skill: state.firestore.data.skill,
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      notify: state.notify,
      users_infos: state.firestore.data.users_infos,
    }),
    { notifyUser }
  )
)(Edit);
