import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import ListApplications from "./ListApplications";
import { notifyCampaignOwnerNewApplication } from "../../helpers/project";

class Applications extends Component {
  constructor(props) {
    super(props);

    this.setCurrentApplication = this.setCurrentApplication.bind(this);
  }

  state = {
    currentApplication: null
  };

  setCurrentApplication(applicationId) {
    this.setState({ currentApplication: applicationId });
  }

  updateApplication() {
    const { firestore } = this.props;

    const validApplication = {
      step: "sourced"
    };

    firestore
      .update(
        {
          collection: "campaign_candidate",
          doc: this.state.currentApplication
        },
        validApplication
      )
      .then(
        notifyCampaignOwnerNewApplication(
          this.state.currentApplication,
          this.props.campaign.jobTitle,
          this.props.creator
        )
      );
  }

  render() {
    const opportunities_ids =
      this.props.interested !== null && this.props.interested !== undefined
        ? Object.keys(this.props.interested)
        : [];

    return (
      <React.Fragment>
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Infos</th>
              <th scope="col">tel</th>
              <th scope="col">options</th>
            </tr>
          </thead>
          <tbody>
            {opportunities_ids.map(opportunity_id => (
              <ListApplications
                key={opportunity_id}
                opportunity_id={opportunity_id}
                opportunity_infos={this.props.interested[opportunity_id]}
                setCurrentApplication={this.setCurrentApplication}
              />
            ))}
          </tbody>
        </table>

        <div
          className="modal fade"
          id="application-validation"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="application-validation"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Accept application</h5>
                <button
                  type="button"
                  className="close btn btn-round"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="material-icons">close</i>
                </button>
              </div>
              <div className="modal-body">
                <h4>Are you sure you want to Accept this application ?</h4>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    this.updateApplication();
                  }}
                >
                  Accept
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  firestoreConnect(),
  connect((state, props) => ({
    users: state.firestore.data.users
  }))
)(Applications);
