const FIREBASE_FUNCTION =
  "https://us-central1-swel-39e81.cloudfunctions.net/email/";

/* const FIREBASE_GMAIL_FUNCTION =
  "https://us-central1-swel-39e81.cloudfunctions.net/gmailApi/"; */

let FIREBASE_GMAIL_FUNCTION =
  "https://us-central1-swel-39e81.cloudfunctions.net/gmailApi/";
if (process.env.NODE_ENV === "development") {
  FIREBASE_GMAIL_FUNCTION =
    "http://localhost:5001/swel-39e81/us-central1/gmailApi/";
}

export async function sendEmail(from, to, subject, text, html) {
  return await fetch(FIREBASE_FUNCTION + "sdem/", {
    method: "POST",
    //mode: "no-cors",
    headers: { "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({
      from,
      to,
      subject,
      text,
      html
    })
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return JSON.parse(data.body);
    });
}

export async function sendCampaignInvite(
  from,
  to,
  subject,
  firstname,
  link,
  language
) {
  return await fetch(FIREBASE_FUNCTION + "sdin/", {
    method: "POST",
    //4mode: "no-cors",
    headers: { "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({
      from,
      to,
      subject,
      firstname,
      link,
      language
    })
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return JSON.parse(data.body);
    });
}

export function getBestEmailFromProfile(profileEmails) {
  let bestEmailAdress = { email: null, score: 0 };
  if (profileEmails !== null && profileEmails !== undefined) {
    let emails = {};

    if (Object.keys(profileEmails)) {
      emails = Object.keys(profileEmails);

      emails.map(index => {
        let email = profileEmails[index];
        if (
          email.email !== "" &&
          email.email !== null &&
          email.score > bestEmailAdress.score
        ) {
          bestEmailAdress.score = email.score;
          bestEmailAdress.email = email.email;
        }
        return null;
      });
    }
  }
  return bestEmailAdress;
}

export async function syncGmail(uid) {
  return await fetch(FIREBASE_GMAIL_FUNCTION + "mailsync/", {
    method: "POST",
    //mode: "no-cors",
    headers: { "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({ uid })
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return JSON.parse(data.body);
    });
}

export async function getLabels(uid) {
  return await fetch(FIREBASE_GMAIL_FUNCTION + "getlabels/", {
    method: "POST",
    //mode: "no-cors",
    headers: { "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({ uid })
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return JSON.parse(data.body);
    });
}

export async function getMessages(uid, pageToken = null) {
  return await fetch(FIREBASE_GMAIL_FUNCTION + "messages/", {
    method: "POST",
    //mode: "no-cors",
    headers: { "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({ uid, pagetoken: pageToken })
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return JSON.parse(data.body);
    });
}

export async function getMessage(uid, id) {
  return await fetch(FIREBASE_GMAIL_FUNCTION + "message/", {
    method: "POST",
    //mode: "no-cors",
    headers: { "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({ uid, id })
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return JSON.parse(data.body);
    });
}
