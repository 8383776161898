import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import Create from "../candidates/Create";
import ListProjects from "./ListProjects";
/* import ListCandidates from "./ListCandidates";
 */ import Wait from "../layout/Wait";
import CampaignModal from "./CampaignModal";
import algoliasearch from "algoliasearch/lite";

import {
  InstantSearch,
  SearchBox,
  /* RefinementList, */
  Configure,
  connectHits,
  Pagination,
} from "react-instantsearch-dom";

//import Create from "../organisation/Create";
import { notifyUser, setProgressBar } from "../../actions/notifyActions";

const Hits = ({ hits }) => (
  <table className="min-w-full tw-mt-3">
    <thead>
      <tr className="tw-border-t tw-border-gray-200">
        <th className="tw-w-24 tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
          <span className="lg:tw-pl-2">Name</span>
        </th>
        <th className="tw-max-w-sm tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
          Job Title
        </th>
        <th className="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
          Contacted
        </th>
        <th className="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-text-right tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
          Location
        </th>
        <th className="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-text-right tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
          Options
        </th>
      </tr>
    </thead>
    <tbody className="tw-bg-white tw-divide-y tw-divide-gray-100">
      {hits.map(
        (hit) => (
          <tr>
            <td className="tw-px-6 tw-py-3 tw-max-w-0 tw-w-full tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900">
              <div className="tw-flex tw-items-center tw-flex tw-items-center tw-space-x-3">
                <div className="">
                  <div className="tw-ext-sm tw-font-medium tw-text-gray-900">
                    <a
                      href={"/candidate/edit/" + hit.objectID}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {hit.profile.name}
                    </a>
                  </div>
                  {hit.profile.emails[0] ? (
                    <div className="tw-text-sm tw-text-gray-500">
                      {hit.profile.emails[0].email}
                    </div>
                  ) : (
                    <div className="tw-text-sm tw-text-red-500">
                      No emails address
                    </div>
                  )}
                </div>
              </div>
            </td>
            <td className="tw-max-w-sm tw-px-6 tw-py-4 tw-whitespace-nowrap truncate">
              <div className="tw-text-sm tw-text-gray-900">
                {hit.profile.position}
              </div>
              <div className="tw-text-sm tw-text-gray-500">
                {hit.profile.company}
              </div>
            </td>
            <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">
              {hit.profile.candidate_has_been_contacted ? (
                <span className="tw-px-2 tw-inline-flex tw-text-xs tw-leading-5 tw-font-semibold tw-rounded-full tw-bg-green-100 tw-text-green-800">
                  Contacted
                </span>
              ) : (
                <span className="tw-px-2 tw-inline-flex tw-text-xs tw-leading-5 tw-font-semibold tw-rounded-full tw-bg-red-100 tw-text-red-800">
                  Not contacted
                </span>
              )}
            </td>
            <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 truncate">
              {hit.profile.location}
            </td>
            <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-right tw-text-sm tw-font-medium">
              <a
                href={"/candidate/edit/" + hit.objectID}
                className="text-blue-600 hover:text-blue-900"
              >
                Edit
              </a>
            </td>
          </tr>
        )
        //console.log(hit)
      )}
    </tbody>
  </table>
);

class AdminHome extends Component {
  state = {
    idCampain: null,
    candidate_start: 0,
  };

  modalCallback(idCampain) {
    this.setState({ idCampain: idCampain });
  }

  render() {
    if (
      !isLoaded(this.props.users_infos) ||
      !isLoaded(this.props.auth) ||
      !isLoaded(this.props.projects) ||
      !isLoaded(this.props.candidates) ||
      !isLoaded(this.props.organisations)
    ) {
      return <Wait />;
    }

    if (
      this.props.users_infos[this.props.auth.uid] === undefined ||
      this.props.users_infos[this.props.auth.uid].admin !== true
    ) {
      return <Redirect to="/notfound" />;
    }

    if (
      this.props.projects === undefined ||
      this.props.candidates === undefined
    )
      return <div>go create some campaigns</div>;

    const project_ids =
      this.props.projects !== null && this.props.projects !== undefined
        ? Object.keys(this.props.projects)
        : [];

    /*     const candidates_ids =
      this.props.candidates !== null && this.props.candidates !== undefined
        ? Object.keys(this.props.candidates)
        : [];

    var candidate_number = 1; */

    const searchClient = algoliasearch(
      "WZVXTOBRTL",
      "98f5c49d2c7c867fcb4171c648375e80"
    );

    const CustomHits = connectHits(Hits);

    return (
      <React.Fragment>
        <div className="tw-mx-auto tw-p-8 tw-bg-white">
          <div className="tw-max-w-7xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8">
            <div className="tw-pb-3 tw-border-b tw-border-gray-200 tw-px-4 tw-py-4 sm:tw-flex sm:tw-items-center sm:tw-justify-between sm:tw-px-6 lg:tw-px-8">
              <div className="tw-flex-1 tw-min-w-0">
                <h1 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900 sm:tw-truncate">
                  Campaigns
                </h1>
              </div>
              <div className="tw-mt-4 tw-flex sm:tw-mt-0 sm:tw-ml-4">
                <button
                  type="button"
                  className="tw-order-1 tw-ml-3 tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-0 sm:tw-ml-0"
                >
                  Share
                </button>
                <button
                  type="button"
                  className="tw-order-0 tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-1 sm:tw-ml-3"
                >
                  Create
                </button>
              </div>
            </div>
            <table className="min-w-full">
              <thead>
                <tr className="tw-border-t tw-border-gray-200">
                  <th className="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                    <span className="lg:tw-pl-2">Project</span>
                  </th>
                  <th className="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                    ID
                  </th>
                  <th className="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                    Contract
                  </th>
                  <th className="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                    Company
                  </th>

                  <th className="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-text-right tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                    Location
                  </th>
                  <th className="tw-pr-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-text-right tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"></th>
                </tr>
              </thead>
              <tbody className="tw-bg-white tw-divide-y tw-divide-gray-100">
                {project_ids.map((project_id) =>
                  this.props.projects !== undefined &&
                  this.props.projects !== null &&
                  this.props.projects[project_id] !== undefined &&
                  this.props.projects[project_id] !== null ? (
                    <ListProjects
                      modalCallback={this.modalCallback.bind(this)}
                      key={project_id}
                      project={this.props.projects[project_id]}
                      project_id={project_id}
                      organisation={
                        this.props.projects[project_id].organisation
                      }
                    />
                  ) : (
                    ""
                  )
                )}
              </tbody>
            </table>
            <div className="tw-border-b tw-border-gray-200 tw-px-4 tw-py-4 sm:tw-flex sm:tw-items-center sm:tw-justify-between sm:tw-px-6 lg:tw-px-8">
              <div className="tw-flex-1 tw-min-w-0">
                <h1 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900 sm:tw-truncate">
                  Candidates
                </h1>
              </div>
              <div className="tw-mt-4 tw-flex sm:tw-mt-0 sm:tw-ml-4">
                <button
                  type="button"
                  className="tw-order-1 tw-ml-3 tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-0 sm:tw-ml-0"
                >
                  Share
                </button>
                <button
                  type="button"
                  className="tw-order-0 tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-order-1 sm:tw-ml-3"
                >
                  Create
                </button>
              </div>
            </div>
            <div className="tw-pt-3 tw-pb-3">
              <InstantSearch
                showMore
                indexName="prod_Candidates"
                searchClient={searchClient}
              >
                <Configure hitsPerPage={10} />
                <SearchBox
                  className="tw-block tw-w-full tw-pl-10 tw-pr-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-leading-5 tw-bg-white tw-shadow-sm tw-placeholder-gray-500 focus:tw-outline-none focus:tw-placeholder-gray-400 focus:tw-ring-1 focus:tw-ring-blue-600 focus:tw-border-blue-600 sm:tw-text-sm"
                  showLoadingIndicator
                />
                {/* <RefinementList
                    attribute="profile.campaign_id"
                    defaultRefinement={["hSnX8lAgfZCj84eQEEsh"]}
                  />
                  <RefinementList
                    attribute="skills.skills"
                    limit={5}
                    showMore
                    searchable
                  /> */}
                <CustomHits className="min-w-full" />
                <div className="tw-bg-white tw-px-4 tw-py-3 tw-flex tw-items-center tw-justify-between tw-border-t tw-border-gray-200 sm:tw-px-6">
                  <div className="tw-hidden sm:tw-flex-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between">
                    <div>Pagination</div>
                    <div className="tw-relative tw-z-0 tw-inline-flex tw-shadow-sm tw--space-x-px">
                      <Pagination />
                    </div>
                  </div>
                </div>
              </InstantSearch>
            </div>
          </div>
        </div>

        <Create />
        <CampaignModal idCampain={this.state.idCampain} />
      </React.Fragment>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect((props) => [
    {
      collection: "profiles",
      limit: 20,
    },
    {
      collection: "campaign_candidate",
      where: ["match_has_been_humanly_validated", "==", false],
      storeAs: "not_validated",
    },
    {
      collection: "projects",
      where: ["archived", "==", false],
    },
    {
      collection: "organisations",
    },
    {
      collection: "campaign_candidate",
      where: [
        ["application.interested", "==", true],
        ["step", "==", "found"],
      ],
      storeAs: "interested",
    },
  ]),
  connect(
    (state, props) => ({
      organisations: state.firestore.data.organisations,
      users_infos: state.firestore.data.users_infos,
      candidates: state.firestore.data.profiles,
      projects: state.firestore.data.projects,
      auth: state.firebase.auth,
      notify: state.notify,
    }),
    { notifyUser, setProgressBar }
  )
)(AdminHome);
