import React, { Component } from "react";
import { compose } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import LoadingScreen from "../layout/Wait";

class LoggedOutRoute extends Component {
  render() {
    const { auth, isInitializing } = this.props;

    if (!auth.isLoaded || isInitializing === true) return <LoadingScreen />;

    if (auth.isLoaded && !auth.isEmpty) {
      const redirectionPath = "/home";
      return <Redirect to={redirectionPath} />;
    } else {
      const Component = this.props.component;

      return <Component />;
    }
  }
}

export default compose(
  firebaseConnect(),
  connect((state, props) => ({
    auth: state.firebase.auth,
    isInitializing: state.firebase.isInitializing,
    notify: state.notify,
  }))
)(LoggedOutRoute);
