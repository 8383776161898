import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { notifyUser } from "../../actions/notifyActions";
import Wait from "../layout/Wait";
import Exp from "./Exp";
import Edu from "./Edu";
import Skills from "./Skills";

import T from "i18n-react";

class Edit extends Component {
  constructor(props) {
    super(props);
    // Create refs
    this.name = React.createRef();
    this.date = React.createRef();
    this.size = React.createRef();
    this.city = React.createRef();
    this.country = React.createRef();
    this.address = React.createRef();
    this.phone = React.createRef();
    this.description = React.createRef();
    this.submit = React.createRef();
  }

  JobtitleChanged = (data) => {
    const { firestore } = this.props;
    const candidate_id = this.props.id_candidate;
    const jobtitle = { jobtitle: data.jobtitle };
    firestore.update({ collection: "candidates", doc: candidate_id }, jobtitle);
  };

  render() {
    if (
      !isLoaded(this.props.exp) ||
      !isLoaded(this.props.edu) ||
      !isLoaded(this.props.skill)
    ) {
      return <Wait />;
    }

    const exp_keys =
      this.props.exp.experience === null ||
      this.props.exp.experience === undefined
        ? []
        : Object.keys(this.props.exp.experience);
    const skill_keys =
      this.props.skill.skills === null || this.props.skill.skills === undefined
        ? []
        : Object.keys(this.props.skill.skills);
    const edu_keys =
      this.props.edu.education === null ||
      this.props.edu.education === undefined
        ? []
        : Object.keys(this.props.edu.education);

    return (
      <>
        <div className="container">
            <div className="tw-bg-gray-100 tw-py-6">
              <div className="tw-max-w-7xl tw-mx-auto sm:tw-px-6 lg:tw-px-8">
                <div className="tw-bg-white tw-shadow tw-overflow-hidden sm:tw-rounded-md">
                  <div className="tw-bg-white tw-px-4 tw-py-5 tw-border-b tw-border-gray-200 sm:tw-px-6">
                    <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {T.translate("profile.experience")}
                    </h3>
                  </div>

                  <ul className="tw-divide-y tw-divide-gray-200">
                    {exp_keys.map((id) => {
                      return (
                        <Exp key={id} exp={this.props.exp.experience[id]} />
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="tw-bg-gray-100 tw-py-6">
              <div className="tw-max-w-7xl tw-mx-auto sm:tw-px-6 lg:tw-px-8">
                <div className="tw-bg-white tw-shadow tw-overflow-hidden sm:tw-rounded-md">
                  <div className="tw-bg-white tw-px-4 tw-py-5 tw-border-b tw-border-gray-200 sm:tw-px-6">
                    <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {T.translate("profile.education")}
                    </h3>
                  </div>

                  <ul className="tw-divide-y tw-divide-gray-200">
                    {edu_keys.map((id) => {
                      //edu={this.props.edu.education[id]}
                      return (
                        <Edu
                          key={id}
                          education={this.props.edu.education[id]}
                        />
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="tw-bg-gray-100 tw-py-6">
              <div className="tw-max-w-7xl tw-mx-auto sm:tw-px-6 lg:tw-px-8">
                <div className="tw-bg-white tw-shadow tw-overflow-hidden sm:tw-rounded-md">
                  <div className="tw-bg-white tw-px-4 tw-py-5 tw-border-b tw-border-gray-200 sm:tw-px-6">
                    <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {T.translate("profile.skills")}
                    </h3>
                  </div>

                  <ul className="tw-divide-y tw-divide-gray-200">
                    <li>
                      <div className="tw-px-4 tw-py-4 sm:tw-px-6">
                        {skill_keys.map((id) => {
                          return (
                            <Skills
                              key={id}
                              //years={this.props.skills[id].years}
                              skill={this.props.skill.skills[id]}
                            />
                          );
                        })}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/*  <div
              className="tab-pane fade"
              id="campaigns"
              role="tabpanel"
              aria-labelledby="campaigns-tab"
              data-filter-list="content-list-body"
            >
              <div className="row content-list-head">
                <div className="col-auto">
                  <h3>Campaigns</h3>
                  <button
                      className="btn btn-round"
                      data-toggle="modal"
                      data-target="#campaign-add-modal"
                    >
                      <i className="material-icons">add</i>
                    </button> 
                </div>
              </div>
              <div className="content-list-body row" />
            </div>*/}
      </>
    );
  }
}

Edit.propTypes = {};

export default compose(
  firebaseConnect(),
  firestoreConnect((props) => [
    {
      collection: "experience",
      doc: props.id_candidate,
      storeAs: "exp",
    },
    {
      collection: "education",
      doc: props.id_candidate,
      storeAs: "edu",
    },
    {
      collection: "skills",
      doc: props.id_candidate,
      storeAs: "skill",
    } /* ,
    {
      collection: "project_candidate",
      where: ["candidate", "==", props.match.params.id_candidate],
      storeAs: "ps"
    } */,
  ]),
  connect(
    (state, props) => ({
      //campaigns: state.firestore.data.ps,
      modal_profiles: state.firestore.data.modal_profiles,
      exp: state.firestore.data.exp,
      edu: state.firestore.data.edu,
      skill: state.firestore.data.skill,
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      notify: state.notify,
    }),
    { notifyUser }
  )
)(Edit);
