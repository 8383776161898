import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { notifyUser, setProgressBar } from "../../actions/notifyActions";
import { isLoaded } from "react-redux-firebase";
import Wait from "../layout/Wait";
import { Redirect } from "react-router-dom";

import { getCampaignPrice } from "../../helpers/payment";

class Payment extends Component {
  state = {
    users: 1,
    projects: 1,
    projects_total: getCampaignPrice(),
    users_total: 0,
    cards: null,
    customer: null,
    paid: false,
    errorMessage: ""
  };

  constructor(props) {
    super(props);
    // Create refs
    this.submit = React.createRef();
    this.firstname = React.createRef();
    this.lastname = React.createRef();
    this.email = React.createRef();
    this.city = React.createRef();
    this.address = React.createRef();
    this.apt = React.createRef();
    this.post = React.createRef();
    this.phone = React.createRef();
    this.cc = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stripe_infos !== undefined &&
      this.props.stripe_infos !== prevProps.stripe_infos
    ) {
      this.setState({
        customer:
          this.props.stripe_infos[this.props.auth.uid] !== undefined &&
          this.props.stripe_infos[this.props.auth.uid].id_customer !== undefined
            ? this.props.stripe_infos[this.props.auth.uid].id_customer
            : ""
      });
    }
  }

  handleSubmit = ev => {
    ev.preventDefault();

    this.submit.current.setAttribute("disabled", "disabled");

    this.props.history.push(`/payment/${this.props.match.params.id_campaign}`);
  };

  render() {
    if (!isLoaded(this.props.stripe_infos)) {
      return <Wait />;
    }

    const id_project = this.props.match.params.id_campaign;
    if (this.props.projects[id_project] === undefined)
      return <Redirect to={{ pathname: "/notfound" }} />;

    if (this.state.paid === true) {
      return (
        <Redirect
          to={{
            pathname: `/campaign/dashboard/${this.props.match.params.id_campaign}`
          }}
        />
      );
    }

    return (
      <React.Fragment>
        <div className="container mt-4 ">
          <div className="mb-9">
            <h2 className=" text-center ft3  ">
              Vous êtes sur le point de lancer votre campagne de recrutement:
            </h2>
            <h4 className="text-center ft4 ">
              {this.props.projects[id_project].jobTitle}
            </h4>
          </div>
          <hr className="sep border-primary" />
          <div className="row">
            <div className="col-lg-4 order-lg-2 ">
              <div className="mb-4">
                <h5 className=" ft4">Order summary</h5>
              </div>

              <div className="card shadow-sm">
                <div className="card-body p-3">
                  <div className="media align-items-center mb-3">
                    <div className="u-avatar position-relative mr-3">
                      <div
                        class="js-quantity input-group u-quantity"
                        style={{ width: "4rem" }}
                      >
                        <input
                          style={{
                            paddingRight: "1.875rem",
                            borderRadius: "0.25rem"
                          }}
                          className="form-control u-quantity__input"
                          type="text"
                          value={this.state.projects}
                        />
                        <div className="u-quantity__arrows">
                          <span className="js-plus u-quantity__arrows-inner fas fa-angle-up" />
                          <span className="js-minus u-quantity__arrows-inner fas fa-angle-down" />
                        </div>
                      </div>
                    </div>
                    <div className="media-body">
                      <h2 className="h6 mb-0">Campaign</h2>
                    </div>
                    <div className="media-body text-right">
                      <span>${this.state.projects_total}</span>
                    </div>
                  </div>

                  <div className="media align-items-center">
                    <div className="u-avatar position-relative mr-3">
                      <div
                        class="js-quantity input-group u-quantity"
                        style={{ width: "4rem" }}
                      >
                        <input
                          style={{
                            paddingRight: "1.875rem",
                            borderRadius: "0.25rem"
                          }}
                          className="form-control u-quantity__input"
                          type="text"
                          value={this.state.users}
                        />
                        <div className="u-quantity__arrows">
                          <span className="js-plus u-quantity__arrows-inner fas fa-angle-up" />
                          <span className="js-minus u-quantity__arrows-inner fas fa-angle-down" />
                        </div>
                      </div>
                    </div>
                    <div className="media-body">
                      <h2 className="h6 mb-0">User(s)</h2>
                    </div>
                    <div className="media-body text-right">
                      <span>${this.state.users_total}</span>
                    </div>
                  </div>

                  <hr className="my-4" />

                  <form className="js-validate" noValidate="novalidate">
                    <label className="sr-only" htmlFor="discountSrEmail">
                      Discount
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="discountSrEmail"
                        placeholder="Discount"
                        aria-label="Discount"
                        aria-describedby="discountEmailButton"
                      />
                      <div className="input-group-append">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          id="discountEmailButton"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </form>

                  <hr className="my-4" />

                  <div className="media align-items-center">
                    <h3 className="h6 text-secondary mr-3">Subtotal</h3>
                    <div className="media-body text-right">
                      <span>
                        ${this.state.projects_total + this.state.users_total}
                      </span>
                    </div>
                  </div>

                  <div className="media align-items-center">
                    <h3 className="h6 text-secondary mr-3">Tax</h3>
                    <div className="media-body text-right">
                      <span>
                        ${(this.state.projects_total + this.state.users) * 0.2}
                      </span>
                    </div>
                  </div>

                  <hr className="my-4" />

                  <div className="media align-items-center">
                    <h3 className="h6 text-secondary mr-3">Total</h3>
                    <div className="media-body text-right">
                      <span className="font-weight-semi-bold">
                        $
                        {this.state.projects_total +
                          this.state.users_total +
                          (this.state.projects_total + this.state.users) * 0.2}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8 order-lg-1">
              <form
                className="js-validate"
                onSubmit={this.handleSubmit}
                novalidate="novalidate"
              >
                <div className="mb-4">
                  <h5 className=" ft4">Billing address</h5>
                </div>

                <div className="row mb-9">
                  <div className="col-md-6">
                    <div className="js-form-message mb-6">
                      <label className="form-label">
                        First name
                        <span className="text-danger">*</span>
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        placeholder="Jack"
                        aria-label="Jack"
                        required=""
                        data-msg="Please enter your frist name."
                        data-error-className="u-has-error"
                        data-success-className="u-has-success"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="js-form-message mb-6">
                      <label className="form-label">
                        Last name
                        <span className="text-danger">*</span>
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        placeholder="Wayley"
                        aria-label="Wayley"
                        required=""
                        data-msg="Please enter your last name."
                        data-error-className="u-has-error"
                        data-success-className="u-has-success"
                      />
                    </div>
                  </div>

                  <div className="w-100" />

                  <div className="col-md-6">
                    <div className="js-form-message mb-6">
                      <label className="form-label">
                        Email address
                        <span className="text-danger">*</span>
                      </label>

                      <input
                        type="email"
                        className="form-control"
                        name="emailAddress"
                        placeholder="jackwayley@gmail.com"
                        aria-label="jackwayley@gmail.com"
                        required=""
                        data-msg="Please enter a valid email address."
                        data-error-className="u-has-error"
                        data-success-className="u-has-success"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="js-form-message mb-6">
                      <label className="form-label">Phone</label>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="+1 (062) 109-9222"
                        aria-label="+1 (062) 109-9222"
                        data-msg="Please enter your last name."
                        data-error-className="u-has-error"
                        data-success-className="u-has-success"
                      />
                    </div>
                  </div>

                  <div className="w-100" />

                  <div className="col-md-8">
                    <div className="js-form-message mb-6">
                      <label className="form-label">
                        Street address
                        <span className="text-danger">*</span>
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        name="streetAddress"
                        placeholder="470 Lucy Forks"
                        aria-label="470 Lucy Forks"
                        required=""
                        data-msg="Please enter a valid address."
                        data-error-className="u-has-error"
                        data-success-className="u-has-success"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="js-form-message mb-6">
                      <label className="form-label">Apt, suite, etc.</label>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="YC7B 3UT"
                        aria-label="YC7B 3UT"
                        data-msg="Please enter a valid address."
                        data-error-className="u-has-error"
                        data-success-className="u-has-success"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="js-form-message mb-6">
                      <label className="form-label">
                        City
                        <span className="text-danger">*</span>
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        name="cityAddress"
                        placeholder="Paris"
                        aria-label="Paris"
                        required=""
                        data-msg="Please enter a valid address."
                        data-error-className="u-has-error"
                        data-success-className="u-has-success"
                      />
                    </div>
                  </div>
                  <div className="w-100" />
                  <div className="col-md-6">
                    <div className="js-form-message mb-6">
                      <label className="form-label">
                        Country
                        <span className="text-danger">*</span>
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        name="cityAddress"
                        placeholder="France"
                        aria-label="France"
                        required=""
                        data-msg="Please enter a valid address."
                        data-error-className="u-has-error"
                        data-success-className="u-has-success"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="js-form-message mb-6">
                      <label className="form-label">
                        Postcode/Zip
                        <span className="text-danger">*</span>
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        name="postcode"
                        placeholder="99999"
                        aria-label="99999"
                        required=""
                        data-msg="Please enter a postcode or zip code."
                        data-error-className="u-has-error"
                        data-success-className="u-has-success"
                      />
                    </div>
                  </div>
                </div>
                <div className="row d-flex center flex-row-reverse">
                  <div className="">
                    <button
                      data-dismiss="modal"
                      className="btn btn-primary  p-2"
                      ref={this.submit}
                      style={{ minWidth: "6rem" }}
                      type="submit"
                    >
                      Next
                    </button>
                  </div>
                  <div className="col-md-8">{this.state.errorMessage}</div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ firebase: { auth }, firestore: { ordered } }) => ({
  auth
});

export default compose(
  firebaseConnect(),
  connect(mapStateToProps),
  firestoreConnect(props => [
    {
      collection: "projects",
      doc: props.match.params.id_campaign
    },
    {
      collection: "stripe",
      doc: props.auth.uid
    },
    {
      collection: "users_infos",
      doc: props.auth.uid
    }
  ]),
  connect(
    (state, props) => ({
      user_infos: state.firestore.data.users_infos,
      projects: state.firestore.data.projects,
      stripe_infos: state.firestore.data.stripe,
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      notify: state.notify
    }),
    { notifyUser, setProgressBar }
  )
)(Payment);
