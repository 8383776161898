import React, { Component } from "react";
import T from "i18n-react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isLoaded } from "react-redux-firebase";
import { notifyUser } from "../../actions/notifyActions";
import { Redirect } from "react-router-dom";
import Wait from "../layout/Wait";

class Edit extends Component {
  constructor(props) {
    super(props);
    // Create refs
    this.name = React.createRef();
    this.date = React.createRef();
    this.size = React.createRef();
    this.city = React.createRef();
    this.country = React.createRef();
    this.address = React.createRef();
    this.phone = React.createRef();
    this.description = React.createRef();
    this.zip = React.createRef();
    this.submit = React.createRef();
  }

  formHasError() {
    var hasError = false;
    if (this.name.current.value === "") {
      this.name.current.className = "form-control is-invalid";
      hasError = true;
    } else this.name.current.className = "form-control";
    if (this.date.current.value === "") {
      this.date.current.className = "form-control is-invalid";
      hasError = true;
    } else this.date.current.className = "form-control";

    if (this.size.current.value === "") {
      this.size.current.className = "form-control is-invalid";
      hasError = true;
    } else this.size.current.className = "form-control";
    if (this.city.current.value === "") {
      this.city.current.className = "form-control is-invalid";
      hasError = true;
    } else this.city.current.className = "form-control";
    if (this.address.current.value === "") {
      this.address.current.className = "form-control is-invalid";
      hasError = true;
    } else this.address.current.className = "form-control";
    if (this.phone.current.value === "") {
      this.phone.current.className = "form-control is-invalid";
      hasError = true;
    } else this.phone.current.className = "form-control";
    if (this.country.current.value === "") {
      this.country.current.className = "form-control is-invalid";
      hasError = true;
    } else this.country.current.className = "form-control";
    if (this.description.current.value === "") {
      this.description.current.className = "form-control is-invalid";
      hasError = true;
    } else this.description.current.className = "form-control";
    if (this.zip.current.value === "") {
      this.zip.current.className = "form-control is-invalid";
      hasError = true;
    } else this.zip.current.className = "form-control";
    return hasError;
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { notifyUser, firestore } = this.props;

    const hasErrors = this.formHasError();

    if (!hasErrors) {
      this.submit.current.setAttribute("disabled", "disabled");

      // Updated user
      const organisation = {
        name: this.name.current.value,
        date: this.date.current.value,
        address: this.address.current.value,
        phone: this.phone.current.value,
        size: this.size.current.value,
        city: this.city.current.value,
        zip: this.zip.current.value,
        country: this.country.current.value,
        description: this.description.current.value,
      };
      firestore
        .update(
          { collection: "organisations", doc: this.props.match.params.id_org },
          organisation
        )
        .then((infos) => {
          this.submit.current.removeAttribute("disabled");
          this.props.history.push("/home");
        });
    } else {
      notifyUser(T.translate("settings.error"), "error");
      this.submit.current.removeAttribute("disabled");
    }
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    if (
      !isLoaded(this.props.users_infos) &&
      !isLoaded(this.props.organisations)
    ) {
      return <Wait />;
    }

    if (
      this.props.users_infos === undefined ||
      this.props.organisations === undefined
    ) {
      return <Wait />;
    }

    if (
      this.props.match.params.id_org !== undefined &&
      this.props.organisations[this.props.match.params.id_org] === undefined
    ) {
      return <Redirect to={{ pathname: "/notfound" }} />;
    }

    const organisation = this.props.organisations[
      this.props.match.params.id_org
    ]
      ? this.props.organisations[this.props.match.params.id_org]
      : null;
    return (
      <>
        <div className="tw-antialiased tw-font-sans tw-bg-gray-200">
          <div className="">
            <div className="tw-bg-gray-100">
              <div className="tw-max-w-7xl tw-mx-auto tw-py-6 sm:tw-px-6 lg:tw-px-8">
                <div>
                  <div className="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6">
                    <div className="md:tw-col-span-3">
                      <div className="tw-px-4 sm:tw-px-0">
                        <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                          {T.translate("organisation.edit")}
                        </h3>
                        <p className="tw-mt-1 tw-text-sm tw-text-gray-600">
                          {T.translate("organisation.welcomeDescription")}
                        </p>
                      </div>
                    </div>
                    <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-2">
                      <form onSubmit={this.onSubmit}>
                        <div className="tw-shadow sm:tw-rounded-md sm:tw-overflow-hidden">
                          <div className="tw-px-4 tw-py-5 tw-bg-white tw-space-y-6 sm:tw-p-6">
                            <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                              <div className="tw-col-span-3 sm:tw-col-span-2">
                                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                                  {T.translate("organisation.name")}
                                </label>
                                <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                                  <input
                                    type="text"
                                    placeholder={T.translate(
                                      "organisation.name"
                                    )}
                                    name="name"
                                    className="form-control"
                                    ref={this.name}
                                    defaultValue={
                                      organisation ? organisation.name : ""
                                    }
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                              <div className="tw-col-span-3 sm:tw-col-span-2">
                                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                                  {T.translate("organisation.yearCreation")}
                                </label>
                                <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                                  <input
                                    type="date"
                                    placeholder={T.translate(
                                      "organisation.yearCreation"
                                    )}
                                    name="date"
                                    ref={this.date}
                                    className="form-control"
                                    defaultValue={
                                      organisation ? organisation.date : ""
                                    }
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                              <div className="tw-col-span-3 sm:tw-col-span-2">
                                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                                  {T.translate("organisation.size")}
                                </label>
                                <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                                  <select
                                    placeholder={T.translate(
                                      "organisation.size"
                                    )}
                                    name="size"
                                    className="form-control"
                                    ref={this.size}
                                    defaultValue={
                                      organisation ? organisation.size : ""
                                    }
                                    onChange={this.onChange}
                                  >
                                    <option>
                                      {T.translate("organisation.size")}
                                    </option>
                                    <option>1 - 15</option>
                                    <option>16 - 50</option>
                                    <option>51 - 250</option>
                                    <option>250 - 1000+</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                              <div className="tw-col-span-3 sm:tw-col-span-3">
                                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                                  {T.translate("organisation.address")}
                                </label>
                                <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                                  <input
                                    type="text"
                                    placeholder={T.translate(
                                      "organisation.address"
                                    )}
                                    name="address"
                                    defaultValue={
                                      organisation ? organisation.address : ""
                                    }
                                    className="form-control"
                                    ref={this.address}
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                              <div className="tw-col-span-3 sm:tw-col-span-1">
                                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                                  {T.translate("organisation.city")}
                                </label>
                                <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                                  <input
                                    type="text"
                                    placeholder={T.translate(
                                      "organisation.city"
                                    )}
                                    name="city"
                                    defaultValue={
                                      organisation ? organisation.city : ""
                                    }
                                    className="form-control"
                                    ref={this.city}
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                              <div className="tw-col-span-3 sm:tw-col-span-1">
                                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                                  {T.translate("organisation.zip")}
                                </label>
                                <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                                  <input
                                    type="text"
                                    placeholder={T.translate(
                                      "organisation.zip"
                                    )}
                                    name="address"
                                    defaultValue={
                                      organisation ? organisation.zip : ""
                                    }
                                    className="form-control"
                                    ref={this.zip}
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                              <div className="tw-col-span-3 sm:tw-col-span-1">
                                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                                  {T.translate("organisation.country")}
                                </label>
                                <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                                  <input
                                    type="text"
                                    placeholder={T.translate(
                                      "organisation.country"
                                    )}
                                    name="country"
                                    defaultValue={
                                      organisation ? organisation.country : ""
                                    }
                                    className="form-control"
                                    ref={this.country}
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                              <div className="tw-col-span-3 sm:tw-col-span-2">
                                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                                  {T.translate("organisation.phone")}
                                </label>
                                <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                                  <input
                                    type="phone"
                                    placeholder={T.translate(
                                      "organisation.phone"
                                    )}
                                    name="phone"
                                    defaultValue={
                                      organisation ? organisation.phone : ""
                                    }
                                    className="form-control"
                                    ref={this.phone}
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                              <div className="tw-col-span-3 sm:tw-col-span-3">
                                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                                  {T.translate("organisation.description")}
                                </label>
                                <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                                  <textarea
                                    type="text"
                                    placeholder={T.translate(
                                      "organisation.description"
                                    )}
                                    name="description"
                                    className="form-control"
                                    rows={6}
                                    ref={this.description}
                                    defaultValue={
                                      organisation
                                        ? organisation.description
                                        : ""
                                    }
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="tw-px-4 tw-py-3 tw-bg-gray-50 tw-text-right sm:tw-px-6">
                              <button
                                type="submit"
                                ref={this.submit}
                                className="tw-inline-flex tw-justify-center tw-py-2 tw-px-4 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
                              >
                                {T.translate("settings.save")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Edit.propTypes = {
  firestore: PropTypes.object.isRequired,
  notify: PropTypes.object.isRequired,
  notifyUser: PropTypes.func.isRequired,
};

export default compose(
  firebaseConnect(),
  firestoreConnect((props) => [
    {
      collection: "users_infos",
      doc: props.user_id,
    },
    {
      collection: "organisations",
      doc: props.match.params.id_org,
    },
  ]),
  connect(
    (state, props) => ({
      users_infos: state.firestore.data.users_infos,
      organisations: state.firestore.data.organisations,
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      notify: state.notify,
    }),
    { notifyUser }
  )
)(Edit);
