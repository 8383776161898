import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";

//import Create from "../organisation/Create";
import { notifyUser } from "../../actions/notifyActions";
import T from "i18n-react";

class Notvalid extends Component {
  state = {};

  render() {
    var icon = (
      <svg
        className="tw-h-6 tw-w-6 tw-text-green-600"
        x-description="Heroicon name: check"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5 13l4 4L19 7"
        ></path>
      </svg>
    );
    var title = "Payment successful";
    var desc =
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.";
    var link = "/";
    var link_txt = "Go back to dashboard";
    var iconClass = "tw-bg-green-100";

    switch (this.props.reason) {
      case "invit":
        icon = (
          <svg
            className="tw-h-6 tw-w-6 tw-text-red-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        );
        title = T.translate("access.invit_error_title");
        desc = T.translate("access.invit_error_desc");
        link = "/home";
        link_txt = "Go back to dashboard";
        iconClass = "tw-bg-red-100";
        break;
      default:
    }
    return (
      <React.Fragment>
        <div className="tw-flex tw-bg-gray-500 tw-items-end tw-justify-center tw-min-h-screen tw-pt-4 tw-px-4 tw-pb-20 tw-text-center sm:tw-block sm:tw-p-0">
          <div
            className="tw-inline-block tw-align-bottom tw-bg-white tw-rounded-lg tw-px-4 tw-pt-5 tw-pb-4 tw-text-left tw-overflow-hidden tw-shadow-xl sm:tw-my-8 sm:tw-align-middle sm:tw-max-w-sm sm:tw-w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div
                className={`tw-mx-auto tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-full ${iconClass}`}
              >
                {icon}
              </div>
              <div className="tw-mt-3 tw-text-center sm:tw-mt-5">
                <h3
                  className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900"
                  id="modal-headline"
                >
                  {title}
                </h3>
                <div className="tw-mt-2">
                  <p className="tw-text-sm tw-text-gray-500">{desc}</p>
                </div>
              </div>
            </div>
            <div className="tw-mt-5 sm:tw-mt-6">
              <a
                href={link}
                type="button"
                className="hover:tw-text-white  tw-inline-flex tw-justify-center tw-w-full tw-rounded-md tw-border tw-border-transparent tw-shadow-sm tw-px-4 tw-py-2 tw-bg-blue-600 tw-text-base tw-font-medium tw-text-white hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-text-sm"
              >
                {link_txt}
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  firebaseConnect(),
  connect(),
  firestoreConnect(),
  connect(
    (state, props) => ({
      notify: state.notify,
    }),
    { notifyUser }
  )
)(Notvalid);
