import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import T from "i18n-react";
import { TitleComponent } from "../layout/Title";
import { Papercups } from "@papercups-io/chat-widget";

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <TitleComponent title={"Hived.ai | " + T.translate("Beta.title")} />
        <div className="tw-bg-white">
          <div className="tw-max-w-7xl tw-mx-auto tw-py-16 tw-px-4 sm:tw-px-6 lg:tw-py-20 lg:tw-px-8">
            <div className="lg:tw-grid lg:tw-grid-cols-3 lg:tw-gap-8">
              <div>
                <h2 className="tw-text-3xl tw-font-extrabold tw-text-gray-900">
                  {T.translate("faq.welcome")}
                </h2>
                <p className="tw-mt-4 tw-text-lg tw-text-gray-500">
                  {T.translate("faq.description")}{" "}
                  <a
                    href="#papercups"
                    onClick={() => {
                      Papercups.open();
                    }}
                    className="tw-font-medium tw-text-blue-600 hover:tw-text-blue-900"
                  >
                    {T.translate("faq.support")}
                  </a>
                  .
                </p>
              </div>
              <div className="tw-mt-12 lg:tw-mt-0 lg:tw-col-span-2">
                <dl className="tw-space-y-12">
                  <div>
                    <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {T.translate("faq.question1")}
                    </dt>
                    <dd className="tw-mt-2 tw-text-base tw-text-gray-500">
                      {T.translate("faq.response1")}
                    </dd>
                  </div>

                  <div>
                    <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {T.translate("faq.question2")}
                    </dt>
                    <dd className="tw-mt-2 tw-text-base tw-text-gray-500">
                      {T.translate("faq.response2")}
                    </dd>
                  </div>

                  <div>
                    <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {T.translate("faq.question3")}
                    </dt>
                    <dd className="tw-mt-2 tw-text-base tw-text-gray-500">
                      {T.translate("faq.response3")}
                    </dd>
                  </div>

                  <div>
                    <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {T.translate("faq.question4")}
                    </dt>
                    <dd className="tw-mt-2 tw-text-base tw-text-gray-500">
                      {T.translate("faq.response4")}
                    </dd>
                  </div>
                  <div>
                    <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {T.translate("faq.question5")}
                    </dt>
                    <dd className="tw-mt-2 tw-text-base tw-text-gray-500">
                      {T.translate("faq.response5")}
                    </dd>
                  </div>
                  <div>
                    <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                      {T.translate("faq.question6")}
                    </dt>
                    <dd className="tw-mt-2 tw-text-base tw-text-gray-500">
                      {T.translate("faq.response6")}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default compose(firebaseConnect(), firestoreConnect(), connect())(Faq);
