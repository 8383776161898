import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { sendCampaignInvite } from "../../helpers/mail";
import T from "i18n-react";
import { isLoaded } from "react-redux-firebase";
import Wait from "../layout/Wait";
import { subscribe, getPlanId } from "../../helpers/payment";
import { invite } from "../../helpers/project";

class Invite extends Component {
  state = {
    errorMessage: "",
    newInviteSub: null,
    successMessage: "",
    open: false,
  };
  constructor(props) {
    super(props);
    // Create refs
    this.email = React.createRef();
    this.role = React.createRef();
    this.submit = React.createRef();
  }

  formHasError() {
    var hasError = false;
    if (this.email.current.value === "") {
      this.email.current.className = "form-control is-invalid";
      hasError = true;
    } else this.email.current.className = "form-control";
    if (this.role.current.value === "") {
      this.role.current.className = "form-control is-invalid";
      hasError = true;
    } else this.role.current.className = "form-control";
    return hasError;
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { firestore } = this.props;

    const hasErrors = this.formHasError();

    if (!hasErrors) {
      this.submit.current.setAttribute("disabled", "disabled");

      const user_infos = this.props.users_infos[this.props.auth.uid];
      if (
        this.props.stripe_infos[this.props.user_id].id_customer !== null &&
        this.props.stripe_infos[this.props.user_id].id_customer !== undefined
      ) {
        invite(this.props.project, this.email.current.value).then(
          (response) => {
            if (response.response !== undefined) {
              if (response.response.error === false) {
                if (response.response.alreadyInvited === false) {
                  var plan = getPlanId("invite");

                  subscribe(
                    this.props.stripe_infos[this.props.user_id].id_customer,
                    plan,
                    20,
                    1
                  ).then((rslt) => {
                    if (
                      rslt.message !== undefined &&
                      rslt.message === "Success" &&
                      rslt.subscription !== undefined &&
                      rslt.subscription.id !== undefined
                    ) {
                      this.setState(
                        { newInviteSub: rslt.subscription.id },
                        () => {
                          if (
                            this.state.newInviteSub !== null &&
                            this.state.newInviteSub !== undefined
                          ) {
                            const invite = {
                              email: this.email.current.value,
                              role: this.role.current.value,
                              project: this.props.project,
                              organisation: this.props.organisation,
                              rsvp: false,
                              subscription: this.state.newInviteSub,
                              date: new Date(),
                            };

                            firestore
                              .add({ collection: "invites" }, invite)
                              .then((infos) => {
                                var language =
                                  (navigator.languages &&
                                    navigator.languages[0]) ||
                                  navigator.language ||
                                  navigator.userLanguage;
                                if (language.length > 2) {
                                  language = language.split("-")[0];
                                  language = language.split("_")[0];
                                }

                                sendCampaignInvite(
                                  "contact@hived.ai",
                                  this.email.current.value,
                                  "You've been invited by " +
                                    user_infos.firstname,
                                  user_infos.firstname,
                                  "https://www.hived.ai/bta/tr54637",
                                  language
                                );
                                this.setState({
                                  successMessage: "Invitation sent",
                                });
                                this.submit.current.removeAttribute("disabled");
                              });
                          }
                        }
                      );
                    } else {
                      this.submit.current.removeAttribute("disabled");
                      this.setState({
                        errorMessage:
                          "Une erreure s'est produite veuillez reéssayer",
                      });
                    }
                  });
                } else {
                  this.setState({
                    successMessage: "User has already been invited",
                  });
                  this.submit.current.removeAttribute("disabled");
                }
              } else {
                this.setState({
                  errorMessage: "Une erreure s'est produite veuillez reéssayer",
                });
                this.submit.current.removeAttribute("disabled");
              }
            } else {
              this.setState({
                errorMessage: "Une erreure s'est produite veuillez reéssayer",
              });
              this.submit.current.removeAttribute("disabled");
            }
          }
        );

        /*
         */
      }
    } else {
      this.submit.current.removeAttribute("disabled");
    }
  };
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    if (!isLoaded(this.props.stripe_infos)) {
      return <Wait />;
    }
    if (
      this.props.stripe_infos[this.props.user_id] === undefined ||
      this.props.stripe_infos[this.props.user_id] === null ||
      this.props.stripe_infos[this.props.user_id].id_customer === null ||
      this.props.stripe_infos[this.props.user_id].id_customer === undefined
    )
      //user is not a customer and should not be able to invite for this campaign
      return "error";

    const roles = Object.keys(this.props.roles);

    return (
      <React.Fragment>
        <form
          onSubmit={this.onSubmit}
          className="modal fade"
          id="user-invite-modal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="user-invite-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="tw-bg-white tw-overflow-hidden tw-shadow tw-rounded-lg">
                <div className="tw-px-4 tw-py-5 sm:tw-p-6">
                  <div className="modal-body">
                    <div>
                      <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                        {T.translate("invite.colleagues")}
                      </h3>
                      <p className="tw-mt-1 tw-text-sm tw-text-gray-500">
                        Your monthly payment will increase by €80
                      </p>
                    </div>
                    <div className="tw-space-y-8 tw-divide-y tw-divide-gray-200">
                      <div className="tw-mt-6 tw-grid tw-grid-cols-1 tw-gap-y-6 tw-gap-x-4 sm:tw-grid-cols-6">
                        <div className="sm:tw-col-span-12">
                          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                            {T.translate("invite.as")}
                          </label>
                          <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                            <select
                              name="role"
                              className="form-control"
                              ref={this.role}
                              onChange={this.onChange}
                            >
                              {roles.map((role) => {
                                if (role !== "creator")
                                  return (
                                    <option value={role} key={role}>
                                      {role[0].toUpperCase() + role.slice(1)}
                                    </option>
                                  );
                                return "";
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="sm:tw-col-span-12">
                          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                            {T.translate("invite.email")}
                          </label>
                          <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                            <input
                              type="email"
                              ref={this.email}
                              className="form-control"
                              placeholder={T.translate("invite.recipient")}
                              aria-label={T.translate("invite.recipient")}
                              aria-describedby="recipient-email-address"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="tw-pt-5">
                      <div className="tw-flex tw-justify-end">
                        <button
                          type="submit"
                          ref={this.submit}
                          className="tw-ml-3 tw-inline-flex tw-justify-center tw-py-2 tw-px-4 border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
                        >
                          {T.translate("invite.invite_user")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ firebase: { auth }, firestore: { ordered } }) => ({
  auth,
});

export default compose(
  firebaseConnect(),
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "users_infos",
      doc: props.auth.uid,
    },
    {
      collection: "stripe",
      doc: props.user_id,
    },
  ]),
  connect((state, props) => ({
    users_infos: state.firestore.data.users_infos,
    stripe_infos: state.firestore.data.stripe,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    notify: state.notify,
  }))
)(Invite);
