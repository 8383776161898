import React, { Component } from "react";

import T from "i18n-react";
import Avatar from "@material-ui/core/Avatar";
import { timeSince } from "../../../helpers/project";
import { getColorFromString } from "../../../helpers/global";

const Indicators = (props) => {
  function getIndicator(indicator) {
    switch (indicator) {
      case "talk":
        return (
          <i
            data-title={T.translate("project.talk")}
            data-toggle="tooltip"
            className="fas fa-comment ico-green"
          ></i>
        );
      case "slipping":
        return (
          <i
            data-title={T.translate("project.slipping")}
            data-toggle="tooltip"
            className="fas fa-exclamation-circle ico-red"
          ></i>
        );
      case "coveted":
        return (
          <i
            data-title={T.translate("project.coveted")}
            data-toggle="tooltip"
            className="fas fa-fire ico-red"
          />
        );
      case "leader":
        return (
          <i
            data-title={T.translate("project.leader")}
            data-toggle="tooltip"
            className="fas fa-fist-raised ico-blue"
          />
        );
      case "available":
        return (
          <i
            data-title={T.translate("project.disponible")}
            data-toggle="tooltip"
            className="fas fa-stopwatch ico-green"
          />
        );
      case "rare":
        return (
          <i
            data-title={T.translate("project.rare")}
            data-toggle="tooltip"
            className="fas fa-star ico-yellow"
          />
        );
      case "turnover":
        return (
          <i
            data-title={T.translate("project.turnover")}
            data-toggle="tooltip"
            className="fas fa-exchange-alt ico-orange"
          />
        );
      default:
        return "";
    }
  }
  return getIndicator(props.indicator);
};

class Card extends Component {
  render() {
    var language =
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage;
    if (language.length > 2) {
      language = language.split("-")[0];
      language = language.split("_")[0];
    }

    const indicators_keys =
      this.props.indicators === null || this.props.indicators === undefined
        ? []
        : Object.keys(this.props.indicators);
    var background = "#ffffff";
    if (this.props.laneId === "rejected")
      background = "tw-bg-gray-100 tw-border-1 tw-border-gray-200";
    if (this.props.laneId === "hired")
      background = "tw-bg-green-50 tw-border-1 tw-border-green-200";
    /* "tw-text-white tw-bg-gradient-to-r tw-from-green-400 tw-to-blue-500 hover:tw-from-pink-500 hover:tw-to-yellow-500"; */
    return (
      <div
        className={` ${background} card card-kanban`}
        style={{ wordBreak: "break-all" }}
        tabIndex="0"
      >
        <div className="progress">
          {/* <div
          className="progress-bar bg-success"
          role="progressbar"
          style={{ width: "12%" }}
          aria-valuenow="12"
          aria-valuemin="0"
          aria-valuemax="100"
        /> */}
        </div>

        <div className="card-body">
          {/*  <div className="dropdown card-options">
          <button
            className="btn-options"
            type="button"
            id="kanban-dropdown-button-9"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            <a className="dropdown-item" href="#l">
              Edit
            </a>
            <a className="dropdown-item text-danger" href="#l">
              Archive Card
            </a>
          </div>
        </div> */}
          <div className="card-title">
            <a
              href="#l"
              onClick={() =>
                this.props.modalCallback(
                  this.props.candidate,
                  this.props.laneId,
                  this.props.id
                )
              }
              data-toggle="modal"
              data-target="#task-modal"
            >
              <div className="media ">
                {this.props.picture == null ? (
                  <img alt="..." className="avatar" src={this.props.picture} />
                ) : (
                  <Avatar
                    className="avatar "
                    style={{
                      color: "#fff",
                      backgroundColor:
                        "#" + getColorFromString(this.props.name),
                      fontSize: "small",
                      fontWeight: "bold",
                    }}
                  >
                    {this.props.initials.toUpperCase()}
                  </Avatar>
                )}
                <div className="media-body ml-1">
                  <h6 className="tw-text-blue-600">{this.props.name}</h6>
                  <p className="text-small">{this.props.jobtitle}</p>
                </div>
              </div>
            </a>
          </div>
          <hr className="hr-card" />
          <div className="card-details">
            <small>
              <i
                data-title={this.props.location}
                data-toggle="tooltip"
                className="fas fa-location-arrow tw-text-gray-400"
              />{" "}
              {this.props.location}
            </small>
          </div>
          <div className="card-details">
            <small>
              <i
                data-title={this.props.industry}
                data-toggle="tooltip"
                className="fas fa-industry tw-text-gray-400"
              />{" "}
              {this.props.industry}
            </small>
          </div>
          <div className="card-details">
            {/* <small>
              {this.props.summary !== (undefined || null)
                ? this.props.summary.substr(0, 30)
                : ""}
              ...
            </small> */}
          </div>
          <div className="card-meta d-flex justify-content-between">
            {/* <div className="d-flex align-items-center">
            <i className="material-icons">playlist_add_check</i>
            <span>1/8</span>
          </div> */}

            <div className="d-flex align-items-center">
              <span className="text-small ">
                {/* <LastActivity opportunity={this.props.id} /> */}
                {this.props.slipping === true &&
                this.props.step !== "rejected" ? (
                  <span data-toggle="modal" data-target="#myModal">
                    <i
                      data-title={T.translate("project.slipping")}
                      data-toggle="tooltip"
                      className="fas fa-exclamation-circle ico-red"
                    ></i>
                  </span>
                ) : (
                  ""
                )}
                {indicators_keys.map((id) => {
                  return (
                    <Indicators
                      key={id}
                      indicator={this.props.indicators[id]}
                    />
                  );
                })}
              </span>
            </div>
            <div className="d-flex align-items-center">
              <span
                data-toggle="tooltip"
                data-title={T.translate("project.last_activity")}
                className="text-small"
              >
                <small style={{ color: "#b9bec7" }}>
                  <i className="fas fa-heartbeat"></i>
                  {this.props.last_activity !== null
                    ? language === "fr"
                      ? T.translate("activity.ago") +
                        " " +
                        timeSince(
                          new Date(this.props.last_activity.seconds * 1000)
                        )
                      : timeSince(
                          new Date(this.props.last_activity.seconds * 1000)
                        ) +
                        " " +
                        T.translate("activity.ago")
                    : ""}
                </small>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
