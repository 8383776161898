import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { firebaseConnect, firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import T from "i18n-react";
import { notifyUser } from "../actions/notifyActions";

class Login extends Component {
  state = {
    email: "",
    password: "",
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { firebase, notifyUser } = this.props;

    const { email, password } = this.state;

    firebase
      .login({
        email,
        password,
      })
      .catch((err) => notifyUser(T.translate("login.wrong"), "error"));
  };

  googleAuth = async (e) => {
    const { firebase, firestore } = this.props;

    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
    provider.addScope("https://mail.google.com/");
    provider.addScope("https://www.googleapis.com/auth/calendar.events");
    firebase.auth().useDeviceLanguage();
    /*     provider.setCustomParameters({
      'login_hint': 'user@example.com'
    }); */

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        if (
          result.additionalUserInfo !== undefined &&
          result.user !== undefined &&
          result.additionalUserInfo.providerId === "google.com"
        ) {
          /* console.log(result); */
          if (result.credential.accessToken) {
            firestore.update(
              { collection: "users_infos", doc: result.user.uid },
              {
                google: {
                  accessToken: result.credential.accessToken,
                  refreshToken: result.user.refreshToken,
                },
              }
            );
          }
        }
      })
      .catch(function (error) {
        // Handle Errors here.
        //var errorCode = error.code;
        //var errorMessage = error.message;
        // The email of the user's account used.
        //var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // ...
      });

    /* const data = await firebase.login({
      provider: "google",
      type: "popup",
      scopes: [
        "email",
        "https://mail.google.com/",
        "https://www.googleapis.com/auth/calendar.events"
      ] // not required
    });
    if (
      data.additionalUserInfo !== undefined &&
      data.user !== undefined &&
      data.additionalUserInfo.providerId === "google.com"
    ) {
      console.log(data);
      if (data.credential.accessToken) {
        firestore.update(
          { collection: "users_infos", doc: data.user.uid },
          {
            google: {
              accessToken: data.credential.accessToken,
              refreshToken: data.user.refreshToken,
              expirationTime: data.credential.expirationTime
            }
          }
        );
      }
    } */
    /* .then(async data => {
        
        }
      })
      .catch(err => notifyUser(T.translate("login.wrong"), "error"))
      .bind(this); */
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    //return <Redirect to="/notfound" />;
    this.props.notify.message = "";
    this.props.notify.messageType = "";
    return (
      <>
        <div className="tw-min-tw-h-screen tw-bg-gray-50 tw-flex tw-flex-col tw-justify-center tw-py-12 sm:tw-px-6 lg:tw-px-8">
          <div className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-md">
            <h2 className="tw-mt-6 tw-text-center tw-text-3xl tw-font-extrabold tw-text-gray-900">
              {T.translate("login.welcome")}{" "}
            </h2>
            <p className="tw-mb-2 tw-mt-2 tw-text-center tw-text-sm tw-text-gray-600 tw-max-w">
              {T.translate("login.login_hint")}{" "}
              <a
                href="signup"
                className="tw-font-medium tw-text-blue-600 hover:tw-text-blue-500"
              >
                {T.translate("login.login_hint_link")}
              </a>
            </p>
          </div>

          <div className="tw-mt-8 sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-md">
            <div className="tw-bg-white tw-py-8 px-4 tw-shadow sm:tw-rounded-lg sm:tw-px-10">
              <form onSubmit={this.onSubmit} className="tw-space-y-6">
                <div>
                  <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                    {T.translate("signup.email")}
                  </label>
                  <div className="tw-mt-1">
                    <input
                      placeholder={T.translate("signup.email")}
                      value={this.state.email}
                      onChange={this.onChange}
                      id="email"
                      name="email"
                      type="email"
                      autocomplete="email"
                      required
                      className="tw-appearance-none tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-placeholder-gray-400 focus:tw-outline-none focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                    {T.translate("signup.password")}
                  </label>
                  <div className="tw-mt-1">
                    <input
                      id="password"
                      placeholder={T.translate("signup.password")}
                      value={this.state.password}
                      onChange={this.onChange}
                      name="password"
                      type="password"
                      autocomplete="password"
                      required
                      className="tw-appearance-none tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-placeholder-gray-400 focus:tw-outline-none focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm"
                    />
                  </div>
                </div>

                <div className="tw-flex tw-items-center tw-justify-between">
                  <div className="tw-flex tw-items-center">
                    <input
                      id="remember_me"
                      name="remember_me"
                      type="checkbox"
                      className="tw-h-4 tw-w-4 tw-text-blue-600 focus:tw-ring-blue-500 tw-border-gray-300 tw-rounded"
                    />
                    <label className="tw-mb-0 tw-ml-2 tw-block tw-text-sm tw-text-gray-900">
                      Remember me
                    </label>
                  </div>

                  <div className="tw-text-sm">
                    <a
                      href="#l"
                      className="tw-font-medium tw-text-blue-600 hover:tw-text-blue-500"
                    >
                      {T.translate("login.forgot")}
                    </a>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="tw-w-full tw-flex tw-justify-center tw-py-2 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
                  >
                    {T.translate("login.login")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Login.propTypes = {
  firebase: PropTypes.object.isRequired,
};

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  connect(
    (state, props) => ({
      notify: state.notify,
    }),
    { notifyUser }
  )
)(Login);
