const FIREBASE_CALENDAR_FUNCTION =
  "https://us-central1-swel-39e81.cloudfunctions.net/calendarApi/";

export async function getEvents(uid) {
  return await fetch(FIREBASE_CALENDAR_FUNCTION + "listevents/", {
    method: "POST",
    //mode: "no-cors",
    headers: { "Access-Control-Allow-Origin": "*" },
    body: JSON.stringify({ uid })
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return JSON.parse(data.body);
    });
}
