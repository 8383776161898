import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import T from "i18n-react";
import { TitleComponent } from "../layout/Title";
import {
  getCampaignPrice,
  getProPrice,
  getSeatPrice,
} from "../../helpers/offers";
import Slider from "rc-slider";

class Price extends Component {
  state = {
    subscriptions: 10,
    accounts: 10,
    globalCost: getCampaignPrice(),
    subscriptionsCost: getCampaignPrice(),
    accountsCost: 0,
  };

  GetAccountsPrice(numberOfAccounts) {
    return getSeatPrice(numberOfAccounts);
  }

  GetSubPrice(numberOfAccounts) {
    return getCampaignPrice(numberOfAccounts);
  }

  onSliderChange = (subscriptions) => {
    if (subscriptions === 0)
      this.setState({
        accounts: 0,
      });
    else if (subscriptions > 0 && this.state.accounts === 0)
      this.setState({
        accounts: 10,
      });
    this.setState({
      subscriptions,
    });
  };

  onAccountsChange = (accounts) => {
    if (accounts === 0)
      this.setState({
        subscriptions: 0,
      });
    else if (accounts > 0 && this.state.accounts === 0)
      this.setState({
        subscriptions: 10,
      });
    this.setState({
      accounts,
    });
  };

  onAfterChange = (accounts) => {
    if (this.state.subscriptions === 30 && this.state.accounts <= 20)
      this.setState({
        accounts: 20,
        subscriptionsCost: getProPrice(),
        accountsCost: 0,
        globalCost: getProPrice(),
      });
    else
      this.setState({
        accountsCost:
          this.state.accounts / 10 > 0
            ? (this.state.accounts / 10 - 1) *
              this.GetAccountsPrice(this.state.accounts / 10 - 1)
            : 0,
        subscriptionsCost:
          (this.state.subscriptions / 10) *
          this.GetSubPrice(this.state.subscriptions / 10),
        globalCost:
          this.state.accounts / 10 > 0
            ? (this.state.accounts / 10 - 1) *
                this.GetAccountsPrice(this.state.accounts / 10 - 1) +
              (this.state.subscriptions / 10) *
                this.GetSubPrice(this.state.subscriptions / 10)
            : 0,
      });
  };

  render() {
    return (
      <React.Fragment>
        <TitleComponent title={"Hived.ai | " + T.translate("Beta.title")} />
        <div className="tw-relative tw-bg-white">
          <div className="tw-absolute tw-inset-0" aria-hidden="true">
            <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-w-1/2 tw-bg-blue-700"></div>
          </div>
          <div className="tw-relative tw-max-w-7xl tw-mx-auto lg:tw-px-8 lg:tw-grid lg:tw-grid-cols-2">
            <div className="tw-bg-white tw-py-16 tw-px-4 sm:tw-py-24 sm:tw-px-6 lg:tw-px-0 lg:tw-pr-8">
              <div className="tw-max-w-lg tw-mx-auto lg:tw-mx-0">
                <h2 className="tw-text-base tw-font-semibold tw-tracking-wide tw-text-blue-600 tw-uppercase">
                  {T.translate("landing.full")}
                </h2>
                <p className="tw-mt-2 tw-text-2xl tw-font-extrabold tw-text-gray-900 sm:tw-text-3xl">
                  {T.translate("landing.everything")}
                </p>
                <dl className="tw-mt-12 tw-space-y-10">
                  <div className="tw-flex">
                    <div className="tw-flex-shrink-0 tw-h-12 tw-w-12 tw-flex tw-items-center tw-justify-center tw-bg-blue-500 tw-rounded-md">
                      {/* <!-- Heroicon name: calendar --> */}
                      <svg
                        className="tw-h-6 tw-w-6 tw-text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                    <div className="tw-ml-4">
                      <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                        {T.translate("landing.automation")}
                      </dt>
                      <dd className="tw-mt-2 tw-text-base tw-text-gray-500">
                        {T.translate("landing.automation_desc")}
                      </dd>
                    </div>
                  </div>
                  <div className="tw-flex">
                    <div className="tw-flex-shrink-0 tw-h-12 tw-w-12 tw-flex tw-items-center tw-justify-center tw-bg-blue-500 tw-rounded-md">
                      {/* <!-- Heroicon name: view-list --> */}
                      <svg
                        className="tw-h-6 tw-w-6 tw-text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 6h16M4 10h16M4 14h16M4 18h16"
                        />
                      </svg>
                    </div>
                    <div className="tw-ml-4">
                      <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                        {T.translate("landing.list")}
                      </dt>
                      <dd className="tw-mt-2 tw-text-base tw-text-gray-500">
                        {T.translate("landing.list_desc")}
                      </dd>
                    </div>
                  </div>

                  <div className="tw-flex">
                    <div className="tw-flex-shrink-0 tw-h-12 tw-w-12 tw-flex tw-items-center tw-justify-center tw-bg-blue-500 tw-rounded-md">
                      {/* <!-- Heroicon name: view-boards --> */}
                      <svg
                        className="tw-h-6 tw-w-6 tw-text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2"
                        />
                      </svg>
                    </div>
                    <div className="tw-ml-4">
                      <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                        {T.translate("landing.board")}
                      </dt>
                      <dd className="tw-mt-2 tw-text-base tw-text-gray-500">
                        {T.translate("landing.board_desc")}
                      </dd>
                    </div>
                  </div>

                  <div className="tw-flex">
                    <div className="tw-flex-shrink-0 tw-h-12 tw-w-12 tw-flex tw-items-center tw-justify-center tw-bg-blue-500 tw-rounded-md">
                      {/* <!-- Heroicon name: users --> */}
                      <svg
                        className="tw-h-6 tw-w-6 tw-text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                      </svg>
                    </div>
                    <div className="tw-ml-4">
                      <dt className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                        {T.translate("landing.collaborate")}
                      </dt>
                      <dd className="tw-mt-2 tw-text-base tw-text-gray-500">
                        {T.translate("landing.collaborate_desc")}
                      </dd>
                    </div>
                  </div>
                </dl>
              </div>
            </div>
            <div className="tw-bg-blue-700 tw-py-16 px-4 sm:tw-py-24 sm:tw-px-6 lg:tw-bg-none lg:tw-px-0 lg:tw-pl-8 lg:tw-flex lg:tw-items-center lg:tw-justify-end">
              <div className="tw-max-w-lg tw-mx-auto tw-w-full tw-space-y-8 lg:tw-mx-0">
                <div>
                  <h2 className="tw-sr-only">Price</h2>
                  <p className="tw-relative tw-grid tw-grid-cols-2">
                    <span className="tw-flex tw-flex-col tw-text-center">
                      <span className="tw-text-5xl tw-font-extrabold tw-text-white tw-tracking-tight">
                        {T.translate("landing.euro")}
                        {getCampaignPrice()}
                      </span>
                      <span className="tw-mt-2 tw-text-base tw-font-medium tw-text-blue-200">
                        {T.translate("landing.per_job")}
                      </span>
                      <span className="tw-sr-only">plus</span>
                    </span>
                    <span
                      className="tw-pointer-events-none tw-absolute tw-h-12 tw-w-full tw-flex tw-items-center tw-justify-center"
                      aria-hidden="true"
                    >
                      {/* <!-- Heroicon name: plus --> */}
                      <svg
                        className="tw-h-6 tw-w-6 tw-text-blue-300"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                      </svg>
                    </span>
                    <span>
                      <span className="tw-flex tw-flex-col tw-text-center">
                        <span className="tw-text-5xl tw-font-extrabold tw-text-white tw-tracking-tight">
                          {T.translate("landing.euro")}
                          {getSeatPrice()}
                        </span>
                        <span className="tw-mt-2 tw-text-base tw-font-medium tw-text-blue-200">
                          {T.translate("landing.additional_user")}
                        </span>
                      </span>
                    </span>
                  </p>
                </div>
                <ul className="tw-bg-blue-800 tw-bg-opacity-50 tw-rounded sm:tw-grid sm:tw-grid-cols-2 sm:tw-grid-rows-3 sm:tw-grid-flow-col">
                  <li className="tw-py-4 tw-px-4 tw-flex tw-items-center tw-space-x-3 tw-text-base tw-text-white">
                    {/* <!-- Heroicon name: check --> */}
                    <svg
                      className="tw-h-6 tw-w-6 tw-text-blue-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span>{T.translate("landing.high_qual")}</span>
                  </li>
                  <li className="tw-border-t tw-border-blue-400 tw-border-opacity-25 tw-py-4 tw-px-4 tw-flex tw-items-center tw-space-x-3 tw-text-base tw-text-white">
                    {/* <!-- Heroicon name: check --> */}
                    <svg
                      className="tw-h-6 tw-w-6 tw-text-blue-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span>{T.translate("landing.ai_automation")}</span>
                  </li>
                  {/*  <!-- Heroicon name: check --> */}
                  <li className="tw-border-t tw-border-blue-400 tw-border-opacity-25 tw-py-4 tw-px-4 tw-flex tw-items-center tw-space-x-3 ttw-ext-base tw-text-white">
                    <svg
                      className="tw-h-6 tw-w-6 tw-text-blue-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span>{T.translate("landing.candidate_exp")}</span>
                  </li>
                  <li className="tw-border-t tw-border-blue-400 tw-border-opacity-25 tw-py-4 tw-px-4 tw-flex tw-items-center tw-space-x-3 tw-text-base tw-text-white sm:tw-border-t-0 sm:tw-border-l">
                    {/*  <!-- Heroicon name: check --> */}
                    <svg
                      className="tw-h-6 tw-w-6 tw-text-blue-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span>{T.translate("landing.team_collab")}</span>
                  </li>
                  <li className="tw-border-t tw-border-blue-400 tw-border-opacity-25 tw-py-4 tw-px-4 tw-flex tw-items-center tw-space-x-3 tw-text-base tw-text-white sm:tw-border-l">
                    {/* <!-- Heroicon name: check --> */}
                    <svg
                      className="tw-h-6 tw-w-6 tw-text-blue-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span>{T.translate("landing.pro_support")}</span>
                  </li>
                  <li className="tw-border-t tw-border-blue-400 tw-border-opacity-25 tw-py-4 tw-px-4 tw-flex tw-items-center tw-space-x-3 tw-text-base tw-text-white sm:tw-border-l">
                    {/* <!-- Heroicon name: check --> */}
                    <svg
                      className="tw-h-6 tw-w-6 tw-text-blue-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span>{T.translate("landing.cancel_any")}</span>
                  </li>
                </ul>
                <a
                  href="/signup"
                  className="tw-bg-white tw-border tw-border-transparent tw-rounded-md tw-w-full tw-px-8 tw-py-4 tw-flex tw-items-center tw-justify-center tw-text-lg tw-leading-6 tw-font-medium tw-text-blue-600 hover:tw-bg-blue-50 md:tw-px-10"
                >
                  {T.translate("landing.start")}
                </a>
                <a
                  href="/signup"
                  className="tw-block tw-text-center tw-text-base tw-font-medium tw-text-blue-200 hover:tw-text-white"
                >
                  {T.translate("landing.try")}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className=" tw-bg-white "
          style={{
            paddingBottom: "8rem",
            paddingTop: "4.5rem",
          }}
        >
          <div className="tw-text-center tw-max-w-7xl tw-mx-auto tw-px-8 sm:tw-px-12 lg:tw-px-16 tw-flex-grow md:tw-flex-grow-0">
            <h1 className="tw-mt-2 tw-mb-8 tw-text-2xl tw-font-extrabold tw-text-gray-900 sm:tw-text-3xl">
              {T.translate("landing.estimate")}
            </h1>
            <h4 className="text-center mb-2 text-muted">
              {T.translate("landing.how_many_offers")}
            </h4>
            <div className="tw-mb-3 row">
              <div className="col-xl-6 tw-text-left">
                <h5 className="tw-text-blue-900 tw-semi-bold">
                  <span>{this.state.subscriptions / 10}</span>{" "}
                  <span className="font-weight-light">
                    {T.translate("landing.offers_count")}
                  </span>
                </h5>
              </div>
              <div className="tw-text-right col-xl-6">
                <h5 className="tw-text-blue-900 tw-semi-bold">
                  <span>{this.state.subscriptionsCost}</span>{" "}
                  <span className="font-weight-light">
                    {T.translate("landing.euros_month")}
                  </span>
                </h5>
              </div>
            </div>
            <div className="clearfix visible-xs" />
            <div
              style={{
                position: "relative",
                width: "100%",
                minHeight: "1px",
                paddingRight: "12px",
                paddingLeft: "12px",
                maxWidth: "100%",
                marginBottom: "13px",
              }}
            >
              <Slider
                step={10}
                dots
                value={this.state.subscriptions}
                onChange={this.onSliderChange}
                trackStyle={{ backgroundColor: "rgb(36,99,235)", height: 10 }}
                railStyle={{ height: 10 }}
                onAfterChange={this.onAfterChange}
                handleStyle={{
                  borderColor: "rgb(36,99,235)",
                  height: 28,
                  width: 28,
                  marginLeft: -14,
                  marginTop: -9,
                }}
              />
            </div>
            <div className="row">
              <div className="col-xl-6 tw-text-left">
                <span className="text-small">0</span>
              </div>
              <div className="tw-text-right col-xl-6">
                <span className="text-small">10</span>
              </div>
            </div>
          </div>
          <br />
          <div className="tw-max-w-7xl tw-mx-auto tw-px-8 sm:tw-px-12 lg:tw-px-16 tw-flex-grow md:tw-flex-grow-0">
            <h4 className="text-center mb-2 text-muted">
              {T.translate("landing.how_many_people")}
            </h4>
            <div className="tw-mb-3 row">
              <div className="col-xl-6">
                <h5 className="tw-text-blue-900 tw-semi-bold">
                  <span>{this.state.accounts / 10}</span>{" "}
                  <span className="font-weight-light">
                    {T.translate("landing.users")}
                  </span>
                </h5>
              </div>
              <div className="text-right col-xl-6">
                <h5 className="tw-text-blue-900 tw-semi-bold">
                  <span className="">{this.state.accountsCost}</span>{" "}
                  <span className="font-weight-light">
                    {T.translate("landing.euros_month")}
                  </span>
                </h5>
              </div>
            </div>
            <div className="clearfix visible-xs" />
            <div
              style={{
                position: "relative",
                width: "100%",
                minHeight: "1px",
                paddingRight: "12px",
                paddingLeft: "12px",
                maxWidth: "100%",
                marginBottom: "13px",
              }}
            >
              <Slider
                step={10}
                dots
                value={this.state.accounts}
                onChange={this.onAccountsChange}
                trackStyle={{ backgroundColor: "rgb(36,99,235)", height: 10 }}
                railStyle={{ height: 10 }}
                onAfterChange={this.onAfterChange}
                handleStyle={{
                  borderColor: "rgb(36,99,235)",
                  height: 28,
                  width: 28,
                  marginLeft: -14,
                  marginTop: -9,
                }}
              />
            </div>
            <div className="row">
              <div className="col-xl-6">
                <span className="text-small">0</span>
              </div>
              <div className="text-right col-xl-6">
                <span className="text-small">10</span>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div
              className=" h6 text-center"
              style={{ width: "100%", minHeight: "103px" }}
            >
              {this.state.subscriptions / 10 === 3 &&
              this.state.accounts / 10 <= 2 ? (
                <span className="tw-text-gray-500 ">Pro Subscription</span>
              ) : (
                <div>
                  <div className=" tw-pb-1">
                    <span className="tw-text-gray-500 ">
                      {this.GetSubPrice(this.state.subscriptions / 10)}{" "}
                      {T.translate("landing.euro")} x{" "}
                      {this.state.subscriptions / 10}{" "}
                      {T.translate("landing.offers_count")}
                    </span>
                  </div>

                  <span className="tw-text-gray-500 tw-pt-2">
                    + {this.GetAccountsPrice(this.state.accounts / 10 - 1)}{" "}
                    {T.translate("landing.euro")} x{" "}
                    {this.state.accounts > 0 ? this.state.accounts / 10 - 1 : 0}{" "}
                    {T.translate("landing.add_user")}
                  </span>
                </div>
              )}

              <div
                className="tw-mt-8 tw-text-2xl tw-text-blue-600"
                style={{
                  fontWeight: "700",
                  marginTop: "1rem",
                }}
              >
                <span className="tw-text-gray-600">
                  {" "}
                  {T.translate("landing.monthly_cost")}
                </span>{" "}
                {this.state.globalCost}€
              </div>
            </div>

            <div className="cmb-3 col-4-medium-and-up offset-4-medium-and-up">
              <div className="tw-place-self-center tw-rounded-md tw-shadow">
                <a
                  href="/signup"
                  className="tw-w-full tw-flex tw-items-center tw-justify-center tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 hover:tw-text-white md:tw-py-4 md:tw-text-lg md:tw-px-10"
                >
                  {T.translate("landing.header_action")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  connect((state, props) => ({
    auth: state.firebase.auth,
  }))
)(Price);
