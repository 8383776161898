import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import T from "i18n-react";
import ReactMarkdown from "react-markdown";

class Exp extends Component {
  render() {
    /*     if (!isLoaded(this.props.experience)) {
      return <Wait />;
    } */

    let date = "";
    if (
      this.props.exp.dateRange.dateEnd !== undefined &&
      this.props.exp.dateRange.dateEnd !== null &&
      this.props.exp.dateRange.dateStart !== undefined &&
      this.props.exp.dateRange.dateStart !== null
    ) {
      date =
        T.translate("profile.from") +
        " " +
        this.props.exp.dateRange.dateStart +
        " " +
        T.translate("profile.to") +
        " " +
        this.props.exp.dateRange.dateEnd;
    } else {
      if (
        this.props.exp.dateRange.dateStart !== undefined &&
        this.props.exp.dateRange.dateStart !== null
      )
        date =
          T.translate("profile.since") +
          " " +
          this.props.exp.dateRange.dateStart;
      else if (
        this.props.exp.dateRange.datedateEndStart !== undefined &&
        this.props.exp.dateRange.datedateEndStart !== null
      )
        date =
          T.translate("profile.left") + " " + this.props.exp.dateRange.dateEnd;
    }
    return (
      <>
        <li>
          <div className="tw-px-4 tw-py-4 sm:tw-px-6">
            <div className="tw-flex tw-items-center tw-justify-between">
              <p className="tw-font-medium tw-truncate">
                {this.props.exp.position} - {this.props.exp.organization}
              </p>
              <div className="tw-ml-2 tw-flex-shrink-0 tw-flex">
                <p className="tw-px-2 tw-inline-flex tw-text-xs tw-leading-5 tw-font-semibold tw-rounded-full tw-bg-green-100tw- text-green-800">
                  {date}
                </p>
              </div>
            </div>
            <div className="tw-text-sm tw-leading-relaxed tw-mt-2 sm:tw-flex sm:tw-justify-between">
              <p>
                <ReactMarkdown>{this.props.exp.description}</ReactMarkdown>
              </p>
            </div>
            {/* <div className="tw-mt-2 sm:tw-flex sm:tw-justify-between">
                <div className="sm:tw-flex">
                  <p className="tw-flex tw-items-center tw-text-sm tw-text-gray-500">
                    <svg
                      className="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-400"
                      x-description="Heroicon name: users"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
                    </svg>
                    Engineering
                  </p>
                  <p className="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500 sm:tw-mt-0 sm:tw-ml-6">
                    <svg
                      className="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-400"
                      x-description="Heroicon name: location-marker"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Remote
                  </p>
                </div>
                <div className="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500 sm:tw-mt-0">
                  <svg
                    className="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-400"
                    x-description="Heroicon name: calendar"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <p>
                    Closing on
                    <time datetime="2020-01-07">January 7, 2020</time>
                  </p>
                </div>
              </div> */}
          </div>
        </li>
        {/*     <div className="row mb-3">
        <div className="col-12">
          <h5
            data-filter-by="text"
            style={{ lineHeight: "1.5" }}
            className="mb-0 H5-filter-by-text"
          >
            {this.props.exp.position}
          </h5>
        </div>
        <div className="col-12">
          <h6 className="mb-0" style={{ lineHeight: "1.5" }}>
            {this.props.exp.organization}
          </h6>
        </div>
        <div className="col-12 text-small mb-1">{date}</div>
        <div
          className="col-12  text-small"
          style={{ color: "rgb(53, 53, 53)" }}
        >
          {this.props.exp.description}
        </div> */}
      </>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  /*     props => [
    {
      collection: "experiences",
      doc: props.exp,
      storeAs: props.exp 
    }
  ] */
  connect((state, props) => ({
    /* experience: state.firestore.data[props.exp], */
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    notify: state.notify,
  }))
)(Exp);
