import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import T from "i18n-react";
import ReactModal from "react-modal";
import {
  getCompanyDissmissalReasons,
  getCandidateDissmissalReasons
} from "../../../helpers/project";
import ReactTooltip from "react-tooltip";

class Dissmissal extends Component {
  constructor(props) {
    super(props);
    // Create refs
    this.who = React.createRef();
    this.reason = React.createRef();
    this.email = React.createRef();
    this.submit = React.createRef();
  }
  state = {
    dissmissed: this.props.dissmissed,
    who: null,
    reason: null
  };

  componentDidUpdate(nextProps) {
    if (
      nextProps.dissmissed !== this.state.dissmissed ||
      this.props.candidate !== nextProps.candidate
    ) {
      this.setState({ dissmissed: nextProps.dissmissed });
    }
  }

  formHasError() {
    var hasError = false;
    if (this.who.current.value === "") {
      this.who.current.className = "form-control col-6 is-invalid";
      hasError = true;
    } else this.who.current.className = "form-control col-6";
    if (this.reason.current != null) {
      if (this.reason.current.value === "") {
        this.reason.current.className = "form-control col-6 is-invalid";
        hasError = true;
      } else this.reason.current.className = "form-control  col-6";
    }
    return hasError;
  }

  onSubmit = e => {
    e.preventDefault();

    const { firestore } = this.props;

    const hasErrors = this.formHasError();

    if (!hasErrors) {
      this.submit.current.setAttribute("disabled", "disabled");
      const application_update = {
        final_decision: {
          status: "dissmissed",
          who: this.who.current.value,
          reason: this.reason.current != null ? this.reason.current.value : "",
          date: new Date(),
          email_candidate: this.email.current.checked
        }
      };

      firestore
        .update(
          { collection: "campaign_candidate", doc: this.props.application },
          application_update
        )
        .then(() => {
          this.props.done(false);
          this.setState({ dissmissed: false });
        });
    } else {
      this.submit.current.removeAttribute("disabled");
    }
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    if (
      !isLoaded(this.props.cdiss) &&
      !isLoaded(this.props.application_infos)
    ) {
      return "";
    }

    const companyReasons = getCompanyDissmissalReasons();
    const candidateReasons = getCandidateDissmissalReasons();

    let who = "";
    let reason = "";
    let email_candidate = "";

    if (
      this.props.application_infos !== undefined &&
      this.props.application_infos.final_decision !== undefined &&
      this.props.application_infos.final_decision.status !== undefined &&
      this.props.application_infos.final_decision.status === "dissmissed"
    ) {
      email_candidate =
        this.props.application_infos.final_decision.email_candidate !==
        undefined
          ? this.props.application_infos.final_decision.email_candidate
          : "";
      reason =
        this.props.application_infos.final_decision.reason !== undefined
          ? this.props.application_infos.final_decision.reason
          : "";
      who =
        this.props.application_infos.final_decision.who !== undefined
          ? this.props.application_infos.final_decision.who
          : "";
    }

    let firstname =
      this.props.cdiss !== undefined &&
      this.props.cdiss.firstName !== undefined &&
      this.props.cdiss.firstName !== null
        ? this.props.cdiss.firstName
        : "";
    return (
      <ReactModal
        ariaHideApp={false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)"
          },
          overlay: {
            backgroundColor: "rgba(0,0,0,0.6)"
          }
        }}
        isOpen={this.state.dissmissed}
        /*    onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles} */
        contentLabel="Example Modal"
      >
        <div className="content-list col-12" style={{ maxWidth: "700px" }}>
          {/* <h3>
            {this.props.cdiss.firstName + " " + this.props.cdiss.secondName}
          </h3> */}
          {/* <div style={{ textAlign: "center" }} className="col-12">
            <h4 className="mb-3">It's ok! we're on it.</h4>
            <img
              alt="Google"
              src="/theme/img/components/worries.svg"
              style={{ maxHeight: "250px" }}
            />
          </div> */}
          <h5 className="mt-3">{T.translate("dissmissed.title")}</h5>
          <h5 className="mt-3">
            {T.translate("dissmissed.desc")} {firstname}.
          </h5>
          <div className="mt-3">
            <form onSubmit={this.onSubmit} className="col-12">
              <div className="form-group row align-items-center mt-1">
                <label className="col-5">{T.translate("dissmissed.who")}</label>
                <select
                  placeholder="Who?"
                  name="who"
                  className="form-control col-6"
                  onChange={this.onChange}
                  ref={this.who}
                  defaultValue={who}
                >
                  <option value="">------------</option>
                  <option value="company">
                    {T.translate("dissmissed.company")}
                  </option>
                  <option value="candidate">
                    {T.translate("dissmissed.candidate")}
                  </option>
                </select>
              </div>
              {this.state.who === "company" ||
              this.state.who === "candidate" ? (
                <div className="form-group row align-items-center mt-1">
                  <label className="col-5">
                    {T.translate("dissmissed.reason")}
                  </label>
                  <select
                    placeholder="Reason?"
                    name="reason"
                    className="form-control col-6"
                    onChange={this.onChange}
                    ref={this.reason}
                    defaultValue={reason}
                  >
                    {this.state.who === "company" ? (
                      <React.Fragment>
                        <option value="">------------</option>
                        {companyReasons.map((reason, i) => {
                          return (
                            <option key={i} value={reason.id}>
                              {reason.text}
                            </option>
                          );
                        })}
                      </React.Fragment>
                    ) : this.state.who === "candidate" ? (
                      <React.Fragment>
                        <option value="">------------</option>
                        {candidateReasons.map((reason, i) => {
                          return (
                            <option key={i} value={reason.id}>
                              {reason.text}
                            </option>
                          );
                        })}
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </select>
                </div>
              ) : (
                ""
              )}
              <div className="form-group row align-items-center mt-1">
                <label className="col-5">
                  {T.translate("dissmissed.email")}{" "}
                  <i
                    data-tip={T.translate("dissmissed.email_exp")}
                    className="fas fa-question-circle "
                  />
                  <ReactTooltip place="right" type="light" effect="solid" />
                </label>
                <div className="custom-control custom-checkbox custom-checkbox-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="notify-2"
                    name="email"
                    //defaultChecked={users_email_settings.mention}
                    ref={this.email}
                    onChange={this.onChange}
                    defaultChecked={email_candidate}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="notify-2"
                  ></label>
                </div>
              </div>

              <div className="d-flex justify-content-between mt-5">
                <div></div>
                <button
                  ref={this.submit}
                  disabled={
                    this.state.who !== null && this.state.reason !== null
                      ? false
                      : "disabled"
                  }
                  className="btn btn-primary"
                  type="submit"
                >
                  {T.translate("dissmissed.save")}
                </button>
                {/* <a
                  href="#"
                  className="btn btn-error"
                  onClick={() => {
                    this.props.cancelDrag(true);
                    this.props.done(false);
                  }}
                >
                  Cancel
                </a> */}
              </div>
            </form>
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(props => [
    {
      collection: "profiles",
      doc:
        props.candidate !== null &&
        props.candidate.profile !== undefined &&
        props.candidate.profile !== null &&
        props.candidate.profile !== ""
          ? props.candidate.profile
          : "",
      storeAs: "cdiss"
    },
    {
      collection: "campaign_candidate",
      doc:
        props.application !== undefined && props.application !== null
          ? props.application
          : "",
      storeAs: "app_" + props.application
    }
  ]),
  connect((state, props) => ({
    cdiss: state.firestore.data.cdiss,
    application_infos: state.firestore.data["app_" + props.application],
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    notify: state.notify
  }))
)(Dissmissal);
