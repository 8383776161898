import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";

//import Create from "../organisation/Create";
import { notifyUser, setProgressBar } from "../../actions/notifyActions";
import { isLoaded } from "react-redux-firebase";
import Wait from "../layout/Wait";

import { Elements, StripeProvider, CardElement } from "react-stripe-elements";
import { injectStripe } from "react-stripe-elements";
import { Redirect } from "react-router-dom";
import T from "i18n-react";

import {
  storeProjectSubscription,
  getCards,
  multiSubscribe,
  createCustomerStripe,
  getCampaignPrice,
  getPlanId,
  addCard,
} from "../../helpers/payment";

class _CardForm extends React.Component {
  state = {
    customer: this.props.customer,
    errorMessage: "",
  };
  constructor(props) {
    super(props);
    this.submit = React.createRef();
  }

  subscribe = (customer, nb_users, nb_projects) => {
    var items = [];
    if (nb_projects > 0)
      items.push({
        plan: getPlanId("hived_as_you_go"),
        quantity: nb_projects,
      });
    if (nb_users > 0)
      items.push({
        plan: getPlanId("hived_premium"),
        quantity: nb_users,
      });
    multiSubscribe(customer, items, 20).then((res) => {
      if (res.error !== undefined && res.error !== null)
        //res.error
        this.setState({
          errorMessage: T.translate("payment.error"),
        });
      else {
        this.props.paid(res.subscription.id);
      }
    });
  };

  handleSubmit = (ev) => {
    ev.preventDefault();

    this.submit.current.setAttribute("disabled", "disabled");

    this.props.stripe
      .createToken({
        name:
          this.props.user_infos.firstname +
          " " +
          this.props.user_infos.lastname,
      })
      .then(({ token, error }) => {
        if (token !== undefined) {
          if (this.state.customer === null) {
            createCustomerStripe(
              this.props.auth.uid,
              this.props.auth.email,
              token,
              this.props.user_infos,
              this.props.firestore
            ).then((customer) => {
              this.setState({ customer });
              addCard(token, customer).then(() => {
                this.subscribe(
                  this.state.customer,
                  this.props.users,
                  this.props.projects
                );
              });
            });
          } else {
            //this.submit.current.removeAttribute("disabled");
            addCard(token, this.props.customer).then(() => {
              this.subscribe(
                this.state.customer,
                this.props.users,
                this.props.projects
              );
            });
            //this.submit.current.setAttribute("disabled", "disabled");
          }
        } else {
          if (error.code !== undefined && error.code !== "incomplete_number")
            this.setState({ errorMessage: T.translate("payment.error") });
          this.submit.current.removeAttribute("disabled");
        }
      });
  };
  render() {
    return (
      <form
        className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-md"
        onSubmit={this.handleSubmit}
      >
        <div className="card">
          <CardElement />
        </div>
        <div className="tw-mt-2 d-flex flex-row-reverse">
          <div className="tw-mt-2 col-md-12">
            <button
              className="hover:tw-text-white tw-inline-flex tw-justify-center tw-w-full tw-rounded-md tw-border tw-border-transparent tw-shadow-sm tw-px-4 tw-py-2 tw-bg-blue-600 tw-text-base tw-font-medium tw-text-white hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-text-sm"
              style={{ minWidth: "6rem" }}
              ref={this.submit}
              type="submit"
            >
              {T.translate("payment.subscribe")}
            </button>
            <div
              className="col-md-12 center mt-2 text-center"
              style={{ color: "red" }}
            >
              {this.state.errorMessage}
            </div>
            <div className="mt-2 mb-2">
              <small>{T.translate("payment.disclaimer")}</small>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const CardForm = injectStripe(_CardForm);

class Payment extends Component {
  state = {
    users: 1,
    projects: 1,
    projects_total: getCampaignPrice(),
    users_total: 0,
    cards: null,
    customer: null,
    paid: false,
    errorMessage: "",
    isLoading: true,
  };

  constructor(props) {
    super(props);
    // Create refs
    this.submit = React.createRef();
    this.firstname = React.createRef();
    this.lastname = React.createRef();
    this.email = React.createRef();
    this.location = React.createRef();
    this.cc = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stripe_infos !== undefined &&
      this.props.stripe_infos !== prevProps.stripe_infos
    ) {
      this.setState({
        customer:
          this.props.stripe_infos[this.props.auth.uid] !== undefined &&
          this.props.stripe_infos[this.props.auth.uid].id_customer !== undefined
            ? this.props.stripe_infos[this.props.auth.uid].id_customer
            : "",
      });
      this.getCardsFromStripe();
    }
    if (this.state.paid === true) this.props.hasPaidSubsription(true);
  }

  changeToPaid(subscription) {
    storeProjectSubscription(
      this.props.firestore,
      this.props.id_campaign,
      subscription
    ).then((infos) => {
      this.setState({ paid: true });
    });
  }

  getCardsFromStripe() {
    try {
      if (
        this.props.stripe_infos !== undefined &&
        this.props.stripe_infos[this.props.auth.uid] !== undefined
      ) {
        getCards(this.props.stripe_infos[this.props.auth.uid].id_customer).then(
          (cards) => {
            this.setState({
              cards,
              isLoading: false,
            });
          }
        );
      } else {
        this.setState({
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  }

  subscribe = (e) => {
    //e.preventDefault();
    this.submit.current.setAttribute("disabled", "disabled");
    var items = [];
    /*     if (this.state.projects > 0)
     */ items.push({
      plan: getPlanId("hived_as_you_go"),
      quantity: this.state.projects,
    });
    /*     if (this.state.users - 1 > 0)
      items.push({
        plan: getPlanId("hived_premium"),
        quantity: this.state.users
      }); */
    return multiSubscribe(this.state.customer, items, 20).then((res) => {
      if (
        res.message !== undefined &&
        res.message === "Success" &&
        res.subscription !== undefined &&
        res.subscription.id !== undefined
      ) {
        storeProjectSubscription(
          this.props.firestore,
          this.props.id_campaign,
          res.subscription.id
        ).then((infos) => {
          this.setState({ paid: true });
        });
      } else {
        this.submit.current.removeAttribute("disabled");
        if (res.error !== undefined && res.error === null)
          this.setState({ errorMessage: res.error });
        else
          this.setState({
            errorMessage: T.translate("payment.error"),
          });
        this.submit.current.classList.toggle("MyClass");
      }
    });
  };

  render() {
    if (
      !isLoaded(this.props.stripe_infos) ||
      !isLoaded(this.props.campaign_user_exists) ||
      !isLoaded(this.props.projects)
    ) {
      return <Wait />;
    }

    if (this.props.campaign_user_exists === null)
      return <Redirect to="/notfound" />;

    if (this.state.isLoading === true) return <Wait />;

    const id_project = this.props.id_campaign;
    if (this.props.projects[id_project] === undefined)
      return <Redirect to={{ pathname: "/notfound" }} />;

    if (
      this.state.paid === true ||
      (this.props.projects[id_project].stripe_subscription !== undefined &&
        this.props.projects[id_project].stripe_subscription !== null)
    ) {
      return (
        <Redirect
          to={{
            pathname: `/campaign/dashboard/${this.props.id_campaign}`,
          }}
        />
      );
    }

    const user_infos = this.props.user_infos[this.props.auth.uid];

    var sak = "pk_live_y5E5k85olFygjRp0GWq9Pht1";
    //if (process.env.NODE_ENV === "development")
    //var sak = "pk_test_hzJN5lZ8zc6WdkZygtqVRCJw";

    return (
      <React.Fragment>
        <div className="tw-bg-gray-100">
          <div className="tw-max-w-7xl tw-mx-auto tw-py-12 sm:tw-px-6 lg:tw-px-8">
            <div className="tw-max-w-4xl tw-mx-auto">
              <div className="tw-bg-white tw-shadow tw-overflow-hidden sm:tw-rounded-lg">
                <div className="tw-px-4 tw-py-5 tw-sm:px-6">
                  <h3 className="tw-m-2 tw-text-center tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-600">
                    {T.translate("payment.title")}
                  </h3>
                  <p className="tw-text-center tw-mt-1 tw-text-4xl tw-text-sm tw-text-blue-800">
                    {this.props.projects[id_project].jobTitle}
                  </p>
                </div>
                <div className="tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-p-0 justify-center">
                  <div
                    id="creditCard"
                    ref={this.cc}
                    data-target-group="paymentMethods"
                    className="tw-container tw-mx-auto tw-m-8 tw-p-4 "
                  >
                    <div className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-md tw-mb-4">
                      <div className="tw-text-center">
                        <div>
                          <span className="tw-text-3xl">
                            {T.translate("payment.product")}
                          </span>{" "}
                          <small>{T.translate("payment.price")}</small>
                        </div>
                        <div className="tw-mt-4">
                          <h6 className="">{T.translate("payment.method")}</h6>
                        </div>
                      </div>
                    </div>
                    {this.state.cards !== null &&
                    this.state.cards.cards !== undefined &&
                    this.state.cards.cards.data !== undefined &&
                    this.state.cards.cards.data[0] !== undefined ? (
                      <div className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-md">
                        <div className="card ">
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-auto">
                                <img
                                  alt="visa"
                                  src="/theme/img/logo-payment-visa.svg"
                                  className="rounded-0"
                                />
                              </div>
                              <div className="col d-flex align-items-center">
                                <span>
                                  •••• •••• ••••{" "}
                                  {this.state.cards.cards.data[0].last4}
                                </span>
                                <small className="ml-2">
                                  Exp:{" "}
                                  {this.state.cards.cards.data[0].exp_month +
                                    "/" +
                                    this.state.cards.cards.data[0].exp_year}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tw-mt-4 d-flex flex-row-reverse">
                          <div className="col-md-12">
                            <button
                              className="hover:tw-text-white tw-inline-flex tw-justify-center tw-w-full tw-rounded-md tw-border tw-border-transparent tw-shadow-sm tw-px-4 tw-py-2 tw-bg-blue-600 tw-text-base tw-font-medium tw-text-white hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 sm:tw-text-sm"
                              style={{ minWidth: "6rem" }}
                              ref={this.submit}
                              onClick={() => this.subscribe()}
                            >
                              {T.translate("payment.subscribe")}
                            </button>
                            <div
                              className="col-md-12 text-center mt-2 "
                              style={{ color: "red" }}
                            >
                              {this.state.errorMessage}
                            </div>
                          </div>
                        </div>
                        <div className="mb-2">
                          <small>{T.translate("payment.disclaimer")}</small>
                        </div>
                      </div>
                    ) : (
                      <StripeProvider apiKey={sak}>
                        <React.Fragment>
                          <Elements>
                            <CardForm
                              customer={
                                this.props.stripe_infos[this.props.auth.uid] !==
                                undefined
                                  ? this.props.stripe_infos[this.props.auth.uid]
                                      .id_customer
                                  : null
                              }
                              user_infos={user_infos}
                              auth={this.props.auth}
                              firestore={this.props.firestore}
                              projects={this.state.projects}
                              users={this.state.users - 1}
                              paid={this.changeToPaid.bind(this)} // default for now to change
                            />
                          </Elements>
                        </React.Fragment>
                      </StripeProvider>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ firebase: { auth }, firestore: { ordered } }) => ({
  auth,
});

export default compose(
  firebaseConnect(),
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "projects",
      doc: props.id_campaign,
    },
    {
      collection: "users_projects",
      where: [
        ["project", "==", props.id_campaign],
        ["user_id", "==", props.auth.uid],
      ],
      storeAs: props.id_campaign + "_user_projects",
    },
    {
      collection: "stripe",
      doc: props.auth.uid,
    },
    {
      collection: "users_infos",
      doc: props.auth.uid,
    },
  ]),
  connect(
    (state, props) => ({
      user_infos: state.firestore.data.users_infos,
      campaign_user_exists:
        state.firestore.data[props.id_campaign + "_user_projects"],
      projects: state.firestore.data.projects,
      stripe_infos: state.firestore.data.stripe,
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      notify: state.notify,
    }),
    { notifyUser, setProgressBar }
  )
)(Payment);
