import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import T from "i18n-react";
import { timeSince } from "../../helpers/project";
import { getColorFromString } from "../../helpers/global";
import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";

class Notification extends Component {
  updateNotificationSeen() {
    const { firestore } = this.props;
    firestore
      .collection("users")
      .doc(this.props.auth.uid)
      .collection("notifications")
      .doc(this.props.id)
      .update({
        seen: true,
      });
  }

  render() {
    if (
      !isLoaded(this.props.n_candidate) ||
      !isLoaded(this.props.campaigns) ||
      !isLoaded(this.props.user_infos)
    ) {
      return "";
    }

    var campaign;
    //check if it's single campaing in array
    if (this.props.campaigns) {
      if (this.props.campaigns[this.props.dt.campaign])
        campaign = this.props.campaigns[this.props.dt.campaign];
      else campaign = this.props.campaigns;
    }
    var language =
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage;
    if (language.length > 2) {
      language = language.split("-")[0];
      language = language.split("_")[0];
    }

    var picture =
      this.props.user_infos.picture === "" ||
      this.props.user_infos.picture === null ||
      this.props.user_infos.picture === undefined ? (
        <Avatar
          className="avatar tw-h-10 tw-w-10 tw-rounded-full tw-bg-gray-400 tw-flex tw-items-center tw-justify-center"
          style={{
            color: "#fff",
            backgroundColor:
              "#" +
              getColorFromString(
                this.props.user_infos.firstname +
                  " " +
                  this.props.user_infos.lastname
              ),
            fontSize: "small",
            fontWeight: "bold",
          }}
        >
          {this.props.user_infos.firstname[0].toUpperCase() +
            this.props.user_infos.lastname[0].toUpperCase()}
        </Avatar>
      ) : (
        <img
          alt=""
          src={this.props.user_infos.picture}
          className="tw-h-10 tw-w-10 tw-rounded-full tw-bg-gray-400 tw-flex tw-items-center tw-justify-center filter-by-alt"
          data-filter-by="alt"
        />
      );

    var activity_text = "";
    var createSvg = "";
    var bblcolor = "";
    switch (this.props.dt.type) {
      case "note":
        bblcolor = "tw-bg-yellow-500";
        createSvg = (
          <svg
            className="tw-h-5 tw-w-5 text-white tw-bg-yellow-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
            />
          </svg>
        );
        activity_text = (
          <React.Fragment>
            <Link ///candidate/${this.props.dt.candidate}
              to={`/campaign/dashboard/${this.props.dt.campaign}`}
              data-filter-by="text"
              className="tw-text-blue-600"
            >
              <span data-filter-by="text" className="SPAN-filter-by-text">
                {" "}
                {T.translate("activity.created")}{" "}
              </span>
              {T.translate("activity.in")}{" "}
              <span className="tw-text-gray-900" data-filter-by="text">
                {campaign.jobTitle}
              </span>
            </Link>
          </React.Fragment>
        );
        break;
      case "move":
        bblcolor = "tw-bg-blue-500";
        createSvg = (
          <svg
            className="tw-h-5 tw-w-5 text-white tw-bg-blue-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
            />
          </svg>
        );
        activity_text = (
          <React.Fragment>
            <span data-filter-by="text" className="SPAN-filter-by-text">
              {T.translate("activity.moved")}{" "}
            </span>
            <Link
              to={`/campaign/dashboard/${this.props.dt.campaign}/candidate/${this.props.dt.candidate}`}
              data-filter-by="text"
              className="tw-text-blue-600"
            >
              {this.props.n_candidate.firstName +
                " " +
                this.props.n_candidate.secondName}
            </Link>{" "}
            {T.translate("activity.to")}{" "}
            <span className="tw-text-gray-900" data-filter-by="text">
              "
              {this.props.dt.step[0].toUpperCase() +
                this.props.dt.step.slice(1)}{" "}
              "
            </span>
            {T.translate("activity.in")}{" "}
            <span className="tw-text-gray-900" data-filter-by="text">
              {campaign.jobTitle}
            </span>
          </React.Fragment>
        );
        break;
      default:
        activity_text = "";
    }

    return (
      <li className="tw-relative tw-pb-3 tw-pt-3">
        <div className="tw-group tw-flex tw-justify-between tw-items-center">
          <Link
            to={`/campaign/dashboard/${this.props.dt.campaign}`}
            onClick={() => {
              if (this.props.dt.seen === false) this.updateNotificationSeen();
            }}
            className="tw--m-1 tw-p-1 tw-block"
          >
            <div
              className="tw-absolute tw-inset-0 group-hover:tw-bg-gray-200"
              aria-hidden="true"
            ></div>
            <div className="tw-p-2 tw-flex-1 tw-flex tw-items-center tw-min-w-0 tw-relative">
              <span className="tw-flex-shrink-0 tw-inline-block tw-relative">
                <div className="tw-relative">
                  {picture}

                  <span
                    className={`${bblcolor} tw-absolute tw--bottom-0.5 tw--right-1 tw-bg-gray-100 tw-rounded-tl tw-px-0.5 tw-py-px`}
                  >
                    {createSvg}
                  </span>
                </div>
                {this.props.dt.seen === false ? (
                  <span
                    className="tw-absolute tw-top-0 tw-right-0 tw-block tw-h-2.5 tw-w-2.5 tw-rounded-full tw-ring-2 tw-ring-white tw-bg-blue-400"
                    aria-hidden="true"
                  ></span>
                ) : (
                  ""
                )}
              </span>
              <div className="tw-ml-4">
                <div className="tw-flex tw-items-center tw-justify-between">
                  <p className="tw-text-base tw-font-medium tw-text-gray-900 ">
                    {this.props.user_infos.firstname[0].toUpperCase() +
                      this.props.user_infos.firstname.slice(1) +
                      " " +
                      this.props.user_infos.lastname[0].toUpperCase() +
                      this.props.user_infos.lastname.slice(1)}
                  </p>
                  <p className="tw-text-xs tw-text-gray-500">
                    {language === "fr"
                      ? T.translate("activity.ago") +
                        " " +
                        timeSince(new Date(this.props.dt.date.seconds * 1000))
                      : timeSince(new Date(this.props.dt.date.seconds * 1000)) +
                        " " +
                        T.translate("activity.ago")}
                  </p>
                </div>
                <p className="tw-text-sm tw-font-medium tw-text-gray-500">
                  {activity_text}
                </p>
              </div>
            </div>
          </Link>
        </div>
      </li>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect((props) => [
    {
      collection: "profiles",
      doc: props.dt.candidate,
      storeAs: "profile_" + props.dt.candidate,
    },
    {
      collection: "projects",
      doc: props.dt.campaign,
    },
    {
      collection: "users_infos",
      doc: props.dt.user,
    },
  ]),
  connect((state, props) => ({
    user_infos: state.firestore.data.users_infos[props.dt.user],
    n_candidate: state.firestore.data["profile_" + props.dt.candidate],
    campaigns: state.firestore.data.projects,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    notify: state.notify,
  }))
)(Notification);
