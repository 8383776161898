import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";

class ListApplications extends Component {
  render() {
    const { opportunity_infos } = this.props;

    return (
      <tr>
        <td>
          {opportunity_infos.application.firstname +
            " " +
            opportunity_infos.application.lastname}
        </td>
        <td>{opportunity_infos.application.email}</td>
        <td>{opportunity_infos.application.infos}</td>
        <td>{opportunity_infos.application.tel}</td>
        <td>
          <a
            data-toggle="modal"
            data-target="#application-validation"
            className="dropdown-item"
            href="#l"
            onClick={() => {
              this.props.setCurrentApplication(this.props.opportunity_id);
            }}
          >
            Accept
          </a>
          <a
            className="dropdown-item"
            href={`/candidate/edit/${opportunity_infos.profile}`}
          >
            See profile
          </a>
          <div className="dropdown-divider" />
          <a className="dropdown-item" href="#l">
            Delete
          </a>
        </td>
      </tr>
    );
  }
}

export default compose(
  firestoreConnect(),
  connect((state, props) => ({
    candidate_infos: state.firestore.data[props.candidate_id],
  }))
)(ListApplications);
