import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import Wait from "../../layout/Wait";
import Activty from "./Activity";
import T from "i18n-react";

class Activities extends Component {
  render() {
    if (!isLoaded(this.props.opportunity_infos)) {
      return <Wait />;
    }

    var activities =
      this.props.opportunity_infos === null ||
      this.props.opportunity_infos === undefined
        ? []
        : Object.keys(this.props.opportunity_infos);
    //activities = activities.reverse();
    //console.log("activities => ", activities);
    return (
      <>
        <div className="tw-bg-gray-100 tw-py-6">
          <div className="tw-max-w-7xl tw-mx-auto sm:tw-px-6 lg:tw-px-8">
            <div className="tw-bg-white tw-shadow sm:tw-rounded-lg sm:tw-overflow-hidden">
              <div className="tw-divide-y tw-divide-gray-200">
                <div className="tw-x-4 tw-py-5 sm:tw-px-6">
                  <h2
                    id="notes-title"
                    className="tw-text-lg tw-font-medium tw-text-gray-900"
                  >
                    {T.translate("activity.activity")}
                  </h2>
                </div>
                <div className="tw-py-8">
                  <div className="tw-max-w-xl tw-mx-auto px-6">
                    <div className="tw-flow-root">
                      <ul className="tw--mb-8">
                        {activities.length > 0 ? (
                          activities.map((id) => {
                            if (
                              this.props.opportunity_infos[id] !== null &&
                              this.props.opportunity_infos[id] !== undefined
                            ) {
                              if (
                                this.props.opportunity_infos[id].user ===
                                "Hived.ai"
                              )
                                this.props.opportunity_infos[id].user =
                                  "w2FJu9tlJjNLJ7z4dJio6WAf7Ct1"; //saad to switch to someone or Hived in UI
                              return (
                                <Activty
                                  key={id}
                                  activity={this.props.opportunity_infos[id]}
                                  activity_user={
                                    this.props.opportunity_infos[id].user
                                  }
                                  step={this.props.opportunity_infos[id].step}
                                  candidate={this.props.candidate_name}
                                  date={this.props.opportunity_infos[id].date}
                                />
                              );
                            }
                            return "";
                          })
                        ) : (
                          <li className="list-group-item">
                            {T.translate("activity.no_activity")}
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect((props) => [
    {
      collection: "campaign_candidate",
      doc: props.opportunity,
      subcollections: [{ collection: "history" }],
      orderBy: ["date", "desc"],
      limit: 10,
      storeAs: "opp_history_" + props.opportunity,
    },
  ]),
  connect((state, props) => ({
    opportunity_infos: state.firestore.data["opp_history_" + props.opportunity],
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    notify: state.notify,
  }))
)(Activities);
