import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
//import Create from "../organisation/Create";
import { notifyUser } from "../../actions/notifyActions";
import Opportunity from "./Opportunity";
import Wait from "../layout/Wait";
import { Redirect } from "react-router-dom";

class Reach extends Component {
  render() {
    if (!isLoaded(this.props.campaign_candidate)) {
      return <Wait />;
    }

    if (
      this.props.campaign_candidate === null ||
      this.props.campaign_candidate === undefined ||
      this.props.campaign_candidate[this.props.match.params.id_opportunity] ===
        null ||
      this.props.campaign_candidate[this.props.match.params.id_opportunity] ===
        undefined
    ) {
      return <Redirect to="/notfound" />;
    }

    return (
      <Opportunity
        opportunity={this.props.match.params.id_opportunity}
        opportunity_infos={
          this.props.campaign_candidate[this.props.match.params.id_opportunity]
        }
        campaign={
          this.props.campaign_candidate[this.props.match.params.id_opportunity]
            .campaign
        }
      />
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(props => [
    {
      collection: "campaign_candidate",
      doc: props.match.params.id_opportunity
    }
  ]),
  connect(
    (state, props) => ({
      auth: state.firebase.auth,
      campaign_candidate: state.firestore.data.campaign_candidate,
      notify: state.notify
    }),
    { notifyUser }
  )
)(Reach);
