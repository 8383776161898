import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
//import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
/* import { isLoaded } from "react-redux-firebase";
import T from "i18n-react";
import Wait from "../layout/Wait"; */
//import Create from "../organisation/Create";
import { notifyUser, setProgressBar } from "../../actions/notifyActions";
import Video from "twilio-video";
import { getToken } from "../../helpers/twilio";
/* const { createLocalVideoTrack } = require("twilio-video"); */

class VideoInterview extends Component {
  constructor(props) {
    super();
    this.state = {
      identity: null,
      roomName: "interview",
      previewTracks: null,
      localMediaAvailable: false,
      hasJoinedRoom: false,
      activeRoom: null,
      remoteHasJoined: false,
    };
    this.start = this.start.bind(this);
    this.connected = this.connected.bind(this);
    this.end = this.end.bind(this);
    this.removeTracks = this.removeTracks.bind(this);
    this.removeParticipantTracks = this.removeParticipantTracks.bind(this);
  }

  componentDidMount() {
    getToken(this.props.auth.uid, "interview").then((token) => {
      if (token.response !== undefined && token.response.token !== undefined) {
        this.setState({
          identity: this.props.auth.uid,
          token: token.response.token,
        });
      }
    });
    /* fetch("{Your_API_key}", { method: "GET" })
      .then(res => res.json())
      .then(response => {
        let { identity, token } = response;
        this.setState({
          identity,
          token
        });
      })
      .catch(err => console.log(err)); */
  }

  start() {
    let options = {
      name: this.state.roomName,
      audio: true,
      video: { width: 1080 },
    }; // To initialize connection to twilio
    /* if (this.state.previewTracks) {
      connectOptions.tracks = this.state.previewTracks;
      } */

    Video.connect(this.state.token, options).then(this.connected, (error) => {
      //console.error(error.message);
    });
  }

  // Add your video track
  addTracks(tracks, container) {
    tracks.forEach((track) => {
      if (track.track !== null) container.appendChild(track.track.attach());
      /* else console.log("undefined track => ", track); */
    });
  }

  // Add participant video track
  addParticipantTracks(participant, container) {
    var tracks = Array.from(participant.tracks.values());
    this.addTracks(tracks, container);
  }

  // After connection is success
  connected(room) {
    this.setState({
      activeRoom: room,
      localMediaAvailable: true,
      hasJoinedRoom: true,
    });
    var previewContainer = this.refs.localMedia; // Note: Twilio reasponse will give video object
    if (!previewContainer.querySelector("video")) {
      this.addParticipantTracks(room.localParticipant, previewContainer);
    }
    // Following functions are called based on the actions
    room.participants.forEach((participant) => {
      var previewContainer = this.refs.remoteMedia;

      participant.tracks.forEach((publication) => {
        if (publication.isSubscribed) {
          const track = publication.track;
          let divToAttach = track.attach();
          previewContainer.appendChild(divToAttach);
        }
      });

      participant.on("trackSubscribed", (track) => {
        let divToAttach = track.attach();
        divToAttach.class = "democlass";
        previewContainer.appendChild(divToAttach);
      });
    });

    room.on("participantConnected", (participant) => {
      /*       console.log(`Participant "${participant.identity}" connected`);
      
       */
      if (this.state.remoteHasJoined === false)
        this.setState({ remoteHasJoined: true });
      var previewContainer = this.refs.remoteMedia;
      participant.tracks.forEach((publication) => {
        if (publication.isSubscribed) {
          const track = publication.track;
          const div = document.createElement("div");
          div.id = participant.sid;
          div.innerText = participant.identity;
          div.appendChild(track.attach());
          previewContainer.appendChild(div);
        }
      });

      participant.on("trackSubscribed", (track) => {
        previewContainer.appendChild(track.attach());
      });
    });

    room.on("trackAdded", (track, participant) => {
      //console.log("track =>", track);
      var previewContainer = this.refs.remoteMedia;
      this.addTracks([track], previewContainer);
    });
    room.on("trackRemoved", (track, participant) => {
      this.log(participant.identity + " removed track: " + track.kind);
      this.removeTracks([track]);
    });
    room.on("participantDisconnected", (participant) => {
      this.state.activeRoom.disconnect();
    });
    room.on("disconnected", () => {
      if (this.state.previewTracks) {
        this.state.previewTracks.forEach((track) => {
          track.stop();
        });
      }
      this.removeParticipantTracks(room.localParticipant);
      room.localParticipant.tracks.forEach((publication) => {
        const attachedElements = publication.track.detach();
        attachedElements.forEach((element) => element.remove());
      });

      room.participants.forEach((publication) => {
        if (publication.track !== undefined && publication.track !== null) {
          const attachedElements = publication.track.detach();
          attachedElements.forEach((element) => element.remove());
        }
      });

      this.setState({
        activeRoom: null,
        hasJoinedRoom: false,
        localMediaAvailable: false,
      });
    });
  }

  // Function called when user click end button
  end() {
    this.state.activeRoom.disconnect();
    this.setState({ hasJoinedRoom: false, localMediaAvailable: false });
  }

  // Removing local track
  removeTracks(tracks) {
    tracks.forEach((track) => {
      if (track.track)
        track.track.detach().forEach((detachedElement) => {
          detachedElement.remove();
        });
    });
  }

  // Removing participant's track
  removeParticipantTracks(participant) {
    var tracks = Array.from(participant.tracks.values());
    this.removeTracks(tracks);
  }

  componentDidUpdate(pervProps) {}

  render() {
    /*     if (!isLoaded(this.props.users_infos)) {
      return <Wait />;
    } */
    /*     console.log("remote has joined => ", this.state.remoteHasJoined);
     */ return (
      <div className="container">
        {/* <div
          id="screen-share-btn-container"
          className="col-2 float-right text-right mt-2"
        >
          <button
            id="screen-share-btn"
            type="button"
            className="btn btn-lg"
            disabled=""
          >
            <i id="screen-share-icon" className="fas fa-share-square"></i>
          </button>
        </div> */}
        <div id="buttons-container" className="row justify-content-center mt-3">
          <div className="col-md-2 text-center">
            <button
              id="mic-btn"
              type="button"
              className="btn btn-block btn-dark btn-lg"
              disabled=""
            >
              <i id="mic-icon" className="fas fa-microphone"></i>
            </button>
          </div>
          <div className="col-md-2 text-center">
            <button
              id="video-btn"
              type="button"
              className="btn btn-block btn-dark btn-lg"
              disabled=""
            >
              <i id="video-icon" className="fas fa-video"></i>
            </button>
          </div>

          <div className="col-md-2 text-center">
            {this.state.hasJoinedRoom ? (
              <button
                id="exit-btn"
                type="button"
                className="btn btn-block btn-danger btn-lg"
                onClick={this.end}
              >
                <i id="exit-icon" className="fas fa-phone-slash"></i>
              </button>
            ) : (
              <button
                id="exit-btn"
                type="button"
                className="btn btn-block btn-success btn-lg"
                onClick={this.start}
              >
                Join
              </button>
            )}
          </div>
        </div>
        {this.state.hasJoinedRoom ? (
          <>
            <div className="row">
              <div className="col-7">
                {/* <div className="row">
                  <div id="local-stream-container" className="col">
                    <div id="mute-overlay" className="col">
                      <i id="mic-icon" className="fas fa-microphone-slash"></i>
                    </div>
                    <div id="no-local-media" className="col text-center">
                      <i id="user-icon" className="fas fa-user"></i>
                    </div>
                  </div>
                </div> */}
                <div
                  className="remote mt-5 row p-0 inter-video"
                  ref="remoteMedia"
                  id="remote-media"
                >
                  {!this.state.remoteHasJoined ? (
                    <div className="container">
                      <div className="mt-5 mb-5 row justify-content-md-center">
                        <h6 style={{ color: "#FFFFFF" }}>
                          Waiting for Saad to connect
                        </h6>
                      </div>
                    </div>
                  ) : (
                    " "
                  )}
                </div>
              </div>
              <div className="col-5">
                {/* <div className="row">
                  <div id="local-stream-container" className="col">
                    <div id="mute-overlay" className="col">
                      <i id="mic-icon" className="fas fa-microphone-slash"></i>
                    </div>
                    <div id="no-local-media" className="col text-center">
                      <i id="user-icon" className="fas fa-user"></i>
                    </div>
                  </div>
                </div> */}
                <div
                  className="local mt-5 inter-video row col-3 p-0"
                  ref="localMedia"
                  id="local-media"
                ></div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      /* <div className="maincontainer">
        {this.showLocalTrack}
        <div className="local">
          {this.state.hasJoinedRoom ? (
            <>
              <button onClick={this.end}> End </button>
              <div className="remote" ref="remoteMedia" id="remote-media" />
              <div className="local" ref="localMedia" id="local-media" />
            </>
          ) : (
            <button onClick={this.start}> Start </button>
          )}
        </div>
      </div> */
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  connect(
    (state, props) => ({
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      notify: state.notify,
      simplewebrtc: state.simplewebrtc,
    }),
    { notifyUser, setProgressBar }
  )
)(VideoInterview);
