import React from "react";
import T from "i18n-react";

const Demo = () => {
  var language =
    (navigator.languages && navigator.languages[0]) || navigator.language;
  if (language.length > 2) {
    language = language.split("-")[0];
    language = language.split("_")[0];
  }
  if (language && language === "fr")
    return (
      <React.Fragment>
        <div className="tw-relative tw-bg-gray-50 tw-pb-10 tw-px-4 sm:tw-px-6 lg:tw-pb-28 lg:tw-px-8">
          <div className="tw-absolute tw-inset-0">
            <div className="tw-bg-white tw-h-1/3 sm:tw-h-2/3"></div>
          </div>
          <div className="tw-relative tw-max-w-7xl tw-mx-auto">
            <div className="tw-text-center">
              <h2 className="tw-text-3xl tw-tracking-tight tw-font-extrabold tw-text-gray-900 sm:tw-text-4xl">
                {T.translate("landing.resources")}
              </h2>
              <p className="tw-mt-3 tw-max-w-2xl tw-mx-auto tw-text-xl tw-text-gray-500 sm:tw-mt-4">
                {T.translate("landing.blog")}
              </p>
            </div>
            <div className="tw-mt-12 tw-max-w-lg tw-mx-auto tw-grid tw-gap-5 lg:tw-grid-cols-3 lg:tw-max-w-none">
              <div className="tw-flex tw-flex-col tw-rounded-lg tw-shadow-lg tw-overflow-hidden">
                <div className="tw-flex-shrink-0">
                  <img
                    className="tw-h-48 tw-w-full tw-object-cover"
                    src="https://pro-hived-ai-s3storage.s3.amazonaws.com/2021/02/christin-hume-Hcfwew744z4-unsplash-2048x1365.jpg"
                    alt=""
                  />
                </div>
                <div className="tw-flex-1 tw-bg-white tw-p-6 tw-flex tw-flex-col tw-justify-between">
                  <div className="tw-flex-1">
                    <p className="tw-text-sm tw-font-medium tw-text-blue-600">
                      <a
                        href="https://blog.hived.ai/fr/comment-sourcer-les-bons-candidats/"
                        className="hover:tw-underline"
                      >
                        Article
                      </a>
                    </p>
                    <a
                      href="https://blog.hived.ai/fr/comment-sourcer-les-bons-candidats/"
                      className="tw-block tw-mt-2"
                    >
                      <p className="tw-text-xl tw-font-semibold tw-text-gray-900">
                        Comment “sourcer” les bons candidats
                      </p>
                      <p className="tw-mt-3 tw-text-base tw-text-gray-500">
                        Sourcer signifie trouver et contacter des candidats
                        actifs ou passifs qui ne postulent pas directement à nos
                        offres. C’est une tâche compliquée qui demande un
                        investissement sérieux en temps. Effectuée de la bonne
                        manière, elle{" "}
                      </p>
                    </a>
                  </div>
                  <div className="tw-mt-6 tw-flex tw-items-center">
                    <div className="tw-flex-shrink-0">
                      <a href="https://blog.hived.ai/fr/comment-sourcer-les-bons-candidats/">
                        <span className="tw-sr-only">Saad Zniber</span>
                        <img
                          className="tw-h-10 tw-w-10 tw-rounded-full"
                          src="https://saadzniber.com/static/me-1bf3935c1acfc863a47780fe6f4dcfd4.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="tw-ml-3">
                      <p className="tw-text-sm tw-font-medium tw-text-gray-900">
                        <a
                          href="https://blog.hived.ai/fr/comment-sourcer-les-bons-candidats/"
                          className="hover:tw-underline"
                        >
                          Saad Zniber
                        </a>
                      </p>
                      <div className="tw-flex tw-space-x-1 tw-text-sm tw-text-gray-500">
                        <span>6 min read</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tw-flex tw-flex-col tw-rounded-lg tw-shadow-lg tw-overflow-hidden">
                <div className="tw-flex-shrink-0">
                  <img
                    className="tw-h-48 tw-w-full tw-object-cover"
                    src="https://pro-hived-ai-s3storage.s3.amazonaws.com/2021/02/windows-hNiNxhUfCfQ-unsplash-scaled.jpg"
                    alt=""
                  />
                </div>
                <div className="tw-flex-1 tw-bg-white tw-p-6 tw-flex tw-flex-col tw-justify-between">
                  <div className="tw-flex-1">
                    <p className="tw-text-sm tw-font-medium tw-text-blue-600">
                      <a
                        href="https://blog.hived.ai/fr/comment-recruter-en-tant-que-fondateur/"
                        className="hover:tw-underline"
                      >
                        Article
                      </a>
                    </p>
                    <a
                      href="https://blog.hived.ai/fr/comment-recruter-en-tant-que-fondateur/"
                      className="tw-block tw-mt-2"
                    >
                      <p className="tw-text-xl tw-font-semibold tw-text-gray-900">
                        Comment recruter en tant que fondateur
                      </p>
                      <p className="tw-mt-3 tw-text-base tw-text-gray-500">
                        En tant que fondateur, faire les bons recrutements pour
                        votre startup est l’une des meilleures choses que vous
                        puissiez faire pour sa réussite. Cela étant dit, le
                        recrutement peut être décourageant, en particulier
                        lorsque votre
                      </p>
                    </a>
                  </div>
                  <div className="tw-mt-6 tw-flex tw-items-center">
                    <div className="tw-flex-shrink-0">
                      <a href="https://blog.hived.ai/fr/comment-recruter-en-tant-que-fondateur/">
                        <span className="tw-sr-only">Saad Zniber</span>
                        <img
                          className="tw-h-10 tw-w-10 tw-rounded-full"
                          src="https://saadzniber.com/static/me-1bf3935c1acfc863a47780fe6f4dcfd4.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="tw-ml-3">
                      <p className="tw-text-sm tw-font-medium tw-text-gray-900">
                        <a
                          href="https://blog.hived.ai/fr/comment-recruter-en-tant-que-fondateur/"
                          className="hover:tw-underline"
                        >
                          Saad Zniber
                        </a>
                      </p>
                      <div className="tw-flex tw-space-x-1 tw-text-sm tw-text-gray-500">
                        <span>6 min read</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tw-flex tw-flex-col tw-rounded-lg tw-shadow-lg tw-overflow-hidden">
                <div className="tw-flex-shrink-0">
                  <img
                    className="tw-h-48 tw-w-full tw-object-cover"
                    src="https://pro-hived-ai-s3storage.s3.amazonaws.com/2021/02/austin-distel-wD1LRb9OeEo-unsplash-2048x1536.jpg"
                    alt=""
                  />
                </div>
                <div className="tw-flex-1 tw-bg-white tw-p-6 tw-flex tw-flex-col tw-justify-between">
                  <div className="tw-flex-1">
                    <p className="tw-text-sm tw-font-medium tw-text-blue-600">
                      <a
                        href="https://blog.hived.ai/fr/comment-analyser-ses-process-de-recrutement/"
                        className="hover:tw-underline"
                      >
                        Article
                      </a>
                    </p>
                    <a
                      href="https://blog.hived.ai/fr/comment-analyser-ses-process-de-recrutement/"
                      className="tw-block tw-mt-2"
                    >
                      <p className="tw-text-xl tw-font-semibold tw-text-gray-900">
                        Comment analyser ses process de recrutement ?
                      </p>
                      <p className="tw-mt-3 tw-text-base tw-text-gray-500">
                        L’optimisation de vos processus de recrutement et la
                        mise en place d’une organisation bien huilée passe
                        d’abord par l’analyse de l’existant. Comprenez par là,
                        avoir une idée claire de la manière dont votre
                        entreprise trouve, attire, recrute, et “onboard” ses
                        nouveaux talents
                      </p>
                    </a>
                  </div>
                  <div className="tw-mt-6 tw-flex tw-items-center">
                    <div className="tw-flex-shrink-0">
                      <a href="https://blog.hived.ai/fr/comment-analyser-ses-process-de-recrutement/">
                        <span className="tw-sr-only">Saad Zniber</span>
                        <img
                          className="tw-h-10 tw-w-10 tw-rounded-full"
                          src="https://saadzniber.com/static/me-1bf3935c1acfc863a47780fe6f4dcfd4.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="tw-ml-3">
                      <p className="tw-text-sm tw-font-medium tw-text-gray-900">
                        <a
                          href="https://blog.hived.ai/fr/comment-analyser-ses-process-de-recrutement/"
                          className="hover:tw-underline"
                        >
                          Saad Zniber
                        </a>
                      </p>
                      <div className="tw-flex tw-space-x-1 tw-text-sm tw-text-gray-500">
                        <span>6 min read</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  else
    return (
      <React.Fragment>
        <div className="tw-relative tw-bg-gray-50 tw-pb-10 tw-px-4 sm:tw-px-6 lg:tw-pb-28 lg:tw-px-8">
          <div className="tw-absolute tw-inset-0">
            <div className="tw-bg-white tw-h-1/3 sm:tw-h-2/3"></div>
          </div>
          <div className="tw-relative tw-max-w-7xl tw-mx-auto">
            <div className="tw-text-center">
              <h2 className="tw-text-3xl tw-tracking-tight tw-font-extrabold tw-text-gray-900 sm:tw-text-4xl">
                {T.translate("landing.resources")}
              </h2>
              <p className="tw-mt-3 tw-max-w-2xl tw-mx-auto tw-text-xl tw-text-gray-500 sm:tw-mt-4">
                {T.translate("landing.blog")}
              </p>
            </div>
            <div className="tw-mt-12 tw-max-w-lg tw-mx-auto tw-grid tw-gap-5 lg:tw-grid-cols-3 lg:tw-max-w-none">
              <div className="tw-flex tw-flex-col tw-rounded-lg tw-shadow-lg tw-overflow-hidden">
                <div className="tw-flex-shrink-0">
                  <img
                    className="tw-h-48 tw-w-full tw-object-cover"
                    src="https://pro-hived-ai-s3storage.s3.amazonaws.com/2021/01/christin-hume-Hcfwew744z4-unsplash-2048x1365.jpg"
                    alt=""
                  />
                </div>
                <div className="tw-flex-1 tw-bg-white tw-p-6 tw-flex tw-flex-col tw-justify-between">
                  <div className="tw-flex-1">
                    <p className="tw-text-sm tw-font-medium tw-text-blue-600">
                      <a
                        href="https://blog.hived.ai/en/how-to-source-the-right-candidates/"
                        className="hover:tw-underline"
                      >
                        Article
                      </a>
                    </p>
                    <a
                      href="https://blog.hived.ai/en/how-to-source-the-right-candidates/"
                      className="tw-block tw-mt-2"
                    >
                      <p className="tw-text-xl tw-font-semibold tw-text-gray-900">
                        How to source the right candidates
                      </p>
                      <p className="tw-mt-3 tw-text-base tw-text-gray-500">
                        Sourcing means reaching out to active and passive
                        candidates who do not apply directly to our open roles.
                        It is a complicated and time-consuming task but done the
                        right way; it can lead you to{" "}
                      </p>
                    </a>
                  </div>
                  <div className="tw-mt-6 tw-flex tw-items-center">
                    <div className="tw-flex-shrink-0">
                      <a href="https://blog.hived.ai/en/how-to-source-the-right-candidates/">
                        <span className="tw-sr-only">Saad Zniber</span>
                        <img
                          className="tw-h-10 tw-w-10 tw-rounded-full"
                          src="https://saadzniber.com/static/me-1bf3935c1acfc863a47780fe6f4dcfd4.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="tw-ml-3">
                      <p className="tw-text-sm tw-font-medium tw-text-gray-900">
                        <a
                          href="https://blog.hived.ai/en/how-to-source-the-right-candidates/"
                          className="hover:tw-underline"
                        >
                          Saad Zniber
                        </a>
                      </p>
                      <div className="tw-flex tw-space-x-1 tw-text-sm tw-text-gray-500">
                        <span>6 min read</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tw-flex tw-flex-col tw-rounded-lg tw-shadow-lg tw-overflow-hidden">
                <div className="tw-flex-shrink-0">
                  <img
                    className="tw-h-48 tw-w-full tw-object-cover"
                    src="https://pro-hived-ai-s3storage.s3.amazonaws.com/2021/01/windows-hNiNxhUfCfQ-unsplash-scaled.jpg"
                    alt=""
                  />
                </div>
                <div className="tw-flex-1 tw-bg-white tw-p-6 tw-flex tw-flex-col tw-justify-between">
                  <div className="tw-flex-1">
                    <p className="tw-text-sm tw-font-medium tw-text-blue-600">
                      <a
                        href="https://blog.hived.ai/en/how-to-hire-as-a-founder/"
                        className="hover:tw-underline"
                      >
                        Article
                      </a>
                    </p>
                    <a
                      href="https://blog.hived.ai/en/how-to-hire-as-a-founder/"
                      className="tw-block tw-mt-2"
                    >
                      <p className="tw-text-xl tw-font-semibold tw-text-gray-900">
                        How to hire as a founder?
                      </p>
                      <p className="tw-mt-3 tw-text-base tw-text-gray-500">
                        As a founder, making the right hires for your startup is
                        one of the best things you can do for its success. That
                        being said, hiring can be daunting, especially when your
                        main focus is
                      </p>
                    </a>
                  </div>
                  <div className="tw-mt-6 tw-flex tw-items-center">
                    <div className="tw-flex-shrink-0">
                      <a href="https://blog.hived.ai/en/how-to-hire-as-a-founder/">
                        <span className="tw-sr-only">Saad Zniber</span>
                        <img
                          className="tw-h-10 tw-w-10 tw-rounded-full"
                          src="https://saadzniber.com/static/me-1bf3935c1acfc863a47780fe6f4dcfd4.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="tw-ml-3">
                      <p className="tw-text-sm tw-font-medium tw-text-gray-900">
                        <a
                          href="https://blog.hived.ai/en/how-to-hire-as-a-founder/"
                          className="hover:tw-underline"
                        >
                          Saad Zniber
                        </a>
                      </p>
                      <div className="tw-flex tw-space-x-1 tw-text-sm tw-text-gray-500">
                        <span>6 min read</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
};

export default Demo;
