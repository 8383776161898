import React, { Component } from "react";
import T from "i18n-react";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isLoaded } from "react-redux-firebase";
import ProfileImage from "./ProfileImage";
import { notifyUser } from "../../actions/notifyActions";
import { getLanguage } from "../../helpers/global";
import Wait from "../layout/Wait";

class UserInfos extends Component {
  constructor(props) {
    super(props);
    // Create refs
    this.firstname = React.createRef();
    this.lastname = React.createRef();
    this.email = React.createRef();
    this.location = React.createRef();
    this.bio = React.createRef();
    this.jobtitle = React.createRef();
    this.submit = React.createRef();
    this.language = React.createRef();
  }

  formHasError() {
    var hasError = false;
    if (this.firstname.current.value === "") {
      this.firstname.current.className = "form-control is-invalid";
      hasError = true;
    } else this.firstname.current.className = "form-control";
    if (this.lastname.current.value === "") {
      this.lastname.current.className = "form-control is-invalid";
      hasError = true;
    } else this.lastname.current.className = "form-control";

    if (this.email.current.value === "") {
      this.email.current.className = "form-control is-invalid";
      hasError = true;
    } else this.email.current.className = "form-control";
    if (this.location.current.value === "") {
      this.location.current.className = "form-control is-invalid";
      hasError = true;
    } else this.location.current.className = "form-control";
    if (this.bio.current.value === "") {
      this.bio.current.className = "form-control is-invalid";
      hasError = true;
    } else this.bio.current.className = "form-control";
    if (this.jobtitle.current.value === "") {
      this.jobtitle.current.className = "form-control is-invalid";
      hasError = true;
    } else this.jobtitle.current.className = "form-control";
    if (this.language.current.value === "") {
      this.language.current.className = "form-control is-invalid";
      hasError = true;
    } else this.language.current.className = "form-control";
    return hasError;
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { user_id, firestore } = this.props;
    const hasErrors = this.formHasError();
    const { firebase, notifyUser } = this.props;

    if (!hasErrors) {
      this.submit.current.setAttribute("disabled", "disabled");

      // Updated user
      const updUser = {
        firstname: this.firstname.current.value,
        lastname: this.lastname.current.value,
        location: this.location.current.value,
        bio: this.bio.current.value,
        jobtitle: this.jobtitle.current.value,
        email: this.email.current.value,
        language: this.language.current.value,
      };

      // Update client in firestore

      firestore
        .update({ collection: "users_infos", doc: user_id }, updUser)
        .then(() => {
          if (this.submit !== undefined)
            if (this.submit.current) {
              window.scrollTo(0, 0);
              this.submit.current.removeAttribute("disabled");
            }
          notifyUser(T.translate("settings.saved"), "success");
        });

      if (this.props.email !== this.email.current.value) {
        firebase
          .auth()
          .currentUser.updateEmail(this.email.current.value)
          .then(function () {})
          .catch(function (error) {
            switch (error.code) {
              case "auth/requires-recent-login":
                notifyUser(T.translate("settings.reloginError"), "error");
                firebase.logout();
                break;
              default:
                notifyUser(T.translate("settings.reloginError"), "error");
                firebase.logout();
            }
          });
      }
    } else {
      notifyUser(T.translate("settings.error"), "error");
      if (this.submit.current) {
        window.scrollTo(0, 0);
        this.submit.current.removeAttribute("disabled");
      }
    }
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    if (!isLoaded(this.props.users_infos)) {
      return <Wait />;
    }

    var users_infos = null;
    if (
      this.props.users_infos !== null &&
      this.props.users_infos !== undefined &&
      this.props.users_infos[this.props.user_id] !== null &&
      this.props.users_infos[this.props.user_id] !== undefined
    )
      users_infos = this.props.users_infos[this.props.user_id];
    //else return <Redirect to={{ pathname: "/settings" }} />;

    var language = getLanguage();

    const propsEmailValue = this.props.email;
    return (
      <div
        className="tab-pane fade show active"
        role="tabpanel"
        id="profile"
        aria-labelledby="profile-tab"
      >
        <div className="tw-antialiased tw-font-sans tw-bg-gray-200">
          <div className="">
            <div className="tw-bg-gray-100">
              <div className="tw-max-w-7xl tw-mx-auto tw-py-6 sm:tw-px-6 lg:tw-px-8">
                <div>
                  <div className="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6">
                    <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-3">
                      <form onSubmit={this.onSubmit}>
                        <div className="tw-shadow sm:tw-rounded-md sm:tw-overflow-hidden">
                          <div className="tw-px-4 tw-py-5 tw-bg-white tw-space-y-6 sm:tw-p-6">
                            <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                              <div className="tw-col-span-3 sm:tw-col-span-3">
                                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                                  {T.translate("settings.profile_pic")}
                                </label>
                                <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                                  <ProfileImage
                                    user_id={this.props.user_id}
                                    image={
                                      users_infos !== null &&
                                      users_infos !== undefined &&
                                      users_infos.picture !== undefined
                                        ? users_infos.picture
                                        : ""
                                    }
                                    location="images"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                              <div className="tw-col-span-3 sm:tw-col-span-3">
                                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                                  {T.translate("settings.name")}
                                </label>
                                <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                                  <input
                                    type="text"
                                    placeholder={T.translate("settings.name")}
                                    name="firstname"
                                    className="form-control"
                                    ref={this.firstname}
                                    defaultValue={
                                      users_infos !== undefined &&
                                      users_infos !== null &&
                                      users_infos.firstname !== undefined
                                        ? users_infos.firstname
                                        : ""
                                    }
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                              <div className="tw-col-span-3 sm:tw-col-span-3">
                                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                                  {T.translate("settings.last_name")}
                                </label>
                                <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                                  <input
                                    type="text"
                                    placeholder={T.translate(
                                      "settings.last_name"
                                    )}
                                    name="firstname"
                                    className="form-control"
                                    ref={this.lastname}
                                    defaultValue={
                                      users_infos !== undefined &&
                                      users_infos !== null &&
                                      users_infos.lastname !== undefined
                                        ? users_infos.lastname
                                        : ""
                                    }
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                              <div className="tw-col-span-3 sm:tw-col-span-3">
                                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                                  {T.translate("settings.job_title")}
                                </label>
                                <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                                  <input
                                    type="text"
                                    placeholder={T.translate(
                                      "settings.job_title"
                                    )}
                                    name="lastname"
                                    ref={this.jobtitle}
                                    className="form-control"
                                    defaultValue={
                                      users_infos !== undefined &&
                                      users_infos.jobtitle !== undefined
                                        ? users_infos.jobtitle
                                        : ""
                                    }
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                              <div className="tw-col-span-3 sm:tw-col-span-3">
                                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                                  {T.translate("settings.email")}
                                </label>
                                <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                                  <input
                                    type="email"
                                    placeholder={T.translate(
                                      "settings.email_hint"
                                    )}
                                    name="email"
                                    className="form-control"
                                    ref={this.email}
                                    defaultValue={
                                      propsEmailValue !== undefined &&
                                      propsEmailValue !== null
                                        ? propsEmailValue
                                        : ""
                                    }
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                              <div className="tw-col-span-3 sm:tw-col-span-3">
                                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                                  {T.translate("settings.location")}
                                </label>
                                <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                                  <input
                                    type="text"
                                    placeholder={T.translate(
                                      "settings.location_hint"
                                    )}
                                    name="location"
                                    className="form-control"
                                    ref={this.location}
                                    defaultValue={
                                      users_infos !== undefined &&
                                      users_infos.location !== undefined
                                        ? users_infos.location
                                        : ""
                                    }
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                              <div className="tw-col-span-3 sm:tw-col-span-3">
                                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                                  {T.translate("settings.language")}
                                </label>
                                <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                                  <select
                                    placeholder={T.translate(
                                      "project.language"
                                    )}
                                    name="language"
                                    className="form-control"
                                    ref={this.language}
                                    onChange={this.onChange}
                                    defaultValue={
                                      users_infos !== undefined &&
                                      users_infos.language !== undefined
                                        ? users_infos.language
                                        : language
                                    }
                                  >
                                    <option value="">
                                      {T.translate("project.language")}
                                    </option>
                                    <option value={"FR_fr"}>
                                      {T.translate("project.language_french")}
                                    </option>
                                    <option value="EN_en">
                                      {T.translate("project.language_english")}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                              <div className="tw-col-span-3 sm:tw-col-span-3">
                                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                                  {T.translate("settings.bio")}
                                </label>
                                <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                                  <textarea
                                    type="text"
                                    placeholder={T.translate(
                                      "settings.bio_hint"
                                    )}
                                    name="bio"
                                    className="form-control"
                                    rows="4"
                                    ref={this.bio}
                                    defaultValue={
                                      users_infos !== undefined &&
                                      users_infos.bio !== undefined
                                        ? users_infos.bio
                                        : ""
                                    }
                                    onChange={this.onChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="tw-px-4 tw-py-3 tw-bg-gray-50 tw-text-right sm:tw-px-6">
                              <button
                                type="submit"
                                ref={this.submit}
                                className="tw-inline-flex tw-justify-center tw-py-2 tw-px-4 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
                              >
                                {T.translate("settings.save")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserInfos.propTypes = {
  firestore: PropTypes.object.isRequired,
  user_id: PropTypes.string.isRequired,
  notify: PropTypes.object.isRequired,
  notifyUser: PropTypes.func.isRequired,
};

export default compose(
  firebaseConnect(),
  firestoreConnect((props) => [
    {
      collection: "users_infos", //"users_infos",
      doc: props.user_id, //"ToAdAEdT27bnSC8nEtrn7BqBVaI3"]
    },
  ]),
  connect(
    (state, props) => ({
      users_infos: state.firestore.data.users_infos,
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      notify: state.notify,
    }),
    { notifyUser }
  )
)(UserInfos);
